import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { CollectionV2 } from '@models/collection-v2';
import { TuneV2 } from '@models/tune-v2';
import { UserService } from '@services/user.service';
import { DatePipe } from '@angular/common';
// Purchasing
import { SnackbarService } from '@services/snackbar.service';
import { PaymentV2 } from '@app/models/payment-v2';
import { MatDialog } from '@angular/material/dialog';

import { DialogPurchaseComponent } from '@modules/_shared/ui/components/dialog-purchase/dialog-purchase.component';
import { ApiAmazingtunesV2Service } from '@app/services/api-amazingtunes-v2.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ApiDownloadService } from '@services/api-download.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';


@Component({
  selector: 'app-collection-card-v2',
  templateUrl: './collection-card-v2.component.html',
  styleUrls: ['./collection-card-v2.component.scss'],
  inputs: ['collection', 'hide_artist', 'list_parent']
})
export class CollectionCardV2Component implements OnInit {

  public collection:CollectionV2;
  public playlist: TuneV2[] = [];
  public hide_artist: boolean = false;
  public path:string;
  // For routing
  public type_url:any = {
    Single:'singles',
    Album:'albums',
    ExtendedPlayer:'eps', // hmmm....
    EP:'eps'
  };

  // For mainImageLabel
  public types:any = {
    Single:'Single',
    Album:'Album',
    ExtendedPlayer:'EP',
    EP:'EP'
  };

  public is_redirecting:boolean;
  public list_parent:string;

  public has_downloaded:boolean;
  public is_downloading:boolean;
  @ViewChild('downloadButton') downloadButton: ElementRef;

  constructor(private loadingIndicatorService:LoadingIndicatorService, private renderer:Renderer2,  private router:Router, private api_tunes:ApiAmazingtunesV2Service, private api_dl:ApiDownloadService, private snackbar:SnackbarService, private dialog: MatDialog, public datePipe:DatePipe, public userService: UserService ) { }

  ngOnInit(): void {

    // console.log('collection card:', this.collection.attributes.name, this.collection._is_pseudo_single, this.collection._tunes[0]);
    this.path = '/profile/'+this.collection.artist.attributes.permalink+'/'+this.type_url[this.collection.attributes.type]+'/'+this.collection.id;
    // Don't create a 1-tune-playlist from a 'pseudo_single' or it repeats itself. (As playlists are set to repeat in the player)
    if(!this.collection._is_pseudo_single){
      this.collection._tunes.forEach(tune => {
        this.playlist.push( tune );
      });
    }

    if(this.collection._tunes.length > 1){
      this.collection._router_link = this.path;
    } else {
      this.collection._router_link = '/profile/'+this.collection.artist.attributes.permalink+'/tunes/' + this.collection._tunes[0].id;
    }
  }

  buy(){
    console.log('Buy: ', this.collection.attributes.name);

    // dealt with in the template wrt pseudo singles
    // if(this.collection.attributes.distribution.preference !== 'sell'){
    //   this.snackbar.show('This '+this.collection.attributes.type+' is not available for sale.');
    //   return;
    // }

    let resource_sale:any = this.collection;
    if(this.collection._is_pseudo_single){
      resource_sale = this.collection._tunes[0];
    }

    // open dialog
    const dialogRef = this.dialog.open(DialogPurchaseComponent, {
      width: '500px',
      panelClass: 'dialogBuySubscription',
      data: { resource: resource_sale },
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(data => {
      // console.log('The Purchasing dialog was closed: ', data);
      if (data && data.resource) {
        // console.log('Start Purchase of ', data.resource);
        this.loadingIndicatorService.startLoading();
        this.api_tunes.purchaseResource(data.resource).subscribe((data:PaymentV2) => {
          // console.log('redirect to payment gateway: ', data);
          this.is_redirecting = true; // Show an indicator while we redirect.
          if (data.attributes.payment_approval_url) {
            // console.log('redirect user for payment to : ', data.attributes.payment_approval_url);
            window.open(data.attributes.payment_approval_url, '_self');
            return;
            // On returning from the payment gateway, the user is sent to /payments/:gateway/purchases to complete the transaction.
          }
        },
        (error)=> {
          console.log('Error: ', error);
          this.loadingIndicatorService.stopLoading();
          this.snackbar.show('There was an error initiating the purchase: '+error.message);
        });
      }
    });
  }


  downloadTune(tune:TuneV2) {
    // Downloads 'psuedo-single' which is just one tune.
    if(!this.userService.loggedIn()){
      this.snackbar.snackbarRef = this.snackbar.snackBar.open('You need to be logged in to download free tunes.','Log In', {
        duration:3000,
        horizontalPosition:'end',
        verticalPosition:'bottom'
      });
      this.snackbar.snackbarRef.onAction().subscribe(() => {
          if(typeof window !== 'undefined'){
            localStorage.setItem('return_path', '/profile/'+tune.artist.attributes.permalink+'/tunes/'+tune.id);
          }
          this.router.navigate(['/connect']);
      });
      return;
    }
    if (this.is_downloading || this.has_downloaded) {
      return;
    }
    if (this.list_parent !== 'user-downloads' && (tune.attributes.is_private || !tune.attributes.is_ready_to_stream || tune.attributes.distribution.preference !== 'free_download')) {
      this.snackbar.show('This tune is not available for free download.');
      return;
    }
    this.api_tunes.downloadTune(tune).subscribe((data) => {
      // console.log('Download tune info:', data.meta.download_url);
      if (data.meta && data.meta.download_url) {
        this.renderer.addClass(this.downloadButton.nativeElement, 'downloading');
        this.is_downloading = true;
        this.api_dl.download(data.meta.download_url, tune).subscribe(
          (progress_percentage) => {
            // console.log('download progress: ', progress_percentage);
            this.downloadButton.nativeElement.innerHTML = progress_percentage + '%';
          },
          (error) => {
            console.log('Download Error: ', error);
            this.is_downloading = false;
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.snackbar.show('Error downloading tune: ' + error.message);
          },
          () => {
            // console.log('Complete!');
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.downloadButton.nativeElement.innerHTML = 'done';
            this.is_downloading = false;
            this.has_downloaded = true;
            // do all tunes have .meta now?
            // This will swap the div/buttons
            // if (tune.meta) {
            //   tune.meta.download_url = data.meta.download_url;
            // } else {
            //   tune.meta = { download_url: data.meta.download_url }
            // }
          }
        );
      }
    },
    (error) => {
      console.log('Download Error!', error);
      this.snackbar.show(error.message, 'snackbarWarning');
    });
  }


}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TuneV2 } from '@models/tune-v2';
import { CollectionV2 } from '@models/collection-v2';

export interface DialogData {
  artist_tunes: TuneV2[];
  collection: CollectionV2;
}

/**
 * Dialog list of all an artist's tunes for Collection creation.
 */

@Component({
  selector: 'app-dialog-add-tunes',
  templateUrl: './dialog-add-tunes.component.html',
  styleUrls: ['./dialog-add-tunes.component.scss']
})
export class DialogAddTunesComponent implements OnInit {

  public artist_tunes: TuneV2[];
  public selected_tunes:TuneV2[];
  public collection: CollectionV2;

  constructor(
    public dialogRef: MatDialogRef<DialogAddTunesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // console.log('select tunes dialog data: ', data.artist_tunes);

    // PRIVATE TUNES SHOULD NOT BE ADDED TO COLLECTIONS.
    this.artist_tunes = data.artist_tunes.filter(tune => !tune.attributes.is_private);

    this.collection = data.collection;
    this.selected_tunes = [];
  }

  selected(event){
    // console.log('select tunes dialog selected: ', event.source._value);
    this.selected_tunes = event.source._value;
  }

  close(){
    this.dialogRef.close( {selected_tunes: this.selected_tunes} );
  }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { PlayerComponent } from './components/player/player.component';
import { UiModule } from '@modules/_shared/ui/ui.module';
import { PlayerEmbedComponent } from './components/player-embed/player-embed.component';

@NgModule({
  declarations: [
    PlayerComponent,
    PlayerEmbedComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule
  ],
  exports:[
    PlayerComponent,
  ],
})
export class PlayerModule { }

import { Component, OnInit, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { SnackbarService } from '@services/snackbar.service';
import { MessagingService } from '@services/messaging.service';
import { Platform } from '@angular/cdk/platform'; // for mobile browser detection
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-notification-bell',
  templateUrl: './show-notification-bell.component.html',
  styleUrls: ['./show-notification-bell.component.scss']
})
export class ShowNotificationBellComponent implements OnInit, OnDestroy, AfterContentInit {

  public allows_notifications: boolean = true;
  // User has notification for this show set or not.
  public has_notification: boolean = false;
  private has_notif_sub: Subscription;

  @Input('show_uuid') show_uuid: string;
  @Input('bell_title') bell_title?: string;

  constructor(private snackbar: SnackbarService, private messagingService: MessagingService, private platform: Platform) {
    // Desktop Safari should be available to support in macOS Ventura. Due around October 2022.
    // console.log('shownotibell: check messaging service');
    if (!this.messagingService.is_supported) { 
      this.allows_notifications = false;
      // console.log('shownotibell: NOPE');
    }
  }

  ngAfterContentInit(): void {
    if (this.allows_notifications) {
      const _token = this.messagingService.getStoredToken();
      if (_token) {
        this.messagingService.checkShowNotification(_token, this.show_uuid).subscribe(data => {
          if (data) {
            this.has_notification = true;
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    if (this.has_notif_sub) {
      this.has_notif_sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    // console.log('bell uuid:', this.show_uuid, this.bell_title);
    this.has_notif_sub = this.messagingService.hasNotificationEmitter.subscribe(data => {
      if (data) {
        // console.log('HAS NOTIFICATION:', data, this.show_uuid);
        if (data.uuid && data.uuid === this.show_uuid) {
          // console.log('THIS SHOW!!', data.has_notification);
          // update this just in case more appear on the same page (eg: Shell's show on the Schedule page and her two shows)
          this.has_notification = data.has_notification;
        }
      }
    });
  }

  toggleNotification() {
    // console.log('toggleNotification: ', this.has_notification, this.show_uuid);
    if (!this.has_notification) {
      this.messagingService.registerNotification(this.show_uuid).subscribe(
        (data) => {
          // this.has_notification = !this.has_notification;
          // the messagingService EventEmitter will now update this.
          this.snackbar.show('You will now receive a browser notification when this show starts', 'snackbarInfo');
        },
        (error) => {
          this.snackbar.show('Error registering notification', 'snackbarWarning');
          console.log('Error registering notification', error);
        });
    } else {
      this.messagingService.deleteNotification(this.show_uuid).subscribe(
        (data) => {
          // this.has_notification = !this.has_notification;
          // the messagingService EventEmitter will now update this.
          this.snackbar.show('Your browser notification for this show has been cancelled', 'snackbarInfo');
        },
        (error) => {
          this.snackbar.show('Error deleting notification', 'snackbarWarning');
          console.log('Error deleting notification', error);
        });
    }
  }

}

import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import { UserService } from '@services/user.service';
import { inject } from "@angular/core";

export function apiRequestInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

    const skipUrls: string[] = [
        'transloadit',
        'amazonaws',
        '/users/',
        '/radio/',
        '/charts/',
        '/subscriptions/',
        '/airplays',
        '/airings',
        '/me',
        'onair.json',
        'upcoming.json',
        '=user_private',
        '/likes',
        '/all',
        '/playlists',
        '/collections',
        '/payments',
        '/stream/',
        '.mp3',
        '/upload',
        'transloadit.com',
        'assemblies',
        '/resumable/',
        environment.search_proxy_host
    ]

    let _headers: HttpHeaders = new HttpHeaders();
    let addBypassHeader = false;
    let addBypassParam = false;

    const userService = inject(UserService)

    for (const skippUrl of skipUrls) {
        if (request.urlWithParams.includes(skippUrl) && !request.urlWithParams.includes('amazonaws') && !request.urlWithParams.includes('stream.amazingtunes')) {
            // console.log('Add ngsw-bypass PARAM for: ',skippUrl, request.urlWithParams);
            addBypassParam = true;
            break;
        }
        // Use header for amazonaws downloads
        if (request.urlWithParams.includes('amazonaws')) {
            addBypassHeader = true;
            break;
        }
    }

    // Search API - Make sure these never get cached by the SW.
    if (request.url.includes(environment.search_proxy_host)) {
        addBypassParam = true;
    }

    if (addBypassHeader) {
        // console.log('adding ngsw-bypass HEADER for: ', request.urlWithParams);
        // Docs: https://angular.io/guide/service-worker-devops#bypassing-the-service-worker
        _headers = _headers.append('ngsw-bypass', 'true');
        request = request.clone({
            headers: _headers
        });
    }

    if (addBypassParam) {
        // console.log('adding ngsw-bypass PARAMETER for: ', request.urlWithParams);
        let req_params = request.params;
        req_params = req_params.set('ngsw-bypass', 'true');
        request = request.clone({
            params: req_params
        });
    }

    if (request.url.includes('amazingtunes-ingest')) {
        // console.log('VIDEO UPLOAD BYPASS HEADER was added: ', request);   
        // Try adding bypass with header again...
        _headers = _headers.append('ngsw-bypass', 'true');
        request = request.clone({
            headers: _headers
        });
    }

    // Amazingtunes API
    if (request.url.includes(environment.api_endpoint_v2)) {
        if (userService.loggedIn() && userService.getJWToken()) {
            _headers = _headers.append('authorization', `Bearer ${userService.getJWToken()}`);
        }
        _headers = _headers.append('X-Site-Identifier', environment.site_country);
        request = request.clone({
            headers: _headers
        });
    }

    // Schedule API
    if (request.url.includes(environment.schedule_api_endpoint)) {
        // Schedule API now supports this.
        _headers = _headers.append('X-Site-Identifier', environment.site_country);
        // Clone request and apply headers. (Must be cloned due to request immutability)
        request = request.clone({
            headers: _headers,
        });
        // console.log('SCHEDULE API : REQUEST: ', request, request.urlWithParams);
    }

    // Wordpress API (Polling)
    if (request.url.includes('admin-ajax.php')) {
        // console.log('amazingradio WP-AJAX :REQUEST: ', request.urlWithParams);
        _headers = _headers.append('Cache-Control', 'no-cache');
        _headers = _headers.append('Pragma', 'no-cache');
        _headers = _headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
        // Add a header for the playlist polls if logged in.
        if (userService.loggedIn()) {
            _headers = _headers.append('X-Amazing', userService.get().attributes.permalink);
        }
        request = request.clone({
            headers: _headers,
            withCredentials: true
        });
        // console.log('amazingradio WP-AJAX :REQUEST: ', request);
    }

    // Wordpress API (JSON)
    if (request.url.includes('/wp-json/')) {
        // console.log('amazingradio WP-JSON:REQUEST: ', request.urlWithParams);
        // Add withCredentials for CORS etc. to requests to Wordpress REST API.
        request = request.clone({
            withCredentials: true,
        });
        if (request.url.includes('playlist-polls')) {
            // console.log('amazingradio playlist-polls REQUEST: ', request);
            // Add a header for the playlist polls if logged in.
            // Don't cache this.
            _headers = _headers.append('Cache-Control', 'no-cache');
            _headers = _headers.append('Pragma', 'no-cache');
            _headers = _headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
            if (userService.loggedIn()) {
                _headers = _headers.append('X-Amazing', userService.get().attributes.permalink);
                request = request.clone({
                    headers: _headers,
                    withCredentials: true
                });
                // console.log('logged in playlist-polls GET request: ', request);
            }
        }
    }

    return next(request);
}

import { Component, OnInit, OnChanges } from '@angular/core';

import { ApiAmazingradioWpService } from '@services/api-amazingradio-wp.service';

import { LatestSessionsService } from '@services/latest-sessions.service';

import { Post } from '@models/wp-post';


@Component({
  selector: 'app-latest-sessions-block',
  templateUrl: './latest-sessions-block.component.html',
  styleUrls: ['./latest-sessions-block.component.scss'],
  inputs: ['exclude', 'count']
})
export class LatestSessionsBlockComponent implements OnInit, OnChanges {

  public latest_sessions: Post[] = [];
  private sessions: Post[];
  public exclude: number = 0; // The ID of the session page it's being used on. Get set by the [exclude] binding.
  public count: number = 6;   // Default. Can be set in the [count] template binding.

  constructor(private latestSessionsService: LatestSessionsService) { }

  ngOnChanges():void {
    // console.log('ngOnChanges', this.exclude, this.sessions );
    this.filterSessions();
  }

  ngOnInit(): void {
    this.latestSessionsService.getLatestSessions().subscribe(data => {
      this.sessions = data;
      this.filterSessions();
    });
  }

  filterSessions(){
    if(!this.sessions){
      return;
    }
    this.latest_sessions = this.sessions.filter(item => {
      // Remove the post if it's the same as the post page it's on.
      return item.id !== this.exclude;
    });
    if(this.latest_sessions.length > 6){
      this.latest_sessions.splice(5, 1);
    }
  }
}

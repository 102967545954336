import { Component, OnInit, Inject, Renderer2, PLATFORM_ID, ElementRef, ViewChild, NgZone, AfterViewInit, EventEmitter, OnDestroy } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import { VoiceClipData } from '@models/voice-clips';
import { TuneV2, AudioItem, TuneUploadData, TransloaditData } from '@models/tune-v2';

import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SafeUrl } from '@angular/platform-browser';
import { SnackbarService } from '@services/snackbar.service';
import { saveAs } from 'file-saver';
import { trigger, state, style, transition, animate } from '@angular/animations';

import Uppy from '@uppy/core';
import Transloadit, { AssemblyResponse } from '@uppy/transloadit';

// declare var MediaRecorder;
// Mediarecorder Recorded type:
// iOS/Macos Safari   : audio/mp4
// Chrome (macos)     : audio/webm;codecs=opus
// Firefox (macos)    : audio/ogg; codecs=opus 

/**
 * Link about possible live radio stream visualisation.
 * https://stackoverflow.com/questions/73268782/javascript-audio-visualisation-in-safari-not-working-for-internet-radio-streams
 * ideas etc.: https://codepen.io/mrvii/pen/PoRamqQ
 * 
 */

export interface RecordingItem {
  time?: number;
  title?: string;
  date?: Date;
  duration?: number;
  audio_blob: Blob;
  audio_blob_url?: string;
  wave_blob?: Blob;
  wave_blob_url?: string;
  safe_wave_url?: SafeUrl;
  wave_data_uri?: string;
}

@Component({
  selector: 'app-recorder-uploader',
  templateUrl: './recorder-uploader.component.html',
  styleUrls: ['./recorder-uploader.component.scss'],
  inputs: ['resource', 'dialog'],
  animations: [
    trigger('fadeIn', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', animate(400)),
    ]),
  ]
})
export class RecorderUploaderComponent implements OnInit, AfterViewInit, OnDestroy {

  public tune?: TuneV2;
  public resource: any;
  public resource_type?: string;

  // parent dialog
  public dialog?: any;

  public show_example: boolean = false;

  // uploader
  @ViewChild('fileName', { static: false }) fileName: ElementRef;
  @ViewChild('fileDetails', { static: false }) fileDetails: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('dragArea', { static: false }) dragArea: ElementRef;
  @ViewChild('browse', { static: false }) browse: ElementRef;
  @ViewChild('progressFile', { static: false }) progressFile: ElementRef;
  @ViewChild('progressRec', { static: false }) progressRec: ElementRef;
  private progress?: ElementRef;

  // recorder
  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChild('canvasCont', { static: false }) canvasCont: ElementRef;
  @ViewChild('level', { static: false }) level: ElementRef;

  public recordingEvent = new EventEmitter<RecordingItem>();

  public is_mobile: boolean = false;
  private uploadCheckSub: Subscription;
  public progressVal: number;
  private upload_pre_data: TuneUploadData;
  private transloadit_id?: string | null;
  private transloadit_url?: string | null;

  public file: File;
  public files: Set<File> = new Set();
  private filesArray: Array<File> = [];

  public file_uploaded: boolean = false;
  public uploading: boolean = false;
  public check_selected_files: boolean = false;
  public is_ready_to_upload: boolean = false;

  public uploadSuccessful: boolean = false;
  public totalFilesize: number = 0;
  public filesInfo: string = '';
  public audio_item: AudioItem;
  public disable_upload_cancel: boolean = true;
  public disable_browse: boolean = false;

  public fileUploadedMessage: string = '';
  public accepted_audio_file_exts?: string[];
  public fileFormInputAccepts?: string;

  public option: string = 'upload';

  private mediaRecorder: any = null;
  public is_recording: boolean = false;
  public is_media_recorder_running: boolean = false;
  public recorded_type: string = '....';

  public audio_context: AudioContext;
  private AudioContext: any;
  private microphone: MediaStreamAudioSourceNode;
  private monitorNode: GainNode;
  private analyser: AnalyserNode;
  public waveContext: CanvasRenderingContext2D;
  private waveWidth: number;
  private waveHeight: number;
  private anim: any; // animFrame
  private waveCol: string = '#ede364';
  public wave_url: any;
  public status: string = 'ready';
  public duration: number = 0;
  public is_ready_to_play: boolean = false;
  public is_playing: boolean = false;
  public recordedItem?: RecordingItem | null;

  private _audio_player = new Audio();
  private is_paused: boolean = false;
  private has_played: boolean = false;
  public current_time: number = 0;

  private uppy?: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private platform: Platform,
    private api: ApiAmazingtunesV2Service,
    private ngZone: NgZone,
    private snackbar: SnackbarService,
    private renderer: Renderer2,
  ) {

    if (this.platform.IOS || this.platform.ANDROID) {
      this.is_mobile = true;
    }

    if (typeof window !== 'undefined' && window.AudioContext) {
      this.AudioContext = window.AudioContext
      // this.AudioContextPrototype = AudioContext.prototype;
      // console.log('AudioContext', this.AudioContext);

      this.recordingEvent.subscribe(data => {
        console.log('New recordingEvent: ', data);
        this.recordedItem = data;
        this.is_ready_to_play = true;
      });

    } else {
      console.log('Incompatible browser. No AudioContext');
    }

    // Get accepted file formats and build structure required for form input accept. 
    this.api.getSiteConfig().subscribe(data => {
      this.accepted_audio_file_exts = data.data.attributes.accepted_audio_file_exts;
      // add webm for MediaRecorder/Chrome
      this.accepted_audio_file_exts.push('webm')

      this.accepted_audio_file_exts.forEach((aaf, n) => {
        this.accepted_audio_file_exts[n] = '.' + aaf;
      })
      this.fileFormInputAccepts = this.accepted_audio_file_exts.join(', ');
      // console.log(this.fileFormInputAccepts);
    });

    // Test MediaRecorder support.

    // var types = [
    //   "video/webm",
    //   "audio/webm",
    //   "video/webm\;codecs=vp8",
    //   "video/webm\;codecs=h264",
    //   "audio/webm\;codecs=opus",
    //   "audio/m4a",
    //   "audio/mp4",
    //   "video/mp4",
    //   "audio/ogg"
    // ];

    // console.log('MediaRecorder', MediaRecorder);

    // for (var i in types) {
    //   console.log("Is " + types[i] + " supported? " + (MediaRecorder.isTypeSupported(types[i]) ? "Maybe!" : "Nope :("));
    // }

  }

  ngOnInit(): void {

    if (!this.resource) {
      this.snackbar.show('No resource provided');
      this.dialog.close();
    }
    // console.log('REC/UPL resource input:', this.resource);
    this.resource_type = this.resource.type;

  }

  ngOnDestroy(): void {
    if (this.uploading) {
      this.snackbar.show('Voice clip upload cancelled', 'snackbarWarning');
    }
  }

  // uploader 
  browseFiles() {
    if (this.uploading || this.disable_browse) {
      return;
    }
    // Triggers the hidden file input element browse button.
    this.fileInput.nativeElement.click();
  }

  selectedFiles(event: any) {
    if (this.uploading) {
      return;
    }
    if (event.target.files.length > 1) {
      this.snackbar.show('One file at a time please!', 'snackbarWarning', 6000);
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log('files: ', event.target.files[i]);
      // Check for audio files only. Using mime type.
      if (event.target.files[i].type.indexOf('audio/') !== 0) {
        console.log('NOT AUDIO!');
        this.snackbar.show('Audio files only please!', 'snackbarWarning', 6000);
        return;
      }
    }
    this.check_selected_files = true;

    this.audio_item = {};
    this.filesArray = [];
    this.files.clear();
    this.filesInfo = null;
    this.chosenFiles(event.target.files);

  }
  droppedFiles(event: any) {
    if (this.uploading) {
      return;
    }
    // console.log('DROPPED: ', event);
    this.filesInfo = null;
    this.files.clear();
    this.filesArray = [];

    event.preventDefault();
    event.stopPropagation();
    // Drag events
    // We seem to need to store these now for use after the timeout... ?
    var _el = this.dragArea.nativeElement;
    let _ren = this.renderer;
    // Un-highlight element.
    this.renderer.removeClass(this.dragArea.nativeElement, 'dragOver');
    // Dropped files list...
    if (event.dataTransfer.files.length > 1) {
      this.snackbar.show('One file at a time please!', 'snackbarWarning', 6000);
      return;
    }
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      // console.log('files: ', event.dataTransfer.files[i]);
      // Check for audio files only. Using mime type.
      if (event.dataTransfer.files[i].type.indexOf('audio/') !== 0) {
        this.renderer.addClass(this.dragArea.nativeElement, 'dragOverInvalid');
        setTimeout(function () {
          _ren.removeClass(_el, 'dragOverInvalid');
        }, 1000);
        this.snackbar.show('Audio files only please!', 'snackbarWarning', 6000);
        return; //  alert('Please select audio files only. (accepted types...)');
      }
    }
    this.chosenFiles(event.dataTransfer.files);
  }

  dragOver(event: any) {
    // Highlight element when holding files over it.
    // For some reason the files list is empty at this point, making it hard to check the mimetype beforehand.
    // Though this can be done *after* dropping files.. but it *should* work? Possibly not. Most bizarre.
    // edit: hmm: https://html.spec.whatwg.org/multipage/dnd.html#dndevents
    // console.log(event.dataTransfer.files);
    this.renderer.addClass(this.dragArea.nativeElement, 'dragOver');
  }

  dragLeave(event: any) {
    // Un-highlight element.
    this.renderer.removeClass(this.dragArea.nativeElement, 'dragOver');
  }

  chosenFiles(filesList: FileList) {
    // console.log('chosenFiles: ', filesList);
    // Either by drag/drop or browse.
    for (let key in filesList) {
      if (!isNaN(parseInt(key))) {
        this.filesArray.push(filesList[key]);
      }
    }
    // Fast de-duper. Overkill, but interesting ;)
    // https://jsperf.com/uniq-by-prop/
    const uniqeByProp_map = prop => arr =>
      Array.from(
        arr
          .reduce(
            (acc, item) => (
              item && item[prop] && acc.set(item[prop], item),
              acc
            ), // using map (preserves ordering)
            new Map()
          )
          .values()
      );
    const uniqeByProp = uniqeByProp_map('name');
    const dedupedFiles = uniqeByProp(this.filesArray);
    // console.log('deduped array: ', dedupedFiles);
    this.files.clear();
    this.totalFilesize = 0;
    dedupedFiles.forEach((file: File) => {
      this.totalFilesize += file.size;
      this.files.add(file);
    });
    // Update UI
    this.updateSelection();
    // console.log('files: ', this.files);
  }

  updateSelection() {
    this.check_selected_files = false;
    if (this.files.size === 0) {
      return;
    }
    this.audio_item = {};
    this.uploadSuccessful = false;
    this.disable_upload_cancel = false;

    this.renderer.addClass(this.browse.nativeElement, 'disabled');
    this.filesInfo = this.files.size + ' audio file' + (this.files.size === 1 ? '' : 's') + ' ready for upload';
    this.totalFilesize = 0;
    // this.files is a Set, so must be iterated like this. Some of this sort of thing will be used for multiples.
    this.files.forEach((file: File) => {
      this.totalFilesize += file.size;
    });

    this.audio_item.audio_blob = this.filesArray[0];
    this.audio_item.audio_blob_url = URL.createObjectURL(this.filesArray[0]);
    console.log('updateSelection(): total file size: ', this.totalFilesize, this.files);
    console.log('audio blob (File): ', this.audio_item.audio_blob);

    this._audio_player.ondurationchange = (data) => {
      console.log('duration: ', this._audio_player.duration, data);
      this.audio_item.duration = this._audio_player.duration;

      console.log('%cREADY TO UPLOAD....', 'color:orange;font-size:12px', this.audio_item);
      this.is_ready_to_upload = true;

    };

    this._audio_player.src = this.audio_item.audio_blob_url;
    console.log('load audio blob url', this.audio_item.audio_blob_url);
    this._audio_player.load();

    // console.log('%cREADY TO UPLOAD....', 'color:orange;font-size:12px');
    // this.is_ready_to_upload = true;
    // this.startUpload();
  }

  clearFiles() {
    // clear files and abort upload if uploading 
    this.is_ready_to_upload = false;

    this.audio_item = {};
    this.filesInfo = null;
    this.recordedItem = null;

    if (this.filesArray.length > 0 && (this.uploading || this.disable_upload_cancel)) {
      console.log('Abort upload... ', this.uploading);
      // Note: Uses 'takeUnitl' to stop the HTTPRequest
      // https://stackoverflow.com/questions/46068908/how-to-cancel-unsubscribe-all-pending-http-requests-angular-4/46158317

      this.files.clear();
      this.filesArray = [];
      this.snackbar.show('Upload cancelled');
    }
    // console.log('clearFiles', this.filesArray.length);
    this.uploading = false;
    this.uploadSuccessful = false;

    if (this.filesArray.length > 0) {
      this.renderer.removeClass(this.fileName.nativeElement, 'done');
      this.renderer.removeClass(this.fileDetails.nativeElement, 'done');
    }
    this.check_selected_files = false;
    this.filesArray = [];
    this.files.clear();
    this.disable_browse = false;
    this.file_uploaded = false;
    this.transloadit_id = null;
    this.transloadit_url = null;
    this.updateSelection();
    this.renderer.removeClass(this.browse.nativeElement, 'disabled');
    if (this.uploadCheckSub) {
      this.uploadCheckSub.unsubscribe();
    }
  }

  isMobile() {
    return this.is_mobile;
  }

  setOption(option: string) {

    if (this.is_playing || this.is_recording || this.is_ready_to_upload || this.is_ready_to_play) {
      return;
    }

    if (this.is_recording) {
      this.snackbar.show('Please stop recording before choosing to upload a file.');
      return;
    }

    if (this.is_ready_to_upload) {
      this.clearFiles();
    }

    this.option = option;
    // required to define ViewChild ElementRefs again after swtiching.
    if (this.option === 'record') {
      // Timeout required
      setTimeout(() => {
        this.ngAfterViewInit();
      }, 2)
    } else {
      // clear any previous recording..
      this.is_ready_to_play = false;
      this.duration = 0;
      this.recordedItem = null;
      this.is_paused = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.option !== 'record') {
      return;
    }
    // console.log(this.canvas.nativeElement.offsetHeight)
    this.updateWaveCanvasContext();
    // console.log('waveContext:', this.waveContext);
    // draw mid line
    this.waveContext.beginPath();
    this.waveContext.moveTo(0, this.waveHeight / 2);
    this.waveContext.lineTo(this.waveWidth, this.waveHeight / 2);
    this.waveContext.lineWidth = 2;
    this.waveContext.strokeStyle = '#888888';
    this.waveContext.stroke();
  }

  toggleRecording() {
    this.is_recording = !this.is_recording;
    this._toggleMediaRecorder();
  }

  async _toggleMediaRecorder() {
    // console.log('toggleMediaRecorder');
    if (this.is_media_recorder_running) {
      if (this.anim) {
        cancelAnimationFrame(this.anim);
      }
      this.waveContext.clearRect(0, 0, this.waveWidth, this.waveHeight * 2.2);
      this.mediaRecorder.stop();
      this.is_media_recorder_running = false;
      this.level.nativeElement.style.width = '0%';

      if (this.microphone) {
        this.microphone.mediaStream.getTracks()[0].stop(); // This will fully close the device connection and stop the mic use indicator.   
        this.microphone.disconnect();
      }
      // this.newAudioContext();
      console.log('Recorder stopped');
      this.status = 'recording stopped';
      return;
    }

    this.is_ready_to_play = false;
    this.canvasCont.nativeElement.style.backgroundImage = 'none';

    let stream;
    const constraints = { video: false, audio: { sampleSize: 16, channelCount: 1, sampleRate: 48000 } };
    try {
      // const supported = navigator.mediaDevices.getSupportedConstraints();
      // console.log('supported constraints:', supported);
      stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (!stream) {
        this.status = 'No mic permission. reload and try again';
        return;
      }

      this.newAudioContext();

      // AudioWorklet - not using now
      // this.visualiserNode = await this.setupVisualiserWorkletNode();

      this.audio_context.resume();

      this.microphone = this.audio_context.createMediaStreamSource(stream);

      // console.log('Mic connected:', this.microphone);

      // Setup analyser for live waveform drawing.
      this.analyser = this.audio_context.createAnalyser();
      this.analyser.smoothingTimeConstant = 0.3;
      this.analyser.fftSize = 1024;

      // needs headphones! 
      this.monitorNode = this.audio_context.createGain();
      // Leave audio volume at zero by default.
      this.monitorNode.gain.value = 0;

      this.microphone                             // Media stream source
        .connect(this.analyser)                   // draws live waveform
        // .connect(this.visualiserNode)          // shows levels - via AudioWorklet node
        .connect(this.monitorNode)                // stops feedback
        .connect(this.audio_context.destination); // required
    } catch (error) {
      throw new Error(`
      MediaDevices.getUserMedia() threw an error. 
      Stream did not open.
      ${error.name} - 
      ${error.message}
    `);
    }

    // this.mediaRecorder = new MediaRecorder(stream, { bitsPerSecond: 128000 });
    this.mediaRecorder = new MediaRecorder(stream);

    this.mediaRecorder.addEventListener('stop', (e) => {
      console.log('mediaRecorder : AEV : ONSTOP', e);
    }, false);

    // Will fire when recording is stopped.
    this.mediaRecorder.addEventListener('dataavailable', async (e) => {
      console.log('MediaRecorder data available', e);
      console.log('DATA:', e.data.type);
      console.log('SIZE:', e.data.size);

      this.recorded_type = e.data.type.split(';')[0];
      const _blob: Blob = e.data as Blob;
      const _array_buffer = await this.blobToArrayBufferPromise(_blob)
      console.log('_array_buffer', _array_buffer);
      const _decoded_audio_buffer = await this.audio_context.decodeAudioData(_array_buffer);
      console.log('_decoded_audio_buffer', _decoded_audio_buffer);
      console.log('_decoded_audio_buffer.duration: ', _decoded_audio_buffer.duration);

      this.duration = _decoded_audio_buffer.duration;
      // console.log('size: ' + audio_buffer.length);
      console.log('_decoded_audio_buffer channels : ', _decoded_audio_buffer.numberOfChannels);
      console.log('_decoded_audio_buffer sample rate: ', _decoded_audio_buffer.sampleRate);

      const _png_waveform = await this.drawWaveformPromise(_decoded_audio_buffer, this.waveCol, 600, this.waveHeight);

      // console.log('PNG WAVEFORM', _png_waveform);
      // console.log('%cPNG blob ok', '#ff9900');
      // console.log('%cwaveform blob type : ' + _png_waveform.type, 'color:#ff9900');
      // console.log('%cwaveform blob size : ' + _png_waveform.size, 'color:#ff9900');

      const url = URL.createObjectURL(_blob);

      this.wave_url = URL.createObjectURL(_png_waveform);

      this.canvasCont.nativeElement.style.background = 'url(' + this.wave_url + ')';
      this.canvasCont.nativeElement.style.backgroundSize = '100% 100%';
      this.canvasCont.nativeElement.style.backgroundRepeat = 'no-repeat';
      this.canvasCont.nativeElement.style.backgroundPosition = 'center';
      this.canvasCont.nativeElement.style.backgroundColor = 'black';

      this.recordedItem = {
        audio_blob: _blob,
        wave_blob: _png_waveform,
        audio_blob_url: url,
        wave_blob_url: this.wave_url,
        duration: this.duration
      }

      console.log('RECORDED ITEM:', this.recordedItem);
      this.is_ready_to_play = true;
    }, false);

    this.level.nativeElement.style.left = '0%';
    // this.mediaRecorder.start(1000); // with timeslice - delivers Blobs ondataavailable
    this.mediaRecorder.start();

    console.log('Recorder started');
    this.is_media_recorder_running = true;
    this.status = 'recording...';
    this.duration = 0;
    // Start drawing live waveform
    this.ngZone.runOutsideAngular(() => {
      this.draw();
    });
  }


  draw() {
    // Draws waveform image during recording
    this.waveContext.clearRect(0, 0, this.waveWidth, this.waveHeight * 2);
    let dataArray = new Uint8Array(this.analyser.frequencyBinCount); // analyser.fftSize / 2
    this.analyser.getByteTimeDomainData(dataArray);
    // console.log(dataArray);
    // console.log('DRAW:', y.length);
    this.waveContext.lineWidth = 2;
    this.waveContext.strokeStyle = '#ede364';
    this.waveContext.beginPath();
    const sliceWidth = (this.waveWidth * 1.0) / dataArray.length;
    let x = 0;
    for (let i = 0; i < dataArray.length; i++) {
      let v = dataArray[i] / 128.0;
      let y = (v * this.waveHeight) / 2;
      if (i === 0) {
        this.waveContext.moveTo(x, y);
      } else {
        this.waveContext.lineTo(x, y);
      }
      x += sliceWidth;
    }
    this.waveContext.lineTo(this.waveWidth, this.waveHeight / 2);
    this.waveContext.stroke();
    // loop
    this.anim = this.ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
      this.draw();
    }));
  }

  newAudioContext() {
    try {
      if (this.audio_context) {
        this.audio_context.close();
        this.audio_context = null;
      }
      this.audio_context = new this.AudioContext();
      this.audio_context.resume();
      console.log('newAudioContext: ', this.audio_context);
    } catch (e) {
      console.log(e);
      setTimeout(() => {
        alert('Incompatible Browser?\n\nTry Chrome, Safari, Firefox or Edge');
      }, 500);
      return;
    }
  }

  blobToArrayBufferPromise(blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // reader.onprogress = (e) => {
      //   // console.log('blobToArrayBuffer progress : ', e);
      // };
      reader.onload = (e) => {
        console.log('blobToArrayBuffer done : ', e);
        resolve(reader.result);
      };
      reader.onerror = (e) => {
        console.log('blobToArrayBuffer error', e);
        reject(e)
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  drawWaveformPromise(audio_buffer, col, w, h): Promise<any> {
    // Draws waveform image after recording
    return new Promise((resolve, reject) => {
      col = col || '#ede364';
      w || 1000;
      h || 1000;
      let canvas_wave = document.createElement('canvas');
      canvas_wave.width = w;
      canvas_wave.height = h;
      let wave_ctx = canvas_wave.getContext('2d');
      wave_ctx.fillStyle = col;
      const data = audio_buffer.getChannelData(0); // left only for now
      const step = Math.ceil(data.length / w);
      const amp = h / 2;
      for (let i = 0; i < w; i++) {
        let min = 1.0;
        let max = -1.0;
        for (let j = 0; j < step; j++) {
          const datum = data[(i * step) + j];
          if (datum < min)
            min = datum;
          if (datum > max)
            max = datum;
        }
        wave_ctx.fillRect(i, (1 + min) * amp, 1, Math.max(1, (max - min) * amp));
      }
      // create a PNG from the canvas
      canvas_wave.toBlob((blob) => {
        // console.log('rendered waveform image blob: ', blob);
        canvas_wave = null;
        wave_ctx = null;
        audio_buffer = null;
        resolve(blob);
      }, 'image/png');
    });
  }

  resetRecording() {
    this.is_ready_to_play = false;
    this.is_ready_to_upload = false;
    this.duration = 0;
    this.recordedItem = null;
    this.is_paused = false;
    this.level.nativeElement.style.left = '0%';
    this.level.nativeElement.style.width = '0%';
    this.canvasCont.nativeElement.style.backgroundImage = 'none';
    this.transloadit_id = null;
    this.transloadit_url = null;
    this.ngAfterViewInit();
  }

  togglePlay() {
    if (this.is_playing) {
      this._audio_player.pause();
      console.log('paused');
      this.is_playing = false;
      this.is_paused = true;
      return;
    }

    if (this.is_paused) {
      this._audio_player.play();
      console.log('unpause');
      this.is_paused = false;
      return;
    }

    if (this.recordedItem && this.recordedItem.audio_blob) {
      this.playBlob(this.recordedItem.audio_blob);
    }
  }

  downloadRecording() {
    if (!this.recordedItem?.audio_blob) {
      return;
    }
    console.log('Download intro for:', this.resource?.attributes?.name);

    const ext = this.recordedItem.audio_blob.type.split(';')[0].split('/')[1];
    saveAs(this.recordedItem.audio_blob, this.resource?.attributes?.name.split(' ').join('_') + '_intro.' + ext);

  }

  playBlob(_blob: Blob) {

    // establish recorded duration.. (webm has an issue where the metadata is incorrect)
    // .webm  blobs generated from the MediaRecorder API in Chromium-based browsers are missing the duration metadata
    // https://formidable.com/blog/2022/screen-webcam-mixing-recording/

    this.level.nativeElement.style.width = '2px';

    this._audio_player.volume = 1.0;
    this._audio_player.onplaying = () => {
      console.log('playing');
      this.status = 'playing';
      this.is_playing = true;
    };

    this._audio_player.onplay = () => {
      console.log('play');
      this.status = 'play';
      this.is_playing = true;
    };

    this._audio_player.onended = () => {
      this.status = 'stopped';
      console.log('playback stopped');
      this.is_playing = false;
      this.current_time = 0;
      this.level.nativeElement.style.left = '0%';
      setTimeout(() => {
        this.status = 'ready';
      }, 1000);

    };

    // _audio_player.ontimeupdate = (data) => {
    //   //console.log('timeupdate:', data);
    //   console.log('currentTime', _audio_player.currentTime, this.duration);
    // };

    this._audio_player.addEventListener('timeupdate', (data) => {
      // console.log('currentTime', this._audio_player.currentTime, this.duration);
      this.current_time = this._audio_player.currentTime;
      this.has_played = true;
      this.level.nativeElement.style.left = (this._audio_player.currentTime / this.duration) * 100 + '%';

    }, false);


    // Apparently known Chrome Webm bug with Infinity duration. 
    // So we'll use the one from the audio_buffer earlier. 

    this._audio_player.onloadeddata = (data) => {
      console.log('audio loadeddata: duration:', this._audio_player.duration);
      this.status = JSON.stringify(data);
      this._audio_player.play();
    };


    this._audio_player.oncanplaythrough = (data: any) => {
      // console.log('audio canplaythrough', data.target?.duration);
      console.log('DURATION:', this.duration);
      // Infinity? Use duration from decoded audio_buffer
      this.status = 'ready';
      this.is_ready_to_play = true;
    };

    this._audio_player.onerror = (err) => {
      console.log('audio error: ', err);
      this.status = JSON.stringify(err);
    };
    this._audio_player.src = URL.createObjectURL(_blob);
    console.log('load audio blob', _blob);
    this._audio_player.load();
  }

  updateWaveCanvasContext() {
    this.waveContext = this.canvas.nativeElement.getContext('2d');
    this.waveContext.imageSmoothingEnabled = false;
    this.waveHeight = this.waveContext.canvas.height;
    this.waveWidth = this.waveContext.canvas.width;
  }

  calculateTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

  secsToTime(value: number): string {
    let min = Math.floor(value / 60)
    let sec = Math.round(value % 60)
    return min + (sec >= 10 ? ":" : ":0") + sec;
  }

  // Upload 

  startUpload() {
    if (this.uploading) {
      console.log('already uploading');
      return;
    }
    if (this.recordedItem) {
      console.log('start upload of recording: ', this.recordedItem);
      const _type = this.recordedItem.audio_blob.type.split(';')[0].split('/')[1];
      const recordedFile = new File([this.recordedItem.audio_blob], `VOICE_CLIP-${this.resource.type}-${this.resource.id}.${_type}`, {
        type: this.recordedItem.audio_blob.type,
      });
      console.log('File', recordedFile);
      this.filesArray.push(recordedFile);
      this.audio_item = {
        audio_blob: recordedFile,
        audio_blob_url: this.recordedItem.audio_blob_url,
        duration: this.recordedItem.duration,
      }
      this.progress = this.progressRec;
    } else {
      this.progress = this.progressFile;
    }

    if (!this.audio_item) {
      this.snackbar.show('No audio item?', 'snackbarWarning');
      return;
    }
    console.log('start upload:', this.audio_item);

    // get the upload params ... 
    this.api.getVoiceClipUploadParams().subscribe((data) => {
      console.log('VC params for Transloadit: ', data);

      this.upload_pre_data = data;
      const upload_data: TransloaditData = {
        file: this.audio_item.audio_blob,
        params: JSON.stringify(data.params),
        signature: data.signature,
        upload_url: this.upload_pre_data.upload_url
      };

      this.uploadFile(upload_data);

    },
      (error) => {
        console.log('getVoiceClipUploadParams error.');
        this.snackbar.show(error.message);
      });
  }

  uploadFile(upload_data: TransloaditData) {
    if (typeof window === "undefined") {
      return;
    }

    console.log('%cStarting upload:', 'font-size:14px;color:green', upload_data);

    // this.disableButtons();

    this.filesInfo = 'Uploading ...';
    this.snackbar.snackBar.open('Uploading file ...', null, { panelClass: 'snackbarBusy', horizontalPosition: 'end', verticalPosition: 'bottom' });

    this.renderer.removeClass(this.progress.nativeElement, 'done');
    this.renderer.addClass(this.progress.nativeElement, 'uploading');

    this.uploading = true;
    this.progressVal = 0;

    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: this.accepted_audio_file_exts
      }
    }).use(Transloadit, {
      waitForEncoding: true,
      assemblyOptions: {
        signature: this.upload_pre_data.signature,
        params: {
          auth: { key: this.upload_pre_data.params.auth.key, expires: this.upload_pre_data.params.auth.expires },
          template_id: this.upload_pre_data.params.template_id,
        },
      },
    }).on('progress', (value) => {

      this.progressVal = value;
      console.log('Upload progress percentage: ' + this.progressVal + '%');
      this.filesInfo = `Uploading: ${this.progressVal}%`;
      this.renderer.setStyle(this.progress.nativeElement, 'width', `${this.progressVal}%`);
    }).on('transloadit:upload', (file, assembly) => {
      console.log('transloadit:upload', file, assembly)
      // file uploaded.. processing begins...
      this.uploadSuccessful = true;
      this.snackbar.snackBar.open('Processing upload ... ', null, { panelClass: 'snackbarBusy', horizontalPosition: 'end', verticalPosition: 'bottom' });
      this.renderer.addClass(this.progress.nativeElement, 'waiting');
      this.filesInfo = 'Completing. Please wait a moment ... ';

      this.transloadit_id = assembly.assembly_id;
      this.transloadit_url = assembly.assembly_ssl_url;

      this.snackbar.snackBar.open('Processing upload ... ', null, { panelClass: 'snackbarBusy', horizontalPosition: 'end', verticalPosition: 'bottom' });
      this.uploadSuccessful = true;
      console.log('Check for Transloadit response ..');
      this.renderer.addClass(this.progress.nativeElement, 'waiting');
      this.filesInfo = 'Completing. Please wait a moment ... ';

      // Start testing for Transloadit execution..
      this.testUploadReady(this.transloadit_url);
    })
      .on('transloadit:assembly-executing', (assembly) => {
        console.log('transloadit:assembly-executing', assembly.execution_start, assembly.execution_duration)
      })
      .on('transloadit:result', (stepName, result, assembly) => {
        console.log('transloadit:result', stepName, result, assembly)
      })
      .on('transloadit:complete', (assembly: AssemblyResponse) => {
        console.log('transloadit:complete', assembly)
        if (assembly.ok === 'ASSEMBLY_COMPLETED') {
          console.log('upload and assembly completed')
        } else {
          console.log('upload/assembly failed?', assembly)
        }
      })
      .on('error', (error) => {
        console.error('upload error', error);

        console.log('VOICE CLIP UPLOAD ERROR: ', error);
        this.snackbar.show('Voice Clip Upload Error: ' + error.name + ' - Details: ' + error.details + ' ' + error.message, 'snackbarWarning');
      })
      .addFile({
        name: upload_data.file.name,
        type: upload_data.file.type,
        data: upload_data.file
      })

  }

  testUploadReady(transloadit_url: string) {
    // console.log('testUploadReady: ', transloadit_url);
    this.uploadCheckSub = this.api.checkTransloaditUploadStatus(transloadit_url)
      .subscribe({
        next: (data) => {
          // console.log('UPLOAD STATUS:', data.ok, data.message);
          if (data.ok === 'ASSEMBLY_EXECUTING') {
            console.log('still processing... ');
            setTimeout(() => {
              this.testUploadReady(transloadit_url);
            }, 2500);
            return;
          }
          // console.log('Voice clip upload successful.');

          this.file_uploaded = true;
          this.uploading = false;

          let snd = new Audio();
          snd.src = '/assets/ding.m4a';
          snd.play();

          this.filesInfo = 'Upload successful.';

          this.renderer.removeClass(this.progress.nativeElement, 'waiting');
          this.renderer.removeClass(this.progress.nativeElement, 'uploading');
          this.renderer.setStyle(this.progress.nativeElement, 'width', '0%');

          // Now create the Voice Clip..
          let _voice_clip: VoiceClipData = {
            data: {
              type: 'voice_clip',
              attributes: {
                category: 'track_intro',
                transloadit_id: this.transloadit_id,
                description: `Intro: ${this.resource.attributes.name}`
              },
              relationships: {
                tune: {
                  data: {
                    id: this.resource.id,
                    type: 'tune'
                  }
                }
              }
            }
          }
          // Check if this tune is on behalf of a Label Artist
          if (this.resource?._is_manager) {
            _voice_clip.data.relationships.user = {
              data: {
                type: 'user',
                id: this.resource.artist.id,
              }
            }
          }
          this.api.createVoiceClip(_voice_clip).subscribe(data => {
            // console.log('New voice clip created:', data);
            this.snackbar.show('Your track intro has been created.');
            this.dialog.close(data);
          },
            (error) => {
              console.log('Error creating voice_clip:', error);
              this.snackbar.show('Error creating Voice Clip', 'snackbarWarning');
            });
        }
      });
  }

  toggleExample() {
    this.show_example = !this.show_example;
  }

}


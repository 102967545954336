import { Component, OnInit } from '@angular/core';

// :: Experimental ::
/*
  This will be used as a custom element (registered in app.module.ts) to replace <router-link> tags from
  Wordpress post links to amazingradio profiles.
  The a href link replacement happens in the wp-api service in the replaceAmazingLinks() method
  after getting a post (or tip/session/etc.)
*/

@Component({
  selector: 'app-router-link',
  templateUrl: './router-link.component.html',
  styleUrls: ['./router-link.component.scss'],
  inputs: ['path', 'text', '_class']
})
export class RouterLinkComponent implements OnInit {

  public path: string;
  public text: string;
  public _class: string;

  constructor() { }

  ngOnInit(): void {
  //   console.log('%crouter-link:','color:yellow' , this.text, this.path, this._class);
  }

}

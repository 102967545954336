import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SearchAutoCompleteSuggestions, AutoCompleteResponseOptions, SearchResults, SearchResultsHit } from '@models/search';
import { isPlatformBrowser } from '@angular/common';
import { GenreV2 } from '@models/genre-v2';

/*

search: https://search.amazing-media.com/proxy_php/search.2.php?n=10&o=wp_amazingradio,amazingtunes&p=1&q=Royal%2520Blood&t=
autocomplete: https://search.amazing-media.com/proxy_php/autocomplete.php?o=wp_amazingradio,amazingtunes&q=roya

*/

const BASE_URI = environment.search_proxy_host;
const SEARCH_ORIGINS: string[] = ['wp_amazingradio', 'amazingtunes'];
const SEARCH_ORIGINS_US: string[] = ['wp_amazingradio_us', 'amazingtunes'];

@Injectable({
  providedIn: 'root'
})
export class ApiSearchService {

  private origins_string: string;

  private collection_routing:any = {
    Single: 'singles',
    Album: 'albums',
    ExtendedPlayer: 'eps'
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {
    if(environment.site_country === 'us'){
      this.origins_string = SEARCH_ORIGINS_US.join(',');
    } else {
      this.origins_string = SEARCH_ORIGINS.join(',');
    }
  }

  suggest(term: string): Observable<AutoCompleteResponseOptions[]> {

    let params_data = new HttpParams();
    params_data = params_data.set('q', term); // query
    params_data = params_data.set('o', this.origins_string); // origins

    return this.http.get<SearchAutoCompleteSuggestions>(BASE_URI + '/proxy_php/autocomplete.php', { params: params_data })
      .pipe(
        map(data => {
          let options = data.autocomplete_suggest[0].options;
          return options;
        })
      );
  }

  search(term: string, origin: string = this.origins_string, page: number = 1, per_page: number = 20): Observable<SearchResults> {

    let params_data = new HttpParams();
    params_data = params_data.set('n', per_page.toString());  // results per page
    params_data = params_data.set('p', page.toString());      // result page
    params_data = params_data.set('o', origin);               // origins
    params_data = params_data.set('q', term);                 // query term

    // params_data = params_data.set('f', 'preference');         // field?

    // params_data = params_data.set('t', '');                   // what was this for? it seems to need it

    // console.log('Search API: term, origin: ', term, origin);
    return this.http.get<SearchResults>(BASE_URI + '/proxy_php/search.2.php', { params: params_data })
      .pipe(
        tap(data => {
          // Put user results at the top of each page of results.
          const _userhits: SearchResultsHit[] = [];
          let j = 1;
          if(data.hits){
            data.hits.forEach((hit, i) => {
              if (hit._type === 'User') {
                _userhits.push(data.hits.splice(i, 1)[0]);
              }
            });
          }
          data.hits = _userhits.concat(data.hits);
        }),
        map(data => {
          // console.log('DATA', data);
          if(data.hits && data?.hits[0] !== null){
            data.hits.map(hit => {
              if (hit._index === 'amazingtunes') {
                if(hit._type === 'Tune' || hit._type === 'Collection'){
                  hit._source._find_by_routerlink = '/profile/' + hit._source.artist_permalink + (hit._type === 'Tune' ? '/tunes/': '/'+this.collection_routing[hit._source.type]+'/' ) + hit._source.uuid;
                }
              }
              if ((hit._index === 'wp_amazingradio' || hit._index === 'wp_amazingradio_us') && hit._type === 'post') {
                hit._source._find_by_routerlink = '/news/' + hit._source.post_name;
              }
              else if (hit._index === 'wp_amazingradio' && hit._type === 'amazing-sessions') {
                hit._source._find_by_routerlink = '/sessions/' + hit._source.sessions_year[0] + '/' + hit._source.post_name;
              }
              else if ((hit._index === 'wp_amazingradio' || hit._index === 'wp_amazingradio_us') && hit._type === 'tips') {
                hit._source._find_by_routerlink = '/tips/' + hit._source.tips_year[0] + '/' + hit._source.post_name;
                // console.log('hit._source._find_by_routerlink', hit._source._find_by_routerlink);
              }
            });
          }
          // console.log('results', data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  searchStore(term?:string, types?:string, page: number = 1, per_page: number = 20, genre_id?: number, skip_loading?:boolean ) : Observable<SearchResults> {

    // console.log('searchStore:', term, types, page, per_page);

    let params_data = new HttpParams();
    params_data = params_data.set('n', per_page.toString());  // results per page
    params_data = params_data.set('p', page.toString());      // result page
    params_data = params_data.set('o', 'amazingtunes');       // origin
    if(term){
      params_data = params_data.set('q', term);               // search query term
    }
    if(types){
      params_data = params_data.set('t', types);              // eg: Tunes,Collections
    } else {
      params_data = params_data.set('t', 'Tune,Collection');  // both by default
    }

    if(genre_id){
      params_data = params_data.set('g', genre_id.toString());
    }

    // For now, only show items for sale in USD on the USA site.
    if(environment.site_country === 'us'){
      params_data = params_data.set('c', 'USD');
    }

    if(skip_loading){
      params_data = params_data.set('skip_loading', 'true');
    }

    return this.http.get<SearchResults>(BASE_URI + '/proxy_php/es_proxy.php', { params: params_data })
    .pipe(
      // tap(data => {
      //   console.log('searchStore tap: ', data);
      // }),
      // map(data => {
      //   data.meta.per_page = parseInt(data.meta.per_page);
      //   return data;
      // }),
      catchError(this.handleError)
    )

  }


  // Check Genres for sellable items...
  genresStock(genres:GenreV2[]) : Observable<GenreV2[]> {
    // console.log('genresStock', genres);
    let params_data = new HttpParams();
    params_data = params_data.set('skip_loading', 'true');
    return this.http.post<any>(BASE_URI + '/proxy_php/es_genres_stock.php', JSON.stringify(genres), { params: params_data } );
  }


  // Common error handler
  private handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) { // NOT SSR
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        console.error('A client-side or network error occurred:', error.error.message);
        // return an observable with a user-facing error message
        return throwError({ status: -1, message: error.error.message });
      } else {
        // The backend returned an unsuccessful response code.
        console.log('ERROR: ', error);
        let error_message = error.message;
        if (error.error && error.error.errors) {
          error_message = error.error.errors[0].title;
        }
        return throwError({ status: error.status, message: error_message });
      }
    } else {
      console.log('SEARCH ERROR: ', error);
      let error_message = error.message;
      if (error.error && error.error.errors) {
        error_message = error.error.errors[0].title;
      }
      let error_text:string;
      if(error.error.text){
        error_text = error.error.text;
      }

      return throwError({ status: error.status, message: error_message, text: error_text});
    }
  };

}


import { Injectable } from '@angular/core';
import { HallOfFame } from '@models/hall-of-fame'


@Injectable({
  providedIn: 'root'
})
export class HallOfFameData {

  constructor() { }

  public halloffame:HallOfFame[] = [
    {
      name: 'Dry The River',
      image: 'https://assets.amazingtunes.com/collection_images/217387/square_300.jpg?1428670111',
      permalink: 'pluggedinpr-drytheriver',
      interview: '/news/interview-dry-the-river',
      session:'7adq8xmzGwo',
      date_first_upload: 'July 23, 2010',
      date_first_airplay: 'Aug 03, 2010',
    },
    {
      name: 'Daughter',
      image: 'https://assets.amazingtunes.com/user_images/40272/square_300.jpg?1428654655',
      permalink: 'ohdaughter',
      interview: '/news/interview-daughter',
      session:'DAMUbo6it4k',
      date_first_upload: 'Dec 12, 2010',
      date_first_airplay: 'Jan 03, 2011',
    },
    {
      name: 'Bastille',
      image: 'https://assets.amazingradio.com/wp-content/uploads/bastille1.jpg',
      permalink: 'bastilleuk',
      date_first_upload: 'Jun 06, 2011',
      date_first_airplay: 'Jun 13, 2011',
    },
    {
      name: 'Lianne La Havas',
      image: 'https://assets.amazingtunes.com/collection_images/120739/square_300.jpg?1428644549',
      permalink: 'liannelahavas',
      date_first_upload: 'Oct 11, 2011',
      date_first_airplay: 'Oct 17, 2011',
    },
    {
      name: 'Alt-J',
      image: 'https://assets.amazingtunes.com/user_images/377719/square_300.jpg?1504871413',
      permalink: 'altj',
      interview: 'qWu7vZcrWsY',
      session: 'YqMCucTWFdg',
      date_first_upload: 'Jan 09, 2012',
      date_first_airplay: 'Jan 09, 2012',
    },
    {
      name: 'HAIM',
      image: 'https://assets.amazingtunes.com/user_images/497615/square_300.jpg?1589295768',
      permalink: 'haim',
      interview: '/news/interview-haim',
      date_first_upload: 'Mar 26, 2012',
      date_first_airplay: 'Mar 28, 2012',
    },
    {
      name: 'Chvrches',
      image: 'https://assets.amazingtunes.com/user_images/241003/square_600.jpg?1428696199',
      permalink: 'chvrches',
      interview: '/news/interview-chvrches',
      date_first_upload: 'May 18, 2012',
      date_first_airplay: 'May 21, 2012',
    },
    {
      name: 'Savages',
      image: 'https://assets.amazingradio.com/wp-content/uploads/savages-1.jpg',
      permalink: 'andyneilson',
      interview: '/news/interview-savages',
      date_first_upload: 'May 24, 2012',
      date_first_airplay: 'May 24, 2012',
    },
    {
      name: 'Spring King',
      image: 'https://assets.amazingtunes.com/user_images/307455/square_300.jpg?1455155272',
      permalink: 'springking',
      interview: '/news/interview-spring-king-3',
      date_first_upload: 'June 28, 2012',
      date_first_airplay: 'Jul 07, 2012',
    },
    {
      name: 'James Bay',
      image: 'https://assets.amazingtunes.com/user_images/239191/square_300.jpg?1509470262',
      permalink: 'jamesbay',
      interview: 'WXERYePj4GM',
      session: '0fVmXkq2WW0',
      date_first_upload: 'Dec 03, 2012',
      date_first_airplay: 'Dec 10, 2012',
    },
    {
      name: 'London Grammar',
      image: 'https://assets.amazingtunes.com/user_images/173849/square_300.jpg?1428680149',
      permalink: 'londongrammar',
      interview: 'DiXERbr8wA4',
      date_first_upload: 'Dec 13, 2012',
      date_first_airplay: 'Dec 22, 2012',
    },
    {
      name: 'The 1975',
      image: 'https://assets.amazingtunes.com/user_images/715763/square_300.jpg?1657213633',
      permalink: 'the1975',
      interview: '/news/interview-the-1975-3',
      session: 'c9eftz0Hug8',
      date_first_upload: 'Jan 12, 2013',
      date_first_airplay: 'Jan 15, 2013',
    },
    {
      name: 'Glass Animals',
      image: 'https://assets.amazingtunes.com/user_images/216587/square_300.jpg?1428691264',
      permalink: 'radioactivepromotions-glassanimals',
      interview: '/news/interview-glass-animals',
      date_first_upload: 'May 23, 2013',
      date_first_airplay: 'May 27, 2013',
    },
    {
      name: 'Slaves',
      image: 'https://assets.amazingtunes.com/user_images/410529/square_300.jpg?1530628232',
      permalink: 'slaves',
      interview: 'mcGg8iVbYSo',
      session: 'BKm9ncdy5L4',
      date_first_upload: 'Nov 04, 2013',
      date_first_airplay: 'Nov 06, 2014',
    },
    {
      name: 'Royal Blood',
      image: 'https://assets.amazingtunes.com/user_images/230687/square_300.jpg?1428694312',
      permalink: 'royalblood',
      interview: '/news/interview-royal-blood',
      date_first_upload: 'Feb 10, 2014',
      date_first_airplay: 'Feb 11, 2014',
    },
    {
      name: 'Little Simz',
      image: 'https://assets.amazingtunes.com/collection_images/282031/square_300.jpg?1509461634',
      permalink: 'littlesimz',
      interview: '/news/interview-little-simz',
      date_first_upload: 'Mar 06, 2014',
      date_first_airplay: 'Mar 10, 2014',
    },
    {
      name: 'Shura',
      image: 'https://assets.amazingtunes.com/user_images/256829/square_300.jpg?1428699330',
      permalink: 'weareshura',
      interview: '/news/interview-shura',
      date_first_upload: 'Mar 07, 2014',
      date_first_airplay: 'Mar 10, 2014',
    },
    {
      name: 'Wolf Alice',
      image: 'https://assets.amazingtunes.com/user_images/215437/square_300.jpg?1428691004',
      permalink: 'radioactivepromotions-wolfalice',
      interview: 'RTpmlVT-Ebw',
      session: 'jtLuJl0OzAc',
      date_first_upload: 'Apr 03, 2014',
      date_first_airplay: 'Apr 03, 2014',
    },
    {
      name: 'Loyle Carner',
      image: 'https://assets.amazingtunes.com/user_images/336259/square_300.jpg?1472729077',
      permalink: 'loylecarner',
      date_first_upload: 'Sep 09, 2014',
      date_first_airplay: 'Sep 11, 2014',
    },
    {
      name: 'Mura Masa',
      image: 'https://assets.amazingtunes.com/user_images/233951/square_300.jpg?1428694868',
      permalink: 'muramasa',
      interview: '/news/interview-mura-masa',
      date_first_upload: 'Oct 31, 2014',
      date_first_airplay: 'Nov 07, 2014',
    },
    {
      name: 'Dua Lipa',
      image: 'https://assets.amazingtunes.com/user_images/307007/square_300.jpg?1455016733',
      permalink: 'dualipa-dualipa',
      interview: '/news/interview-dua-lipa',
      date_first_upload: 'Sep 02, 2015',
      date_first_airplay: 'Sep 07, 2015',
    },
    {
      name: 'Declan Mckenna',
      image: 'https://assets.amazingtunes.com/user_images/350749/square_300.jpg?1484131092',
      permalink: 'declanmckenna',
      interview: '/news/interview-declan-mckenna',
      session: 'd7LjZei_U2s',
      date_first_upload: 'Jan 12, 2016',
      date_first_airplay: 'Jan 17, 2016',
    },
    {
      name: 'Jorja Smith',
      image: 'https://assets.amazingtunes.com/tune_images/456121/square_300.jpg?1565947891',
      permalink: 'jorja',
      date_first_upload: 'Mar 05, 2016',
      date_first_airplay: 'Mar 07, 2016',
    },
    {
      name: 'Rag\'n\'Bone Man',
      image: 'https://assets.amazingtunes.com/tune_images/352881/square_300.jpg?1485517511',
      permalink: 'ragnboneman',
      date_first_upload: 'Aug 26, 2016',
      date_first_airplay: 'Aug 26, 2016',
    },
    {
      name: 'Sam Fender',
      image: 'https://assets.amazingtunes.com/tune_images/458053/square_300.jpg?1567674823',
      permalink: 'samfendermusic',
      interview: '/news/interview-sam-fender-absolutely-head-spinning',
      date_first_upload: 'Mar 31, 2017',
      date_first_airplay: 'Apr 03, 2017',
    },
    {
      name: 'Sigrid',
      image: 'https://assets.amazingtunes.com/collection_images/443087/square_300.jpg?1556279318',
      permalink: 'sigrid',
      date_first_upload: 'Jan 09, 2018',
      date_first_airplay: 'Jan 10, 2018',
    },
    {
      name: 'Fontaines D.C.',
      image: 'https://assets.amazingtunes.com/user_images/426291/square_300.jpg?1543416086',
      permalink: 'fontainesdc',
      interview: '/news/interview-shell-zenner-chats-2020-with-fontaines-d-c',
      date_first_upload: 'Jun 06, 2018',
      date_first_airplay: 'Aug 15, 2018',
    },
    {
      name: 'Celeste',
      image: 'https://assets.amazingtunes.com/tune_images/477201/square_300.jpg?1580398126',
      permalink: 'polydor-celeste',
      date_first_upload: 'Nov 01, 2018',
      date_first_airplay: 'Nov 12, 2018',
    },
    {
      name: 'Arlo Parks',
      image: 'https://assets.amazingtunes.com/user_images/424739/square_300.jpg?1542217206',
      permalink: 'arloparks',
      date_first_upload: 'Nov 14, 2018',
      date_first_airplay: 'Nov 16, 2018',
    },
    {
      name: 'Beabadoobee',
      image: 'https://assets.amazingtunes.com/user_images/779765/square_600.jpg?1676458095',
      permalink: 'beabadoobee',
      interview: 'UBvnBgWin7A',
      date_first_upload: 'Dec 08, 2018',
      date_first_airplay: 'Jan 06, 2019',
    },
    {
      name: 'Wet Leg',
      image: 'https://assets.amazingradio.com/wp-content/uploads/artistSquaresWetLeg.jpg',
      permalink: 'wetleg',
      interview: 'M0H4VEtoyNo',
      date_first_upload: 'Nov 19, 2019',
      date_first_airplay: 'Nov 21, 2019',
    },
    {
      name: 'The Last Dinner Party',
      image: 'https://assets.amazingtunes.com/tune_images/803083/square_600.jpg',
      permalink: 'thelastdinnerparty',
      date_first_upload: 'Apr 25, 2023',
      date_first_airplay: 'Apr 25, 2023',
    },
    {
      name: 'Tom A Smith',
      image: 'https://assets.amazingtunes.com/user_images/811621/square_600.jpg',
      permalink: 'tomasmith',
      interview: 'lbroo8nG-wA',
      date_first_upload: 'May 22, 2023',
      date_first_airplay: 'May 23, 2023',
    },
  ];
}
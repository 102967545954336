<section>

  <!-- Presenter Promo for USA visitors on the US site -->
  <!--<div class="usa-promo with-x-large-margin" [routerLink]="['/page', 'presenter-search']" *ngIf="show_usa_promo" title="Amazing Radio USA is looking for a new presenter ... ">
    <div class="banner-inner">
      <div class="banner-title">
        AMAZING RADIO USA
      </div>
      <div class="banner-sub-title">
        <strong>IS LOOKING FOR A PRESENTER</strong>
        <br>Find out more about how you can enter
      </div>    
    </div>
  </div>-->

  <!-- Banner for USA visitors on the UK site -->
  <div class="usa-banner with-x-large-margin" (click)="goToOtherCountrySite($event)" *ngIf="show_usa_banner"
    title="Head over to Amazing Radio USA for dedicated shows and the best new and emerging music from across the United States">
    <div class="banner-inner">
      <div class="banner-sub-title">
        <strong>Visting from the United States?</strong>
        <br>Head over to
      </div>
      <div class="banner-title">
        AMAZING RADIO USA
      </div>
    </div>
  </div>

  <!-- Hero Carousel -->
  <app-hero-carousel [with_x_large_margin]="true"></app-hero-carousel>

  <div class="bg-yellow-flat-bar section with-x-large-margin"></div>
  <div class="spacer bg-dark with-x-large-margin"></div>

  <div class="spacer bg-dark with-x-large-margin"></div>

  <div class="features-cont">

    <div class="features">

      <app-home-feature-row *ngIf="topFiveTunesImages" role="button"
        aria-label="The Amazing Chart. This week's movers and shakers." background_class="gold"
        title="The Amazing Chart" sub_title="This week's movers and shakers" router_link="/chart" image=""
        [row_images]="topFiveTunesImages">
      </app-home-feature-row>

      <app-home-feature-row *ngIf="featuredPlaylistsImages" role="button" background_class="green"
        title="Featured Playlists"
        [sub_title]="'Tunes from your '+(site_country === 'uk' ? 'favourite':'favorite')+' genres'"
        router_link="/playlists/featured" image="" [row_images]="featuredPlaylistsImages">
      </app-home-feature-row>

      <app-home-feature-row *ngIf="hallOfFameImages" role="button"
        aria-label="Hall Of Fame. It all started here for these artists." background_class="cyan" title="Hall Of Fame"
        sub_title="It all started here for these artists" router_link="/halloffame" image=""
        [row_images]="hallOfFameImages">
      </app-home-feature-row>

      <app-home-feature-row *ngIf="testimonialsImages" role="button"
        aria-label="Testimonials. See what artists are saying about us." background_class="blue" title="Testimonials"
        sub_title="See what artists are saying about us" router_link="/testimonials" image=""
        [row_images]="testimonialsImages">
      </app-home-feature-row>


      <!--<app-home-feature-row *ngIf="site_country === 'uk'" role="button"
        aria-label="Head over to Amazing Radio USA for the shows from the United States" background_class="usa"
        title="Amazing Radio USA" sub_title="Dedicated Shows from the United States"
        external_link="https://amazingradio.us/shows" image="" [row_images]="UsaRowImages">
      </app-home-feature-row>-->

      <!-- <app-home-feature-row *ngIf="tunesForSaleImages?.length > 0" role="button" aria-label="Latest Tunes For Sale"
        background_class="yellow" title="Amazing Music Store" sub_title="Latest Tunes For Sale. 100% Goes To Artists"
        router_link="/store" image="" [row_images]="tunesForSaleImages">
      </app-home-feature-row> -->

      <app-home-feature-row *ngIf="homeFirstSpinsImages?.length > 0" role="button"
        aria-label="First Spin. You heard it here first." background_class="purple" title="First Spin"
        sub_title="You heard it here first" router_link="/firstspin" image="" [row_images]="homeFirstSpinsImages">
      </app-home-feature-row>

      <app-home-feature-row *ngIf="homeFirstLookImages?.length > 0" imageScale="true" role="button" [zoom_image]="true"
        aria-label="First Look. You saw it here first." background_class="pink" title="First Look"
        sub_title="Watch videos from our Amazing Artists" router_link="/firstlook" image=""
        [row_images]="homeFirstLookImages">
      </app-home-feature-row>

      <app-home-feature-row role="button" aria-label="Why Go Amazing? How talent gets noticed." background_class="red"
        title="Why Go Amazing?" sub_title="How talent gets noticed" router_link="/benefits" image="" [row_images]="[{image_url:'/assets/banners/music-hall.jpg'},
                    {image_url:'/assets/banners/gigguide.jpg'},
                    {image_url:'/assets/banners/microphone.jpg'},
                    {image_url:'/assets/banners/mixing-desk.jpg'},
                    {image_url:'/assets/banners/vinyl-player.jpg'}]">
      </app-home-feature-row>
    </div>
  </div>

  <div class="benefits-cont">
    <div class="topRowThreeColumnsNew">
      <div role="contentinfo"
        aria-label="We help talent get noticed by Showcasing emerging talent. Playing the best new music 24 7 and Building Careers"
        class="get-noticed header">
        <span aria-hidden="true">
          We help talent <span class="break"><br></span>get noticed by
        </span>
      </div>
      <div class="join-today header">
        <span aria-hidden="true">
          Join us today <span aria-hidden="true" class="break"><br></span>for a chance to
        </span>
      </div>
      <div class="amazing-account header">
        <span aria-hidden="true">
          An Amazing account <span class="break"><br></span>gives you
        </span>
      </div>
      <div class="get-noticed-text text" aria-hidden="true">
        Showcasing emerging talent<br>
        Playing the best new music 24/7<br>
        Building Careers
      </div>
      <div class="join-today-text text" aria-hidden="true">
        Get on the radio<br>
        Sell your music - keep 100%<br>
        Grow your audience
      </div>
      <div class="amazing-account-text text" aria-hidden="true">
        Your unique Amazing profile URL<br>
        Unlimited digital locker storage<br>
        Auto-transcoding for streaming
      </div>
    </div>
  </div>

  <div class="section with-x-large-margin">
    <div class="section-content">
      <div class="section-header">
        <h2>What we do</h2>
      </div>
      <p>Amazing Radio helps new and emerging artists get discovered and make money, without getting screwed.</p>
      <p>Since we launched in England in 2007, we have given global first-ever radio play to countless artists - our
        Hall of Fame all started here. We've launched in America and bought CMJ, to try to help America's best new
        musicians. We promise to <span [innerHTML]="site_country === 'uk'?'honour':'honor'"></span> artists and do all
        we can to build careers.</p>
    </div>
  </div>

</section>
import { Injectable, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Modelled on the `Meta` service in @angular/platform-browser
 * A service to update <link> tags in the index.html (on route chnage).
 *
 * via : https://github.com/angular/angular/issues/15776#issuecomment-352695731
 *
 * Usage:eg:
 *
 *     this.linkService.updateCanonicalLink({
 *       rel:'canonical',
 *       href:'https://foo.com'
 *     });
 *
 */

 export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
    [prop: string]: string;
};

@Injectable({
  providedIn: 'root'
})
export class HTMLLinkService {

  constructor(
    @Inject(DOCUMENT) private document,
    private rendererFactory: RendererFactory2,
  ) {}

  /**
   * Inject the State into the bottom of the <head>
   */
   addTag(tag: LinkDefinition, forceCreation?: boolean) {

    try {
      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');

      const head = this.document.head;
      const selector = this._parseSelector(tag);

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      renderer.appendChild(head, link);
      // console.log('%cadded link rel canonical href:', 'color:orange',link.href);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }

  removeTag(attrSelector: string) {
    if (attrSelector) {
      try {
        const renderer = this.rendererFactory.createRenderer(this.document, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
        });
        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        const linkTags = this.document.querySelectorAll('link[' + attrSelector + ']');
        for (const link of linkTags) {
          renderer.removeChild(head, link);
          // console.log('Removed link tag', attrSelector, link);
        }
      } catch (e) {
        console.log('Error while removing tag ' + e.message);
      }
    }
  }

  updateCanonicalLink(link:LinkDefinition) {
    if(!link.rel){
      throw new Error('No rel found in LinkDefinition');
    }
    this.removeTag('rel="canonical"');
    this.addTag(link);

  }

  private _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }
}

import { Injectable } from '@angular/core';
import { Country } from '@models/configurations';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';

/**
 * Countries Service
 *
 * Imported by the player.componenet, so will only load once. And immediately.
 * Then the cached list can also be obtained for using in forms.
 * Useful also for providing the name of a country, based on the user's country attribute (ISO 1366-1)
 * All flag images are stored in /assets/flags/xx.png (Where the country code is lowercased.)
 */

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  public countries:Country[] = [];

  constructor(private api:ApiAmazingtunesV2Service) {
    this.getCountries();
  }

  getCountries(force?:boolean):Country[] {
    if(typeof window === 'undefined'){
      return;
    }
    if(this.countries.length && !force){
      return this.countries;
    }
    this.api.getCountries().subscribe(data => {
      this.countries = data;
      // console.log('caching countries', data);
      return this.countries;
    });
  }

  getCountryNameByCode(code: string): string {
    let _cname: string = '';
    this.countries.forEach(c => {
      if (c.code === code) {
        _cname = c.name;
      }
    });
    return _cname;
  }

  getCountryCodeByName(name: string): string {
    let _ccode: string = '';
    this.countries.forEach(c => {
      if (c.name === name) {
        _ccode = c.code;
      }
    });
    return _ccode;
  }

}

import { Component, OnInit } from '@angular/core';
import { UserV2} from '@models/user-v2';
import { Router }  from '@angular/router';
import { UserService } from '@services/user.service';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  selector: 'app-user-card-v2',
  templateUrl: './user-card-v2.component.html',
  styleUrls: ['./user-card-v2.component.scss'],
  inputs:['user', 'list_parent']
})
export class UserCardV2Component implements OnInit {

  public user: UserV2;
  public list_parent:string; // likes-all || likes-artists : to show/hide icon

  constructor(private router:Router, private snackbar: SnackbarService, public userService:UserService) { }

  editUser(){
    this.router.navigate(['/profile', this.userService.get().attributes.permalink, 'artists', this.user.attributes.permalink_in_label, 'edit']);
  }

  ngOnInit(): void {
  //  console.log('UserCard: ', this.user);
  }

}

<div class="player-wrapper">
  <div #playerCont (mousemove)="mouseMove($event)"  class="player-cont" (dblclick)="toggleFullScreen($event)" (click)="onVideoClick()">

    <div class="controls_cont" @fadeInOut *ngIf="show_controls" (mouseover)="mouseOver()" (mouseout)="startUITimer()">
      <div class="scrub" (click)="noopClick($event)">
        <app-slider *ngIf="!is_live && loaded" col_track="#f6ea5b" col_thumb="white" col_bg="#444" class="slider" (click)="noopClick($event)" [value]="progress" (sliderEvent)="sliderEvent($event)"></app-slider>
      </div>
      <div class="controls" (click)="noopClick($event)">
        <!-- main control button -->
        <mat-icon class="controls-play-btn" (click)="toggleVideo()" [class]="buffering && !has_error ? 'spinner':''" [svgIcon]="playing ? (is_live ? 'stop' : 'pause') : (buffering ? 'buffering':'play')"></mat-icon>
        <span *ngIf="duration && !loading; else loadingDuration" class="time" [innerHTML]="is_live ? 'LIVE': (currentTime | secsToTime) + ' / ' + (duration | secsToTime)"></span>
        <div *ngIf="playlist_controls && video_playlist" class="prevnext">
          <mat-icon svgIcon="skip_previous"></mat-icon>
          <mat-icon svgIcon="skip_next"></mat-icon>
        </div>
        <span class="space"></span>
        <span class="p-res" [innerHTML]="p_res"></span>
        <mat-icon class="controls-volume" *ngIf="!is_ios" (click)="toggleVolume($event)" [innerHTML]="is_muted ? 'volume_off':'volume_up'"></mat-icon>
        <div #vol *ngIf="!is_mobile" class="vol-cont">
          <app-slider col_track="white" col_thumb="white" col_bg="#888" class="slider" (click)="noopClick($event)" [value]="volume" (sliderEvent)="sliderVolEvent($event)"></app-slider>
        </div>
        <mat-icon class="controls-fullscreen" (click)="toggleFullScreen($event)" [innerHTML]="fullscreen ? 'fullscreen_exit':'fullscreen'"></mat-icon>
      </div>
    </div>

    <ng-template #loadingDuration>
      <span class="time">-- / --</span>
    </ng-template>
    <!-- -->
    <div @fadeInOut  *ngIf="!loaded" class="poster-cont" (click)="noopClick($event)">
      <mat-icon *ngIf="!has_error; else error" (mouseout)="startUITimer()" [title]="!loaded ? (loading ? 'Cancel Loading':'Load Video') :''" class="poster-play-btn" [class]="loading ? ' spinner':''" [svgIcon]="loading ? 'buffering':'play'" (click)="onPosterPlayBtnClick($event)"></mat-icon>
      <ng-template #error>
        <mat-icon svgIcon="warning" class="poster-play-btn warning"></mat-icon>
      </ng-template>
      <img [src]="poster">
    </div>
    <video #video id="hls-video-el" class="video" crossorigin="anonymous" preload="none" playsinline></video>
    <div *ngIf="has_error" @fadeInOut class="errors" (click)="noopClick($event)">
      Error loading video.
    </div>

    <div *ngIf="!hide_meta && video_title && show_info" @fadeInOut class="info" (mouseover)="mouseOver()" (mouseout)="startUITimer()" (click)="noopClick($event)">
      <div *ngIf="video_user" (click)="goToArtistProfile()" class="video-user" [innerHTML]="video_user"></div>
      <div (click)="goToVideoPage()" class="video-title" [innerHTML]="video_title"></div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';

interface Reportable {
  id?:string;
  type?:string;
}

/**
 * Provides data for 'Report a Problem' link in the footer
 */

@Injectable({
  providedIn: 'root'
})
export class ReportableService {

  public reportable:Reportable;

  constructor() { }

}

import { Component, OnInit } from '@angular/core';
// import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Router, NavigationStart } from '@angular/router';
import { UserService } from '@services/user.service';
import { UserV2 } from '@models/user-v2';
import { trigger, transition, style, animate } from '@angular/animations';
import { filter } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BreakpointObserverService, Breakpoints } from '@services/breakpoint-observer.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { DialogLabelArtistsComponent } from '@modules/_shared/ui/components/dialog-label-artists/dialog-label-artists.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  inputs: ['show_burger', 'is_side_nav_open'],
  animations: [
    trigger(
      'underlayFadeInOut',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.3s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]

})
export class HeaderComponent implements OnInit {

  public show_burger:boolean; // force burger menu button if menu forcibly closed at medium width. (from iPad experience)
  public is_side_nav_open:boolean = false;
  public amazing_logo: string = '/assets/logos/2023/RadioWhite.svg';
  
  public logged_in_user?: UserV2 | null;
  public classification?:string | null;
  public is_amazing_uploader_user: boolean | null = null; // Artists or Labels. (Not a Fan, Songwriter or Publisher)

  public show_upload_options:boolean = false;

  public site_country: string;
  public is_dev: boolean = false;
  // For flag urls. Now also allows cross-site login via /connect/auto-login via goToOtherCountrySite() method below.
  public site_urls: any = {
    live: {
      uk: 'https://amazingradio.com',
      us: 'https://amazingradio.us'
    },
    dev: {
      uk: 'https://dev.amazingradio.com',
      us: 'https://dev.amazingradio.us'
    }
  };
  private dev_ports:string[] = ['4200', '4300', '4700'];

  public breakpoints:Breakpoints;

  public is_medium_or_less:boolean = false;

  constructor(
            private api_tunes:ApiAmazingtunesV2Service,
            private dialog: MatDialog,
            private bpoService:BreakpointObserverService,
            private router: Router,
            private userService: UserService
          ) {

            
    // if(environment.site_country === 'us'){
    //   this.amazing_logo = '/assets/logos/logoAmazingUSA.svg'
    // }

    // For dev site flag links
    if (environment.api_endpoint_v2.includes('.mobi')) {
      this.is_dev = true;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((data: NavigationStart) => {
      // close upload options
      this.show_upload_options = false;
    });

    this.userService.userSub.subscribe(data => {
      if(data === null){
        this.logged_in_user = null;
      }
      this.classify();
    });

    this.bpoService.breakpointsSubject.subscribe((data:Breakpoints) => {
      if(data){
        // console.log('breakpoints:', data);
        this.breakpoints = data;
        if(this.breakpoints.is_small || this.breakpoints.is_x_small){
          this.is_medium_or_less = true;
          // this.amazing_logo = '/assets/logos/2023/WordmarkWhite.svg';
        } else {
          this.is_medium_or_less = false;
          // this.amazing_logo = '/assets/logos/2023/FullWhite.svg';
        }
      }
    });


  }

  showUploadOptions() {
    if (!this.logged_in_user) {
      this.router.navigate(['/connect', 'signup']);
      return;
    }
    if (this.logged_in_user?.meta.is_subscription_required && this.logged_in_user?.meta.free_uploads_remaining < 1) {
      this.router.navigate(['/benefits']);
      return;
    }
    if(this.userService.getClassification() === 'fan'){
      this.router.navigate(['/benefits']);
      return;
    }
    // show Upload Tune / Upload Video options
    this.show_upload_options = !this.show_upload_options;
  }

  scrollToTop():void{
    if(typeof window !== 'undefined'){
      window.scrollTo(0,0);
    }
  }

  public toggleSidenav():void {
    this.bpoService.toggleSidenav();
  }

  ngOnInit(): void {
    this.site_country = environment.site_country;
    this.userService.userSub.subscribe(user => {
        this.logged_in_user = user
        this.classify();
    });
  }

  classify(){
    if(this.logged_in_user){
      this.classification = this.userService.getClassification();
      if(this.userService.treatAsArtist() || this.userService.treatAsLabel()){
        this.is_amazing_uploader_user = true;
      } else {
        this.is_amazing_uploader_user = false;
      }
    } else {
      this.classification = null;
      this.is_amazing_uploader_user = null; // set to null here as we check for true|false truthiness in the template
    }
  }

  goHome() {
    if(this.is_side_nav_open){
      this.toggleSidenav();
    }
    this.scrollToTop();
    this.router.navigate(['/']);
  }

  goToOtherCountrySite(event: any) {
    event.preventDefault(); // hijack the href link
    if (typeof window !== 'undefined') {
      // Disable flags in localhost dev environments.
      if (this.dev_ports.includes(window.location.port)) {
        alert('Flag links cannot work in local development.');
        return;
      }
      let other_site_url: string = this.site_country === 'uk' ? (this.is_dev ? this.site_urls.dev.us : this.site_urls.live.us) : (this.is_dev ? this.site_urls.dev.uk : this.site_urls.live.uk);
      if (this.logged_in_user) {
        other_site_url += '/connect/auto-login?access_token=' + this.userService.getJWToken();
      }
      // console.log('goto ', other_site_url);
      window.location.assign(other_site_url);
    }
  }


  upload(event, resource_types:string = 'tunes'){
    event.stopPropagation(); // dont bubble up to the parent click handler.

    this.show_upload_options = false;

    if(this.is_side_nav_open){
      this.toggleSidenav();
    }
    // console.log('Upload Artist tune...');
    if (!this.logged_in_user) {
      this.router.navigate(['/connect', 'signup']);
      return;
    }
    if (this.logged_in_user?.meta.is_subscription_required && this.logged_in_user?.meta.free_uploads_remaining < 1) {
      this.router.navigate(['/benefits']);
      return;
    }
    if(this.userService.getClassification() === 'fan'){
      this.router.navigate(['/benefits']);
      return;
    }

    if(this.userService.getClassification() === 'label'){
      this.openLabelArtistsUploadDialog( resource_types );
      return;
    }
    // Check if terms need reviewing
    if(this.logged_in_user?.meta.terms_need_reviewing){
      if(typeof window === 'undefined'){
        return;
      }
      // Store return page after saving terms options
      localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/' + resource_types + '/new');
      this.router.navigate(['/terms-and-services']);
      return;
    }
    this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, resource_types, 'new']);
  }

  openLabelArtistsUploadDialog(resource_types:string = 'tunes') {
    if (typeof window === "undefined") {
      return; // SSR
    }
    if(this.logged_in_user?.user_stats.label_counts.artists === 1){
      // Just take them to the upload page for their only artist...
      this.api_tunes.getAllLabelArtistsCompact(this.logged_in_user?.attributes.permalink).subscribe(data => {
        // console.log(data[0]);
        if(this.logged_in_user?.meta.terms_need_reviewing){
          if(typeof window === 'undefined'){
            return;
          }
          // Store return page after saving terms options
          localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/artists/' + data[0].permalink_in_label + '/' + resource_types + '/new');
          this.router.navigate(['/terms-and-services']);
          return;
        }
        this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, 'artists', data[0].permalink_in_label, resource_types, 'new']);
      });
    } else {
      // Get compact list of artists for a Dialog list to pick from.
      this.api_tunes.getAllLabelArtistsCompact(this.logged_in_user?.attributes.permalink).subscribe(data => {
        // console.log('All artists (compact: ', data);
        const dialogRef = this.dialog.open(DialogLabelArtistsComponent, {
          width: '560px',
          panelClass: 'dialogPlaylists',
          data: { artists_compact: data },
          scrollStrategy: new NoopScrollStrategy()
        });
        dialogRef.afterClosed().subscribe(artist_in_label => {
          // console.log('The LabelArtistsUploadDialog was closed: ', artist_permalink_in_label);
          if(artist_in_label && artist_in_label.permalink){
            if(this.logged_in_user?.meta.terms_need_reviewing){
              if(typeof window === 'undefined'){
                return;
              }
              // Store return page after saving terms options
              localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/artists/' + artist_in_label.permalink_in_label + '/' + resource_types + '/new');
              this.router.navigate(['/terms-and-services']);
              return;
            }
            // Go to Label Artist Tune or Video Upload
            this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, 'artists', artist_in_label.permalink_in_label, resource_types, 'new']);
          }
        });
      });
    }
  }

}

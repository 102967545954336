<div class="instructions">
    PAY SECURELY BY CARD
  </div>
  
  <form id="payment-form">
    <div id="link-authentication-element">
      <!--Stripe.js injects the Link Authentication Element-->
    </div>
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
    <button id="submit" #submit (click)="submitPayment($event)">
      <!-- <div #spinner class="spinner hidden" id="spinner"></div> -->
      <span *ngIf="selected_plan" #button_text id="button-text">
        PAY {{ selected_plan.attributes.payment_currency }} {{ (selected_plan.attributes.payment_amount | currency : selected_plan.attributes.payment_currency: 'symbol': selected_plan.attributes.payment_amount % 1 === 0 ? '0.0':'0.02') + (selected_plan.attributes.billing_period === 'monthly' ? ' / Month':'') }}
      </span>
    </button>
    <div #payment_message id="payment-message" class="hidden"></div>
    <div class="about">Provided by Stripe.com</div>

  </form>
  
  
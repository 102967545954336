import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { MemberSubscription } from '@models/subscriptions-v2';
import { environment } from '@env/environment';

export interface DialogData {
  subscription?: MemberSubscription;
}


@Component({
  selector: 'app-dialog-funding-source',
  templateUrl: './dialog-funding-source.component.html',
  styleUrls: ['./dialog-funding-source.component.scss'],
  inputs: ['processing']
})
export class DialogFundingSourceComponent implements OnInit {

  @ViewChild('dialogContent') dialogContent: ElementRef;
  
  @Input() processing: boolean = true; // two-way binding to app-card-payment

  public member_subscription: MemberSubscription;
  public site_country: string = environment.site_country;
  public accept_card_payments: boolean = environment.accept_card_payments;
  public will_be_charged_immediately: boolean = false;
  public is_past_due: boolean = false;

  public CARD_PAYMENT_PROVIDER: string = environment.card_payment_provider; // stripe | checkout 

  constructor(
    public dialogRef: MatDialogRef<DialogFundingSourceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api_tunes: ApiAmazingtunesV2Service,
    public userService: UserService,
    private snackbar: SnackbarService
  ) {


    // maybe fix ths for checkout
    if(this.CARD_PAYMENT_PROVIDER === 'checkout'){
      this.processing = false;
    }


    if(data.subscription){
      this.member_subscription = data.subscription;
      // console.log('Subscription for funding: ', this.member_subscription.data);
      if(this.member_subscription.data.attributes?.next_payment_hours < 0){
        // console.log('next_payment_hours:', this.member_subscription.data.attributes.next_payment_hours );
        this.will_be_charged_immediately = true;
        this.is_past_due = true;
        
      }

    } else {
      console.log('No member subscription data injected..');
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {
  }

  addPayPal(){
    // console.log('Add PayPal as funding source... close parent dialog...');
    this.api_tunes.addFundingSource('paypal_subscription', this.member_subscription.data.id).subscribe((data) => {
      // console.log('addFundingSource response: ', data);
      if(data.meta.approval_url){
        // console.log('Redirect to PayPal:', data.meta.approval_url);
        this.dialogRef.close();
        if(typeof window !== 'undefined'){
          this.snackbar.show('Redirecting to PayPal ... ');
          window.open(data.meta.approval_url, '_self');
        }
      } else {
        console.log('No approval URL');
      }
    },
    (error) => {
      console.log('add Paypal as funding source error:', error);
      this.snackbar.show(error.errors.title, 'snackbarWarning');
    });
  }

}

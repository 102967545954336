<!-- Collection card component : Singles/EPs/Albums -->

<div class="card" [class]="hide_artist === true ? 'profileCollection ' + list_parent : ' ' + list_parent ">

  <div class="imageCont square">
    <a [routerLink]="!collection._is_pseudo_single ? ['/profile', collection.artist.attributes.permalink, type_url[collection.attributes.type], collection.id] : ['/profile', collection._tunes[0].artist.attributes.permalink, 'tunes', collection._tunes[0].id]"><img [lazyImgSrc]="collection.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png"></a>
    <span *ngIf="!collection._is_pseudo_single; else pseudoTune" class="imageLabel" title="tunes:{{ collection._tunes.length }}">{{ types[collection.attributes.type] }}</span>
  </div>

  <app-collection-play-button-v2 class="playButton playButtonList" [collection]="collection" [addClass]="collection._tunes[0].attributes.is_private ? ' private':''" [playlist]="playlist" [index]="0" ></app-collection-play-button-v2>
  <!-- Dont show these on logged in user's collections -->
  <div class="buyDownloadContainer" *ngIf="collection.attributes.distribution.preference === 'sell' || (collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'free_download') || (collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'sell')">
    <!-- Opens purchase dialog -->
    <div (click)="buy()" *ngIf="collection.attributes.distribution.preference === 'sell' || (collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'sell') " class="button buy" title="Buy this {{collection.attributes.type}}"><mat-icon class="icon" inline="true">shopping_cart</mat-icon><span>Buy</span></div>
    <!-- We don't actually have a means to do this for collections. Not all tunes might be downloadable. Can only be done on the collection page itself where each tune will have a buy button on the card if necessary. -->
    <!-- Show it for pseudo-singles -->
    <div #downloadButton (click)="downloadTune(collection._tunes[0])" *ngIf="collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'free_download' && !has_downloaded; else downloaded" class="button download" title="Free Download"><mat-icon class="icon" inline="true">cloud_download</mat-icon></div>

  </div>

  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like [title]="collection.meta.liked_by_user ? 'Remove '+collection.attributes.type+' From Likes':'Like '+collection.attributes.type" *ngIf="!collection.attributes.is_private && !collection._is_owner && !collection._is_manager; else privateCollection" class="interact like" [resource]="collection._is_pseudo_single ? collection._tunes[0] : collection"></app-action-button-like>
      <!-- <app-play-button-v2 class="playButton playButtonCard" [playlist]="playlist" [index]="0" [tune]="collection._tunes[0]"></app-play-button-v2> -->
      <app-action-button-playlist *ngIf="!collection._tunes[0].attributes.is_private && !collection.attributes.is_private && !collection._is_owner; else empty" class="interact playlist" [tunes]="collection._tunes" title="Add Collection Tunes to a Playlist"></app-action-button-playlist>
    </div>
	</div>

  <div class="details">

    <!-- 'pseudo-singles' get linked to their tune page -->
    <a class="elipsify name" *ngIf="!hide_artist" [routerLink]="!collection._is_pseudo_single ? ['/profile', collection.artist.attributes.permalink, type_url[collection.attributes.type], collection.id] : ['/profile', collection._tunes[0].artist.attributes.permalink, 'tunes', collection._tunes[0].id]" title="{{ collection.artist.attributes.name }}" [innerHTML]="collection.artist.attributes.name"></a>
    <div class="collectionName elipsify">
      <a class="elipsify title" [class]="(hide_artist ? 'hidden_name':'') + (collection._tunes[0].attributes.is_private ? ' non-streamable':'')" [routerLink]="!collection._is_pseudo_single ? ['/profile', collection.artist.attributes.permalink, type_url[collection.attributes.type], collection.id] : ['/profile', collection._tunes[0].artist.attributes.permalink, 'tunes', collection._tunes[0].id]" title="{{ collection.attributes.name }}" [innerHTML]="collection.attributes.name"></a>
    </div>

    <div class="stats">
      <div class="listLabel" [class]="collection._tunes[0].attributes.is_private ? 'non-streamable':''" [innerHTML]="!collection._is_pseudo_single ? types[collection.attributes.type] : (collection._tunes[0].attributes.is_private ? 'NON-STREAMABLE ':'Tune')"></div>
      <div class="sell" (click)="buy()" *ngIf="!collection._is_pseudo_single && collection.attributes.distribution.preference === 'sell'; else pseudoSell" [title]="'This '+collection.attributes.type+' is for sale'">
        <mat-icon inline="true">bookmark_border</mat-icon> <span >{{ collection.attributes.distribution.sale_price_pence / 100 | currency : collection.attributes.distribution.currency }}</span>
      </div>
    </div>
  </div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private collections (if they exist??) -->
<ng-template #pseudoSell>
  <!-- [routerLink]="['/profile', collection.artist.attributes.permalink, 'tunes', collection._tunes[0].id]" -->
  <div class="sell" (click)="buy()" *ngIf="collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'sell'" [title]="'This Tune is for sale'">
    <mat-icon inline="true">bookmark_border</mat-icon> <span >{{ collection._tunes[0].attributes.distribution.sale_price_pence / 100 | currency : collection._tunes[0].attributes.distribution.currency }}</span>
  </div>
</ng-template>

<ng-template #pseudoDownload>
  <div class="downloadable" *ngIf="collection._is_pseudo_single && collection._tunes[0].attributes.distribution.preference === 'free_download'" [title]="'This Tune is available for Free download'">
    <mat-icon inline="true">cloud_download</mat-icon>
  </div>
</ng-template>
<ng-template #downloaded>
  <div *ngIf="has_downloaded" class="button download" title="Downloaded"><mat-icon class="icon" inline="true">check_circle</mat-icon></div>
</ng-template>
<ng-template #pseudoTune>
  <span class="imageLabel">TUNE</span>
</ng-template>
<ng-template #empty>
  <div></div>
</ng-template>
<ng-template #privateCollection>
  <div *ngIf="collection.artist.id !== userService.getId() && !collection._is_manager; else edit" class="empty" title="private tune"><mat-icon>lock</mat-icon></div>
</ng-template>
<ng-template #edit>
  <!-- The link to edit Singles goes to the Edit Tune Form of the only tune. (If Singles really do only have one tune)  -->
  <app-action-button-edit class="interact edit" [routerLink]="!collection._is_pseudo_single ? ['/profile', collection.artist.attributes.permalink, type_url[collection.attributes.type], collection.id, 'edit'] : ['/profile', collection._tunes[0].artist.attributes.permalink, 'tunes', collection._tunes[0].id, 'edit']" [title]="!collection._is_pseudo_single ? 'Edit '+collection.attributes.type : 'Edit Single Tune'">edit</app-action-button-edit>
</ng-template>

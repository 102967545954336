import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { TuneV2 } from '@models/tune-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { UserService } from '@services/user.service';
import { SnackbarService } from '@services/snackbar.service';
import { ApiAmazingtunesV2Service } from '@app/services/api-amazingtunes-v2.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';

// Buy / Download requirements...
import { ApiDownloadService } from '@services/api-download.service';
import { PaymentV2 } from '@app/models/payment-v2';
import { MatDialog } from '@angular/material/dialog';
import { DialogPurchaseComponent } from '@modules/_shared/ui/components/dialog-purchase/dialog-purchase.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';


@Component({
  selector: 'app-tune-card-v2',
  templateUrl: './tune-card-v2.component.html',
  styleUrls: ['./tune-card-v2.component.scss'],
  inputs: ['tune', 'artist', 'index', 'playlist','playlistHead', 'hide_artist', 'position', 'movement', 'list_parent', 'unique_id', 'playlist_id'],
})
export class TuneCardV2Component implements OnInit, OnDestroy {

  public tune: TuneV2;
  public index: number;
  public path: string;
  public playlist: TuneV2[];
  public playlistHead:PlaylistV2;

  public unique_id:string;
  public playlist_id:string; // if this card belongs to a playlist (or other lists of tunes with a custom id set)

  public url: string;
  public hide_artist: boolean = false; // Optional

  public list_parent:string;

  // private sub_poll:Subscription;
  private ready_poll_interval:any;
  private check_count_limit:number = 20;
  private check_count:number = 0;
  public failed_upload:boolean; // If the checking has given up.
  public old_upload:boolean; // If the upload was ages ago
  public old_upload_limit_days:number = 1; // If the upload was ages ago

  private now:Date = new Date();
  public is_redirecting:boolean;
  public has_downloaded:boolean;
  public is_downloading:boolean;
  @ViewChild('downloadButton') downloadButton: ElementRef;

  constructor(private loadingIndicatorService:LoadingIndicatorService, private renderer:Renderer2, private dialog: MatDialog, private api_dl:ApiDownloadService, public datePipe:DatePipe, private api_tunes:ApiAmazingtunesV2Service, private snackbar: SnackbarService, private router: Router, public userService: UserService) {
    // Detect which route we're on to control display of aired time or chart position.
    this.url = this.router.url;
  }

  ngOnDestroy(): void {
    if(this.ready_poll_interval){
      clearInterval(this.ready_poll_interval);
    }
  }

  checkTune(){
    this.api_tunes.getTuneV2(this.tune.id, true, true).subscribe(data => {
      //console.log('ready:', this.tune, this.tune.attributes.is_ready_to_stream, this.tune.attributes.name);
      this.check_count++;
      if(data.attributes.is_ready_to_stream){
        this.tune = data;

        // Update the user data now...
        this.api_tunes.verifyUserV2(true).subscribe(data => {
          // Hopefully the user classification will have changed by now.
          // console.log('user data updated:', this.userService.get());
          if(typeof window !== 'undefined'){
            // console.log('removing new_upload flag if it exists');
            localStorage.removeItem('new_upload');
          }
          clearInterval(this.ready_poll_interval);
        });
      }

      if(this.tune.attributes.uploaded_at){ // old ones are null.
        const upload_date = new Date(this.tune.attributes.uploaded_at);
        const diff_t = this.now.getTime() - upload_date.getTime();
        const diff_d = diff_t / (1000 * 3600 * 24);
        //console.log('uploaded: ', diff_d + ' days ago', upload_date, this.now);
        if(diff_d >= this.old_upload_limit_days){
          //console.log('Give up! This upload appears to be OLD! Offer to delete it');
          clearInterval(this.ready_poll_interval);
          this.old_upload = true;
          this.failed_upload = true;
        }
      }

      if(this.check_count >= this.check_count_limit){
        // give up...
        clearInterval(this.ready_poll_interval);
        this.failed_upload = true;
      }
    });
  }

  ngOnInit(): void {
    this.check_count = 0;
    // console.log('this tune: ', this.tune);
    // this.tune._can_first_spin = this.tune.attributes.is_ready_to_stream && !this.tune.attributes.is_private && !this.tune.attributes.first_spin_at && (this.tune._is_owner || this.tune._is_manager) && this.tune.artist._subscriptions && this.tune.artist._subscriptions[0].attributes.bonus_first_spins_remaining > 0;    
    // console.log('canFirstSpin: ', this.tune._can_first_spin, this.tune.attributes.name);
    this.path = '/profile/'+this.tune.artist.attributes.permalink+'/tunes/'+this.tune.id;
    if(!this.tune.attributes.is_ready_to_stream){
      // console.log('TuneCardComponent: Tune is not ready to stream yet...', this.tune.attributes.name);
      if (typeof window === "undefined") {
        return;
      }
      this.ready_poll_interval = setInterval(() => {
        this.checkTune();
      }, 5000);
    }
    // if(this.tune.meta.entry_id){
    //   console.log('tune card for playlistV2 entry', this.tune.meta.entry_id);
    // }
  }

  deleteFailedUpload(tune:TuneV2){
    //console.log('delete failed tune: ', tune);
    this.api_tunes.deleteTune(tune).subscribe((data)=> {
      //console.log('delete failed tune ok: ', data);
      this.snackbar.show('The failed tune was deleted');
      this.router.navigate(['/profile']);
    },
    (error)=> {
      this.snackbar.show('Error deleting failed tune: ' + error.message);
    });
  }

  // TUNE PURCHASING FROM THE CARD
  buy() {
    console.log('Buy: ', this.tune.attributes.name);
    if (!this.tune.attributes.is_ready_to_stream || this.tune.attributes.distribution.preference !== 'sell') {
      this.snackbar.show('This tune is not available for sale.');
      return;
    }
    // open dialog
    if (typeof window === "undefined") {
      return; // SSR
    }
    const dialogRef = this.dialog.open(DialogPurchaseComponent, {
      width: '500px',
      panelClass: 'dialogBuySubscription',
      data: { resource: this.tune },
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(data => {
      // console.log('The purchasing dialog was closed: ', data);
      if (data && data.resource) {
        // console.log('Start purchase of ', data.resource);
        // manually control the indicator here, so keep it showing as we redirect.
        this.loadingIndicatorService.startLoading();

        this.api_tunes.purchaseResource(data.resource).subscribe((data:PaymentV2) => {
          // console.log('redirect to payment gateway: ', data);
          // this.is_redirecting = true; // Show an indicator while we redirect.
          if (data.attributes.payment_approval_url) {
            // console.log('redirect user for payment to : ', data.attributes.payment_approval_url);

            window.open(data.attributes.payment_approval_url, '_self');
            return;
            // On returning from the payment gateway, the user is sent to /payments/:gateway/purchases to complete the transaction.
          }
        },
        (error)=> {
          console.log('Error: ', error);
          this.loadingIndicatorService.stopLoading();
          this.snackbar.show('There was an error initiating the purchase: '+error.message);
        });
      }
    });
  }

  // DOWNLOADING FROM THE CARD...
  downloadTune() {
    if(!this.userService.loggedIn()){
      this.snackbar.snackbarRef = this.snackbar.snackBar.open('You need to be logged in to download free tunes.','Log In', {
        duration:3000,
        horizontalPosition:'end',
        verticalPosition:'bottom'
      });
      this.snackbar.snackbarRef.onAction().subscribe(() => {
          if(typeof window !== 'undefined'){
            localStorage.setItem('return_path', '/profile/'+this.tune.artist.attributes.permalink+'/tunes/'+this.tune.id);
          }
          this.router.navigate(['/connect']);
      });
      return;
    }
    if (this.is_downloading || this.has_downloaded) {
      return;
    }
    if (this.list_parent !== 'user-downloads' && (this.tune.attributes.is_private || !this.tune.attributes.is_ready_to_stream || this.tune.attributes.distribution.preference !== 'free_download')) {
      this.snackbar.show('This tune is not available for free download.');
      return;
    }

    this.renderer.addClass(this.downloadButton.nativeElement, 'downloading');

    this.api_tunes.downloadTune(this.tune).subscribe((data) => {
      // console.log('Download tune info:', data.meta.download_url);
      if (data.meta && data.meta.download_url) {
        // this.renderer.addClass(this.downloadButton.nativeElement, 'downloading');
        this.is_downloading = true;
        this.api_dl.download(data.meta.download_url, this.tune).subscribe(
          (progress_percentage) => {
            console.log('received download progress: ', progress_percentage);
            this.downloadButton.nativeElement.innerHTML = progress_percentage + '%';
          },
          (error) => {
            console.log('Error: ', error);
            this.is_downloading = false;
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.snackbar.show('Error downloading tune: ' + error.message);
          },
          () => {
            console.log('Complete!');
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.downloadButton.nativeElement.innerHTML = 'cloud_done';
            this.is_downloading = false;
            this.has_downloaded = true;
            // do all tunes have .meta now?
            // This will swap the div/buttons
            // if (this.tune.meta) {
            //   this.tune.meta.download_url = data.meta.download_url;
            // } else {
            //   this.tune.meta = { download_url: data.meta.download_url }
            // }
          }
        );
      }
    },
    (error) => {
      console.log('Download Error!', error);
      this.snackbar.show(error.message, 'snackbarWarning');
    });
  }



}

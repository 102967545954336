
<section>
  <div class="innerContent">

    <div class="logo-cont"></div>

  	<div class="footerColumns">

      <ul class="logo" aria-hidden="true">
        <a (click)="goHome()"><img class="amazing-logo" [src]="amazing_logo"/></a>
      </ul>

	    <ul class="information">
	    	<li>Information</li>
        <li *ngIf="site_country === 'us'"><a role="link" (click)="goToOtherCountrySite($event)" [href]="is_dev ? site_urls.dev.uk : site_urls.live.uk" target="_blank">Amazing Radio UK</a></li>
        <li *ngIf="site_country === 'uk'"><a role="link" (click)="goToOtherCountrySite($event)" [href]="is_dev ? site_urls.dev.us : site_urls.live.us" target="_blank">Amazing Radio USA</a></li>

        <li *ngIf="site_country === 'us'"><a role="link" href="https://amazing.us/" target="_blank">About us</a></li>
        <li *ngIf="site_country === 'uk'"><a role="link" href="https://amazing.media/" target="_blank">About us</a></li>
        <li role="link" routerLink="/faq">FAQ</li>
        <li role="link" routerLink="/benefits">Benefits</li>
	      <li role="link" routerLink="/features">Features</li>
        <li role="link" routerLink="/contact">Contact</li>
        <li role="link" routerLink="/terms" fragment="refunds">Refunds</li>
        <li role="link" routerLink="/press">Press</li>
        <li><a href="https://amazing.media/branding" target="_blank">Branding</a></li>
	    </ul>

	    <ul class="account">
        <li>Account</li>
	      <li *ngIf="!logged_in_user" role="link" routerLink="/connect">Log In</li>
        <li *ngIf="!logged_in_user" role="link" routerLink="/connect/signup">Upload</li>
        <li *ngIf="logged_in_user" role="link" routerLink="/profile">My Profile</li>
        <li *ngIf="logged_in_user && logged_in_user._subscriptions" role="link" [routerLink]="['/profile', logged_in_user.attributes.permalink, 'subscription']">Manage Subscription</li>
        <li *ngIf="logged_in_user" role="link" routerLink="/connect/logout">Log Out</li>
	    </ul>

      <ul class="apps">
	    	<li>Apps</li>
	      <li><a role="link" href="https://market.android.com/details?id=com.amazingmedia.radio1" target="_blank">Google Play</a></li>
	      <li><a role="link" href="https://apps.apple.com/gb/app/amazingradio/id476404037" target="_blank">Apple App Store</a></li>
	    </ul>

  	</div>

  	<div class="socialIcons">
  		<a target="_blank" role="link" aria-label="Visit Amazing on Facebook" class="facebook" [href]="social_facebook"></a>
			<a target="_blank" role="link" aria-label="Visit Amazing on Twitter" class="twitter" [href]="social_twitter"></a>
			<a target="_blank" role="link" aria-label="Visit Amazing on Instagram" class="instagram" [href]="social_instagram"></a>
      <a target="_blank" role="link" aria-label="Visit Amazing on YouTube" class="youtube" [href]="social_youtube"></a>
      <a target="_blank" role="link" aria-label="Visit Amazing on TikTok" class="tiktok" [href]="social_tiktok"></a>
      <a target="_blank" role="link" aria-label="Visit Amazing on Threads" class="threads" [href]="social_threads"></a>

    </div>

  
  	<div class="copyright" aria-hidden="true"><span *ngIf="site_country === 'us'">&copy; Amazing America {{ dateYear }} - </span><a target="_blank" href="https://theamazinggroup.com/">&copy; The Amazing Group 2007-{{ dateYear }}</a></div>

  	<div class="subLinks">
  		<a role="link" routerLink="/terms">Terms</a>
  		<a role="link" routerLink="/privacy">Cookies &amp; Privacy</a>
      <a role="link" *ngIf="is_reportable" (click)="contentReport()">Report a Problem</a>
  	</div>

  </div>
</section>

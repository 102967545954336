import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeWideComponent } from '@app/main/components/home/home-wide.component';
import { NotFoundComponent } from '@app/main/components/common/not-found/not-found.component';

let routes: Routes = [
  { path: '', component: HomeWideComponent },
  // Send people here if things go wrong. (eg: bad post slugs which we only know are wrong until after trying to get data)
  { path: '404', component: NotFoundComponent },
  // Payment Gateway
  { path: 'payments', loadChildren: () => import('@modules/payments/payments.module').then(mod => mod.PaymentsModule) },
  // Membership plan purchase pages. (Alternative to the Dialogs to allow for more room to explain things)
  { path: 'benefits', loadChildren: () => import('@app/modules/benefits/benefits.module').then(mod => mod.BenefitsModule) },
  { path: 'pricing', redirectTo:'/benefits', pathMatch:'full' },

  { path: 'store', loadChildren: () => import('@modules/store/store.module').then(mod => mod.StoreModule) },

  // Genre Streams
  { path: 'genre-streams', loadChildren: () => import('@modules/genre-streams/genre-streams.module').then(mod => mod.GenreStreamsModule) },
  { path: 'genres', redirectTo:'genre-streams'},

  // Now Playing. Show the current show and tune.
  { path: 'nowplaying', loadChildren: () => import('@app/modules/now-playing/now-playing.module').then(mod => mod.NowPlayingModule) },
  { path: 'onair', loadChildren: () => import('@app/modules/now-playing/now-playing.module').then(mod => mod.NowPlayingModule) },
  // Features pages
  { path: 'features', loadChildren: () => import('@app/modules/features/features.module').then(mod => mod.FeaturesModule) },
  // downloads  :?token=......
  { path: 'downloads', loadChildren: () => import('@modules/downloads/downloads.module').then(mod => mod.DownloadsModule) },
  // Login, Signup, Confirm PW reset.
  { path: 'connect', loadChildren: () => import('@modules/connect/connect.module').then(mod => mod.ConnectModule) },
  // Recently played On Air
  { path: 'aired', loadChildren: () => import('@modules/aired/aired.module').then(mod => mod.AiredModule) },
  // Latest Uploads
  { path: 'latest', loadChildren: () => import('@modules/latest/latest.module').then(mod => mod.LatestModule) },
  // First Spins (duplicate of Latest for now. Does UK have 'First Spin'?)
  { path: 'firstspin', loadChildren: () => import('@modules/firstspin/firstspin.module').then(mod => mod.FirstspinModule) },

  // Info pages now done like below with the WpPageModule.
  // Page comes from a WP Page by its 'slug'. Set below. Uses modules/_shared/wp-page/wp-page.module
  // About Uploads / Subscriptions etc.
  // { path: 'uploads', data: { slug: 'uploads' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'faq', data: { slug: 'faq' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'contact', data: { slug: 'contact' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'press', data: { slug: 'press' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'terms', data: { slug: 'terms' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'cookies', data: { slug: 'cookies' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  { path: 'privacy', data: { slug: 'cookies' }, loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  // Other promos etc. 
  { path: 'page/:slug', loadChildren: () => import('@app/modules/_shared/wp-page/wp-page.module').then(mod => mod.WpPageModule) },
  // Schedule
  { path: 'schedule', loadChildren: () => import('@modules/schedule/schedule.module').then(mod => mod.ScheduleModule) },
  // Chart (when needed)
  { path: 'chart', loadChildren: () => import('@modules/chart/chart.module').then(mod => mod.ChartModule) },
  { path: 'chartarchive', redirectTo:'/chart/archive' },
  // Playlists US : Genres. : jmjhedley
  { path: 'playlists', loadChildren: () => import('@app/modules/playlists/playlists.module').then(mod => mod.PlaylistsModule) },
  // Just route this singular version too.
  // Playlists UK : A/B/C etc.: stevenamazing
  { path: 'playlist', loadChildren: () => import('@app/modules/playlists/playlists.module').then(mod => mod.PlaylistsModule) },

  // Search
  { path: 'search', loadChildren: () => import('@modules/search/search.module').then(mod => mod.SearchModule) },
  // Shows
  // /shows/:show_slug
  { path: 'shows', loadChildren: () => import('@modules/shows/shows.module').then(mod => mod.ShowsModule) },
  { path: 'cmj-music-marathon', redirectTo:'/shows/cmj-music-marathon' },

  { path: 'thetakeover', loadChildren: () => import('@modules/shows/shows.module').then(mod => mod.ShowsModule) },
  // One Night Stand
  { path: 'one-night-stand', loadChildren: () => import('@modules/one-night-stand/one-night-stand.module').then(mod => mod.OneNightStandModule) },
  // Profile. User's profile / all their uploaded content and interactions etc.
  { path: 'profile', loadChildren: () => import('@modules/profile/profile.module').then(mod => mod.ProfileModule) },
  // Use a resolver to determine if the player. subdomain is being used (by embedded iframes, Twitter etc.)
  { path: 'oembed', loadChildren: () => import('@modules/oembed/oembed.module').then(mod => mod.OembedModule) },
  // /news module Wordpress content.
  { path: 'news', loadChildren: () => import('@modules/news/news.module').then(mod => mod.NewsModule) },
  // Support /home to catch some WP news links
  { path: 'home', loadChildren: () => import('@modules/news/news.module').then(mod => mod.NewsModule) },

  // First Look videos
  { path: 'firstlook', loadChildren: () => import('@app/modules/first-look/first-look.module').then(mod => mod.FirstLookModule) },

  // Tips
  { path: 'tips', loadChildren: () => import('@modules/tips/tips.module').then(mod => mod.TipsModule) },

  // Terms & Services pages
  { path: 'terms-and-services', loadChildren: () => import('@modules/terms-and-services/terms-and-services.module').then(mod => mod.TermsAndServicesModule) },

  // Hall of Fame
  { path: 'halloffame', loadChildren: () => import('@modules/hall-of-fame/hall-of-fame.module').then(mod => mod.HallOfFameModule) },

  // Testimonials
  { path: 'testimonials', loadChildren: () => import('@modules/testimonials/testimonials.module').then(mod => mod.TestimonialsModule) },

  // Dev Empty module layout template
  { path: '_template', loadChildren: () => import('@modules/_template/_template.module').then(mod => mod.TemplateModule) },

];

routes.push(
  { path: 'sessions', loadChildren: () => import('@modules/sessions/sessions.module').then(mod => mod.SessionsModule) },
);

// Add these last (both sites)
routes.push(
  // (old) WP post urls.
  { path: 'category/:parent_category/:post_category', loadChildren: () => import('@modules/news/news.module').then(mod => mod.NewsModule) },
  // Custom routing for some shows
  { path: "afternoons", redirectTo:"/shows/amazing-afternoons-with-frankie-francis" },
  { path: "audition", redirectTo:"/shows/audition" },
  { path: "albumoftheweek", redirectTo:"/shows/album-of-the-week" },
  { path: "**", component: NotFoundComponent },
);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

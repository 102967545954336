import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserV2 } from '@models/user-v2';
import { UserService } from '@services/user.service';
@Component({
  selector: 'app-user-card-v3',
  templateUrl: './user-card-v3.component.html',
  styleUrls: ['./user-card-v3.component.scss'],
  inputs: ['user', 'router_link', 'image_url', 'hover_title', 'name', 'show_action']
})
export class UserCardV3Component implements OnInit {

  public user:UserV2;

  public router_link?:string[] | string;   // override. user profile by default.
  public image_url?:string;     // override. user profile image by default.
  public hover_title?:string;   // optional.
  public name?:string;          // override the name/title
  public show_action:boolean;     // optionally show like button (for label artists list)

  constructor(private userService:UserService, private router:Router) { }

  ngOnInit(): void {
  }

  editUser(event:any){
    event.stopPropagation();
    this.router.navigate(['/profile', this.userService.get().attributes.permalink, 'artists', this.user.attributes.permalink_in_label, 'edit']);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Rewind, ShowSchedule } from '@models/show';
import { DatePipe } from '@angular/common';

// Transforms Show archive airings to the 'Rewind' model already being used by the PlayerService to show the right info and links
@Pipe({
  name: 'airingRewind'
})
export class AiringRewindPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(airing: ShowSchedule): Rewind {
    return {
      airing_id: airing.id,
      show_id: airing.show_id,
      s3_url: airing.rewind_url, //  + '?ngsw-bypass=true',
      broadcast_from: airing.start_time,
      broadcast_to: airing.end_time,
      slug: airing.slug,
      plain_slug: airing.plain_slug,
      display_artist: airing.show_name,
      display_title: this.datePipe.transform(airing.start_time, 'EEEE MMMM d, yyyy'),
      show_route: '/shows/'+airing.show_id,
      image_url: airing.square_300_image,
      image_wide_url: airing.show?.homepage_image_wide,
      presenter: airing.presenter,
      presenter_covering: airing.presenter_covering
    };
  }
}

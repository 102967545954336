import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { SnackbarService } from '@services/snackbar.service';
import { PollService } from '@services/poll.service';
// The embedded playlist poll players in posts. That's taking the p's!

// Gets registered as a Custom Element in app.module.ts
// causing the browser to simply render our component when it sees <embed-playlist-poll-v2 id="123"> tags
// Wordpress responds with this format using the ?_angular2=true query parameter.
// This response is created in the 'amazingtunes-playlist-polls' plugin.

import { PlayerService } from '@modules/_shared/player/player.service';
import { TuneV2 } from '@models/tune-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { PlaylistPoll } from '@models/wp-post';
import { ApiAmazingradioWpService } from '@services/api-amazingradio-wp.service';
import { UserService } from '@services/user.service';
import { environment } from '@env/environment';

// Forms models
class PollingFormModel {
  constructor(
    public vote?: string,
  ) { }
}

@Component({
  selector: 'app-playlist-polls-embed-v2',
  templateUrl: './playlist-polls-embed-v2.component.html',
  styleUrls: ['./playlist-polls-embed-v2.component.scss'],
  inputs: ['id', 'playlist_poll'] // the playlist poll id on WP.

})
export class PlaylistPollsEmbedV2Component implements OnInit {

  public pollVote: PollingFormModel;

  public disabled: boolean = false;
  public has_user_voted: boolean = false;
  public voting: boolean = false;
  public just_voted:boolean = false;
  public playlist_poll: PlaylistPoll;
  public playlist: TuneV2[] = [];

  public playlistHead:PlaylistV2;

  // playlist-polls/:id
  public id: number;
  public voted_tune_id: any;

  public has_ended:boolean = false;
  public show_results_after_vote:boolean = false;
  public show_results_at_end:boolean = false;

  private order:boolean = false;

  @ViewChild('pollingForm') pollingForm: NgForm;

  constructor(private pollService:PollService,  private snackbar: SnackbarService, private userService: UserService, public player: PlayerService, private api_wp: ApiAmazingradioWpService) {
    this.pollVote = new PollingFormModel();
  }

  vote() {
    if (this.has_user_voted) {
      return;
    }
    // Do the voting with thw HTTPClient here. (Rather than mix it in with eveything else)
    const polling_endpoint = environment.wp_api_host + '/wp-admin/admin-ajax.php?action=ppollvote';

    // console.log('VOTE!: ', this.voted_tune_id, polling_endpoint);

    let poll_data: any = {
      poll_id: this.playlist_poll.id,
      votes: [this.voted_tune_id]    // An array. Usually 1, but just in case we need to support multiple choices in the future.
    }
    if (this.userService.loggedIn()) {
      poll_data.amazing_id = this.userService.getId();
      poll_data.amazing_permalink = this.userService.get().attributes.permalink;
    }
    this.disabled = true;
    this.voting = true; // show a spinner

    // console.log('POSTING poll data.. Poll data', poll_data);

    this.api_wp.pollVote(poll_data).subscribe((data:PlaylistPoll) => {
      // console.log('pollVote response: ', data);
      this.snackbar.show('Thanks for your vote!');
      this.playlist_poll = data;
      this.voting = false;
      this.just_voted = true;
      // Update the service to update the show-tab-poll comp. data
      this.pollService.set(data);
      this.initPoll();
    },
    (error) => {
      console.log('Polling error: ', error);
      this.snackbar.show('Hmm.. There was an error casting your vote.');
    });

    // this.http.post<any>(polling_endpoint, poll_data, { withCredentials: true }).subscribe((data) => {
    //   this.snackbar.show('Thanks for your vote!');
    //   console.log('Poll Vote Response: ', data);

    //   this.getPlaylistPoll(this.id);
    // },
    // (error)=> {
    //   console.log('Polling error: ', error);
    //   this.snackbar.show('Hmm.. There was an error casting your vote.');
    // });
  }

  getPlaylistPoll(id: number) {
    // console.log('getPlaylistPoll:id:', id);
    this.api_wp.getPlaylistPoll(id).subscribe((data) => {
      // console.log('Playlist Poll data: ', data);
      if (!data.playlist_json) {
        console.log('No playlist JSON!');
        return;
      }
      this.playlist_poll = data;
      this.initPoll();
      this.voting = false; // Hides spinner after a vote.
    },
    (error)=> {
      console.log('Error getting Poll data');
      this.snackbar.show('Oh dear. There was an error getting the poll data.');

    });
  }

  initPoll() {

    // console.log('initPoll: playlist_poll:', this.playlist_poll);

    if (this.playlist_poll.voted) {
      this.voted_tune_id = this.playlist_poll.voted[0];
      this.has_user_voted = true;
      this.disabled = true;
      // console.log('The user has already voted: tune id:', this.voted_tune_id );
    }
    if(!this.playlist_poll.is_active){
      this.disabled = true;
      //console.log('The poll is not active');
    }
    if(this.playlist_poll.in_past){
      this.disabled = true;
      this.has_ended = true;
      //console.log('The poll has ended');
    }

    if(this.playlist_poll.total_votes > 0){
      if(this.order || (this.playlist_poll.voted && this.playlist_poll.show_results_after_vote) || (!this.playlist_poll.is_active && this.playlist_poll.show_results_at_end) ){
        // console.log('Re-order tunes by vote percentages..');
        this.playlist_poll.playlist_json._tunes.sort(function (a, b) {
          return b.meta.percentage - a.meta.percentage;
        });
      }
    }

    this.playlist = [];
    this.playlist_poll.playlist_json._tunes.forEach(tune => {
      this.playlist.push(tune);
    });

    this.playlistHead = {
      type:'playlist',
      id:'audition-poll',
      _router_link: '/shows/audition/poll',
      attributes: {
        name: this.playlist_poll.title.rendered
      }
    }

  }

  ngOnInit(): void {

    if (this.playlist_poll) {
      // Show poll (Audition) data from WP also provides the full Playlist Poll JSON. Used by Show Page Tab for Audition Poll.
      // console.log('PLAYLIST POLL DATA provided: ', this.playlist_poll);
      this.initPoll();
      return;
    }
    // If no playlist data supplied via component property.
    // console.log('Playlist poll ID: ', this.id);
    if (isNaN(this.id)) {
      // console.log('Poll id was not a number.');
      return;
    }
    if (this.id) {
      this.getPlaylistPoll(this.id);
    }
  }
}


import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlaylistPoll } from '@models/wp-post';
@Injectable({
  providedIn: 'root'
})
export class PollService {

  public playlistPoll:PlaylistPoll;
  public pollDataUpdate: BehaviorSubject<PlaylistPoll> = new BehaviorSubject(null);

  constructor() { }

  get():PlaylistPoll {
    return this.playlistPoll;
  }

  set(data:PlaylistPoll){
    this.playlistPoll = data;
    this.pollDataUpdate.next(this.playlistPoll);
  }

}

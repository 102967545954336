<!-- Player embeds component -->

<!-- Individual V2 tune player -->
<div *ngIf="type === 'tune' && tune" class="playerEmbed tunePlayer noselect">
  <img [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png" routerLink="/profile/{{ tune.artist.attributes.permalink }}/tunes/{{ tune.id }}">

  <app-play-button-v2 addClass="playerEmbedPosts" class="playButton" [tune]="tune"></app-play-button-v2>

  <div class="tuneMeta">
    <div class="tuneArtist" [routerLink]="['/profile', tune.artist.attributes.permalink]" [innerHTML]="tune.artist.attributes.name"></div>
    <div class="tuneTitle" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id]" [innerHTML]="tune.attributes.name"></div>
  </div>

</div>

<!-- V2 Collection player -->
<div *ngIf="type === 'product' && collection" class="playerEmbed collectionPlayer noselect">
  <div class="collectionInfo">
    <img [lazyImgSrc]="collection.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png" class="collectionImage" [routerLink]="['/profile', collection.artist.attributes.permalink, collection_routing[collection.attributes.type], collection.id]">
    <div class="collectionMeta">
      <div class="collectionArtist" [routerLink]="['/profile', collection.artist.attributes.permalink]" [innerHTML]="collection.artist.attributes.name"></div>
      <div class="collectionName" [routerLink]="['/profile', collection.artist.attributes.permalink, collection_routing[collection.attributes.type], collection.id]" [innerHTML]="collection.attributes.name"></div>
    </div>
  </div>
  <div class="tunesList">
    <div class="collectionTune" *ngFor="let tune of collection._tunes; let i = index">
      <app-play-button-v2 class="playButton" addClass="playerEmbedPosts" [index]="i" [playlist]="collection._tunes" [tune]="tune"></app-play-button-v2>
      <img [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png" routerLink="/profile/{{ tune.artist.attributes.permalink }}/tunes/{{ tune.id }}">
      <div class="collectionTuneMeta">
        <div *ngIf="collection.artist.id !== tune.artist.id" class="tuneArtist" [innerHTML]="tune.artist.attributes.name"></div>
        <div class="tuneTitle" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id]" [innerHTML]="tune.attributes.name"></div>
      </div>
    </div>
  </div>
</div>

<!-- Playlist player V1 playlist with V2 tunes.  (Now supports transformed V2 data from updated WP plugin) -->
<div *ngIf="type === 'playlist' && playlist" class="playerEmbed playlistPlayer noselect">
  <div class="playlistInfo">
    <img [lazyImgSrc]="playlist.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png" class="playlistImage" routerLink="/profile/{{ playlist._curator.attributes.permalink }}/playlists/{{ playlist.id }}">
    <div class="playlistMeta">
      <div class="playlistName">{{playlist.attributes.name}}</div>
      <div class="playlistAuthor">playlist by <em>{{playlist._curator.attributes.name}}</em> ({{playlist._tunes.length}} tunes)</div>
    </div>
  </div>
  <div class="tunesList">
    <div class="playlistTune" *ngFor="let tune of playlist._tunes; let i = index">
      <app-play-button-v2 class="playButton" addClass="playerEmbedPosts" [index]="i" [playlist]="playlist._tunes" [tune]="tune"></app-play-button-v2>
      <img [lazyImgSrc]="tune.attributes.image_urls.medium" routerLink="/profile/{{ tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" src="/assets/placeholders/placeholder-square-dark-300.png" class="playlistTuneImage">
      <div class="tuneMeta">
        <div class="tuneArtist" [routerLink]="['/profile', tune.artist.attributes.permalink]" [innerHTML]="tune.artist.attributes.name"></div>
        <div class="tuneTitle" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id]" [innerHTML]="tune.attributes.name"></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type === 'airing' && _rewind" class="playerEmbed tunePlayer noselect">
  <img [lazyImgSrc]="_rewind.image_url" src="/assets/placeholders/placeholder-square-dark-300.png" [routerLink]="'/shows' + path">
  <div class="playButton buttonContainer">
    <mat-icon (click)="player.toggleRewindPlayer(_rewind, null)"
    [class]="player.isLoading() && player.rewinds && player.getAudioSource().includes(_rewind.s3_url)
      ? 'spinner' : player.rewinds && player.playing && player.getAudioSource().includes(_rewind.s3_url)
        ? '' : ''"
    [svgIcon]="player.isLoading() && player.rewinds && player.getAudioSource().includes(_rewind.s3_url)
      ? 'buffering' : player.rewinds && player.playing && player.getAudioSource().includes(_rewind.s3_url)
        ? 'pause' : 'play'">
    </mat-icon>
  </div>

  <div class="tuneMeta">
    <div [title]="'Rewind: ' + _rewind.display_artist" class="rewind">Rewind</div>
    <div [title]="_rewind.display_artist" class="tuneArtist" [routerLink]="_rewind.show_route" [innerHTML]="_rewind.display_artist"></div>
    <div [title]="_rewind.display_title" class="tuneTitle" [routerLink]="_rewind.show_route + '/archive/' + _rewind.airing_id" [innerHTML]="_rewind.display_title"></div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { RadioChannel } from '@models/configurations';
import { PlaylistV2 } from '@models/playlist-v2';
import { BehaviorSubject } from 'rxjs';

// Gets the data about the current radio channel configured by the `environment.radio_channel` [uk|us]
// Also provides the list of radio playlists (A/B/C)

@Injectable({
  providedIn: 'root'
})
export class RadioChannelService {

  public radio_channel:RadioChannel;
  public radio_channel_subject: BehaviorSubject<RadioChannel> = new BehaviorSubject(null);
  public radio_playlists_subject: BehaviorSubject<PlaylistV2[]> = new BehaviorSubject(null);
  public radio_playlists:PlaylistV2[]; // 'A/B/C lists etc.

  constructor(private api_tunes:ApiAmazingtunesV2Service) {
    this.getRadioChannelData();
  }

  private getRadioChannelData() {
    this.api_tunes.getRadioChannel().subscribe(data => {
      // console.log('Radio Channel data:', data);
      this.radio_channel = data;
      this.radio_playlists = data.included;
      // console.log('%cRadio Playlists:', 'color:orange',this.radio_playlists);
      this.radio_channel_subject.next(this.radio_channel);
      this.radio_playlists_subject.next(this.radio_playlists);
    },
    error => {
      console.log('ERROR GETTING RADIO CONFIGURATION DATA!', error);
      // This would be bad...
    })
  }
}

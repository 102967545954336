import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompactArtist } from '@models/user-v2';

interface DialogData {
  artists_compact: CompactArtist[];
}

@Component({
  selector: 'app-dialog-label-artists',
  templateUrl: './dialog-label-artists.component.html',
  styleUrls: ['./dialog-label-artists.component.scss']
})
export class DialogLabelArtistsComponent implements OnInit {

  public artists_compact:CompactArtist[];

  constructor(
    public dialogRef: MatDialogRef<DialogLabelArtistsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.artists_compact = data.artists_compact;
  }

  selected(event:any){
    this.dialogRef.close(event.source._value[0]);
  }

  ngOnInit(): void {
  }

}

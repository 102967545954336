import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from "firebase/messaging";
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SnackbarService } from '@services/snackbar.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';

/**
 * Chrome settings : chrome://settings/content/notifications 
 * 
 */


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private messaging: any;
  public messageSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private is_listening: boolean = false;
  public is_supported: boolean = false;

  public hasNotificationEmitter = new EventEmitter<any>();

  constructor(private api_tunes: ApiAmazingtunesV2Service, private platform: Platform, private snackbar: SnackbarService, @Inject(PLATFORM_ID) private platformId: Object) {
    if (typeof window !== 'undefined') { //   && !this.platform.SAFARI && !this.platform.ANDROID
      this.isMessagingSupported().subscribe(res => {
        if(res){
          this.messaging = getMessaging();
          if (this.hasToken()) {
            this.startMessageListener();
          }      
        }
      });
    }
  }

  public requestPermission(): Promise<any> {
    // Request authorisation in the browser for notifications if no token exists. 
    if (!isPlatformBrowser(this.platformId)) {
      // Ignore for SSR
      return;
    }

    return getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
      .then(
        (token) => {
          if (token) {
            // store in localStorage.
            localStorage.setItem('amazing-notifications-token', token);
          } else {
            console.log('No registration token available. Request permission to generate one.');
            this.snackbar.show('Please click OK on the browser notifications permissions popup.', 'snackbarWarning');
          }
          if (!this.is_listening && token) {
            this.startMessageListener();
          }
          return token;
        }).catch((err) => {
          console.log('MessagingService: Error getting Firebase token');
          // Could be that authorisation was refused. 
          return err;
        });
  }

  public startMessageListener() {
    if (!isPlatformBrowser(this.platformId) || this.is_listening) {
      return;
    }
    // console.log('Listening for notifications...');
    this.is_listening = true;
    try {
      onMessage(this.messaging, (payload) => {
        // console.log('%cNotification message recieved: ', 'color:cyan', payload);
        this.messageSubject.next(payload);  // subscribed to in app.component.ts
      });
    } catch(err) {
      console.log('Messaging onMessage init error:', err);
    }
  }

  public hasToken(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    return !!localStorage.getItem('amazing-notifications-token');
  }

  public getStoredToken(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return localStorage.getItem('amazing-notifications-token');
  }

  public getStoredNotifications(): any {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return JSON.parse(localStorage.getItem('amazing-notifications'));
  }

  public clearToken() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    localStorage.removeItem('amazing-notifications-token');
  }

  public deleteFirebaseToken() {
    return deleteToken(this.messaging).then(data => {
      this.clearToken();
    }).catch(error => {
      console.log('deleteToken ERROR: ', error);
    });
  }

  public registerNotification(show_uuid?: string): Observable<any> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Just request permission every time. If it's already granted, the same token will be returned...
    return new Observable(subscriber => {
      this.requestPermission().then(token => {
        this.api_tunes.registerNotification(token, show_uuid).subscribe(
          (data) => {
            this.api_tunes.getNotifications(token).subscribe(data => {
              // emit to any other bells on the page
              this.hasNotificationEmitter.emit({ has_notification: true, uuid: show_uuid })
              subscriber.next(data);
            });
          },
          (error) => {
            console.log('Error registering notification', error);
            subscriber.error(error);
          });
      }).catch(err => {
        // console.log('ERROR GETTING A TOKEN', err);
        subscriber.error(err);
      });
    });

  }

  public deleteNotification(show_uuid?: string): Observable<any> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    return new Observable(subscriber => {
      const _token = this.getStoredToken();
      if (!_token) {
        subscriber.error('No stored token');
      }
      this.api_tunes.deleteNotification(_token, show_uuid).subscribe(
        (data) => {
          this.api_tunes.getNotifications(_token).subscribe(data => {
            // emit to any other bells on the page
            this.hasNotificationEmitter.emit({ has_notification: false, uuid: show_uuid })
            subscriber.next(data);
          });
        },
        (error) => {
          subscriber.error(error);
        });
    });
  }

  public checkShowNotification(token: string, show_uuid: string): Observable<boolean> {
    return new Observable(subscriber => {
      if (typeof window === 'undefined') {
        return;
      }
      // Used localStorage to deal with ExpressionChangedAfterItHasBeenCheckedError which happens if the show-notification-bell has to check state via the API.
      // The value is updated each time api_tunes.getNotifications is called. 
      // Which also happens after toggling. 
      let _notifications = JSON.parse(localStorage.getItem('amazing-notifications'));
      if (_notifications) {
        const _match = (e: any) => e.attributes.target == show_uuid && e.attributes.channel == 'radio_show/' + environment.site_country;
        const _index: number = _notifications.data.findIndex(_match);
        subscriber.next(_index > -1);
      } else {
        this.api_tunes.getNotifications(token).subscribe(data => {
          const _match = (e: any) => e.attributes.target == show_uuid && e.attributes.channel == 'radio_show/' + environment.site_country;
          const _index: number = data.findIndex(_match);
          subscriber.next(_index > -1);
        });
      }
    });
  }

  public isMessagingSupported() : Observable<boolean> {
    return new Observable(subscriber => {
      isSupported().then(res => {
        if(!res){
          console.log('%cMessaging not supported!', 'color:red');
        }
        this.is_supported = res;
        subscriber.next(this.is_supported);
      }).catch(err => {
        console.log('Error checking if messaging is supported');
        subscriber.next(false);
      });
    });
  }

}

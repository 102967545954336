import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchFormService {

  public term: string;
  public formTerm:Subject<string> = new Subject();
  public origins:string;
  public originsSubject:Subject<string> = new Subject();

  constructor() {
    this.formTerm.next(this.term);
  }

  updateTerm(term: string) {
    this.term = term;
    this.formTerm.next(this.term);
  }

  updateOrigins(origins: string) {
    this.origins = origins;
    // this.originsSubject.next(this.origins);
  }

}

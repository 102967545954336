<div class="cont">
  <div class="listCont">
    <h6>Please select an artist</h6>

    <mat-selection-list [multiple]="false" (selectionChange)="selected($event)">
      <mat-list-option *ngFor="let artist of artists_compact" [value]="artist">
        <div class="option">
          <div class="name" [innerHTML]="artist.name"></div>
        </div>
      </mat-list-option>
    </mat-selection-list>

  </div>
</div>

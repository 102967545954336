import { Component, OnInit, OnChanges, Inject, PLATFORM_ID, SimpleChange, SimpleChanges } from '@angular/core';
import { UrlEncodePipe } from '@modules/_shared/ui/pipes/url-encode.pipe';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { Clipboard } from "@angular/cdk/clipboard"
import { SnackbarService } from '@services/snackbar.service';
import { TuneV2 } from '@models/tune-v2';

// Exra Share buttons for the player bar options menu.

@Component({
  selector: 'app-action-player-buttons',
  templateUrl: './action-player-buttons.component.html',
  styleUrls: ['./action-player-buttons.component.scss'],
  inputs: ['tune']
})
export class ActionPlayerButtonsComponent implements OnInit, OnChanges {

  public path: string;
  public site_url: string;
  public tune:TuneV2;

  constructor(private snackbar:SnackbarService, private clipBoard:Clipboard, @Inject(PLATFORM_ID) private platformId: Object, private urlEncode: UrlEncodePipe) {
    this.site_url = environment.site_url;
  }

  ngOnInit(): void {
    this.path = '/profile/' + this.tune.artist.attributes.permalink+'/tunes/'+this.tune.id;

  }

  ngOnChanges(changes:SimpleChanges){
    // update the path when the tune data updates via the player bar.
    this.path = '/profile/' + this.tune.artist.attributes.permalink+'/tunes/'+this.tune.id;
    // console.log('Player actions for tune update: ', this.tune);
  }

  shareTwitter() {
    if (isPlatformBrowser(this.platformId)) {
      let _url = 'https://twitter.com/intent/tweet?url=' + this.urlEncode.transform(this.site_url + this.path) + '&text=' + this.urlEncode.transform(this.tune.artist.attributes.name + ' - ' + this.tune.attributes.name);
      window.open(_url, '_blank', 'width=500,height=450,left=200,top=200');
    }
  }

  shareFacebook() {
    if (isPlatformBrowser(this.platformId)) {
      let _url = 'https://www.facebook.com/share.php?u=' + this.urlEncode.transform(this.site_url + this.path) + '&title=' + this.urlEncode.transform(this.tune.artist.attributes.name + ' - ' + this.tune.attributes.name);
      window.open(_url, '_blank', 'width=500,height=450,left=200,top=200');
    }
  }

  copyToClipboard(){
    if (isPlatformBrowser(this.platformId)) {
      this.clipBoard.copy(this.site_url + this.path);
      this.snackbar.show('URL copied to clipboard');
    }
  }

}

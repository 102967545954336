import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
// Could be useful. Though can be repetitve.
// import { LiveAnnouncer } from '@angular/cdk/a11y';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {

  private loading: boolean = false;
  loadingStatus: Subject<any>;
  constructor(
    // private liveAnnouncer:LiveAnnouncer
  ) {
    this.loadingStatus = new Subject<any>();
  }

  startLoading(url?:string) {
    // console.log('%c>>> LOADING ...', 'color:red', url);
    this.loading = true;
    this.loadingStatus?.next(this.loading);
    // this.liveAnnouncer.announce('Loading data');
  }

  stopLoading(url?:string) {
    // console.log('%cDONE LOADING <<<', 'color:green', url);
    this.loading = false;
    this.loadingStatus?.next(this.loading);
    // this.liveAnnouncer.announce('Loading complete');

  }
}

<!-- <div class="header bg-dark">
  <div class="video-title" [class]="video ? 'artist-link':'' " (click)="goToArtistProfile()" [innerHTML]="video ? video.artist.attributes.name : video_title"></div>
  <div *ngIf="video || video_sub_title" class="video-subtitle" [innerHTML]="video_sub_title ? video_sub_title : video.attributes.name"></div>
</div> -->
<div class="cont">
  <app-hls-player class="hls-player" [playlist_controls]="false" [(state)]="state" [autoplay]="autoplay" [poster]="video.attributes.thumbnail.medium" [video]="video" [video_playlist]="video_playlist" [video_playlist_index]="video_playlist_index" ></app-hls-player>
  <div class="player-foot">
    <div *ngIf="video; else empty;" class="video-interactions">
      <div class="views-likes">
        <div class="views" *ngIf="video._stats; else empty;">
          <mat-icon inline="true" class="views-icon">visibility</mat-icon> <span [innerHTML]="video._stats.plays"></span>
        </div>
        <div class="likes" *ngIf="video._stats; else empty;">
          <mat-icon inline="true" class="likes-icon">favorite</mat-icon> <span [innerHTML]="video._stats.likes"></span>
        </div>
      </div>
      <div class="share-like">
        <div class="like" *ngIf="!video._is_owner && !video._is_manager">
          <div class="btn-like">
            <app-action-button-like [title]="(video.meta.liked_by_user ? 'Unlike':'Like') + ' video' " class="action" [resource]="video"></app-action-button-like>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="video && !has_no_playlist; else empty;" class="controls">
      <div (click)="previousVideo()">
        &lt; PREV
      </div>
      <div (click)="nextVideo()">
        NEXT &gt;
      </div>
    </div>
    <div class="share-close">
      <!-- <div *ngIf="video; else empty;" class="share">
        <app-share-options [hide_label]="true" [title]="video.attributes.name" [path]="'/profile/' + video.artist.attributes.permalink + '/videos/' + video.id"></app-share-options>
      </div> -->
      <div class="close" (click)="close()">
        <span class="txt">Close</span> <mat-icon inline="true" class="close-icon">close</mat-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #empty>
  <div></div>
</ng-template>

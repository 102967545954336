import { Injectable, EventEmitter } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

// Shared Breakpoint Observer.
// Handles SideNav and header comp burger button ...

// SCSS versions
// @mixin bp-large {  // below 1399px
//   @media (max-width: 1399px) {
//     @content;
//   }
// }
// @mixin bp-medium {  // below 1190px
//   @media (max-width: 1190px) {
//     @content;
//   }
// }
// @mixin bp-medium-small { // below 860px
//   @media (max-width: 860px) {
//     @content;
//   }
// }
// @mixin bp-small { // below 660px
//   @media (max-width: 660px) {
//     @content;
//   }
// }
// @mixin bp-x-small {  // below 415px
//   @media (max-width: 415px) {
//     @content;
//   }
// }
// @mixin bp-x-large { // over 1400px
//   @media (min-width: 1400px) {
//     @content;
//   }
// }


interface bp {
  [key: string]:boolean;
}

export interface Breakpoints {
  is_sidenav:boolean;
  is_x_large:boolean;
  is_large:boolean;
  is_medium:boolean;
  is_medium_small:boolean;
  is_small:boolean;
  is_x_small:boolean;
  current_breakpoint?:string;
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverService {

  // Targeting each specific breakpoint
  public bp_x_small:string  = '(max-width: 415px)';
  public bp_small:string  = '(min-width: 415px) and (max-width: 659px)';
  public bp_medium_small:string = '(min-width: 660px) and (max-width: 859px)';
  public bp_medium:string = '(min-width: 860px) and (max-width: 1189px)';
  public bp_large:string  = '(min-width: 1190px) and (max-width: 1399px)';
  public bp_x_large:string  = '(min-width: 1400px)';
  // When to hide the sidenav and show the burger
  public bp_sidenav:string  = '(max-width: 660px)';
  public matched_breakpoints: bp;

  public breakpoints:Breakpoints = {
    is_sidenav:false,
    is_x_large: false,
    is_large: false,
    is_medium: false,
    is_medium_small: false,
    is_small: false,
    is_x_small: false
  };

  public breakpointsSubject: BehaviorSubject<Breakpoints>;

  // helper to talk between the app.component and header.component (and loading indicator)
  public sideNavToggleEvent = new EventEmitter<any>();

  constructor(public bpo:BreakpointObserver) {

    this.breakpointsSubject = new BehaviorSubject(this.breakpoints);

    this.bpo.observe([this.bp_x_large, this.bp_sidenav, this.bp_large,  this.bp_medium, this.bp_medium_small, this.bp_small, this.bp_x_small])
      .subscribe((state: BreakpointState) => {
        // console.log(state.breakpoints);
        this.matched_breakpoints = state.breakpoints;
        this.breakpoints.is_sidenav = this.matched_breakpoints[this.bp_sidenav];

        this.breakpoints.is_x_large = this.matched_breakpoints[this.bp_x_large];
        if(this.breakpoints.is_x_large){
          this.breakpoints.current_breakpoint = 'x-large';
        }
        this.breakpoints.is_large = this.matched_breakpoints[this.bp_large];
        if(this.breakpoints.is_large){
          this.breakpoints.current_breakpoint = 'large';
        }
        this.breakpoints.is_medium = this.matched_breakpoints[this.bp_medium];
        if(this.breakpoints.is_medium){
          this.breakpoints.current_breakpoint = 'medium';
        }
        this.breakpoints.is_medium_small = this.matched_breakpoints[this.bp_medium_small];
        if(this.breakpoints.is_medium_small){
          this.breakpoints.current_breakpoint = 'medium-small';
        }
        this.breakpoints.is_small = this.matched_breakpoints[this.bp_small];
        if(this.breakpoints.is_small){
          this.breakpoints.current_breakpoint = 'small';
        }
        this.breakpoints.is_x_small = this.matched_breakpoints[this.bp_x_small];
        if(this.breakpoints.is_x_small){
          this.breakpoints.current_breakpoint = 'x-small';
        }
        // console.log( 'bp_sidenav :', this.matched_breakpoints[this.bp_sidenav] );
        // console.log( 'bp_x_large   :', this.matched_breakpoints[this.bp_x_large] );
        // console.log( 'bp_large   :', this.matched_breakpoints[this.bp_large] );
        // console.log( 'bp_medium  :', this.matched_breakpoints[this.bp_medium] );
        // console.log( 'bp_medium_small   :', this.matched_breakpoints[this.bp_medium_small] );
        // console.log( 'bp_small   :', this.matched_breakpoints[this.bp_small] );
        // console.log( 'bp_xsmall  :', this.matched_breakpoints[this.bp_x_small] );

        this.breakpointsSubject.next(this.breakpoints);


      });

  }

  // Sidenav handler
  public toggleSidenav():void {
    this.sideNavToggleEvent.emit();
  }

}

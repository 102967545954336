import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { ContentReport } from '@app/models/content-report';

interface DialogData {
  reportable:Reportable; // user|tune|collection|playlist|external_video
}

interface Reason {
  reason:string;
}

interface Reportable {
  id?: string;
  type?: string;
  attributes?: {
    name?: string;
  }
}

class ReportFormModel {
  constructor(
    public reason?: string
  ) { }
}

@Component({
  selector: 'app-dialog-content-report',
  templateUrl: './dialog-content-report.component.html',
  styleUrls: ['./dialog-content-report.component.scss']
})
export class DialogContentReportComponent implements OnInit {

  public reportable:Reportable;

  public reportForm:ReportFormModel;

  constructor(
    private dialogRef: MatDialogRef<DialogContentReportComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private api_tunes: ApiAmazingtunesV2Service,
    private userService: UserService,
    private snackbar: SnackbarService
  ) {

    this.reportForm = new ReportFormModel();

    // console.log('ContentReport:', data.reportable);
    this.reportable = data.reportable;

  }

  ngOnInit(): void {
  }

  submitReport(){

    if(!this.reportForm.reason || this.reportForm.reason === ''){
      this.snackbar.show('Please provide some information for the reason for this Content Report.');
      return;
    }

    // console.log('Report reason: ', this.reportForm);
    const payload:ContentReport = {
      data: {
        type:'content_report',
        attributes: {
          reason: this.reportForm.reason
        },
        relationships: {
          reportable: {
            data: {
              id: this.reportable.id,
              type: this.reportable.type
            }
          }
        }
      }
    };

    this.api_tunes.contentReport(payload).subscribe((data) => {

      this.dialogRef.close();
      this.snackbar.show('Thank you. Your Content Report has been filed.', 'snackbarInfo');
      if(typeof window !== 'undefined'){
        window.scrollTo(0,0);
      }

    },
    (error) => {
      console.log('Report error: ', error);
      this.snackbar.show(error.message, 'snackbarWarning');
    });

  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { UserV2 } from '@models/user-v2';
import { ArtistV1 } from '@models/artist-v1';

@Pipe({
  name: 'artistV1UserV2'
})
export class ArtistV1UserV2Pipe implements PipeTransform {

  transform(user: ArtistV1): UserV2 {
    return {
      id: user.id,
      attributes: {
        name: user.display_name,
        permalink: user.permalink,
        image_urls:{
          large: user.image_shq,
          medium: user.image_hq,
          small: user.image
        }
      }
    };
  };

}

import { Pipe, PipeTransform } from '@angular/core';

// Used for sharing urls to Twitter, Facebook, etc.
@Pipe({
  name: 'urlEncode'
})
export class UrlEncodePipe implements PipeTransform {
  transform(value: string): string {
    return encodeURIComponent(value);
  }
}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalVideo } from '@models/video-v2';
import { VideoPlaylistService } from '@services/video-playlist.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface DialogData {
  video_id?: string;
  video_title?: string;
  video_sub_title?: string;
  video?: ExternalVideo;
  video_playlist_index?: number;
  has_no_playlist?:boolean;
}

@Component({
  selector: 'app-dialog-video-player',
  templateUrl: './dialog-video-player.component.html',
  styleUrls: ['./dialog-video-player.component.scss'],
})
export class DialogVideoPlayerComponent implements OnInit, OnDestroy {

  public video?: ExternalVideo;
  public video_id?: string;
  public video_title?: string;
  public video_sub_title?: string;
  public video_playlist_index?: number;
  public dialog_data: DialogData;
  public has_no_playlist:boolean;

  private vps_sub:Subscription;

  constructor(
    private dialogRef: MatDialogRef<DialogVideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private videoPlaylistService:VideoPlaylistService,
  ) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data: NavigationEnd) => {
      // console.log('url of video dialog', data.urlAfterRedirects);
      // fires if the route url changes. eg: Sending to log in after attempting to like when not logged-in.
      this.close();
    });

    this.dialog_data = data;
    this.has_no_playlist = this.dialog_data?.has_no_playlist;
    // console.log('Video Dialog data:', this.dialog_data );

    if (this.dialog_data.video_playlist_index > -1) {
      this.video_playlist_index = this.dialog_data.video_playlist_index;
      this.videoPlaylistService.current_video_index = this.video_playlist_index;
    }
  }

  ngOnDestroy():void {
    if(this.vps_sub){
      this.vps_sub.unsubscribe();
      // console.log('dialog vps sub destroyed...');
    }
    this.video = null;
    this.video_playlist_index = null;
    this.video_title = null;
    this.video_id = null;
    this.video_sub_title = null;
    this.videoPlaylistService.clearVideoPlaylistIndex();
  }

  ngOnInit(): void {

    // Set when dialog is opened
    this.vps_sub = this.videoPlaylistService.current_video_subject.subscribe(data => {
      if(data){
        // console.log('Dialog VPS update: ', data);
        // Updates when next video in playlist is set...
        this.video = data;
        this.video_playlist_index = this.videoPlaylistService.current_video_index;
        this.video_title = this.video.attributes.name;
        this.video_id = this.video.attributes.resource_id;
        // console.log('Update video to ', this.video_title, this.video_id, this.video);
      } else {
        this.video = null;
        this.video_playlist_index = null;
        this.video_title = null;
        this.video_sub_title = null;
        this.video_id = null;
      }
    });

    if (this.dialog_data.video_id) {
      // support non-artist external_video. Provided title and YouTube video ID.
      this.video_id = this.dialog_data.video_id;
    }
    if (this.dialog_data.video_title) {
      this.video_title = this.dialog_data.video_title;
    }
    if (this.dialog_data.video_sub_title) {
      this.video_sub_title = this.dialog_data.video_sub_title;
    } else {
      this.video_sub_title = null;
    }

    if (this.dialog_data.video) {
      this.video = this.dialog_data.video;
      // Only for ExternalVideo lists (ie: not testimonals / interviews)
    } else {
      this.video = null;
    }
  }

  goToArtistProfile() {
    if (this.video) {
      this.dialogRef.close();
      this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos']);
    }
  }

  nextVideo(){
    this.videoPlaylistService.setNextVideoInPlaylist();
  }

  previousVideo(){
    this.videoPlaylistService.setPreviousVideoInPlaylist();
  }

  close() {
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// A shared service to detect events that will requires the subscriptions to be updated if on the profile-home component page.

@Injectable({
  providedIn: 'root'
})
export class SubscriptionUpdateService {

  public updateSubscriptionEvent: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  update(data:any){
    this.updateSubscriptionEvent.next(data);
  }

}

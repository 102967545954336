import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionPlans, Plan } from '@models/subscriptions-v2';
import { UserV2 } from '@models/user-v2';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { environment } from '@env/environment';

export interface DialogData {
  plans: Plan[];
}

@Component({
  selector: 'app-dialog-topup-subscription',
  templateUrl: './dialog-topup-subscription.component.html',
  styleUrls: ['./dialog-topup-subscription.component.scss']
})
export class DialogTopupSubscriptionComponent implements OnInit {

  public plans: Plan[];
  public selected_plan:Plan;
  public user:UserV2;
  public site_country:string = environment.site_country;
  public accept_card_payments:boolean = environment.accept_card_payments;
  public user_type_strings:any = {
    fan:'New Users',
    artist: 'Artists',
    label: 'Labels'
  };

  constructor(
      public dialogRef: MatDialogRef<DialogTopupSubscriptionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private api_tunes: ApiAmazingtunesV2Service,
      public userService: UserService,
      private snackbar: SnackbarService
  ) {

    this.user = this.userService.get();

    this.api_tunes.getSubscriptionPlans().subscribe(data => {
      // console.log('DialogTopupSubscriptionComponent Plans: ', data);
      this.plans = data.data;
      // if(this.plans.length === 1){
      //   this.selected_plan = this.plans[0];
      // }
      this.plans.forEach(plan => {
        // Showing 'fan' doesn't seem to make sense from the UI perspective. (Though naturally doesn't from a system/data one))
        plan.attributes.eligible_user_types.forEach((_eut, i) => {
          if(_eut === 'fan'){
            plan.attributes.eligible_user_types.splice(i, 1);
          }
        });

        // console.log('user eligibility', plan.attributes.eligible_user_types);
        if(plan.attributes.eligible_user_types.length === 1){
          plan._plan_user_string = this.user_type_strings[plan.attributes.eligible_user_types[0]] + ' only';
        } else {
          let _user_type_strings:string[] = [];
          plan.attributes.eligible_user_types.forEach((_type) => {
            _user_type_strings.push(this.user_type_strings[_type]);
          });
          let _user_type_string:string = _user_type_strings.slice(0, -1).join(', ')+' or '+_user_type_strings.slice(-1);
          plan._plan_user_string = _user_type_string;
        }
      });
    });
  }

  selectPlan(plan:Plan){
    if(this.plans.length > 1 && this.selected_plan && plan.id === this.selected_plan.id){
      this.selected_plan = null;
      return;
    }
    this.selected_plan = plan;
  }

  close(plan:Plan, gateway:string){

    if(!plan){
      this.snackbar.show('Please select an option first');
      return;
    }

    this.dialogRef.close( {selected_plan: this.selected_plan, gateway:gateway } );
  }

  cancel(){
    this.dialogRef.close();
  }

  ngOnInit(): void { }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeSlipService {

  public offset:number = 0;
  public offset_str:string = 'LIVE';

  constructor() { }

  reset() :void {
    this.offset = 0;
    this.offset_str = 'LIVE';
  }

}

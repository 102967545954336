<div class="header">
  <h6 [innerText]="'Delete '+type+' Account'"></h6>
</div>

<div class="cancelCont">

  <div class="deleteCont">

    <div>

      <h2>Are you sure you want to delete {{ type==='artist' ? 'this artist':'your account' }}?</h2>

      Please be fully aware that:

      <br><br>
      <ul>
        <li *ngIf="type !== 'artist'">Any active subscriptions will be terminated; stored payment details deleted and no more billing will occur (where
          applicable).</li>
        <li>Any tracks purchased by other users on the site will be retained so those who purchased them can still
          download them, but they will no longer appear on Amazing Radio and will not be eligible for airplay.</li>
        <li>All other uploads {{ type==='artist'?'by this artist':'' }} will be permanently removed.</li>
        <li>Account deletion is permanent and cannot be undone.</li>

      </ul>

    </div>

    <br>
    <span>CLICK BELOW TO<br></span>
    <div class="label warn link withicon" (click)="deleteAccount()">
      <mat-icon inline="true">cancel</mat-icon> DELETE {{ type==='artist' ? 'THIS ARTIST':'YOUR ACCOUNT' }}
    </div>

  </div>
  <p class="instructions">
    THIS ACTION CANNOT BE REVERSED
  </p>

  <div class="label link med-grey small" (click)="cancel()">
    cancel
  </div>

</div>
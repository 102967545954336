<mat-icon role="button" [title]="addClass === ' private' ? 'This tune is not available to stream on-demand' : 'Play ' + collection.attributes.name + ' by ' + collection.artist.attributes.name" (click)="parentClick ? null : playPause()"
class="playButton"
[class]="
collection.id === player.playlistData.id ?
  (player.isLoading()
  && !player.featured
  && !player.rewinds
  && !player.streaming
  && !player.streaming_custom
   ? 'spinner '+addClass : (player.isPlaying() ? 'playing':'') + addClass ) : '' + addClass"
[svgIcon]="
  !player.isLoading() &&
  player.isPlaying() &&
  !player.streaming &&
  !player.rewinds &&
  !player.featured &&
  !player.streaming_custom &&
  collection.id === player.playlistData.id
    ? svg_icons.pause : (
        player.isLoading() && collection.id === player.playlistData.id )
          ? svg_icons.buffering :  svg_icons.play">
</mat-icon>


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { DialogDonateComponent } from '@modules/_shared/ui/components/dialog-donate/dialog-donate.component';
import { SnackbarService } from '@services/snackbar.service';
import { UserV2 } from '@models/user-v2';
import { PaymentVerification } from '@app/models/payment-v2';

import { LoadingIndicatorService } from '@services/loading-indicator.service';

@Component({
  selector: 'app-action-button-donate',
  templateUrl: './action-button-donate.component.html',
  styleUrls: ['./action-button-donate.component.scss'],
  inputs: ['user', 'return_path']
})
export class ActionButtonDonateComponent implements OnInit {

  public user:UserV2;
  public return_path:string;

  constructor(private loadingIndicatorService:LoadingIndicatorService,  private api_tunes:ApiAmazingtunesV2Service, private router:Router, private dialog: MatDialog, private snackbar:SnackbarService) { }

  ngOnInit(): void {
  }

  openDonateDialog(user: UserV2) {
    if (!user.meta.accepts_donations) {
      this.snackbar.show('This user is not able to accept donations yet!');
      this.router.navigate(['/profile', user.attributes.permalink]);
      return;
    }
    const dialogRef = this.dialog.open(DialogDonateComponent, {
      width: '450px',
      panelClass: 'dialogDonate',
      data: { user: user }
    });
    dialogRef.afterClosed().subscribe(data => {
      // console.log('The Donation dialog was closed: ', data);
      if (data && data.amount && data.user) {
        // console.log('initiate donation... ', data);

        // show spinner..
        this.loadingIndicatorService.startLoading();

        this.api_tunes.makeDonation(data.user, data.amount ).subscribe((data:PaymentVerification) => {
          // console.log('makeDonation response: ', data);
          if (data.data.attributes.payment_approval_url) {
            if (typeof window !== 'undefined' && this.return_path) {
              localStorage.setItem('donation_path', this.return_path);
            }

            console.log('redirect for donation payment to : ', data.data.attributes.payment_approval_url);
            window.open(data.data.attributes.payment_approval_url, '_self');
            return;
            // On returning from the payment gateway, the user is sent to /payments/:gateway/donation   to complete the transaction.
          }
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TuneV2 } from '@models/tune-v2';
import { CollectionV2 } from '@models/collection-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { UserV2 } from '@models/user-v2';
import { ExternalVideo } from '@models/video-v2';

interface RowImages {
  image_url:string;
  routerlink?:string;
}

@Component({
  selector: 'app-home-feature-row',
  templateUrl: './home-feature-row.component.html',
  styleUrls: ['./home-feature-row.component.scss'],
  inputs: ['title', 'sub_title', 'image', 'router_link', 'external_link', 'resource', 'background_class', 'row_images', 'zoom_image'],
})
export class HomeFeatureRowComponent implements OnInit {

  public resource:TuneV2 | CollectionV2 | PlaylistV2 | ExternalVideo | UserV2;
  public title:string;
  public sub_title:string;

  public image:string; // single background row image.
  public row_images:RowImages[];  // TEST: optional if repreesenting a list of things (chart, first spins, news, etc.)

  public router_link:string;
  public external_link:string;

  public zoom_image:boolean; // for video thumbnails. zoom in a bit.

  public background_class:string = ''; // to control the background gradient colour class used.

  constructor(private router:Router ) { }

  ngOnInit(): void { }

  clicked(){

    if(this.router_link){
      this.router.navigateByUrl(this.router_link);
    } else {
      if(this.external_link){
        if(typeof window !== 'undefined'){
          window.location.href = this.external_link;
        }
      }
    }
  }

}

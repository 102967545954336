<div class="header">
  Content Report
</div>
<div class="cont">

  <div class="info">
    If you are a trademark or copyright owner and you believe that your trademark or copyright rights have been violated, please provide as much information as possible in the form below.
  </div>


<mat-form-field class="reasonForm" appearance="outline">
  <mat-label>Reason / Information</mat-label>
  <textarea matInput #reason="ngModel" class="textAreaReason" name="reason"
    [(ngModel)]="reportForm.reason" cdkTextareaAutosize cdkAutosizeMaxRows="10"
    cdkAutosizeMinRows="6" ngModel></textarea>
</mat-form-field>

<div (click)="submitReport()" class="submit label link yellow">
    Submit Report
  </div>
</div>
<div class="footer">
  <strong>{{ reportable.type }}</strong>
  <br>
  {{ reportable.attributes.name }}
</div>

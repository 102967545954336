import { Injectable } from '@angular/core';
import { ExternalVideo } from '@models/video-v2';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VideoPlaylistService {

  public current_video_subject: BehaviorSubject<ExternalVideo> = new BehaviorSubject(null);
  public video_playlist_subject: BehaviorSubject<ExternalVideo[]> = new BehaviorSubject(null);
  public videos:ExternalVideo[] = [];
  public current_video:ExternalVideo;
  public current_video_index:number = -1;

  constructor() { }

  setVideoPlaylist(videos:ExternalVideo[]){
    this.videos = videos;
    // Update service subject subscribers
    this.video_playlist_subject.next(this.videos);
  }

  setCurrentVideoIndex(index: number) {
    if(this.videos.length){
      this.current_video_index = index;
      this.current_video = this.videos[this.current_video_index];
      // Update service subject subscribers
      this.current_video_subject.next(this.current_video);
    }
  }

  clearVideoPlaylist(){
    //console.log('clearVideoPlaylist');
    this.videos = [];
    this.current_video = null;
    this.current_video_index = -1;
    this.video_playlist_subject.next(this.videos);
    this.current_video_subject.next(this.current_video);
  }

  clearVideoPlaylistIndex() {
    // Do this when dialog gets closed.
    //console.log('clearVideoPlaylistIndex');
    this.current_video_index = -1;
    this.current_video = null;
  }

  setNextVideoInPlaylist(){
    if(this.videos.length){
      this.current_video_index++;
      if(this.current_video_index === this.videos.length){
        this.current_video_index = 0;
      }
      this.current_video = this.videos[this.current_video_index];
      //console.log('Next video set: ', this.current_video_index, this.current_video);
      this.current_video_subject.next(this.current_video);
    }
  }

  setPreviousVideoInPlaylist(){
    if(this.videos.length){
      this.current_video_index--;
      if(this.current_video_index < 0){
        this.current_video_index = this.videos.length - 1;
      }
      this.current_video = this.videos[this.current_video_index];
      //console.log('Previous video set: ', this.current_video_index, this.current_video);
      this.current_video_subject.next(this.current_video);
    }
  }

  // Update this in te cached list of videos.
  toggleLike(id:string){
    this.videos.forEach(data => {
      if(data.id === id){
        data.meta.liked_by_user = !data.meta.liked_by_user;
      }
    })
    if(this.current_video?.id === id){
      this.current_video.meta.liked_by_user = !this.current_video.meta.liked_by_user;
      //console.log('toggle video like: ', this.current_video.meta.liked_by_user);
    }
  }


}

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SnackbarService } from '@services/snackbar.service';
@Injectable()
export class CheckForUpdateService {

  private polling_interval: number = 240000;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate, private snackbar: SnackbarService) {

    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version ready for use: ${evt.currentVersion.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          if (typeof window !== 'undefined') {
            this.snackbar.snackbarRef = this.snackbar.snackBar.open('Please click reload to access the latest version of this site.', 'Reload', {
              panelClass: 'snackbarNotice',
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            });
            this.snackbar.snackbarRef.onAction().subscribe(() => {
              document.location.reload();
            });
          }
          break;
      }
    });

    updates.unrecoverable.subscribe(event => {
      console.log('Unrecoverable error', event.reason, 'Reload required');

      if (typeof window !== 'undefined') {
        this.snackbar.snackbarRef = this.snackbar.snackBar.open('Please click reload to access the latest version of this site.', 'Reload', {
          panelClass: 'snackbarNotice',
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
        this.snackbar.snackbarRef.onAction().subscribe(() => {
          document.location.reload();
        });
      }
    });

    if (updates.isEnabled) {
      // console.log('CheckForUpdateService enabled');
      setInterval(async () => {
        const updateFound = await updates.checkForUpdate();
        if (updateFound) {
          if (typeof window !== 'undefined') {
            console.log('Site updated. Reload required.');
            this.snackbar.snackbarRef = this.snackbar.snackBar.open('An update to this site has just been released. Please reload to access the latest version.', 'Reload', {
              panelClass: 'snackbarNotice',
              horizontalPosition: 'center',
              verticalPosition: 'bottom'
            });
            this.snackbar.snackbarRef.onAction().subscribe(() => {
              console.log('activating update and reloading page .. ');
              updates.activateUpdate().then(() => document.location.reload());
            });
          }
        }
      }, this.polling_interval);
    }
  }

}

import { Injectable } from '@angular/core';
import { ApiAmazingradioWpService } from '@services/api-amazingradio-wp.service';
import { Post } from '@models/wp-post';
import { BehaviorSubject, Observable } from 'rxjs'
import { tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LatestPostsService {

  public latest_posts: Post[];
  public latestPostsSubject: BehaviorSubject<Post[]> = new BehaviorSubject(null);

  constructor(private api_wp: ApiAmazingradioWpService) {}

  getLatestPosts():Observable<Post[]>{
    if(this.latest_posts){
      return this.latestPostsSubject.asObservable();
    }
    return this.api_wp.getLatestPosts(1, 7, true, true).pipe(
      map(data => {
        this.latest_posts = data.body;
        this.latestPostsSubject.next(this.latest_posts);
        // console.log('getLatestPosts:', this.latest_posts);
        return this.latest_posts;
      })
    )
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlayerService } from '@modules/_shared/player/player.service';
import { TuneV2 } from '@models/tune-v2';
import { UserV2 } from '@models/user-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { CollectionV1V2Pipe } from '@services/tune-v2-v1.pipe';
import { CollectionV2 } from '@models/collection-v2';

// Special Play button for PlaylistV2 cards since they need to get the first tune of the playlist it doesn't know about yet.
// Also, the template play button icon logic will show the right control if the tune that's playing is within that playlist. (he says)

declare var _paq;

@Component({
  selector: 'app-playlist-play-button-v2',
  templateUrl: './playlist-play-button-v2.component.html',
  styleUrls: ['./playlist-play-button-v2.component.scss'],
  inputs: ['playlistHead', 'router_link', 'tune', 'artist', 'playlist_id', 'playlist', 'index', 'addClass', 'parentClick', 'collection_uuid', 'unique_id', 'title'],
  outputs: ['playlistChange', 'playlistHeadChange']
})
export class PlaylistPlayButtonV2Component implements OnInit {

  public playlist_id: string; // if we're just given a playlist id, lazy load using this.

  public tune: TuneV2;
  public artist: UserV2;

  @Input() playlist: TuneV2[];
  @Output() playlistChange: EventEmitter<TuneV2[]> = new EventEmitter<TuneV2[]>();

  @Input() playlistHead: PlaylistV2;
  @Output() playlistHeadChange: EventEmitter<PlaylistV2> = new EventEmitter<PlaylistV2>();


  public index: number;
  public title: string;            // for hover accessibility override
  public collection_uuid: string; // for search results
  public addClass: string = '';
  public parentClick: boolean = false;
  public lazy_loading: boolean = false;

  public router_link: string; // Overrides link used in player header

  public svg_icons = {
    play: 'play',
    pause: 'pause',
    stop: 'stop',
    buffering: 'buffering'
  };

  public collection: CollectionV2 = {
    id: null,
    artist: {
      attributes: {
        name: ''
      }
    },
    attributes: {
      name: ''
    }
  };

  public stateIndex = 0;
  public unique_id: string;
  public has_error: boolean;

  constructor(public player: PlayerService, private api_tunes: ApiAmazingtunesV2Service, private collectionV1V2Pipe: CollectionV1V2Pipe) {

  }

  playPause(event: any) {

    if (this.has_error) {
      // console.log('Play disabled due to previous lazyload error');
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    // console.log('playlist_id ', this.playlist_id);
    if (this.playlist_id && !this.playlist) {
      this.lazy_loading = true;

      this.api_tunes.getPlaylistV2(this.playlist_id, true).subscribe((data) => {
        // console.log('playlist data via id: ', data);
        this.playlist = data._tunes;

        this.playlistHead = data;
        if (this.router_link) {
          this.playlistHead._router_link = this.router_link;
        }
        //delete this.playlistHead?._tunes;
        this.player.setPlaylistHeadDataV2(this.playlistHead);
        this.tune = this.playlist[0];
        this.player.rewinds = false;
        this.player.featured = false;
        this.index = 0;
        this.lazy_loading = false;
        this.player.playTune(this.tune, this.index, this.playlist, this.playlistHead);

        _paq.push(['trackEvent', 'PlayPlaylist', data.attributes.name + ' - ' + data?._curator?.attributes?.name + ' (' + data.id + ')']);
        return;
      },
        (error) => {
          this.lazy_loading = false;
          this.has_error = true;
          this.addClass += ' has_error';
        });
      return;
    }

    if (this.playlistHead && !this.playlist && !this.collection_uuid) {
      // LazyLoad the playlist tunes.
      // Liked playlists have no 'entries/tunes', so fill this up now on the first play
      console.log('lazyload with playlistHead');

      this.lazy_loading = true;
      // this.player.clearTuneId();
      this.player.resetPlayer();

      // console.log('playlistHead: ', this.playlistHead);
      this.player.setPlaylistHeadDataV2(this.playlistHead);
      this.player.loading = true; // Trigger the spinner now
      this.api_tunes.getPlaylistV2(this.playlistHead.id, true).subscribe((data) => {
        // console.log('selected playlist data: ', data);
        this.playlist = data._tunes;
        this.playlist_id = data.id;
        this.tune = this.playlist[0];
        this.player.rewinds = false;
        this.player.featured = false;
        this.index = 0;
        this.lazy_loading = false;
        this.player.playTune(this.tune, this.index, this.playlist, this.playlistHead);

        _paq.push(['trackEvent', 'PlayPlaylist', data.attributes.name + ' - ' + data?._curator?.attributes?.name + ' (' + data.id + ')']);
        return;
      },
        (error) => {
          this.lazy_loading = false;
          this.has_error = true;
          this.addClass += ' has_error';
        });
      return;

    } else if (this.playlistHead && this.playlist) {

      this.tune = this.playlist[0];
      // console.log('%cplaylistHead & playlist data available', 'color:hotpink' ,this.playlistHead);
      this.playlist_id = this.playlistHead.id;

      if (this.tuneExistsInPlaylist(this.player.currentTune?._unique_id)) {
        // console.log('current player tune is in playlist with unique_id: ', this.player.currentTune?._unique_id);
        this.player.toggleAudio();
        return;

      }
      // console.log('play first tune in playlist');
      this.player.playTune(this.tune, 0, this.playlist, this.playlistHead);
      _paq.push(['trackEvent', 'PlayPlaylist', this.playlistHead?.attributes?.name + ' (' + this.playlistHead.id + ')']);
      return;
    } else if (this.collection_uuid) {
      // console.log('Lazy Load Collection...(search results)');
      this.lazy_loading = true;
      this.player.resetPlayer();

      this.api_tunes.getCollectionV2(this.collection_uuid, true).subscribe((data) => {
        this.tune = data._tunes[0];
        this.playlist = data._tunes;
        this.collection = data; //

        // for play button state
        this.playlist_id = this.collection.id;

        // console.log('collection tunes: ', this.collection);
        this.lazy_loading = false;
        this.index = 0;
        this.player.setPlaylistHeadDataV2(this.collection);
        this.player.playTune(this.tune, this.index, this.playlist, this.collection);

        _paq.push(['trackEvent', 'PlayCollection', data.attributes.name + ' - ' + data?.artist?.attributes?.name + ' (' + data.id + ')']);

        return;
      },
        (error) => {
          console.log('%cCollection LazyLoad error', 'color:red');
          this.lazy_loading = false;
          this.has_error = true;
          this.addClass += ' has_error';
        });

      return;

    }

    _paq.push(['trackEvent', 'PlayPlaylist', this.playlistHead?.attributes?.name + ' (' + this.playlistHead.id + ')']);

    // console.log('play playlist tune ...');
    this.player.playTune(this.tune, this.index, this.playlist, this.playlistHead);

  }

  tuneExistsInPlaylist(unique_id: string): boolean {

    let _exists: boolean = false;
    this.playlist.forEach(data => {
      if (data._unique_id === unique_id) {
        _exists = true
      }
    });
    // if(_exists){
    //   console.log('tune.meta.entry is in playlist tune._unique_id');
    // }
    return _exists;
  }

  ngOnInit(): void {
    if (this.addClass) {
      this.addClass = ' ' + this.addClass;
    }
    // console.log('collection_uuid', this.collection_uuid);
    // if(this.playlist_id){
    //   console.log('playlist play button playlist_id:', this.playlist_id);
    // }
  }

}

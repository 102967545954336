import { Component, OnInit } from '@angular/core';

// The embedded players in posts.

import { PlaylistV2 } from '@models/playlist-v2';
import { TuneV2 } from '@app/models/tune-v2';
import { CollectionV2 } from '@models/collection-v2';
import { Rewind, ShowSchedule } from '@models/show';
import { PlayerService } from '@modules/_shared/player/player.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { AiringRewindPipe } from '@services/airing-rewind.pipe';
import { ApiScheduleService } from '@services/api-schedule.service';

@Component({
  selector: 'app-player-embed',
  templateUrl: './player-embed.component.html',
  styleUrls: ['./player-embed.component.scss'],
  inputs: ['tune', 'path', 'type', 'playlist']
})
export class PlayerEmbedComponent implements OnInit {

  public tune: TuneV2;
  public collection: CollectionV2;
  public playlist: PlaylistV2;

  public _tune: TuneV2;
  public _collection: CollectionV2;
  public _playlist: PlaylistV2;
  public _rewind: Rewind;

  public path: string;
  public type: string;

  public collection_routing = {
    Single: 'singles',
    Album: 'albums',
    ExtendedPlayer: 'eps',
    EP: 'eps'
  };

  constructor(public player: PlayerService, private api_tunes_v2: ApiAmazingtunesV2Service, private api_schedule:ApiScheduleService, private airingRewindPipe:AiringRewindPipe) { }

  ngOnInit(): void {
    // console.log('%cplayer-embed: onInit data inputs: ', 'color:yellow',this.type, this.path);
    let permalink: string;
    let id: number;
    let _id: any;

    switch (this.type) {
      case 'tune':
        permalink = this.path.split('/tunes/')[0].substring(1);
        _id = this.path.split('/tunes/')[1];
        // if(!isNaN(_id)){
        //   id = parseInt(this.path.split('/tunes/')[1]);
        //   // console.log('V1 Tune: ', id, permalink);
        //   this.api_tunes_v2.getV1Resource('tune', permalink, id, true).subscribe(data => {
        //     this.tune = data;
        //     this.tune._unique_id = 'embedv1-'+this.tune.id;
        //     // console.log('V1-V2 Tune: ', data);
        //   });
        // } else {
          this.api_tunes_v2.getTuneV2(_id, true).subscribe(data => {
            this.tune = data;
            this.tune._unique_id = 'embedv2-'+this.tune.id;
            // console.log('V2 Tune: ', data);
          });
        //}
        break;
      case 'product':
        // Collection 'release'
        let parts = this.path.split('/');
        _id = parts.pop();
        // if(!isNaN(_id)){
        //   id = parseInt(_id);
        //   let product_type = parts.pop();
        //   permalink = parts.join('/').substring(1);
        //   // console.log('V1 Collection ', id, permalink, product_type);
        //   this.api_tunes_v2.getV1Resource('collection', permalink, id, true).subscribe(data => {
        //     // console.log('V1-V2 Collection: ', data);
        //     this.collection = data;
        //   });
        // } else {
          this.api_tunes_v2.getCollectionV2(_id,  true).subscribe(data => {
            this.collection = data;
            // console.log('V2 Collection: ', data);
          });
        // }
        break;
      case 'playlist':
        permalink = this.path.split('/playlists/')[0].substring(1);
        _id = this.path.split('/playlists/')[1];
        // if(!isNaN(_id)){ // Numerical ID...
        //   id = parseInt(_id);
        //   // console.log('V1 Playlist: ', id, permalink);
        //   this.api_tunes_v2.getV1Resource('playlist', permalink, id, true).subscribe(data => {
        //     // console.log('V1-V2 Playlist: ', data);
        //     this.playlist = data;
        //   });
        // } else {
          // console.log('%cV2 Playlist: ','color:limegreen' ,_id);
          this.api_tunes_v2.getPlaylistV2(_id, true, true).subscribe(data => {
            // console.log('V2 Playlist data: ', data);
            this.playlist = data;
          });
        //}
        break;
      case 'airing':
        if(!this.path){
          console.log('no airing path provided');
          return;
        }
        permalink = this.path.split('/')[1]; // permalink var used for `show_id`
        // console.log('show_id', permalink);
        _id = this.path.split('/').pop();
        if(isNaN(_id)){
          console.log('Airing ID is NaN', _id);
          return;
        }
        //console.log('AIRING ID', _id);
        this.api_schedule.getShowAiring(permalink, _id, true).subscribe(data => {
          //console.log('AIRING data: ', data);
          data.show_id = data.show.id;
          data.show_name = data.show.name;
          this._rewind = this.airingRewindPipe.transform(data);
          //console.log('REWIND data:', this._rewind);
        });
        break;
      default:
        //
    }
  }
}

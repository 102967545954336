import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action-button-edit',
  templateUrl: './action-button-edit.component.html',
  styleUrls: ['./action-button-edit.component.scss'],
  inputs:['with_label'], // 'resource'
})
export class ActionButtonEditComponent implements OnInit {

  // public resource:any;
  public with_label:string;

  constructor() { }

  ngOnInit(): void {
  }

}

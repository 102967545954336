import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators';

/*
  A service to get and update the views/home.component content on load:
  Updates Current/Upcoming Show (in intervals) and sets Featured Media and News
*/

import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { ApiAmazingradioWpService } from '@services/api-amazingradio-wp.service';
import { ApiSearchService } from '@services/api-search.service';
import { ApiScheduleService } from '@services/api-schedule.service';
import { ShowSchedule } from '@models/show';
import { TuneV2 } from '@models/tune-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { Post } from '@models/wp-post';
import { ExternalVideo, Video } from '@models/video-v2';
import { SearchResults, SearchResultsHit } from '@models/search';
import { TuneSearchV1V2Pipe, CollectionSearchV1V2Pipe } from '@services/tune-v2-v1.pipe';
import moment from 'moment';


interface HomeScheduleShows {
  show?: ShowSchedule;
  next_show?: ShowSchedule;
  upcoming_shows?: ShowSchedule[];
}

interface HomeData {
  top_five?: TuneV2[];
  featured_playlists?: PlaylistV2[];
  first_spin?: TuneV2[];
  first_look?: Video[];
  for_sale?: TuneV2[];
}

@Injectable({
  providedIn: 'root'
})
export class HomeContentService {

  private CURRENT_SHOW_INTERVAL = 60000;
  private FIRST_SPIN_INTERVAL = 120000; // 120000; // 2 minutes.

  // Home page current show component will subscribe to these.
  public showSubject: BehaviorSubject<ShowSchedule> = new BehaviorSubject(null);
  public show: ShowSchedule;
  public next_showSubject: BehaviorSubject<ShowSchedule> = new BehaviorSubject(null);
  public next_show: ShowSchedule;
  public has_competitions: boolean = false;

  public home_schedule_shows: HomeScheduleShows;
  public topFiveTunes: TuneV2[];
  public homeNews: Post[];
  public featuredPlaylists: PlaylistV2[];

  public homeFirstSpins:TuneV2[];
  public homeFirstSpinsSubject: BehaviorSubject<TuneV2[]> = new BehaviorSubject(null);

  public topFiveTunesSubject: BehaviorSubject<TuneV2[]> = new BehaviorSubject(null);
  public featuredPlaylistsSubject: BehaviorSubject<PlaylistV2[]> = new BehaviorSubject(null);

  public featuredRowsData:HomeData = {first_spin:null, for_sale: null};
  public featuredRowsDataSubject: BehaviorSubject<HomeData> = new BehaviorSubject(null);

  public results: SearchResults;
  public resultHits: SearchResultsHit[] = [];
  public collection_type: any = {
    Single: 'Single',
    Album: 'Album',
    ExtendedPlayer: 'EP'
  };
  public homeTunesForSale:TuneV2[] = [];

  private show_update_interval:any;

  constructor(private collectionSearchV1V2Pipe:CollectionSearchV1V2Pipe,  private tuneSearchV1V2Pipe: TuneSearchV1V2Pipe, private api_search:ApiSearchService, private api_schedule: ApiScheduleService, private api_wp: ApiAmazingradioWpService, private api_tunes: ApiAmazingtunesV2Service) {

    // Will update the Hero Carousel comp.
    this.getShows();
    this.startShowInterval();
    // Update First Spins
    this.startFirstSpinInterval();
    // Get all other feature rows data via forkJoin.
    this.getHomeFeaturedRowsData();

  }

  getHomeFeaturedRowsData() : Observable<HomeData>{
    if(this.featuredRowsData.first_spin && this.featuredRowsData.featured_playlists && this.featuredRowsData.first_look && this.featuredRowsData.top_five){
      return this.featuredRowsDataSubject.asObservable();
    }

    // Use forkJoin to create the parallel requests to the four endpoints and return as a single observable.
    return forkJoin([this._getTopFive(), this._getFeaturedPlaylists(), this._getHomeFirstSpins(), this._getLatestVideos()]) //, this._getHomeTunesForSale()
    .pipe(
      map(data => {
        // This one will also get updated by interval
        this.homeFirstSpins = data[2];
        this.featuredRowsData = {
          top_five: data[0],
          featured_playlists: data[1],
          first_spin: data[2],
          first_look: data[3],
          //for_sale: data[4]
        };
        // console.log('FeaturedRowsData', this.featuredRowsData);
        this.featuredRowsDataSubject.next(this.featuredRowsData);
        return this.featuredRowsData;
      })
    );
  }

  _getTopFive(): Observable<TuneV2[]> {
    return this.api_tunes.getChartV2('top40', 5, true).pipe(
      map(data => {
        return data._tunes;
      })
    )
  }

  _getLatestExternalVideos() : Observable<ExternalVideo[]> {
    return this.api_tunes.getLatestExternalVideos(1, 5, false).pipe(
      map(data => {
        return data.videos;
      })
    )
  }

  _getLatestVideos() : Observable<Video[]> {
    return this.api_tunes.getLatestVideos(1, 5, false).pipe(
      map(data => {
        return data.videos;
      })
    )
  }

  _getFeaturedPlaylists() : Observable<PlaylistV2[]> {
    return this.api_tunes.getFeaturedPlaylists().pipe(
      map(data => {
        return data.playlists;
      })
    )
  }

  // First Spins Home Featured Row
  getHomeFirstSpins(){
    // console.log('getHomeFirstSpins');
    this.api_tunes.getFirstSpinTunes(0,true, 5)
    .subscribe(data => {
      // console.log('HFS:', data.tunes);
      if(!this.homeFirstSpins || (this.homeFirstSpins && this.homeFirstSpins[0]?.id !== data.tunes[0]?.id)){
        this.homeFirstSpins = data.tunes;
        // Update the full data object cache.
        this.featuredRowsData.first_spin = this.homeFirstSpins;
        this.featuredRowsDataSubject.next(this.featuredRowsData);
        // Update just the first spins.
        this.homeFirstSpinsSubject.next(this.homeFirstSpins);
      }
    });
  }

  _getHomeFirstSpins() : Observable<TuneV2[]> {
    return this.api_tunes.getFirstSpinTunes(0,true, 5)
    .pipe(
      map(data => {
        return data.tunes;
      })
    )
  }

  _getHomeTunesForSale() : Observable<TuneV2[]> {

    return this.api_search.searchStore(null, 'Tune', 1, 5).pipe(
      map(data => {
        data.hits.forEach(hit => {
          // console.log(hit._source.title, moment(hit._source.created).fromNow());
          hit._source._created_time_ago = moment(hit._source.created).fromNow();
          if (hit._type === 'Tune') {
            hit._resource = this.tuneSearchV1V2Pipe.transform(hit._source);
          } else if(hit._type === 'Collection'){
            hit._resource = this.collectionSearchV1V2Pipe.transform(hit._source);
            hit._resource.attributes.type = this.collection_type[hit._resource.attributes.type];
          }
          this.resultHits.push(hit);
          //return this.resultHits;
        });

        this.resultHits.forEach(_hit => {
          this.homeTunesForSale.push(_hit._resource);
        });
        return this.homeTunesForSale;
      })
    )
  }

  stopShowInterval() {
    if (typeof window === "undefined") {
      return;
    }
    clearInterval(this.show_update_interval);
    this.show_update_interval = null;
  }

  startShowInterval() {
    if (typeof window === "undefined") {
      return;
    }
    this.show_update_interval = setInterval(() => {
      this.getShows();

    }, this.CURRENT_SHOW_INTERVAL);
  }

  startFirstSpinInterval() {
    if (typeof window === "undefined") {
      return;
    }
    const interval = setInterval(() => {
      this.getHomeFirstSpins();

    }, this.FIRST_SPIN_INTERVAL);
  }

  getShows(with_next:boolean = true) {
    this.getHomeScheduleShows().subscribe(data => {
      // console.log('HomeScheduleShows: ', data);
      this.show = data.show;
      this.showSubject.next(this.show);
      // Don't update the next show data when playing with offset time-slip.
      if(with_next){
        this.next_show = data.next_show;
        this.next_showSubject.next(this.next_show);  
      }
      // console.log('%cOn Air : ', 'color:orange', this.show.show.name);
      // console.log('%cUp Next: ', 'color:orange', this.next_show.show.name);
    });
  }

  getHomeScheduleShows(): Observable<HomeScheduleShows> {
    // Get both onair and upcoming shows data in a single Observable...
    // So we can compare onair and next show to make sure they're never the same before giving the page the data.
    return this.api_schedule.getOnAirShow()
      .pipe(
        tap(data => {
          if(data.presenter_covering){
            data.show.presenter = data.presenter;
          }
        }),
        // Now get the upcoming_shows ...
        switchMap(data => this.getUpcomingShows(data))
      )
  }

  getUpcomingShows(onair_show:ShowSchedule): Observable<HomeScheduleShows> {
    return this.api_schedule.getUpcomingShows().pipe(
      map(data => {
        let next_show: ShowSchedule;
        // We don't show upcoming shows on the US site (yet), so this will be null
        if (data) {
          next_show = data[0];
            if(next_show.presenter_covering){
              next_show.show.presenter = next_show.presenter;
            }
          if (onair_show.slug === next_show.slug) {
            // The upcoming show is the same as the current show! (Non-Stop New Music will do this)
            // So use the next one...
            next_show = data[1];
          }
        }
        return {
          show: onair_show,
          next_show: next_show,
          upcoming_shows: data
        }
      })
    );
  }

}

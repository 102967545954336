import { Injectable } from '@angular/core';
import { ApiAmazingradioWpService } from '@services/api-amazingradio-wp.service';
import { Post } from '@models/wp-post';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LatestSessionsService {

  public latest_sessions: Post[];

  constructor( private api_wp: ApiAmazingradioWpService ) { }

  getLatestSessions(exclude: number = null):Observable<Post[]>{

    return this.api_wp.getLatestSessions(7, exclude).pipe(
      map(data => {
        this.latest_sessions = data;
        // console.log('getLatestSessions:', this.latest_sessions);
        return this.latest_sessions;
      })
    )
  }

}

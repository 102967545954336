import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlayerService } from '@modules/_shared/player/player.service';
import { Subscription } from 'rxjs';
import { TimeSlipService } from '@services/time-slip.service';

@Component({
  selector: 'app-radio-play-btn',
  templateUrl: './radio-play-btn.component.html',
  styleUrls: ['./radio-play-btn.component.scss'],
  inputs: ['show_listen_live_label', 'live_label_text', 'show_levels', 'addClass']
})
export class RadioPlayBtnComponent implements OnInit, OnDestroy {

  public show_listen_live_label:boolean = true;
  public addClass:string;
  public show_levels:boolean = false;
  public stateIndex: number = 0;
  public live_label_text:string = 'Listen Live';
  private sub_icon_state:Subscription;

  constructor(public player:PlayerService, public timeSlipService: TimeSlipService) { }

  ngOnInit(): void {
    this.sub_icon_state = this.player.iconState.subscribe(state => {
      if (this.player.streaming) {
        this.stateIndex = state;
      } else {
        this.stateIndex = 0;
      }
    });
  }

  ngOnDestroy():void {
    this.sub_icon_state.unsubscribe();
    this.stateIndex = 0;
  }

}

import { Component, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, CropperReadyEvent, CropRect, Dimensions } from './image-cropper/image-cropper.component';

import { UserV2 } from '@models/user-v2';
import { TuneV2 } from '@models/tune-v2';
import { ImageV2 } from '@models/image-v2';
import { CollectionV2 } from '@models/collection-v2';

import { SnackbarService } from '@services/snackbar.service';

export interface DialogData {
  resource: UserV2 | TuneV2 | CollectionV2;
  //imageBase64?: string; // current user|tune|collection image data
  imageUrl?: string;
  imageData?: ImageV2;
  new_image?: boolean;
  type?: string; // profile or header
}

export interface CropRectData { // Note x,y,w,h used by the API. Not the x1,y1,x2,y2 that the cropper returns.
  h: number;
  w: number;
  x: number;
  y: number;
}

@Component({
  selector: 'app-dialog-image-upload',
  templateUrl: './dialog-image-upload.component.html',
  styleUrls: ['./dialog-image-upload.component.scss']
})
export class DialogImageUploadComponent {

  public is_image_valid: boolean = false;

  errorMessage: string;

  minimumWidth: number = 600;              // limit final croprect size, relative to original.
  resizeCroppedImageToWidth: number = 600; // for output image data for UI.
  minimumHeight: number = 600;              // limit final croprect size, relative to original.
  resizeCroppedImageToHeight: number = 600; // for output image data for UI.
  maxFileSize: number = 7000000;
  aspectRatio: number = 1 / 1;
  imageChangedEvent!: any;
  croppedImage!: any;
  originalImage!: any;

  resource: TuneV2 | UserV2 | CollectionV2;

  cropRect: CropRect = null; // From the cropper
  originalSourceImageSize: Dimensions;  // could do with some renaming!
  cropRectData: CropRectData; // via API
  originalImageData: ImageV2;

  showCropper: boolean = false;
  edited: boolean = false;
  inputImageData: string;
  inputImageSize: Dimensions;
  newImageFile: File = null;

  public new_image: boolean = false;
  public sizeIn: string;
  public sizeOut: string;
  public image_type!: string;

  private path_part: string = '_image';
  public is_cropper_ready: boolean = false;
  public orientation: string = 'landscape';

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  @ViewChild('ok_btn') ok_btn: ElementRef;

  constructor(
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<DialogImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    // console.log('dialog-image-upload data: ', data, data.type);
    this.image_type = data.type;
    if (this.image_type === 'header') {
      this.minimumWidth = 900;              // limit final croprect size, relative to original.
      this.resizeCroppedImageToWidth = 900; // for output image data for UI.
      this.minimumHeight = 300;              // limit final croprect size, relative to original.
      this.resizeCroppedImageToHeight = 300; // for output image data for UI.
      this.aspectRatio = 3 / 1;
      this.maxFileSize = 10000000;
      this.path_part = '_header_image';
    }

    this.resource = data.resource;

    // console.log('this.resource._image', this.resource._image)
    if(this.resource._image?.original_width < 600 || this.resource._image?.original_height < 600 ){
      // Legacy image too small.
      // Just activate the select button to allow a new image to be uploaded..
      this.is_cropper_ready = true;
      return;
    }    

    // console.log(this.resource.type); // UserV2, TuneV2 or CollectionV2
    if (this.resource[this.path_part] && this.resource[this.path_part].urls) {
      // console.log('_image data provided: ', this.resource._image);
      this.getImageData(this.resource[this.path_part].urls.precrop, (_data) => {
        
        if (!_data?.base64Data || !_data.size) {
          // console.log('No image data from original image');
          this.is_cropper_ready = true;
          return;
        }
        this.inputImageData = _data.base64Data;
        this.inputImageSize = _data.size;
        //console.log('previous crop rect and dimensions: ', this.resource._image.crop, this.resource._image.original_width, this.resource._image.original_height );
        //console.log('set these for the image-cropper component to be able to position the crop hadles where they were originally set');
        this.originalImageData = this.resource[this.path_part];
        // console.log('originalImageData: ', this.originalImageData);
      
      });
    }

    // console.log('One of the default image situations where an image has never been cropped or set: ', data.imageUrl);
    if (data.new_image) {
      this.new_image = data.new_image;
       // console.log('new_image provided :', this.new_image);
    }
    if (data.imageUrl) {
      // console.log('uses the default image currently set in the resource.attributes');
      // console.log('%cimageUrl', 'color:lime', data.imageUrl);
      this.getImageData(data.imageUrl, (_data) => {
        if (!_data?.base64Data || !_data.size) {
          // console.log('No image data from original image');
          this.is_cropper_ready = true;
          return;
        }
        this.inputImageData = _data.base64Data;
        this.inputImageSize = _data.size;
        let _blob = _data.blob;
        // console.log('inputImageSize:', this.inputImageSize, _blob);
      })
    }
    //}
  }

  getImageData(image_url: string, callback?: any) {
    // safeImgDataUrl
    // console.log('getImageData url:', image_url);
    
    this.is_cropper_ready = false;
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", image_url);
    xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
    xhr.onerror = (err) => {
      console.log('Image Data Error:', image_url, err);
      this.is_cropper_ready = true;
      callback();
    }
    xhr.onload = () => {
      blob = xhr.response;
      // console.log('image as blob data: ', blob); // only provides filesize and type.
      // Is case image is missing on CDN (type is xml)
      if (!blob.type.includes('image')) {
        callback();
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        var base64Data = reader.result;
        let _img = new Image(); // Get the size
        _img.onload = () => {
          callback({ blob: blob, base64Data: base64Data, size: { width: _img.width, height: _img.height } });
          _img = null;
        };
        _img.onerror = (err) => {
          console.log('Error loading selected image blob', err);
          callback();
        };
        _img.src = base64Data.toString();
      }
    }
    xhr.send();
  }

  fileChangeEvent(event: any): void {
    this.is_cropper_ready = false;
    // console.log('fileChangedEvent: ', event);
    // Test file size here...
    let f = event.target.files[0];
    // console.log(f);
    if (f === undefined) {
      return;
    }

    if (f.size > this.maxFileSize) {
      this.errorMessage = 'Image file size is too big. Please choose an image less than 7Mb file size.';
      // alert(this.errorMessage);
      console.warn(this.errorMessage);
      this.snackbar.show(this.errorMessage, 'snackbarWarning');
      return;
    }

    this.getFileImageDimensions(f, (dimensions) => {
      // console.log('Selected image dimensions: ', dimensions);
      if (dimensions.width >= this.minimumWidth && dimensions.height >= this.minimumHeight) {
        // Set new image data to recalculate cropper minimum size
        let new_height = dimensions.height, new_width = dimensions.width, crop_x = 0, crop_y = 0;
        // deal with large portrait images that may go off-screen out of the dialog.
        if (this.image_type === 'profile') {
          if (new_height > new_width && new_height > 600) {
            this.orientation = 'portrait';
            crop_x = 0;
            new_width = dimensions.width;
            new_height = new_width / this.aspectRatio;
            crop_y = (dimensions.height / 2) - (new_height / 2);
          } else {
            crop_y = 0;
            new_height = dimensions.height;
            new_width = new_height / this.aspectRatio;
            crop_x = (dimensions.width / 2) - (new_width / 2);
          }
        } else {
          if (new_height > new_width && new_height > 600) {
            this.orientation = 'portrait';
          }
          // header..
          crop_x = 0;
          new_width = dimensions.width;
          new_height = new_width / this.aspectRatio;
          crop_y = (dimensions.height / 2) - (new_height / 2);
        }

        const newImageData: ImageV2 = {
          original_height: dimensions.height,
          original_width: dimensions.width,
          crop: {
            x: crop_x,
            y: crop_y,
            w: new_width,
            h: new_height
          }
        }
        //
        this.originalImageData = newImageData;
        // console.log('new image originalImageData:', this.originalImageData);
        this.edited = true;
        this.imageChangedEvent = event; // loads into image-cropper
        this.is_image_valid = true;

        // Make sure the OK button scrolls into view with tall portrait images.
        if(this.ok_btn){
          setTimeout(()=>{
            this.ok_btn.nativeElement.scrollIntoView({
              behavior: 'smooth'
            })
          },100)
        } 

      } else {
        this.errorMessage = 'Image is too small. Please choose an image at least ' + this.minimumWidth + ' x ' + this.minimumHeight + ' pixels in size. - Selected image was ' + dimensions.width + 'x' + dimensions.height;
        // alert(this.errorMessage);
        this.is_cropper_ready = true;
        this.is_image_valid = false;
        console.log(this.errorMessage);
        this.snackbar.show(this.errorMessage, 'snackbarWarning'); // see styles.scss
      }
    });
  }

  getFileImageDimensions(file: File, callback: any) {
    let reader = new FileReader();
    let _img = new Image();
    _img.onload = () => {
      //console.log('image File width : ', _img.width);
      //console.log('image File height: ', _img.height);
      callback({
        width: _img.width,
        height: _img.height
      });
    };
    reader.onload = () => {
      _img.src = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  imageCropped(event: ImageCroppedEvent) {
    // console.log('%cImageCroppedEvent: ', 'color:red',event);
    this.croppedImage = event.croppedImageBase64;
    this.cropRect = event.cropRectRelativeToOriginal;
    // test
    let _cropRectData: CropRectData = this.cropRectToCropRectData(this.cropRect);
    // console.log(`%c New cropped image size final: ${_cropRectData.w} x ${_cropRectData.h}`, 'color:lightblue;font-weight:bold');
    if (this.cropRect.x2 - this.cropRect.x1 < 600 || this.cropRect.y2 - this.cropRect.y1 < 600) {
      // console.log(`%c FINAL CROPRECT IS TOO SMALL: ${_cropRectData.w} x ${_cropRectData.h}`, 'color:red;font-weight:bold');
      this.edited = false;
      this.is_image_valid = false;
    } else {
      this.edited = true; // show 'OK' button.
      this.is_image_valid = true;
    }
  }
  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }
  cropperReady(event: CropperReadyEvent) {
    // console.log('%cCropper ready: Image info: ', 'color:lime', event)
    if (event.newImageFile) {
      // only exists if a new image was selected. this will trigger its upload when the dialog is closed with the OK button.
      this.newImageFile = event.newImageFile;
    }
    this.is_cropper_ready = true;
  }
  loadImageFailed() {
    // console.log('loadImageFailed');
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  dataURItoBlob(dataURI: string): Blob {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });

  }

  cropRectToCropRectData(cropRect: CropRect): CropRectData {
    // Temp helper to convert data provided by cropper x1,y1,x2,y2 to x,y,w,h for API
    return {
      x: cropRect.x1,
      y: cropRect.y1,
      w: cropRect.x2 - cropRect.x1,
      h: cropRect.y2 - cropRect.y1
    };
  }

  dataURLtoFile(dataurl: string, filename: string): File {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useCroppedImage(): void {
    // Sends some image information and the cropped Base64 image for the UI, back to the component.
    if (!this.is_image_valid) {
      return;
    }

    // console.log('useCroppedImage: ', this.is_image_valid, this.cropRect);

    if (!this.originalImageData && this.newImageFile === null) {
      const customFile: File = this.dataURLtoFile(this.croppedImage, 'custom.jpg');
      // console.log('Edge case: customFile will be uploaded to replace the missing/default: ', customFile);
      this.newImageFile = customFile;
      this.cropRect = {
        x1: 0,
        y1: 0,
        x2: this.resizeCroppedImageToWidth, // 600
        y2: this.resizeCroppedImageToWidth
      }
    }

    if (this.cropRect === null) {
      // Edge-case where a 600x600 image is picked and no cropping has occured.
      console.log('cropRect is null! ', this.resizeCroppedImageToWidth);
      return;
    }
    // Close dialog and send data back to componenet.
    this.dialogRef.close({
      newImageFile: this.newImageFile,
      croppedImage: this.croppedImage,
      cropRect: this.cropRect
    });
  }

  cancel() {
    this.dialogRef.close();
  }


}

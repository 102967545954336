import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from '@services/user.service';
import { environment } from '@env/environment';
import { MetaTagsService } from '@services/meta-tags.service';

// Feature rows data models
import { PlaylistV2 } from '@models/playlist-v2';
import { TuneV2 } from '@models/tune-v2';
import { ExternalVideo } from '@models/video-v2';
import { UserV2 } from '@models/user-v2';

import { Post } from '@models/wp-post';
import { HallOfFame } from '@models/hall-of-fame';
import { Testimonial } from '@models/testimonials';
// No API requests required for these. Data is local static.
import { HallOfFameData } from '@modules/hall-of-fame/hall-of-fame-data';
import { TestimonialsData } from '@modules/testimonials/testimonials-data';

// API requests for feature rows images.
import { HomeContentService } from '@services/home-content.service';

interface RowImages { // for home-featured-row comps.
  image_url:string;
  routerlink?:string;
}

interface HomeData {
  top_five?: TuneV2[];
  featured_playlists?: PlaylistV2[];
  first_spin?: TuneV2[];
  first_look?: ExternalVideo[];
  for_sale?: TuneV2[];
}

@Component({
  selector: 'app-home-wide',
  templateUrl: './home-wide.component.html',
  styleUrls: ['./home-wide.component.scss'],
})
export class HomeWideComponent implements OnInit {

  public site_country: string = environment.site_country;
  public is_server:boolean = false;
  private logged_in_user:UserV2;
  public is_dev: boolean = false;
  // For flag urls. Now also allows cross-site login via /connect/auto-login via goToOtherCountrySite() method below.
  public site_urls: any = {
    live: {
      uk: 'https://amazingradio.com',
      us: 'https://amazingradio.us'
    },
    dev: {
      uk: 'https://dev.amazingradio.com',
      us: 'https://dev.amazingradio.us'
    }
  };
  private dev_ports:string[] = ['4200', '4300', '4700'];

  public user_country: string; // Two letter code. Set as localStorage 'geo' in app.component via geoip/lookup. Check for 'US'.
  public show_usa_banner:boolean;

  public show_usa_promo:boolean; // Erin's banner looking for presenters. 

  public featuredPlaylists:PlaylistV2[];
  public featuredPlaylistsImages:RowImages[];

  public topFiveTunes:TuneV2[];
  public topFiveTunesImages:RowImages[];

  public homeFirstSpins:TuneV2[];
  public homeFirstSpinsImages:RowImages[];

  public homeFirstLook:TuneV2[];
  public homeFirstLookImages:RowImages[];


  public homeNewPosts:Post[];
  public homeNewPostsImages:RowImages[];

  public testimonials:Testimonial[];
  public testimonialsImages:RowImages[];

  public hallOfFame:HallOfFame[];
  public hallOfFameImages:RowImages[];

  public tunesForSale:TuneV2[];
  public tunesForSaleImages:RowImages[];

  public UsaRowImages:RowImages[];
  public usa_show_row_images:RowImages[] = [
    {image_url:'https://schedule-cdn.amazingradio.com/2021/05/07/15/07/16/9a868ce2-ad7f-40b8-859b-dd4825c421b6/showSquareMike.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2020/11/26/10/51/17/068639aa-bf1d-4f6c-99bf-8ed39471aa98/showSquareErin.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/09/09/10/34/16/59860308-b92b-4eaa-a4dd-084da13f87c7/showSquareTobi.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/04/08/14/35/40/6fdb6a71-b4bf-4d81-bbc5-08af5c79210c/Mimi___Musiqueen_Ft_600.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/04/16/08/26/28/6122e52b-5225-4d5a-900a-119e9d0bd54f/Linton_600.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/04/09/15/07/51/a628157b-001e-41e2-a130-d7818c66faa4/Dominick_600.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/04/28/14/29/49/dd0183c0-4667-472d-8d48-7a7e048d0906/Marc_Ford_600.jpg'},
    {image_url:'https://schedule-cdn.amazingradio.com/2021/04/14/09/17/53/a36014d9-9e7e-46cb-949d-19327b35f014/Jose_600.jpg'},
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private metaTagsService:MetaTagsService, private homeContentService:HomeContentService , public userService: UserService, private hallOfFameData:HallOfFameData, private testimonialsData:TestimonialsData ) {
    // console.log('HomeComponent: ', (isPlatformBrowser(this.platformId) ? 'BROWSER':'SSR'));

    // For dev site flag links
    if (environment.api_endpoint_v2.includes('.mobi')) {
      this.is_dev = true;
    }

    if(this.userService.loggedIn()){
      this.logged_in_user = this.userService.get();
    }

    // // Load HoF and Testimonials. data,
    this.testimonials = testimonialsData.testimonials;
    let _t_row_images:RowImages[] = [];
    this.testimonials.forEach(data => {
      _t_row_images.push({
        image_url:data.image
      })
    });
    this.testimonialsImages = this.shuffle(_t_row_images).splice(0,5);
    // console.log('testimonialsImages', this.testimonialsImages);
    this.hallOfFame = hallOfFameData.halloffame;
    let _h_row_images:RowImages[] = [];
    this.hallOfFame.forEach(data => {
      _h_row_images.push({
        image_url:data.image
      })
    });
    this.hallOfFameImages = this.shuffle(_h_row_images).splice(0,5);
    // console.log('hallOfFameImages', this.hallOfFameImages);

    this.UsaRowImages = this.shuffle(this.usa_show_row_images).splice(0,5);

    if(!isPlatformBrowser(this.platformId)){
      this.is_server = true;
    } else {

      // Show banner on UK site when user has USA IP address. (Not dismissbale yet)
      if(this.site_country === 'uk' && localStorage.getItem('geo')){
        this.user_country = localStorage.getItem('geo');
        if(this.user_country === 'US' && !localStorage.getItem('geo_dismiss')){
          // console.log('%cU%cS%cA', 'color:red', 'color:white', 'color:dodgerblue');
          this.show_usa_banner = true;
        }
      }

      // Show presenter promo banner on US site when user has USA IP address. (Not dismissbale yet)
      if(this.site_country === 'us' && localStorage.getItem('geo')){
        this.user_country = localStorage.getItem('geo');
        if(this.user_country === 'US' && !localStorage.getItem('geo_dismiss')){
          // console.log('%cU%cS%cA', 'color:red', 'color:white', 'color:dodgerblue');
          this.show_usa_promo = true;
        }
      }
    }
    // After disabling scrollPositionRestoration: 'enabled' in the app-routing module (to allow 'sub'-router-outlets to work without affecting the scroll position), we need to try this
    // See issues : https://github.com/angular/angular/issues/24547
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }


    this.metaTagsService.generateTags({
      type:'page',
      description: 'Where talent gets noticed.',
      // resource_title:  'Online Radio | Internet Radio '
    });

    // All rows data returned as Observable<HomeData>
    this.homeContentService.getHomeFeaturedRowsData().subscribe(data => {
      if(!data){
        return;
      }
      let _row_images:RowImages[] = [];
      data?.top_five.forEach(data => {
        _row_images.push( { image_url:data.attributes.image_urls.small } )
      });
      this.topFiveTunesImages = _row_images;

      _row_images = [];
      data.featured_playlists.forEach(data => {
        _row_images.push( { image_url:data.attributes.image_urls.small } )
      });
      this.featuredPlaylistsImages = _row_images;

      _row_images = [];
      data.first_spin.forEach(data => {
        _row_images.push( { image_url:data.attributes.image_urls.small } )
      });
      this.homeFirstSpinsImages = _row_images;

      _row_images = [];
      data.first_look.forEach(data => {
        _row_images.push( { image_url:data.attributes.thumbnail.small } )
      });
      this.homeFirstLookImages = _row_images;

      // _row_images = [];
      // data.for_sale.forEach(data => {
      //   _row_images.push( { image_url:data.attributes.image_urls.small } )
      // });
      // this.tunesForSaleImages = _row_images;

    });

    // First Spins - periodically updated
    this.homeContentService.homeFirstSpinsSubject.subscribe(data => {
      if(data === null){
        return;
      }
      this.homeFirstSpins = data;
      let _row_images:RowImages[] = [];
      this.homeFirstSpins.forEach(data => {
        _row_images.push({
          image_url:data.attributes.image_urls.small,
          routerlink:'/profile/' + data.artist.attributes.permalink + '/tunes/' + data.id
        })
      });
      this.homeFirstSpinsImages = _row_images;
    });
  }

  openExternalLink(url:string){
    if(typeof window !== 'undefined'){
      // console.log('openExternalLink: ', url);
      window.open(url, '_blank');
    }
  }

  shuffle(array: any[]): any[] {
    let counter = array.length, temp, index, _array;
    _array = array;
    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * counter);
      // Decrease counter by 1
      counter--;
      // And swap the last element with it
      temp = _array[counter];
      _array[counter] = _array[index];
      _array[index] = temp;
    }
    return _array;
  }

  ngOnInit(): void {

  }

  goToOtherCountrySite(event: any) {
    event.preventDefault(); // hijack the href link
    if (typeof window !== 'undefined') {
      // Disable flags in localhost dev environments.
      if (this.dev_ports.includes(window.location.port)) {
        alert('Country site links cannot work in local development.');
        return;
      }
      let other_site_url: string = this.site_country === 'uk' ? (this.is_dev ? this.site_urls.dev.us : this.site_urls.live.us) : (this.is_dev ? this.site_urls.dev.uk : this.site_urls.live.uk);
      // if (this.logged_in_user) {
      //   other_site_url += '/connect/auto-login?access_token=' + this.userService.getJWToken();
      // }

      // stop the banner showing again.
      localStorage.setItem('geo_dismiss', 'true')
      // console.log('goto ', other_site_url);
      window.location.assign(other_site_url);
    }
  }
}

<div class="nameCard" role="button" [routerLink]="router_link ? router_link : ['/profile', user.attributes.permalink]">
  <div class="square-img-cont" [title]="hover_title ? hover_title : name ? name : 'Go to the profile for ' + user.attributes.name">
    <img [lazyImgSrc]="image_url ? image_url : user?.attributes?.image_urls?.medium" src="/assets/placeholders/placeholder-square-dark-300.png">
    <div class="overlay">
      <div class="name">
        <div [innerHTML]="name ? name : user.attributes.name" [title]="name ? name : user.attributes.name"></div>
      </div>
      <app-action-button-like class="action-btn" *ngIf="user && show_action && !user._is_manager; else edit" [resource]="user"></app-action-button-like>
    </div>
  </div>
</div>
<ng-template #edit>
  <app-action-button-edit title="Edit Artist" class="action-btn" *ngIf="user && show_action && user._is_manager" (click)="editUser($event)"></app-action-button-edit>
</ng-template>

import { Directive, ElementRef, Input, AfterViewInit, OnChanges} from '@angular/core';
// import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

/*

  Lazyload an img background style.

  Allows images to have a placehloder background image and a 'lazyImgBg' source which replaces the placeholder when loaded.
  eg (in the template):

*/

@Directive({
  selector: '[lazyImgBg]'
})
export class LazyImageBackgroundDirective implements AfterViewInit, OnChanges {

  @Input('lazyImgBg') imageSource: string;
  @Input('zoomScale') imageScale: string;

  constructor(private el: ElementRef) { }

  ngOnChanges(): void {
    // We need this or the directive content doesn't change when the model does.
    if(this.imageSource && typeof window !== "undefined"){
      this.loadBackgroundImage(this.imageSource);
    }
  }


  ngAfterViewInit(): void {
    if(typeof window === "undefined"){
      // Lazy way of detecting SSR..
      this.el.nativeElement.style.background = 'url(' + this.imageSource + ')';
      this.el.nativeElement.style.backgroundSize = 'cover';
      this.el.nativeElement.style.backgroundPosition = 'center';
      return;
    }
    // console.log('lazyImgBg source url: ', this.imageSource);
    if(this.imageSource){
      if(typeof window !== "undefined"){
        this.loadBackgroundImage(this.imageSource);
      }
    }

  }

  loadBackgroundImage(img_url:string){
    this.el.nativeElement.style.background = 'transparent';
    let img = new Image();
    img.onload = (e)=> {
      //console.log('background image loaded', e);
      this.el.nativeElement.style.background = 'url(' + img_url + ')';
      this.el.nativeElement.style.backgroundSize = 'cover';
      this.el.nativeElement.style.backgroundPosition = 'center';
    };
    img.onerror = (e)=> {
      // console.log('error loading background image');
    };
    img.src = img_url;
  }

}

<div class="header"><h6>Buy More Uploads</h6></div>

<div *ngIf="plans" class="subscriptionCont">

  <div class="planCont">

    <div *ngIf="plans.length > 1 && !selected_plan" class="plans_msg">
      <h6>Please select an option below</h6>
    </div>

    <div class="plans">
      <div *ngFor="let plan of plans" (click)="selectPlan(plan)" class="plan" [class]="selected_plan && selected_plan.id === plan.id ? 'selected':''">
        <div class="price">
          <strong>Price:</strong>
          <br>
          <span class="priceAmount">{{ plan.attributes.payment_amount | currency : plan.attributes.payment_currency: 'symbol': plan.attributes.payment_amount % 1 === 0 ? '0.0':'0.02'  }}</span>
        </div>
        <div class="limit">
          <strong>Uploads:</strong>  {{ plan.attributes.upload_limit }}
        </div>
        <div class="eligible-users">
          <span class="small">For</span>
          {{ plan._plan_user_string }}
        </div>
      </div>
    </div>

    <!-- Payment gateway options -->


    <!--  migrate to app-card-payment-stripe option if we need to support this again ... -->

    <!-- Card payment -->
    <app-card-payment [selected_plan]="selected_plan" [parent_dialog_ref]="dialogRef" *ngIf="accept_card_payments && selected_plan"></app-card-payment>
    <!-- Paypal -->
    <div *ngIf="selected_plan">
      <span class="instructions">
        <span *ngIf="accept_card_payments"> OR </span>
        CLICK BELOW TO
      </span>
      <div class="btn-buy" (click)="close(selected_plan, 'paypal')">
        <img title="Please click here to start your purchase" src="/assets/logos/paypal.svg">
        <div>
          <div>Purchase via PayPal</div>
        </div>
      </div>
    </div>

  </div>

  <!-- Only show if the current site has US-Eligible Plans available -->
  <div class="instructions">
    All uploads will be eligible for Amazing Radio USA as well as Amazing Radio UK.
    <span *ngIf="user.attributes.classification === 'label' || user.user_private.self_classification === 'label'">
      <br>As a label, these uploads will be applicable to share among any of your artists.
    </span>
  </div>
  <div class="instructions">
    These are one-off payments, not recurring subscriptions.
  </div>

</div>

<div class="header"><h6>Buy {{ resource.type === 'tune'? resource.type : resource.attributes.type }}</h6></div>

<div *ngIf="resource" class="cont">

  <div class="item">

    <div class="artwork">
      <img [lazyImgSrc]="resource.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png">
    </div>
    <div class="details">
      <div class="name">
        {{ resource.artist.attributes.name }}
      </div>
      <div class="title">
        {{ resource.attributes.name }}
      </div>
    </div>
  </div>

  <div class="label yellow price">
    <strong [innerHTML]="resource.attributes.distribution.sale_price_pence / 100 | currency : resource.attributes.distribution.currency"></strong>
  </div>


  <div class="instructions">
    Click the button below to go to PayPal and make your payment.
    <br>
    <br>
    You will then be redirected back here to confirm the payment and activate a download link which will also be emailed to you.
    <br>
  </div>
  <!-- <br> -->
  <!-- Eventually there will be more payment gateway options -->
  <div class="btn-buy" (click)="buy()">
    <img title="Please click here to start your purchase" src="/assets/logos/paypal.svg">
    <div>
      <div>Purchase via PayPal</div>
    </div>
  </div>

</div>

<div class="footer message">100% of the proceeds goes to <strong>{{ resource.artist.attributes.name }}</strong></div>

import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { MatDialogRef ,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Plan } from '@models/subscriptions-v2';
import { UserV2 } from '@models/user-v2';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { environment } from '@env/environment';

export interface DialogData {
  plans?: Plan[];
  selected_plan?:Plan;
  selected_plans?:Plan[];
}

@Component({
  selector: 'app-dialog-buy-subscription',
  templateUrl: './dialog-buy-subscription.component.html',
  styleUrls: ['./dialog-buy-subscription.component.scss'],
  inputs: ['processing']
})
export class DialogBuySubscriptionComponent {

  public CARD_PAYMENT_PROVIDER: string = environment.card_payment_provider // stripe | checkout 

  public plans: Plan[];
  public selected_plan: Plan;
  public available_plans: Plan[]; // now an array billing_period : 'monthly'|'annual'
  public selected_payment_plan: Plan; // selection if needed. selected_plans[0] if not.
  public saving_percent:number;
  public user: UserV2;
  public site_country: string = environment.site_country;
  public accept_card_payments: boolean = environment.accept_card_payments;

  public upgrade_type:string = 'Artist ';  // deliberate trailing space

  @Input() processing: boolean = true; // two-way binding to app-card-payment

  // dialogContent
  @ViewChild('dialogContent') dialogContent: ElementRef;

  public user_type_strings: any = {
    fan: 'New Users',
    artist: 'Artists',
    label: 'Labels'
  };

  constructor(
    public dialogRef: MatDialogRef<DialogBuySubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public userService: UserService,
    private snackbar: SnackbarService
  ) {

    this.processing = false;
    
    // console.log('DialogData: ', data);

    if(typeof window !== 'undefined'){
      if(localStorage.getItem('self_classification') === 'label'){
        this.upgrade_type = 'Label '; // deliberate trailing space
      }
    }

    if(data.selected_plans){
      // console.log('DialogData: selected_plans ', data.selected_plans);
      this.available_plans = data.selected_plans;

      if(this.available_plans.length === 1){
        this.selected_plan = this.available_plans[0];
      } else {

        let annual_price:number;
        let monthly_price_total:number;

        this.available_plans.forEach(data => {
          if(data.attributes.billing_period === 'annual'){
            annual_price = data.attributes.payment_amount;
          } else if(data.attributes.billing_period === 'monthly'){
            monthly_price_total = data.attributes.payment_amount * 12;
          }
        });
        //console.log('Annual  cost/year: ', annual_price);
        //console.log('Monthly cost/year: ', monthly_price_total);
        this.saving_percent = Math.round( ((monthly_price_total - annual_price) / monthly_price_total ) * 100);
        // console.log('SAVING', this.saving_percent + '%');
      }

    }

    this.user = this.userService.get();

  }

  selectPaymentPlan(plan:Plan){
    if(this.processing){
      return;
    }
    // console.log('plan selected');

    if(plan.id === this.selected_plan?.id){
      this.selected_plan = null;
      return;
    }    
    // console.log('Selected Payment option: ', plan);
    this.selected_plan = plan; 
  }

  selectPlan(plan: Plan) {
    if (this.plans.length > 1 && this.selected_plan && plan.id === this.selected_plan.id) {
      this.selected_plan = null;
      return;
    }
    this.selected_plan = plan;

    // console.log('scrollTop', this.dialogContent.nativeElement.scrollTop);
    // console.log('scrollHeight', this.dialogContent.nativeElement.scrollHeight);
    // console.log('clientHeight', this.dialogContent.nativeElement.clientHeight);

    // Allow time for the card payment comp. to appear (if activated), or the heights will be out.
    setTimeout(() => {
      this.dialogContent.nativeElement.scrollTo(0, this.dialogContent.nativeElement.scrollHeight / 2)
    }, 100);

  }

  close(plan: Plan, gateway: string) {

    if(this.processing){
      return; // already processing (checkout)
    }

    if (!plan) {
      this.snackbar.show('Please select an option first');
      return;
    }
    this.dialogRef.close({ selected_plan: this.selected_plan, gateway: gateway });
  }

  cancel() {
    this.dialogRef.close();
  }

}

<div class="instructions">
  PAY SECURELY BY CARD
</div>

<form class="payment-form" #checkoutPaymentForm novalidate>
  <div class="one-liner">
    <div class="card-frame">
    </div>
    <button #checkoutPayButton id="pay-button" disabled>
      PAY {{ selected_plan.attributes.payment_currency }} {{ (selected_plan.attributes.payment_amount | currency : selected_plan.attributes.payment_currency: 'symbol': selected_plan.attributes.payment_amount % 1 === 0 ? '0.0':'0.02') + (selected_plan.attributes.billing_period === 'monthly' ? ' / Month':'') }}
    </button>
    <div class="discounted" *ngIf="selected_plan.attributes.payment_amount_without_discount">
      First year only.
      {{ (selected_plan.attributes.payment_amount_without_discount | currency : selected_plan.attributes.payment_currency: 'symbol': selected_plan.attributes.payment_amount_without_discount % 1 === 0 ? '0.0':'0.02')}}{{(selected_plan.attributes.billing_period === 'monthly' ? '/month':'/year')}} thereafter.
    </div>
  </div>
  <div class="about">Provided by Checkout.com</div>
</form>


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { MemberSubscription } from '@models/subscriptions-v2';

export interface DialogData {
  subscription?: MemberSubscription;
}

@Component({
  selector: 'app-dialog-cancel-subscription',
  templateUrl: './dialog-cancel-subscription.component.html',
  styleUrls: ['./dialog-cancel-subscription.component.scss']
})
export class DialogCancelSubscriptionComponent implements OnInit {

  public member_subscription:MemberSubscription;

  constructor(
    public dialogRef: MatDialogRef<DialogCancelSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api_tunes: ApiAmazingtunesV2Service,
    public userService: UserService,
    private snackbar: SnackbarService
  ) {

    if(data.subscription){
      this.member_subscription = data.subscription;
      // console.log('Subscription to cancel: ', this.member_subscription.data);
    } else {
      // console.log('No subscription data injected..');
      this.dialogRef.close();
    }
  }

  cancelSubscription():void {

    this.api_tunes.cancelSubscription().subscribe((data) => {
      // console.log('cancelSubscription success:', data);
      this.snackbar.show('Your subscription has been cancelled.');
      this.dialogRef.close({subscription: data});
    },
    (error) => {
      // console.log('cancelSubscription error : ', error);
      if(error.status === 410){
        this.snackbar.show('Your subscription is already cancelled');
      } else if(error.status === 412) {
        this.snackbar.show('Your subscription is not active, so does not need to be cancelled');
      }
      this.dialogRef.close();
    })
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { environment } from '@env/environment';
import { UserV2 } from '@models/user-v2';
import { MatDialog } from '@angular/material/dialog';
import { DialogContentReportComponent } from '@modules/_shared/ui/components/dialog-content-report/dialog-content-report.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators';
import { ReportableService } from '@services/reportable.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public amazing_logo: string = '/assets/logos/2023/RadioWhite.svg';

  public is_server:boolean = false;

  public dateYear:number;
  public site_country:string;

  public logged_in_user:UserV2;

  public social_twitter:string;
  public social_facebook:string;
  public social_instagram:string;
  public social_youtube:string;
  public social_tiktok:string;
  public social_threads:string;

  public user:UserV2;

  public is_dev: boolean = false;
  // For flag urls. Now also allows cross-site login via /connect/auto-login via goToOtherCountrySite() method below.
  public site_urls: any = {
    live: {
      uk: 'https://amazingradio.com',
      us: 'https://amazingradio.us'
    },
    dev: {
      uk: 'https://dev.amazingradio.com',
      us: 'https://dev.amazingradio.us'
    }
  };
  private dev_ports:string[] = ['4200', '4300', '4700'];

  // Content reporting.
  // Only show 'Report a Problem' link on reportable pages.
  // Url will contain /profile/..
  public is_reportable: boolean;    // show/hide report link

  constructor(private router:Router, private userService: UserService, private dialog:MatDialog, private rs:ReportableService) {

    if(typeof window === "undefined"){
      this.is_server = true;
    }

    if (environment.api_endpoint_v2.includes('.mobi')) {
      this.is_dev = true;
    }
    // if(environment.site_country === 'us'){
    //   this.amazing_logo = '/assets/logos/logoAmazingUSA.svg'
    // }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data: NavigationEnd) => {
      if(data.url.includes('/profile/')){
        this.is_reportable = true;
      } else {
        this.is_reportable = false;
        this.rs.reportable = null;
      }
    });

  }

  ngOnInit(): void {
    this.site_country = environment.site_country;

    this.social_twitter = 'https://twitter.com/'+environment.social_twitter;
    this.social_facebook = 'https://facebook.com/'+environment.social_facebook;
    this.social_instagram = 'https://instagram.com/'+environment.social_instagram;
    this.social_youtube = 'https://youtube.com/'+environment.social_youtube;
    this.social_tiktok = 'https://tiktok.com/@'+environment.social_tiktok;
    this.social_threads = 'https://threads.net/@'+environment.social_threads;

    this.dateYear = new Date().getFullYear();
    this.userService.userSub.subscribe(user => {
      this.logged_in_user = user
    });
  }

  goHome() {
    this.scrollToTop();
    this.router.navigate(['/']);
  }

  scrollToTop():void{
    if(typeof window !== 'undefined'){
      window.scrollTo(0,0);
    }
  }

  goToOtherCountrySite(event: any) {
    event.preventDefault(); // hijack the href link
    if (typeof window !== 'undefined') {
      // Disable flags in localhost dev environments.
      if (this.dev_ports.includes(window.location.port)) {
        alert('Country links cannot work in local development.');
        return;
      }
      let other_site_url: string = this.site_country === 'uk' ? (this.is_dev ? this.site_urls.dev.us : this.site_urls.live.us) : (this.is_dev ? this.site_urls.dev.uk : this.site_urls.live.uk);
      if (this.userService.loggedIn()) {
        other_site_url += '/connect/auto-login?route=/&access_token=' + this.userService.getJWToken();
      }
      window.location.assign(other_site_url);
    }
  }

  contentReport(){
    if(!this.rs.reportable){
      console.log('Nothing to report');
      return;
    }
    // console.log('Content Report: ', this.rs.reportable);
    const dialogRef = this.dialog.open(DialogContentReportComponent, {
      width: '560px',
      panelClass: 'dialogReport',
      data: { reportable: this.rs.reportable },
      scrollStrategy: new NoopScrollStrategy()
    });
  }

}

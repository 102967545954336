import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  public snackbarRef:MatSnackBarRef<SimpleSnackBar>;

  constructor(public snackBar:MatSnackBar) { }

  show(message:string, panelClass?:string, duration:number = 4000){
    // console.log('show snackbar');
    this.snackbarRef = this.snackBar.open(message, 'OK', {
      panelClass:panelClass, // eg styles: snackbarWarning class
      duration:duration,
      horizontalPosition:'end',
      verticalPosition:'bottom'
    });

    this.snackbarRef.afterDismissed().subscribe(() => {
      // console.log('The snack-bar was dismissed');
    });

    this.snackbarRef.onAction().subscribe(() => {
      // console.log('The snack-bar action was triggered!');
      // if(actionCallback){
      //   actionCallback('ok was clicked');
      // }
    });
  }

  hide(){
    // console.log('hide snackbar');
    this.snackBar.dismiss();
  }
}

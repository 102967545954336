<div class="header"><h6>Cancel Subscription</h6></div>

<div class="cancelCont" *ngIf="member_subscription">

  <div class="paymentCont">
    Cancelling your Amazing Membership subscription renewal will remove your payment method.
    <br><br>
    Your account will remain active until<br><span class="until" [innerHTML]="member_subscription.data.attributes.next_payment_date | date:'longDate'"></span>
    <br>
    <br>
    <span>CLICK BELOW TO<br></span>
    <div class="label warn link withicon" (click)="cancelSubscription()">
      <mat-icon inline="true">cancel</mat-icon> Cancel Subscription
    </div>

  </div>
  <p class="instructions">
    You can renew your subscription before the date above by adding a new payment method
  </p>
</div>

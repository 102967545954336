<!-- Play/Stop Radio toggle -->
<div class="radio-play-btn" role="button" [title]="player.streaming && player.radio_playing ? 'Stop Amazing Radio':'Play Amazing Radio'" [class]="(show_listen_live_label ? 'listen':'') + ' ' + addClass" (click)="player.toggleRadioStream($event)">
  <mat-icon class="play-btn"
      [class]="(player.isLoading() && player.streaming
          ? 'spinner buffering' : player.streaming && player.radio_playing
              ? 'playing' : 'stopped')"
      [svgIcon]="(player.isLoading() && player.streaming
      ? 'buffering' : player.streaming && player.radio_playing
          ? (show_levels ? 'levels':'stop' ) : 'play')">
  </mat-icon>
  <!-- <div *ngIf="show_listen_live_label" [innerHTML]="player.streaming && player.radio_playing ? timeSlipService.offset_str : ('Listen ' + timeSlipService.offset_str )"></div> -->
  <div *ngIf="show_listen_live_label">
    {{ player.streaming && player.radio_playing ? timeSlipService.offset_str : ('Listen ' + timeSlipService.offset_str ) }}
  </div>

</div>

<!-- <mat-icon inline="true">history</mat-icon> -->

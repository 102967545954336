import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  resource: any;
}

@Component({
  selector: 'app-dialog-recorder-uploader',
  templateUrl: './dialog-recorder-uploader.component.html',
  styleUrls: ['./dialog-recorder-uploader.component.scss']
})
export class DialogRecorderUploaderComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogRecorderUploaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    // console.log('uploader/recorder dialog data: ', this.data.resource);
  }

}

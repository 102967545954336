<!-- Video card component : V3 -->
<div class="video-card" (click)="viewVideo()">
  <div class="cont-4-3" aria-hidden="true">
    <img [title]="'Play video'" [lazyImgSrc]="video.attributes.thumbnail_url" src="/assets/placeholders/placeholder-video-thumb-dark-43.png">
  </div>
  <div class="info-cont">
    <div>
      <div role="link" aria-label="Go to artist profile"  (click)="goToArtistProfile($event)" class="name" *ngIf="show_artist" [innerHTML]="video.artist.attributes.name"></div>
      <div role="link" aria-label="Go to video page" (click)="goToVideoPage($event)" [class]="show_artist ? 'title':'title embolden'" [innerHTML]="video.attributes.name"></div>
      <div *ngIf="url.includes('/firstlook')" title="Date added" class="date-foot" [innerHTML]="video.attributes.created_at| date:'MMM d, h:mm a'"></div>
    </div>
    <div class="edit-delete" *ngIf="owner_page">
      <div title="Remove this video" (click)="deleteVideo($event)" class="delete">
        <mat-icon>delete</mat-icon>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var _paq:any;

export interface DialogData {
  no_dismiss?:boolean;
}



@Component({
  selector: 'app-dialog-ad-tunecore',
  templateUrl: './dialog-ad-tunecore.component.html',
  styleUrls: ['./dialog-ad-tunecore.component.scss']
})
export class DialogAdTunecoreComponent implements OnInit {

  private offer_link:string = 'https://tunecoretalent.pxf.io/c/2729073/849710/11859?u=https%3A%2F%2Fwww.tunecore.com';

  public no_dismiss:boolean;

  constructor(public dialogRef: MatDialogRef<DialogAdTunecoreComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {

    if(data?.no_dismiss){
      this.no_dismiss = true;
    }

  }

  ngOnInit(): void {
  }

  getOffer(){
    if(typeof window !== 'undefined'){
      window.open(this.offer_link, "_blank");
    }
    _paq.push(['trackEvent', 'VisitedOfferBanner', 'Tunecore']);

    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }

  dismiss(){
    if(typeof window !== 'undefined'){
      localStorage.setItem('dismissed_banner_tunecore', 'true');
      _paq.push(['trackEvent', 'DismissedOfferBanner', 'Tunecore']);
      this.close();
    }
  }

}

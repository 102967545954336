<!-- Player Polls embeds component -->
<!-- Playlist Poll player -->
<div *ngIf="playlist_poll" class="playerEmbed playlistPlayer noselect">
  <div class="playlistInfo">
    <!-- <img [src]="playlist.image" class="playlistImage" routerLink="/profile/{{ playlist.creator.permalink }}/playlists/{{ playlist.id }}"> -->
    <img *ngIf="!playlist_poll.is_audition" [src]="playlist_poll.playlist_json.attributes.image_urls.medium" class="playlistImage">

    <div class="playlistMeta" *ngIf="!playlist_poll.is_audition; else audition">
      <div class="playlistName">{{playlist_poll.title.rendered}}</div>
      <div class="playlistAuthor">a playlist poll from <em>{{playlist_poll.playlist_json._curator.attributes.name}}</em>
        ({{playlist.length}} tunes)</div>
      <div class="playlistAuthor pollDate" *ngIf="!has_ended">poll closes
        {{ playlist_poll.datetime_end * 1000 | date:"dd/MM/yyyy 'at' h:mma" }}</div>
    </div>
    <span *ngIf="playlist_poll.is_audition" class="banner">Audition Poll</span>
    <span *ngIf="!playlist_poll.is_audition" class="banner">Playlist Poll</span>

  </div>
  <div class="votingBar" *ngIf="!has_user_voted && !has_ended; else voted">
    <form *ngIf="!voting; else votingIndicator">
      Select your favourite tune and click <input (click)="vote()" [disabled]="disabled || !voted_tune_id"
        class="btn yellow" type="button" value=" VOTE ">
    </form>
  </div>
  <ng-template #votingIndicator>
    VOTING ... <mat-progress-spinner class="spinner" color="accent" diameter="46" mode="indeterminate">
    </mat-progress-spinner>
  </ng-template>

  <ng-template #voted>
    <div *ngIf="!has_ended; else ended" class="votingBar">
      <span *ngIf="!just_voted; else justVoted">You have already voted</span>
    </div>
  </ng-template>
  <ng-template #justVoted>
      Thanks for voting!
  </ng-template>
  <ng-template #ended>
    <div class="votingBar">
      This poll has closed
    </div>
  </ng-template>

  <div class="playlistTunes" [class]="playlist_poll.is_audition ? 'audition':''">
    <mat-radio-group [(ngModel)]="voted_tune_id" [disabled]="disabled">
        <div *ngFor="let tune of playlist; let i = index" [id]="'tune-'+tune.id"
          [class]="tune.id === voted_tune_id ? 'voted':''" class="playlistTune">

          <!-- Result bar. Only shows if already voted AND the poll is set to display it after voting or when the poll closes. -->
          <div class="result_bar" *ngIf="voted_tune_id && (playlist_poll.show_results_after_vote || playlist_poll.show_results_at_end)" [style.width]="playlist_poll.playlist_json._tunes[i].meta.percentage_round + '%'" [title]="playlist_poll.playlist_json._tunes[i].meta.percentage_round + '%'" ></div>

          <div *ngIf="!has_user_voted && !has_ended" class="vote">
            <mat-radio-button class="radio" name="vote" [value]="tune.id"></mat-radio-button>
          </div>

          <img [src]="tune.attributes.image_urls.medium" class="playlistTuneImage" [class]="tune.attributes.is_private ? 'private':''"
            [routerLink]="!tune.attributes.is_private ? ['/profile', tune.artist.attributes.permalink, 'tunes', tune.id] : null">
          <div class="tuneMeta">
            <div>
              <a class="tuneArtist" [routerLink]="['/profile', tune.artist.attributes.permalink]"
                [innerHTML]="tune.attributes.radio_artist"></a>
            </div>
            <div>
              <a class="tuneTitle" *ngIf="!tune.attributes.is_private" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id]"
                [innerHTML]="tune.attributes.radio_name"></a>
              <span title="Private Tune" *ngIf="tune.attributes.is_private" [innerHTML]="tune.attributes.radio_name"></span>

            </div>
          </div>
          <app-play-button-v2 *ngIf="!tune.attributes.is_private" [playlistHead]="playlistHead" class="playButton" [addClass]="tune.id === voted_tune_id ? 'pollPlayButtonVoted':'pollPlayButton'" [index]="i" [playlist]="playlist" [tune]="tune"></app-play-button-v2>
        </div>
    </mat-radio-group>
  </div>
  <!-- If another vote button is needed at the bottom -->
  <!-- <div class="votingBar" *ngIf="!has_user_voted && !has_ended; else voted">
    <form *ngIf="!voting; else votingIndicator">
      Select your favourite tune and click <input (click)="vote()" [disabled]="disabled || !voted_tune_id"
        class="btn yellow" type="submit" value=" VOTE ">
    </form>
  </div> -->
</div>

<ng-template #audition>
  <div class="playlistMeta">
    <div class="playlistName">{{playlist_poll.title.rendered}}</div>
    <div class="playlistAuthor">Poll closes {{ playlist_poll.datetime_end * 1000 | date:"MMMM d, y 'at' h:mma" }}</div>
  </div>
</ng-template>

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { TunesV2, TuneV2 } from '@models/tune-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { FeaturedMediaItem } from '@models/wp-featured-media';
import { Rewind } from '@app/models/show';
// For data still in V1 format. eg: FeaturedMedia via WP.
import { TuneV1V2Pipe } from '@services/tune-v2-v1.pipe';
import { RadioChannelService } from '@services/radio-channel.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { RadioHistoryService } from '@services/radio-history.service';
import { RadioChannel } from '@models/configurations';
import { HomeContentService } from '@services/home-content.service';
import { ShowSchedule } from '@models/show';
import { GenreStream } from '@models/genre-v2';
import { GenreStreamService } from '@services/genre-stream.service';
import { TimeSlipService } from '@services/time-slip.service';
import { SnackbarService } from '@services/snackbar.service';

const PLAY_HOST = environment.play_uri;
let STREAM_HOST; //  = environment.radio_stream; // UK Myriad / scheduled shows etc.
// Temporary US custom stream
// Now Unused. Referenced in toggleCustomStream()
const CUSTOM_STREAM_HOST = 'https://stream.amazingradio.us:8443/us'; // US Shoutcast test stream. 'Back Story'

declare var _paq;

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private radio_channel: RadioChannel;

  public audio: HTMLAudioElement;
  public is_mobile_safari: boolean = false; // Will get set by canSetAudioVolume()
  // Set up ability to subscribe to all useful player Bevaviours in the player component
  public progress: BehaviorSubject<number> = new BehaviorSubject(0);
  public percentLoaded: BehaviorSubject<number> = new BehaviorSubject(0);
  public playerStatus: BehaviorSubject<string> = new BehaviorSubject('');
  public duration_secs: BehaviorSubject<number> = new BehaviorSubject(0);
  public position_secs: BehaviorSubject<number> = new BehaviorSubject(0);
  public is_streaming: BehaviorSubject<boolean> = new BehaviorSubject(true); // Always initialise as the streaming radio player
  public is_streaming_custom: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // Provide subscribables for the Player Component UI
  public displayed_tune: BehaviorSubject<TuneV2> = new BehaviorSubject(null);
  public is_featured: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is_mp3: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public offset: number = 0; // Time-slip offset

  public streaming: boolean = true;
  public streaming_custom: boolean = false; // Temporary measures until US site has its own proper shows. This will play the US 'test' stream.
  public radio_playing: boolean = false;
  public custom_playing: boolean = false;

  public featured: boolean = false;     // Featured media via WP.
  public rewinds: boolean = false;      // Show Rewinds.
  public mp3_source: boolean = false;   // Featured MP3/Rewinds.

  public current_rewind: Rewind;

  public genre_stream: GenreStream; // Genre Stream.
  public genre_stream_playing: boolean = false;
  private genre_streams_sub: Subscription;

  public iconState: BehaviorSubject<number> = new BehaviorSubject(0);

  // Holding text while site loads. Will get overriden by a loaded tune or radio history
  public playerTextOverride = {
    artist: 'Amazing Radio',
    title: 'Where talent gets noticed',
    routerLink: '/'
  };

  public currentTime: number = 0;
  public duration: number = 0;
  public status: string = '';
  public playing: boolean = false;
  public paused: boolean = false;
  public loading: boolean = false;

  private playback_ping: boolean = false;
  private playback_ping_seconds: number = 2; // Was 20 seconds.

  private has_seeked: boolean = false; // disallow pingbacks if manually seeked.

  // HTMLAudio event controlled.
  private is_seeking: boolean = false;
  // private is_seeked: boolean = false;

  public currentTune: TuneV2 = null;
  public playlist: TuneV2[] = []; // Might change naming here. This is just a list of Tunes, not an amazingtunes 'PlaylistV1'
  public playlist_index: number = -1;

  public playlistData: PlaylistV2 = { id: null }; // Set id:null For playlist-play-button state matching.

  public nowPlayingTune: TuneV2;
  public playist_reverse: boolean = false;
  // the tune object to display in the main footer player
  public displayTune: TuneV2 = { id: "0" };
  public unique_id: string; // set when a resource os played from the activity feed action.
  private loading_tune_id: string;

  // Radio History array
  public history: TuneV2[];

  public current_show: ShowSchedule;

  // Rewind sync testing
  public rewind_index: number = 0;
  public rewind_time: number;
  public rewind_stack: number[] = [];
  public rewind_tunes: TuneV2[] = [];
  public rewind_next_time: number = 0;
  public rewind_airing_id: number = null;

  public slider_dir: number = 0; // 1 or -1 depending on direction of slider while sliding
  private last_slide_val: number;

  private ui_timeout: any;
  private ui_timeout_ms: number = 1800000; // 3 minutes

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private tuneV1V2Pipe: TuneV1V2Pipe,
    private api_tunes_v2: ApiAmazingtunesV2Service,
    private radioHistoryService: RadioHistoryService,
    private radioChannelService: RadioChannelService,
    private homeContentService: HomeContentService,
    private genreStreamService: GenreStreamService,
    private timeSlipService: TimeSlipService,
    private snackbar: SnackbarService
  ) {

    if (isPlatformBrowser(this.platformId)) {
      // Non-SSR only
      this.audio = new Audio();
      this.canSetAudioVolume(); // Test to see if this device browser can set audio volume. If not, it's Mobile Safari!
      this.attachListeners();
      this.audio.volume = parseFloat(localStorage.getItem("vol")) || 0.6;



    }

    this.radioChannelService.radio_channel_subject.subscribe(data => {
      if (data !== null) {
        this.radio_channel = data;
        STREAM_HOST = this.radio_channel.data.attributes.stream_url;
        // console.log('radio_channel:', this.radio_channel.data.attributes.name, STREAM_HOST);
      }
    });

    this.homeContentService.showSubject.subscribe(data => {
      if (!data) {
        return;
      }
      if (!this.current_show || this.current_show.slug !== data.slug) {
        this.current_show = data;
        // console.log('Current Show: ', this.current_show);
        // TEST
        // this.current_show.show.hide_now_playing = true;


      }
    });

    this.radioHistoryService.history_subject.subscribe(data => {
      if (data === null) {
        // BehaviorSubject initialises with null so ignore this.
        return;
      }
      this.history = data;
      // console.log('%cRadio .history update via RadioHistoryService: ', 'color:yellow', this.history);
      this.nowPlayingTune = this.history[0];
      if (this.streaming && !this.featured && !this.genre_stream) {
        // update the tune to the PlayerComponent subscriber
        if (this.nowPlayingTune.id !== this.displayTune.id) {
          this.displayed_tune.next(this.nowPlayingTune);
          this.displayTune = this.nowPlayingTune;
        }
      }
    });

  }

  private attachListeners(): void {

    this.audio.addEventListener('timeupdate', this.calculateTime, false);
    this.audio.addEventListener('playing', this.setPlayerStatus, false);
    this.audio.addEventListener('pause', this.setPlayerStatus, false);
    this.audio.addEventListener('progress', this.calculatePercentLoaded, false);
    this.audio.addEventListener('waiting', this.setPlayerStatus, false);
    this.audio.addEventListener('ended', this.setPlayerStatus, false);
    this.audio.addEventListener('durationchange', this.durationChange, false);
    this.audio.addEventListener('seeking', this.seekingEvent, false);
    this.audio.addEventListener('seeked', this.seekedEvent, false);

  }

  private durationChange = (evt: any) => {
    if (evt?.path?.[0]?.duration) {
      // console.log('durationChange: ', evt.path[0].duration);
      this.duration = evt.path[0].duration;
      this.duration_secs.next(evt.path[0].duration);
    }
  }

  private seekingEvent = (evt: any) => {
    // console.log('%cSEEKING', 'color:yellow');
    this.is_seeking = true;
  }

  private seekedEvent = (evt: any) => {
    // console.log('%cSEEKED', 'color:lime');
    this.is_seeking = false;
  }

  private calculateTime = (evt: any) => {

    this.currentTime = this.audio.currentTime;
    if (this.streaming || isNaN(this.audio.duration) || this.audio.duration === Infinity) {
      return;
    }
    // update Observables
    // console.log('calculateTime:',this.duration, this.audio.duration);
    this.position_secs.next(this.currentTime);
    this.setProgress(this.duration, this.currentTime);

    if (this.audio.duration !== this.duration) {
      this.duration = this.audio.duration;
      //console.log(this.duration);
      this.duration_secs.next(this.duration);
    }
  }

  private calculatePercentLoaded = (evt) => {
    if (this.audio.duration > 0) {
      for (var i = 0; i < this.audio.buffered.length; i++) {
        if (this.audio.buffered.start(this.audio.buffered.length - 1 - i) < this.audio.currentTime) {
          let percent = (this.audio.buffered.end(this.audio.buffered.length - 1 - i) / this.audio.duration) * 100;
          // console.log('percent loaded: ', percent);
          // if(percent === 100){
          //     console.log('audio is loaded');
          // }
          this.setPercentLoaded(percent)
          break;
        }
      }
    }
  }

  private setPlayerStatus = (evt) => {
    // console.log('playerStatus: ', evt.type);
    switch (evt.type) {
      case 'playing':
        this.loading_tune_id = null;
        this.playing = true;
        this.paused = false;
        this.loading = false;
        this.status = 'playing';
        this.playerStatus.next(this.status);
        // Set pause or stop icon state is streaming radio or not.
        this.streaming ? this.iconState.next(2) : this.iconState.next(1);

        if (this.ui_timeout) {
          // console.log('clear ui timeout on PLAYING');
          clearTimeout(this.ui_timeout);
          this.ui_timeout = null;
        }

        break;
      case 'pause':
        this.loading_tune_id = null;
        this.playing = false;
        this.paused = true;
        this.loading = false;
        this.status = 'paused';
        this.playerStatus.next(this.status);
        this.iconState.next(0);

        // console.log('starting ui timeout on PAUSE');
        // this.ui_timeout = setTimeout(() => this.timeOutUI(), this.ui_timeout_ms);

        break;
      case 'waiting':
        this.playing = false;
        this.paused = false;
        this.loading = true;
        this.status = 'loading';
        this.playerStatus.next(this.status);
        this.iconState.next(3);
        break;
      case 'ended':
        this.loading_tune_id = null;
        this.playing = false;
        this.paused = false;
        this.loading = false;
        this.status = 'ended';
        this.iconState.next(0);
        this.playerStatus.next(this.status);
        this.currentTime = 0;
        this.audio.currentTime = 0;
        this.progress.next(0); // reset slider
        if (this.playlist.length > 0) {

          this.isPlaylistReversed()
          if (this.playist_reverse) {
            this.playPreviousTune(true)
          } else {
            this.playNextTune(true); // autoplay next tune
          }

        } else {
          // console.log('starting ui timeout on ENDED');
          this.ui_timeout = setTimeout(() => this.timeOutUI(), this.ui_timeout_ms);
        }

        break;
      default:
        this.loading_tune_id = null;
        this.status = 'paused';
        this.playerStatus.next(this.status);
        this.iconState.next(0);
        break;
    }
  }

  public getAudioSource(): string {
    // console.log('Audio source: ', this.audio.src)
    return this.audio.src;
  }

  public setAudioSource(src: string, no_bypass?: boolean): void {

    if (!src.includes('ngsw-bypass') && !no_bypass) {
      src += '?ngsw-bypass=true';
      // console.log('%cAppending ngsw-bypass src now: ', 'color:cyan', src);
    }

    // console.log('%csetAudioSource: ', 'color:orange', src);
    this.audio.src = src;
    this.audio.load();
  }

  public playAudio(): void {
    if (!this.currentTune) {
      return;
    }
    this.audio.play().catch(this.audioError);
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    if (localStorage.getItem('vol')) {
      this.audio.volume = parseFloat(localStorage.getItem('vol'));
    } else {
      this.audio.volume = 0.5;
    }
  }

  public pauseAudio(): void {
    this.audio.pause();
  }

  private timeOutUI(update_content: boolean = false) {
    this.timeSlipService.reset();
    this.resetPlayer();
    if (update_content) {
      this.radioHistoryService.updateRadioHistory();
      this.homeContentService.getShows();
    }
  }

  public resetPlayer(): void {

    if (this.isPlaying()) {
      this.pauseAudio();
    }

    this.setAudioSource('/assets/nowt.mp3'); // loading an 'empty' mp3 will stop the stream
    this.iconState.next(0);
    this.genre_stream_playing = false;
    this.unique_id = null;
    this.currentTune = null;
    this.playlist = [];
    this.currentTime = 0;
    this.duration = null;
    this.has_seeked = false;
    this.playing = false;
    this.radio_playing = false;
    this.genre_stream = null;
    this.playlistData = { id: null };
    this.featured = false; // default
    this.rewinds = false;
    this.current_rewind = null;
    this.resetRewindSync();
    this.streaming_custom = false;
    this.streaming = true; // default
    this.is_streaming.next(this.streaming);
    this.is_streaming_custom.next(this.streaming_custom);
    this.is_featured.next(this.featured);
    this.mp3_source = false;
    this.is_mp3.next(this.mp3_source);

    this.status = 'stopped';
    this.playerStatus.next(this.status);
    if (this.ui_timeout) {
      clearTimeout(this.ui_timeout);
      this.ui_timeout = null;
    }

    // set current on air tune
    this.displayTune = this.history[0];
    this.displayed_tune.next(this.displayTune);
    // console.log('%cPLAYER RESET', 'color:orange');

  }

  public seekAudio(position: number, then_play: boolean = false): void {
    this.audio.currentTime = position;
    this.has_seeked = true;
    if (then_play) {
      this.audio.paused ? this.audio.play().catch(this.audioError) : null;
    }
  }

  public skipBack30(): void {
    let _newTime = this.audio.currentTime - 30;
    if (_newTime > 0) {
      this.seekAudio(_newTime, true);
      this.rewind_time = _newTime;
      // console.log('skipBack30', _newTime);
      this.setRewindPlaylistIndex(_newTime);
    }
  }

  public skipForward30(): void {
    let _newTime = this.audio.currentTime + 30;
    if (_newTime < this.duration) {
      this.seekAudio(_newTime, true);
      this.rewind_time = _newTime;
      // console.log('skipForward30', _newTime);
      this.setRewindPlaylistIndex(_newTime);
    }
  }

  private setProgress(duration: number, currentTime: number): void {
    if (this.streaming) {
      return;
    }
    let progress_percent: number = ((100 / duration) * currentTime) || 0;
    // Register a tune play at (only) 20 seconds.
    if (!this.featured && !this.rewinds && !this.mp3_source && currentTime >= this.playback_ping_seconds && !this.has_seeked && !this.playback_ping) {
      // console.log('send play ping...');
      this.api_tunes_v2.pingTunePlay(this.currentTune.id).subscribe(res => {
        //  console.log('ping response: ', res); // null
      });
      this.playback_ping = true;
    }
    this.progress.next(progress_percent);
    if (this.rewinds && this.rewind_tunes.length > 0) {
      this.setRewindPlaylistIndex(currentTime);
    }
  }

  private resetRewindSync(): void {
    this.rewind_index = 0;
    this.rewind_time = 0;
    this.rewind_tunes = [];
    this.rewind_stack = [];
    this.rewind_next_time = 0;
    this.last_slide_val = 0;
    this.rewind_airing_id = null;
  }

  private setRewindPlaylistIndex(secs: number): void {
    for (let i = 0; i < this.rewind_tunes.length; i++) {
      try {
        if (secs >= this.rewind_tunes[i]._sync_time && secs <= this.rewind_tunes[i + 1]._sync_time && this.rewind_index !== i) {
          this.rewind_index = i;
          // console.log('update rewind index: ', this.rewind_index, this.rewind_tunes[this.rewind_index]);
          // This will update the displayed tune (with slider) in the player.component via `playerTextOverride` without a subscription.
          break;
        }
      } catch (e) {
        // console.log('end of list');
      }
    }
  }

  private setPercentLoaded(percent): void {
    this.percentLoaded.next(parseInt(percent, 10) || 0);
  }

  public getPercentLoaded(): Observable<number> {
    return this.percentLoaded.asObservable();
  }

  public getProgress(): Observable<number> {
    return this.progress.asObservable();
  }

  public getPlayerStatus(): Observable<string> {
    return this.playerStatus.asObservable();
  }

  public toggleAudio(): void {

    if (this.genre_streams_sub) {
      this.genre_streams_sub.unsubscribe();
    }

    if (this.genre_stream) {
      this.toggleGenreStream(this.genre_stream);
      return;
    }

    if (this.rewinds) {
      this.toggleRewindPlayer();
      return;
    }
    if (this.featured) {
      this.toggleFeaturedPlayer();
      return;
    }
    if (!this.currentTune) {
      this.toggleRadioStream();
      return;
    }
    if (!this.audio.src) {
      // console.log('audio source needs loading ?');
      let media_url = `${PLAY_HOST}/${this.currentTune.uuid}.mp3`;
      this.setAudioSource(media_url);
      this.audio.play().catch(this.audioError);
      this.radio_playing = false;
      this.custom_playing = false;
      return;
    }
    this.audio.paused ? this.audio.play().catch(this.audioError) : this.audio.pause();
  }

  public isLoading(tune_id?: string): boolean {
    if (tune_id) {
      if (!this.loading_tune_id) {
        return false;
      }
      if (this.loading && this.loading_tune_id === tune_id) {
        return true;
      } else {
        return false;
      }
    }
    return this.loading;
  }
  public isPaused(): boolean { return this.audio.paused; }
  public isPlaying(): boolean {
    if (!this.audio) {
      return false;
    }
    return !this.audio.paused;
  }


  // Emitted by SliderComponent
  public sliderEvent(event) {
    /*
        event.state = 'start'|'change'|'end'
        event.value = 0.0 - 1.0 float value
    */
    //console.log('sliderEvent:', event);
    switch (event.state) {
      case 'start':
        // console.log('slider start', parseFloat(((event.value * this.duration)).toFixed(2)));
        this.last_slide_val = parseFloat(((event.value * this.duration)).toFixed(2));
        this.audio.pause();
        this.rewind_time = parseFloat(((event.value * this.duration)).toFixed(2));
        this.setRewindPlaylistIndex(this.rewind_time);

        break;
      case 'change':
        this.rewind_time = parseFloat(((event.value * this.duration)).toFixed(2));
        this.currentTime = this.rewind_time;
        // console.log('slider value: ', event.value, (event.value * this.duration));
        if (this.rewinds && this.rewind_tunes.length > 0) {
          this.setRewindPlaylistIndex(this.rewind_time);
          if (this.rewind_time > this.last_slide_val) {
            this.slider_dir = 1;
          } else if (this.rewind_time < this.last_slide_val) {
            this.slider_dir = -1;
          }
          this.last_slide_val = this.rewind_time;
        }
        break;
      case 'end':
        // console.log('slider end', parseFloat(((event.value * this.duration)).toFixed(2)));
        this.slider_dir = 0;
        this.rewind_time = parseFloat(((event.value * this.duration)).toFixed(2));
        this.currentTime = this.rewind_time;
        this.setRewindPlaylistIndex(this.rewind_time);
        this.seekAudio(event.value * this.duration, true);
        break;
      default:
    }
  }
  public volumeSliderEvent(event) {
    this.audio.volume = event.value;

    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    localStorage.setItem('vol', event.value);
  }

  public volumeMute() {
    // Toggles mute
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!localStorage.getItem('vol-temp')) {
      localStorage.setItem('vol-temp', this.getVolume().toString());
      this.audio.volume = 0;
      localStorage.setItem('vol', '0');
    } else {
      this.audio.volume = parseFloat(localStorage.getItem('vol-temp'));
      localStorage.setItem('vol', localStorage.getItem('vol-temp'));
      localStorage.removeItem('vol-temp');
    }

  }

  public getVolume() {
    if (this.audio) {
      return this.audio.volume;
    }
  }

  private canSetAudioVolume(): void {
    /*
      Due to Apple's decision to make iPad iOS 13+ stop identifying an iPad in the userAgent string,
      (unless you go to Settings > Safari and manually turn off Request Desktop site), there is no way to
      determine whether to show the volume control or not since you cannot control HTML5 Audio volune via
      Javascript on a mobile Apple device.

      SO.. this is little hack I came up with. If the volume cannot be set, it's mobile Safari!
    */

    if (!isPlatformBrowser(this.platformId)) {
      // Universal SSR
      return;
    }

    const _audio = new Audio();
    //console.log('test _audio.volume: ', _audio.volume);
    //console.log('set volume to 0.5');
    _audio.volume = 0.5;
    setTimeout(() => {
      // console.log('_audio volume is now: ', _audio.volume);
      if (_audio.volume !== 0.5) {
        // console.log('%cMOBILE SAFARI!', 'font-size:20px;color:red');
        this.is_mobile_safari = true;
      } else {
        this.is_mobile_safari = false;
      }
    }, 1);
  }

  //////////////////////////////////////////////////

  public getCurrentTune(): TuneV2 {
    return this.currentTune;
  }
  public getDisplayTune(): TuneV2 {
    return this.displayTune;
  }

  public getDisplayTuneId(): string {
    return this.displayTune?.id;
  }
  public getCurrentTuneId(): string {
    return this.currentTune?.id;
  }

  public getCurrentTuneEntryId(): number {
    return this.currentTune?.meta.entry_id;
  }

  // wip... for uniqueness in lists of playbuttons which might contain duplicates (playlists, activity feed)
  public setUniqueId(unique_id: string): void {
    this.unique_id = unique_id;
    this.currentTune._unique_id = unique_id;
  }
  public getCurrentTuneActionId(): string {
    return this.currentTune?._unique_id;
  }

  private isPlaylistReversed() {
    if (typeof window !== 'undefined') {
      const _rev = localStorage.getItem('amz-revplaylist')
      if (_rev) {
        this.playist_reverse = true;
      } else {
        this.playist_reverse = false;
      }
    }
  }

  public playNextTune(auto = false) {
    console.log('playNextTune: ', this.playlist_index, this.playlist.length);
    if (this.playlist_index > -1 && this.playlist.length > 1) {
      this.playlist_index++;
      if (this.playlist_index === this.playlist.length) {
        this.playlist_index = 0;
      }
      // Non-streamable tunes must not get played.
      if (this.playlist[this.playlist_index].attributes.is_private && !this.playlist[this.playlist_index]._is_owner) {
        // console.log('non-streamable tune.. next!...');
        this.playNextTune(auto);
        return;
      }
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', (auto ? 'Auto' : '') + 'PlayNextTune', this.playlist[this.playlist_index].attributes.name, this.playlist[this.playlist_index].id]);
      }
      this.playTune(this.playlist[this.playlist_index], this.playlist_index, this.playlist, this.playlistData);
    }
  }
  public playPreviousTune(auto = false) {
    console.log('playPreviousTune: ', this.playlist_index, this.playlist.length);
    if (this.playlist_index > -1 && this.playlist.length > 1) {
      this.playlist_index--;
      if (this.playlist_index < 0) {
        this.playlist_index = this.playlist.length - 1;
      }
      // Non-streamable tunes must not get played.
      if (this.playlist[this.playlist_index].attributes.is_private && !this.playlist[this.playlist_index]._is_owner) {
        // console.log('non-streamable tune.. prev!...');
        this.playPreviousTune(auto);
        return;
      }
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', (auto ? 'Auto' : '') + 'PlayPreviousTune', this.playlist[this.playlist_index].attributes.name, this.playlist[this.playlist_index].id]);
      }
      this.playTune(this.playlist[this.playlist_index], this.playlist_index, this.playlist, this.playlistData);
    }
  }

  public setTune(tune: TuneV2, index?: number, playlist?: Array<TuneV2>) {

    // console.log('set tune: ', tune.attributes.name);

    this.currentTune = tune;
    if (tune._unique_id) {
      this.setUniqueId(tune._unique_id);
    } else {
      this.unique_id = tune.id;
      this.currentTune._unique_id = tune.id;
    }
    this.genre_stream = null;
    this.displayTune = tune;
    this.displayed_tune.next(tune);
    this.progress.next(0);
    this.duration_secs.next(0);
    this.has_seeked = false;
    this.playback_ping = false;
    this.currentTime = 0;
    this.duration = tune.attributes.duration_secs;
    this.duration_secs.next(this.duration);
    this.progress.next(0);
    // Scroll the tune card in to view if it exists. (Latest browsers. Safari doesn't smooth scroll yet.)
    // Edge doesn't go to the right position (yet)
    // let el = document.getElementById(`tune-${tune.uuid}`);
    // if(el){
    //     // in case on another page from the originator playlist/tune
    //     el.scrollIntoView({behavior: "smooth", block: "center"});
    // }

  }

  // Can occur when interrupting the play() method by loading a new audio source;
  audioError(error) {
    // console.log('Audio error: ', error);
  }

  public setPlaylistHeadDataV2(playlistData: PlaylistV2) {
    //console.log('%cplaylistHeadData set in player','color:cyan' ,playlistData.id);
    // For initial playlist-play-button states
    this.playlistData = playlistData;
  }

  public playTune(tune: TuneV2, index?: number, playlist?: any[], playlistData?: any) {
    // console.log('playTune', tune.attributes.name, tune._is_owner);
    this.timeSlipService.reset();
    if (tune.attributes.is_private && !tune._is_owner && !tune._is_manager) {
      // console.log('non-streamable. not the owner');
      if (playlist && playlist.length > 1) {
        // console.log('try next tune', playlist.length, playlist[index+1]);
        this.playlist_index = index;
        this.playlist = playlist;
        this.playNextTune();
      }
      return;
    }
    this.loading_tune_id = tune.id;
    this.genre_stream = null;
    this.streaming_custom = false;
    this.streaming = false;
    this.is_streaming.next(this.streaming);
    this.is_streaming_custom.next(this.streaming_custom);
    this.featured = false;
    this.mp3_source = false;
    this.is_mp3.next(this.mp3_source);
    this.is_featured.next(this.featured);
    this.rewinds = false;
    this.current_rewind = null;
    this.resetRewindSync();

    if (playlistData) {
      this.playlistData = playlistData;
      // console.log('playlistData(head) set:', this.playlistData);
    } else {
      this.playlistData = { id: null };
    }


    if (tune._unique_id && this.unique_id === tune._unique_id) {
      // console.log('toggling audio. tune._unique_id is same as unique_id in player.service', tune._unique_id);
      this.toggleAudio();
      return;
    }

    this.radio_playing = false;
    this.custom_playing = false;
    if (playlist) {
      if (playlist !== this.playlist) {
        // console.log('new playlist:', playlist);
        this.playlist = playlist;
      }
    } else {
      this.playlist = [];
    }
    if (!tune && this.playlist.length > 0) {
      tune = this.playlist[0];
    }
    if (index !== undefined) {
      this.playlist_index = index;
    }
    // console.log('play tune... ', tune.title, index);
    this.setTune(tune, index, playlist);

    // Obtain secure_streaming_url 
    // DOCS: https://amazingtunes.com/docs/api/v2/api-streaming/tune.html

    this.api_tunes_v2.getTuneStreamingUrl(tune.id).subscribe({
      next: (data) => {
        if (data.data.attributes.secure_url) {
          let media_url = data.data.attributes.secure_url;
          // console.log('setAudioSource: ', media_url);
          this.setAudioSource(media_url, true); // no ngsw-bypass
          this.playAudio();
        }
      },
      error: (err) => {
        console.log('player.service: Tune Streaming URL error:', err);
        if (err.errors && err.errors[0]?.title) {
          this.snackbar.show(err.errors[0]?.title, 'snackbarWarning');
        } else {
          this.snackbar.show('Tune streaming URL error', 'snackbarWarning');
        }
        if (playlist && playlist.length > 1) {
          console.log('try next tune', playlist.length, playlist[index + 1]);
          this.playlist_index = index;
          this.playlist = playlist;
          this.playNextTune();
        }
      }
    })

  }

  toggleFeaturedPlayer(featured_media?: FeaturedMediaItem) {
    //console.log('toggleFeaturedPlayer: ', featured_media);
    if (this.featured) {
      // console.log('play/pause featured');
      this.audio.paused ? this.audio.play().catch(this.audioError) : this.audio.pause();
    } else {
      // console.log('load new featured media');
      this.timeSlipService.reset();
      this.currentTune = null;
      this.genre_stream = null;
      this.currentTime = 0;
      this.progress.next(0);
      this.duration_secs.next(0);
      this.streaming_custom = false;
      this.streaming = false;
      this.playlist = [];
      this.playlistData = { id: null };
      this.is_streaming.next(this.streaming);
      this.is_streaming_custom.next(this.streaming_custom);
      this.featured = true;
      this.rewinds = false;
      this.current_rewind = null;
      this.resetRewindSync();
      this.is_featured.next(this.featured);
      this.radio_playing = false;
      this.custom_playing = false;

      if (featured_media.media_url.includes('.mp3')) {
        // console.log('Featured media is .mp3');
        this.displayTune = { // mock a displayTune from the featured media data
          attributes: {
            name: featured_media.title.rendered,
            image_urls: {
              small: featured_media.image_thumbnail_url || featured_media.image_url,
              medium: featured_media.image_square_url || featured_media.image_url,
              large: featured_media.image_square_url || featured_media.image_url
            }
          },
          artist: {
            attributes: {
              name: ''
            }
          },
        };
        this.displayed_tune.next(this.displayTune);
        this.setAudioSource(featured_media.media_url);
        this.audio.play().catch(this.audioError);
        if (!isPlatformBrowser(this.platformId)) {
          // Universal
          return;
        }
        if (typeof _paq !== 'undefined') {
          _paq.push(['trackEvent', 'PlayFeatured', featured_media.title.rendered]);
        }

        if (localStorage.getItem('vol')) {
          this.audio.volume = parseFloat(localStorage.getItem('vol'));
        } else {
          this.audio.volume = 0.5;
        }
        this.mp3_source = true;
        this.is_mp3.next(this.mp3_source);
      } else {
        this.mp3_source = false;
        this.is_mp3.next(this.mp3_source);
        // console.log('Featured media is amazingtunes resource: ', featured_media, featured_media.resource_type);
        if (featured_media.resource.tunes) {
          // Collection or playlist.
          if (featured_media.resource.entries) {
            delete featured_media.resource.entries;
          }
          // These will still be V1 tunes.
          let _playlist: TuneV2[] = [];
          featured_media.resource.tunes.forEach(tune => {
            _playlist.push(this.tuneV1V2Pipe.transform(tune));
          });
          this.playTune(_playlist[0], 0, _playlist, featured_media.resource);
        }

        if (featured_media.resource_type === 'tune') {
          this.playTune(this.tuneV1V2Pipe.transform(featured_media.resource));
        }
      }
    }
  }

  toggleRewindPlayer(rewind?: Rewind, playlist?: TuneV2[], no_playlist?: boolean) {
    // console.log('toggleRewindPlayer');
    if (!rewind && this.rewinds) {
      this.audio.paused ? this.audio.play().catch(this.audioError) : this.audio.pause();
    } else if (rewind && !this.audio.src.includes(rewind.s3_url)) {
      // console.log('load new rewind ', rewind);
      this.timeSlipService.reset();
      this.currentTune = null;
      this.genre_stream = null;
      this.currentTime = 0;
      this.progress.next(0);
      this.duration_secs.next(0);
      this.playlist = [];
      this.playlistData = { id: null };
      this.streaming_custom = false;
      this.streaming = false;
      this.is_streaming.next(this.streaming);
      this.is_streaming_custom.next(this.streaming_custom);
      this.featured = false;
      this.resetRewindSync();
      this.rewinds = true;
      this.current_rewind = rewind;

      this.is_featured.next(this.featured);
      this.mp3_source = true;
      this.is_mp3.next(this.mp3_source);
      this.radio_playing = false;
      this.custom_playing = false;
      this.displayTune = { // mock a displayTune from the featured media data
        attributes: {
          name: rewind.display_title,
          image_urls: {
            small: rewind.image_url,
            medium: rewind.image_url,
            large: rewind.image_url
          }
        },
        artist: {
          attributes: {
            //name: '<em>Rewind:</em> ' + rewind.display_artist
            name: rewind.display_artist
          }
        },
        _show_path: rewind.show_route  // path to show page
      };
      if (rewind.airing_id && !playlist && !no_playlist) { // if no_playlist is true, this will be skipped. For AOTW/ROTW where we never have the actual tunes info.
        // Link to the individual airing, if available
        this.displayTune._show_path += '/archive/' + rewind.airing_id;
        this.rewind_airing_id = rewind.airing_id;
        // console.log('Rewind airing id', rewind.airing_id);
        // console.log('get Airing playlist .. ');
        const timestamp_start = new Date(rewind.broadcast_from).getTime() / 1000;
        this.api_tunes_v2.getShowPlaylistTunes(true, rewind.show_id, rewind.airing_id, timestamp_start).subscribe(data => {
          // console.log('Lazy loaded Airing playlist: ', data);
          // set the rewind playlist
          this.resetRewindSync();
          this.rewind_tunes = data;

        });


      }
      this.displayed_tune.next(this.displayTune);
      this.setAudioSource(rewind.s3_url);
      this.audio.play().catch(this.audioError);
      if (!isPlatformBrowser(this.platformId)) {
        // Universal
        return;
      }
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', 'PlayRewind', rewind.display_artist + ' - ' + rewind.display_title]);
      }

      if (localStorage.getItem('vol')) {
        this.audio.volume = parseFloat(localStorage.getItem('vol'));
      } else {
        this.audio.volume = 0.5;
      }

      // Rewind sync playlist provided.
      if (playlist) {
        this.resetRewindSync();
        this.rewind_tunes = playlist;
        // console.log('set rewind_tunes');
        // console.log(this.rewind_index, this.rewind_tunes[this.rewind_index].artist.attributes.name + ' - ' + this.rewind_tunes[this.rewind_index].attributes.name);
      }

    } else {
      // console.log('play/pause rewind via rewind player');
      this.audio.paused ? this.audio.play().catch(this.audioError) : this.audio.pause();
    }
  }

  // Genre Streams control.
  public toggleGenreStream(gs: GenreStream) {
    // console.log('toggleGenreStream', gs);
    if (this.genre_stream?.id === gs.id) {
      if (this.genre_stream_playing) {
        this.resetPlayer();
        return;
      }
    }
    this.genre_stream = gs;
    this.timeSlipService.reset();
    // console.log('setting', gs.attributes.name, gs.attributes.stream_url);
    this.currentTune = null;
    this.playlist = [];
    this.playlistData = { id: null };
    this.rewinds = false;
    this.current_rewind = null;
    this.resetRewindSync();
    this.streaming = true;
    this.is_streaming.next(this.streaming);
    this.streaming_custom = false;
    this.is_streaming_custom.next(this.streaming_custom);
    this.featured = false; // default
    this.is_featured.next(this.featured);
    this.mp3_source = false;
    this.is_mp3.next(this.mp3_source);
    this.radio_playing = false;
    // start an update for the genre stream to get the current tune log item..
    this.loading = true;
    this.displayTune = this.genre_stream._current_tune;
    this.displayed_tune.next(this.displayTune);
    this.setAudioSource(this.genre_stream.attributes.stream_url);
    this.audio.play().catch(this.audioError);
    this.genre_stream_playing = true;
    // console.log('PlayGenreStream: ', this.genre_stream.attributes.name);
    _paq.push(['trackEvent', 'PlayGenreStream', this.genre_stream.attributes.name]);

    if (this.genre_streams_sub) {
      this.genre_streams_sub.unsubscribe();
    }

    if (!this.genreStreamService.update_interval && typeof window !== 'undefined') {
      this.genreStreamService.startGenreStreamsUpdate();
    }

    this.genre_streams_sub = this.genreStreamService.genre_streams_subject.subscribe(data => {
      if (data) {
        data.data.forEach(_gs => {
          // console.log('_gs',_gs);
          if (_gs.id === this?.genre_stream?.id) {
            if (this.displayTune?.id !== _gs._current_tune.id) {
              this.displayTune = _gs._current_tune;
              this.displayed_tune.next(this.displayTune);
            }
          }
        });
      }
    });

  }

  // Play Radio stream
  public toggleRadioStream(evt?: any) {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    this.currentTune = null;
    this.genre_stream = null;
    this.playlist = [];
    this.playlistData = { id: null };
    this.featured = false; // default
    this.rewinds = false;
    this.current_rewind = null;
    this.resetRewindSync();
    this.streaming_custom = false;
    this.streaming = true; // default
    this.is_streaming.next(this.streaming);
    this.is_streaming_custom.next(this.streaming_custom);
    this.is_featured.next(this.featured);
    this.mp3_source = false;
    this.is_mp3.next(this.mp3_source);
    // console.log('toggleradioStream: radio_playing:', this.radio_playing);
    if (!this.radio_playing) {
      this.loading = true;
      this.displayTune = this.history[0];
      this.displayed_tune.next(this.displayTune);
      if (this.timeSlipService.offset > 0) {
        const _stream_url = new URL(STREAM_HOST);
        const _STREAM_HOST = _stream_url.protocol + '//' + _stream_url.host + '/' + (this.timeSlipService.offset) + _stream_url.pathname + _stream_url.search;
        // console.log('STREAM_HOST with offset:', _STREAM_HOST);
        this.setAudioSource(_STREAM_HOST);
      } else {
        this.setAudioSource(STREAM_HOST);
      }

      this.audio.play().catch(this.audioError);

      this.radio_playing = true;
      // console.log('PlayRadio: Current Show:', this.current_show.show.name);
      _paq.push(['trackEvent', 'PlayRadio', this.current_show.show.name]);

    } else {
      // console.log('reset/stop');
      this.resetPlayer();
      if (this.timeSlipService.offset > 0) {
        this.ui_timeout = setTimeout(() => this.timeOutUI(), this.ui_timeout_ms);
      }

    }

  }

  public toggleCustomStream() {
    this.currentTune = null;
    this.genre_stream = null;
    this.playlist = [];
    this.playlistData = { id: null };
    this.featured = false;
    this.rewinds = false;
    this.current_rewind = null;
    this.resetRewindSync();
    this.streaming = false;
    this.streaming_custom = true;
    this.is_streaming_custom.next(this.streaming_custom);
    this.is_streaming.next(this.streaming);
    this.is_featured.next(this.featured);
    this.mp3_source = true;
    this.is_mp3.next(this.mp3_source); // It's not really, but it stops other things happening, like tune play pings and the options menu showing on the player.
    if (!this.custom_playing) {
      this.timeSlipService.reset();
      this.loading = true;
      this.displayTune = this.history[0];
      this.displayed_tune.next(this.displayTune);
      this.setAudioSource(CUSTOM_STREAM_HOST);
      this.audio.play().catch(this.audioError);
    } else {
      // console.log('reset/stop');
      this.resetPlayer();
    }
    this.custom_playing = !this.custom_playing;
  }

}

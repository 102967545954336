<div class="cropper-container noselect">

  <div *ngIf="!is_cropper_ready" id="loading-ind">
    <div class="cont">
      <mat-icon svgIcon="buffering" class="spinner"></mat-icon>
      <br>
      <h6>LOADING</h6>
    </div>
  </div>

  <!-- Hidden file input. triggered by fileInput.click() -->
  <input #fileInput style="display: none" accept=".jpeg,.jpg,.png" type="file" (change)="fileChangeEvent($event)" />
  <!-- Open file browser -->
  <div>
    <!-- <button class="btn yellow" mat-flat-button (click)="fileInput.click()">Pick</button> -->
    <div class="label link yellow" role="button" mat-flat-button (click)="fileInput.click()">Select</div>
    <div class="center small">{{ sizeIn }}</div>
  </div>
  <!-- The cropped image -->
  <img id="cropped-image" [class]="image_type === 'header' ? 'header':''" src="/assets/placeholders/placeholder-square-dark-300.png" [lazyImgSrc]="croppedImage" />
  <!-- Disabled until image is manually cropped -->
  <div>
    
    <!-- <div class="btn-ok" [class]="is_image_valid ? 'label link upload blue':'label link upload blue disabled'" (click)="useCroppedImage()">OK</div> -->
    <div class="label small blue"(click)="cancel()">Cancel</div>


    <div class="center small">{{ sizeOut }}</div>
  </div>

  <div class="cropper-hint">
    Select a new image at least {{minimumWidth}}x{{minimumHeight}} in size.<br>Then drag the box to crop the image and click OK.
  </div>

  <div id="cropper-container" [class]="image_type" [class]="orientation === 'portrait' ? 'portrait':''">
    <!-- Image cropping interface /*[resizeToWidth]="600"*/ -->
    <image-cropper [imageBase64]="inputImageData" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio" [minimumWidth]="minimumWidth" [originalImageData]="originalImageData"
      [onlyScaleDown]="false" [roundCropper]="false" format="jpeg" outputType="base64"
      [resizeToWidth]="resizeCroppedImageToWidth" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
      [style.display]="showCropper ? null : 'none'" [alignImage]="'center'">
    </image-cropper>
  </div>

  <div class="cropper-hint">
    <div #ok_btn class="btn-ok" [class]="is_image_valid ? 'label link upload yellow':'label link upload blue disabled'" (click)="useCroppedImage()">OK</div>
    <!-- <div class="label small yellow"(click)="cancel()">Cancel</div> -->
  </div>

</div>

import { Injectable } from '@angular/core';
import { Testimonial } from '@models/testimonials';

@Injectable({
  providedIn: 'root'
})
export class TestimonialsData {

  constructor() { }

  public testimonials:Testimonial[] = [
    {
      name:"AK/DK",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-21.jpg",
      routerlink:"/profile/rocketpr-akdk",
      quote:"We wanted to say a massive thanks to Amazing Radio. They've always been brilliant at getting our stuff out there",
      youtube_id : "EB1_xwLwz1Y"
    },
    {
      name:"Ali Horn",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-1-2.jpg",
      routerlink:"/profile/alihorn",
      quote:"If you aren't onto it already, Amazing Radio is probably the best station for emerging talent",
      youtube_id : "fcnetvVhtjg"
    },
    {
      name:"Artificial Pleasure",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-2-1.jpg",
      routerlink:"/profile/ajpr-artificialpleasure",
      quote:"Amazing Radio were one of the first UK stations to pick up on us",
      youtube_id : "0cwRco2ZK3A"
    },
    {
      name:"Bdrmm",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-3-1.jpg",
      routerlink:"/profile/soniccathedral-bdrmm",
      quote:"Amazing Radio is a great platform for emerging artists",
      youtube_id : "Bbj2Iy4jYRE"
    },
    {
      name:"Broken Hands",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-4-1.jpg",
      routerlink:"/profile/brokenhandsband",
      quote:"It's such a unique system what Amazing Radio have, it's a direct access to airplay",
      youtube_id : "--aeraaE2z4"
    },
    {
      name:"Bugeye",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-5-1.jpg",
      routerlink:"/profile/bugeye",
      quote:"Amazing Radio gave us our first taste of radio airplay",
      youtube_id : "1-KKYdPOq_8"
    },
    {
      name:"Gizmo Varillas",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-6.jpg",
      routerlink:"/profile/gizmovarillas",
      quote:"I remember uploading my first song back in 2014 and ever since I've been on their rotation",
      youtube_id : "Yo3PzzQOF6I"
    },
    {
      name:"Glassmasterer",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-7.jpg",
      routerlink:"/profile/glassmasterer",
      quote:"Amazing Radio is a terrific platform, since uploading my tunes I've had a fair few plays",
      youtube_id : "oF3YwK6-pH8"
    },
    {
      name:"Hoofa",
      image:"https://api.amazingradio.com/wp-content/uploads/Hoofa.Square300.210449.jpg",
      routerlink:"/profile/hoofaband",
      quote:"Since Amazing Radio supported us we've gone on to sell out shows",
      youtube_id : "W-rUH3fC0lo"
    },
    {
      name:"Hourglvss",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-8.jpg",
      routerlink:"/profile/hourglvss",
      quote:"Thank you Amazing Radio for giving Supreme Beings all the love. It's really helped our followers grow",
      youtube_id : "p-xSNdxiaOc"
    },
    {
      name:"James Leonard Hewitson",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-9.jpg",
      routerlink:"/profile/jamesleonardhewitson",
      quote:"I've had plenty of coverage and live sessions. It's helped build my profile as an artist",
      youtube_id : "CvOIeiVawM0"
    },
    {
      name:"Katie Pearlman",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-10.jpg",
      routerlink:"/profile/katie-pearlman",
      quote:"Amazing has been great. They have playlisted and aired almost all of my songs. Getting that momentum and confidence has been huge.",
      youtube_id : "Z4spWaIW92o"
    },
    {
      name:"Kids In America",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-11.jpg",
      routerlink:"/profile/kidsinamericaus",
      quote:"We got involved last year when our band released our first single and we have seen support accross the board",
      youtube_id : "7V98f0-oYh4"
    },
    {
      name:"Klangstof",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-12.jpg",
      routerlink:"/profile/klangstof1",
      quote:"I just wanted to say thank you to Amazing Radio for supporting us ever since out first record came out",
      youtube_id : "l5Br7aafSr8"
    },
    {
      name:"KOKO",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-13.jpg",
      routerlink:"/profile/dannyjnrroberts-koko",
      quote:"Massive thanks to Amazing Radio and the presenters for getting behind our new track I Don't Want To Start Fights",
      youtube_id : "Jw6YxnOo47U"
    },
    {
      name:"KOYO",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-14.jpg",
      routerlink:"/profile/88watt-koyo",
      quote:"We put one of our singles forward and it all just kinda went from there really. One of our singles even got to #7 in the Amazing Chart",
      youtube_id : "DWpdpmZ0CCo"
    },
    {
      name:"Lazy Day",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-15.jpg",
      routerlink:"/profile/theydo-lazyday",
      quote:"Amazing Radio has been such a cool home for Lazy Day's music over the years",
      youtube_id : "voptEvLv6H8"
    },
    {
      name:"Lila Blue",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-16.jpg",
      routerlink:"/profile/lilablue",
      quote:"I'm so grateful to Amazing Radio for all the support they have given my music, from my appearance on One Night Stand to all the airplay they have given my most recent album",
      youtube_id : "zr-dHC1iXAc"
    },
    {
      name:"okay(K)",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-17.jpg",
      routerlink:"/profile/okayk",
      quote:"I remember when I first got on Amazing Radio it help build traction, gain exposure, I built momentum on all my songs.",
      youtube_id : "6Ynct4EpQBc"
    },
    {
      name:"Soleille",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-18.jpg",
      routerlink:"/profile/soleille",
      quote:"I have Amazing Radio to thank, they played my first single on the radio. It gave me legitimacy",
      youtube_id : "mC9wcK-0VPs"
    },
    {
      name:"Sea Girls",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-19.jpg",
      routerlink:"/profile/polydor-seagirls",
      quote:"Amazing Radio have been with us for a long long time, right from the start. They gave us our first interview.",
      youtube_id : "PXKuOqkGtpc"
    },
    {
      name:"Superlative",
      image:"https://api.amazingradio.com/wp-content/uploads/Superlative.Square300.203931.jpg",
      routerlink:"/profile/officialsuperlative",
      quote:"They've always listened, always given it a chance to be played. I know that my music is being listened to by somebody.",
      youtube_id : "qRdQRIlnJPA"
    },
    {
      name:"The Clause",
      image:"https://api.amazingradio.com/wp-content/uploads/square_300-20.jpg",
      routerlink:"/profile/yespleasepromo-theclause",
      quote:"They've been actively involved in everything we're doing, taking interest in how we are moving on as a band",
      youtube_id : "zBwlkkmqr9Y"
    },
    {
      name:"Vandebilt",
      image:"https://api.amazingradio.com/wp-content/uploads/Vandebilt-300x300.jpg",
      routerlink:"/profile/vandebilt",
      quote:"Just want to say a massive thank you to Amazing Radio for playlisting our singles and all the cool live sessions that we've done",
      youtube_id : "RDtMetPhIFs"
    }
  ];

}



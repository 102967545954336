import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Clipboard } from "@angular/cdk/clipboard"
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@services/snackbar.service';
import { environment } from '@env/environment';

export interface DialogData {
  resource?:any;
  path?:string;
}

@Component({
  selector: 'app-dialog-custom-embed',
  templateUrl: './dialog-custom-embed.component.html',
  styleUrls: ['./dialog-custom-embed.component.scss']
})
export class DialogCustomEmbedComponent implements OnInit {

  public resource:any;
  public path:string;

  public site_url:string = environment.site_url;
  private dev_ports:string[] = ['4200', '4300', '4700'];
  private dev_localhosts:string[] = ['localhost', 'local.amazingradio.com'];

  public iframe_src:string = this.site_url;
  public model_embed_style:string = 'square';
  public embed_code:string = '';
  public resource_embed_type:string = 'tune';

  constructor(
    public dialogRef: MatDialogRef<DialogCustomEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackbar: SnackbarService,
    private clipBoard:Clipboard, @Inject(PLATFORM_ID) private platformId: Object,
  ) {

    if(data.resource){
      this.resource = data.resource;
      // console.log('Custom Embed for resource:', this.resource);
      // console.log('type:', this.resource.type);

      if(this.resource.type !== 'tune'){
        this.resource_embed_type = 'list';
      }

      if(data.path){
        this.path = data.path;
        // console.log('path: ', this.path);
        this.setEmbedCode();
      }
    } else {

      if(data.path){
        this.path = data.path;
        // console.log('Custom Embed for path:', this.path);
      } else {
        this.snackbar.show('No info provided for embedding', 'snackbarWarning');
        this.dialogRef.close();
      }
    }
  }

  setEmbedCode(){

    // For easier local dev/testing
    if(typeof window !== 'undefined'){
      if(this.dev_localhosts.includes(window.location.hostname) && this.dev_ports.includes(window.location.port) ){
        this.site_url = 'http://' + window.location.host;
      }
    }

    // console.log('resource embed type: ', this.resource_embed_type);
    this.iframe_src = this.site_url + this.path.split('/profile/').join('/oembed/');
    if(this.resource_embed_type === 'list'){
      this.embed_code =  this.codeList(this.iframe_src);
    } else {
      this.embed_code = this.model_embed_style === 'square' ? this.codeSquare(this.iframe_src) : this.codeWide(this.iframe_src);
    }

  }

  codeSquare(frame_src:string): string {
    return '<div style="max-width:450px; position:relative; width:100%; padding-top:100%; overflow:hidden;"><iframe style="max-height:400px; position:absolute; width:100%; height:100%; top:0; left:0;" src="'+frame_src+'"></iframe></div>';
  }

  codeWide(frame_src:string): string {
    return '<div style="position:relative; height:150px;"><iframe style="position:absolute; width:100%; height:100%; top:0; left:0;" src="'+frame_src+'"></iframe></div>';
  }

  codeList(frame_src:string): string {
    return '<div style=" height:450px; position:relative; width:100%;"><iframe style="position:absolute; width:100%; height:100%; top:0; left:0;" src="'+frame_src+'"></iframe></div>';
  }

  copyToClipBoard(){
    if (isPlatformBrowser(this.platformId)) {
      this.clipBoard.copy(this.embed_code);
      this.snackbar.show('Embed code copied to clipboard');
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {

  }

}


<div class="header">
	<h6>Embed {{ resource.attributes.type || resource.type }}</h6>
</div>

<div class="cont" [class]="resource_embed_type !== 'tune' ? 'list-resource':''">

  <div class="instructions" *ngIf="resource_embed_type === 'tune'">

    <div class="options">
      <div>
        <h6 class="options-label">Layout Options</h6>
        <mat-radio-group (change)="setEmbedCode()" class="radio-group" name="embed_style" [(ngModel)]="model_embed_style">
          <mat-radio-button class="embed-style-option" color="accent" value="square">Square</mat-radio-button>
          <br>
          <mat-radio-button class="embed-style-option" color="accent" value="wide">Wide</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="preview" [class]="model_embed_style === 'square' ? 'preview-square':'preview-wide'">

          <img [src]="'/assets/extras/embed-' + model_embed_style + '.svg'">

      </div>

    </div>




  </div>

  <div class="code">

    <textarea spellcheck="false">{{ embed_code }}</textarea>

    <div (click)="copyToClipBoard()" title="Copy embed code to clipboard" class="copy label yellow link small">Copy To Clipboard</div>

  </div>


</div>

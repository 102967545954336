import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MemberSubscription, FundingSource } from '@models/subscriptions-v2';
import { SnackbarService } from '@services/snackbar.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { environment } from '@env/environment';

// Analytics
declare var fbq;
declare var gtag;
declare var _paq;

declare var Frames; // checkout.com Frames API

// https://docs.checkout.com/testing/test-card-numbers

// TEST CARDS:
// 4242 4242 4242 4242
// CVV: 100
// MM/YY:  12/22
// ...
// 4543 4740 0224 9996
// CVV: 956
// MM/YY:  11/22

@Component({
  selector: 'app-card-funding',
  templateUrl: './card-funding.component.html',
  styleUrls: ['./card-funding.component.scss'],
  inputs: ['member_subscription', 'parent_dialog_ref'],
})
export class CardFundingComponent implements OnInit, AfterViewInit {

  private checkout_key: string = environment.checkout_key;
  public member_subscription: MemberSubscription;

  public funding_source:FundingSource;

  private card_token: string;
  private card_scheme: string;

  public parent_dialog_ref:any; // Attempt to call the parent component, which is a Material Dialog, to close it.

  @ViewChild('checkoutPaymentForm') checkoutPaymentForm: ElementRef;
  @ViewChild('checkoutPayButton') checkoutPayButton: ElementRef;

  constructor(private ngZone:NgZone, private loadingIndicator: LoadingIndicatorService, private router: Router, private api_tunes: ApiAmazingtunesV2Service, private snackbar:SnackbarService ) {
    //
  }

  ngOnInit(): void {
    //

  }

  closeParentDialog(){
    if(this.parent_dialog_ref){
      this.parent_dialog_ref.close();
    }
  }


  ngAfterViewInit(): void {
    // Component *must* have a selected_plan input.
    if(!this.member_subscription){
      console.log('No member subscription provided');
      if(this.parent_dialog_ref){
        this.parent_dialog_ref.close();
      }
      return;
    }
    // console.log('Card funding for: ', this.member_subscription.data);
    // console.log('Amount: ', this.member_subscription.data.relationships.recurring_plan.data.attributes.payment_currency, this.member_subscription.data.relationships.recurring_plan.data.attributes.payment_amount);

    Frames.init(this.checkout_key);
    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      (event) => {
        // console.log("CARD_VALIDATION_CHANGED: %o", event);
        this.checkoutPayButton.nativeElement.disabled = !Frames.isCardValid();
      }
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZED,
      (event) => {
        // console.log('Checkout card tokenized event', event);
        if(event.token && event.scheme){
          this.card_token = event.token;
          this.card_scheme = event.scheme;
          // console.log('Card funding tokenisation OK: ', this.card_token, this.card_scheme);

          this.api_tunes.addFundingSource('card', this.member_subscription.data.id, this.card_token, this.card_scheme).subscribe((data) => {
            // console.log('addFundingSource response: ', data);

            if(data.meta.approval_url && data.meta.approval_status === 'pending'){
              if(typeof window === 'undefined'){
                return;
              }
              // Go to approval URL...
              this.snackbar.show('Redirecting to payment provider ... ');
              window.open(data.meta.approval_url, '_self');
              // will return us to  /payments/:gateway/funding-return to collect a cko-session-id  ...
            } else if(data.meta.approval_status === 'declined'){
              console.log('CARD DECLINED... ');
              this.snackbar.show('Card Declined', 'snackbarWarning');
            } else {
              this.funding_source = data;
              // ....
              this.parent_dialog_ref.close({
                funding_source:this.funding_source
              });
            }
          },
          (error) => {
            // status 406 : declined...
            console.log('addFundingSource error: ', error);
            this.snackbar.show('Error Adding Funding Source', 'snackbarWarning');

          });

        }
      }
    );

    this.checkoutPaymentForm.nativeElement.addEventListener("submit", (event) => {
      event.preventDefault();
      // this.loadingIndicator.startLoading();
      Frames.submitCard();
    });
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TuneV2 } from '@models/tune-v2';
import { CollectionV2 } from '@models/collection-v2';
import { SnackbarService } from '@services/snackbar.service';
export interface DialogData {
  resource: TuneV2|CollectionV2;
}

declare var _paq;

@Component({
  selector: 'app-dialog-purchase',
  templateUrl: './dialog-purchase.component.html',
  styleUrls: ['./dialog-purchase.component.scss']
})
export class DialogPurchaseComponent implements OnInit {

  public resource: TuneV2|CollectionV2;

  constructor(
    public dialogRef: MatDialogRef<DialogPurchaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackbar:SnackbarService,
  ) {

    if(!data.resource){
      // console.log('Purchase dialog data:', data);
      this.snackbar.show('No Resource Data');
      return;
    }

    this.resource = data.resource;

    if(this.resource.attributes.distribution.preference !== 'sell'){
      this.snackbar.show('This is not For Sale');
      // Nobody 'should' ever see this. But just in case.
      return;
    }
    // console.log('Purchase resource:', this.resource);
  }

  ngOnInit(): void {
  }


  buy(){

    console.log('BUY');
    this.dialogRef.close({ resource:this.resource });

    _paq.push(['trackEvent', 'Purchase', this.resource.type  + ' (' + this.resource.attributes.distribution.sale_price_pence + ') ' + this.resource.attributes.name + ' ('+this.resource.id+')' ]);

  }

  close(){
    this.dialogRef.close();
  }


}

<!-- Playlist card-v2 component -->

<div class="card" [class]="list_parent">

  <div class="imageCont square">
    <a [routerLink]="['/profile', playlist._curator.attributes.permalink, 'playlists', playlist.id]"><img [lazyImgSrc]="playlist.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png"></a>
    <span class="imageLabel">{{ playlist.attributes.tune_count }} tune{{ playlist.attributes.tune_count === 1 ? '':'s' }}</span>
  </div>

  <app-playlist-play-button-v2 class="playButton playButtonList" [playlistHead]="playlist"></app-playlist-play-button-v2>

  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like [title]="playlist.meta.liked_by_user ? 'Remove Playlist From Likes':'Like Playlist'" *ngIf="!playlist.attributes.is_private && !this.playlist._is_owner && !this.playlist._is_manager; else privatePlaylist" [resource]="playlist" class="interact like"></app-action-button-like>
      <app-playlist-play-button-v2 class="playButton playButtonCard" [playlist_id]="playlist.id" [playlistHead]="playlist"></app-playlist-play-button-v2>
      <app-action-button-playlist title="Add all Tunes to a Playlist" *ngIf="!playlist.attributes.is_private && !this.playlist._is_owner && !this.playlist._is_manager; else empty" class="interact playlist" [playlist_id]="playlist.id" [tunes]="playlist._tunes"></app-action-button-playlist>
    </div>
	</div>

  <div class="details">
    <a *ngIf="!hide_curator" class="elipsify name" [routerLink]="['/profile',playlist._curator.attributes.permalink]" [title]="playlist._curator.attributes.name" [innerHTML]="playlist._curator.attributes.name"></a>
    <a class="elipsify title" [class]="hide_curator ? (featured ? 'hidden_name featured' : (is_genre ? 'hidden_name genre':'hidden_name')) : ''" [routerLink]="['/profile', playlist._curator.attributes.permalink, 'playlists', playlist.id]" title="{{ playlist.attributes.name }}" [innerHTML]="playlist.attributes.name"></a>
    <span class="listLabel tuneCount" [class]="list_parent ? 'listLabel playlistInfo':'' " [innerHTML]="(playlist.attributes.tune_count + ' tune' + (playlist.attributes.tune_count === 1 ? '':'s') + ' - ' + (playlist.attributes.duration_secs | secsToTime) )"></span>
  </div>

</div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private playlists (if they exist??) -->
<ng-template #empty>
</ng-template>

<ng-template #privatePlaylist>
  <div *ngIf="playlist._curator.id !== userService.getId(); else edit" class="empty" title="private tune"><mat-icon>lock</mat-icon></div>
</ng-template>
<ng-template #edit>
  <app-action-button-edit class="interact edit" [routerLink]="['/profile', playlist._curator.attributes.permalink, 'playlists', playlist.id, 'edit']" title="edit playlist">edit</app-action-button-edit>
</ng-template>

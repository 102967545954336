import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver'; // https://www.npmjs.com/package/file-saver
import { TuneV2 } from '@models/tune-v2';
/*
  Rather than just use the a download="" href, we can let the browser download the file, and show progress, then open save as dialog with the filename set.
*/
declare var _paq;

@Injectable({
  providedIn: 'root'
})
export class ApiDownloadService {

  constructor(private http: HttpClient) {}

  download(url:string, tune?:TuneV2, purchased:boolean = false){
    const progress_event = new Subject<any>();
    console.log('%cDOWNLOAD: '+ url, 'color:cyan');
    // https://s3.amazonaws.com/development_amazingtunes/d91f496d-4057-4dd5-9de1-b6f86d369d5c.mp3?response-content-type=audio%2Fmpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYC2RR7T2MG7WHA6F%2F20240205%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240205T173206Z&X-Amz-Expires=900&X-Amz-SignedHeaders=host&X-Amz-Signature=38e60860e178c5d6ab5e4d5a27a20aa6e3bfb66e04f463e0411f4263a1c5e06a
    this.http.get(url, { responseType:'blob', reportProgress:true, observe:'events'}).pipe(catchError(this.handleError))
    .subscribe(event => {
      let filename:string;
      if(event.type === HttpEventType.DownloadProgress){
        const perc = Math.round((event['loaded'] / event['total']) * 100);
         console.log('DownloadProgress event: ', perc+'% / ', event['total']);
        progress_event.next(perc);
      }
      if(event['headers'] && event['headers'].get('content-disposition')){
        const [_, contentDisposition] = event['headers'].get('Content-Disposition').split('filename=');
        filename = contentDisposition.replace(/"/g,"");
         console.log('%cDownload Filename: ', 'color:orange', filename);
      }

      if(event['status'] === 200 && event['body']){
        console.log('%cFile download complete:', 'color:green', filename);
        //console.log(event);
        progress_event.complete();
        const _fileBlob:Blob = event['body'];
        // file-saver
        saveAs(_fileBlob, filename);
        if (typeof _paq != "undefined") {
          _paq.push(['trackEvent', purchased? 'PurchaseDownload':'TuneDownload', tune?.attributes?.name + ' ('+tune?.id+')' ]);
        }
      }
    });
    return progress_event.asObservable();

  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent && (typeof window !== "undefined")) { // SSR
      console.log('client side error: ', errorResponse.error.message);
    } else {
      console.log('server side error:', errorResponse)
    }
    return throwError(errorResponse);
  };
}

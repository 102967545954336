<!-- Cookie bar component -->

<div *ngIf="!hidden" class="cookieBar">
	<div class="innerContent">
		<div class="cookieInfo">
			<p>Cookies help us deliver this site and services. By using this site and our services, you agree to our use of cookies.</p>
			<div class="cookieButtons">
				<a role="button" (click)="acceptCookies()" class="btn blue">Got It</a>
				<a role="link" routerLink="/cookies" class="btn dark">Learn More</a>
			</div>
		</div>
	</div>
</div>

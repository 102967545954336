import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-labels-info',
  templateUrl: './dialog-labels-info.component.html',
  styleUrls: ['./dialog-labels-info.component.scss']
})
export class DialogLabelsInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogLabelsInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

  labelChoiceOK(){
    if(typeof window !== 'undefined'){
      localStorage.setItem('label_choice', 'true');
    }
    this.close();
  }

  labelChoiceNotLabel(){
    if(typeof window !== 'undefined'){
      localStorage.setItem('label_choice', 'false');
    }
    this.close();
  }


  close(){
    this.dialogRef.close();
  }


}

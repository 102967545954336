import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '@models/video-v2';
import { TuneV2 } from '@models/tune-v2';

export interface DialogData {
  artist_tunes: TuneV2[];
  video?: Video;
}

/**
 * Dialog list of an artist's tunes for assigning to a Video upload.
 */

@Component({
  selector: 'app-dialog-all-tunes',
  templateUrl: './dialog-all-tunes.component.html',
  styleUrls: ['./dialog-all-tunes.component.scss']
})
export class DialogAllTunesComponent implements OnInit {

  public artist_tunes: TuneV2[];
  public selected_tune:TuneV2;

  constructor(
    public dialogRef: MatDialogRef<DialogAllTunesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    if(data.artist_tunes){
      this.artist_tunes = data.artist_tunes;
    }
  }

  selected(event){
    // console.log('select tune dialog selected: ', event.source._value);
    this.selected_tune = event.source._value[0];
    this.close();
  }

  close(){
    this.dialogRef.close( {selected_tune: this.selected_tune} );
  }

  ngOnInit(): void {
  }

}

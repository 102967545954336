import { Component, OnInit } from '@angular/core';
import { CollectionV2 } from '@models/collection-v2';
import { MatDialog } from '@angular/material/dialog';
import { DialogPurchaseComponent } from '@modules/_shared/ui/components/dialog-purchase/dialog-purchase.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { SnackbarService } from '@services/snackbar.service';
import { PaymentV2 } from '@models/payment-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';

@Component({
  selector: 'app-store-card-collection',
  templateUrl: './store-card-collection.component.html',
  styleUrls: ['./store-card-collection.component.scss'],
  inputs: ['resource']
})
export class StoreCardCollectionComponent implements OnInit {

  public resource:CollectionV2;
  public is_redirecting: boolean;

  public collection_routing: any = {
    Single: 'singles',
    Album: 'albums',
    ExtendedPlayer: 'eps',
    EP: 'eps'
  };

  public collection_type: any = {
    Single: 'Single',
    Album: 'Album',
    ExtendedPlayer: 'EP'
  };

  constructor(private dialog:MatDialog, private loadingIndicatorService:LoadingIndicatorService, private snackbar:SnackbarService, private api_tunes:ApiAmazingtunesV2Service) { }

  ngOnInit(): void {

    // console.log('COLLECTION For Sale:', this.resource);

  }

  buy(resource: CollectionV2) {
    // open dialog
    console.log('BUY', resource);

    if (typeof window === "undefined") {
      return; // SSR
    }
    const dialogRef = this.dialog.open(DialogPurchaseComponent, {
      width: '500px',
      panelClass: 'dialogBuySubscription',
      data: { resource: resource },
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(data => {
      //console.log('The purchasing dialog was closed: ', data);
      if (data && data.resource) {
        //console.log('Start purchase of ', data.resource);
        this.loadingIndicatorService.startLoading();

        this.api_tunes.purchaseResource(data.resource).subscribe((data: PaymentV2) => {
          // console.log('redirect to payment gateway: ', data);
          this.is_redirecting = true; // Show an indicator while we redirect.
          if (data.attributes.payment_approval_url) {
            // console.log('redirect user for payment to : ', data.attributes.payment_approval_url);
            window.open(data.attributes.payment_approval_url, '_self');
            return;
            // On returning from the payment gateway, the user is sent to /payments/:gateway/purchases to complete the transaction.
          }
        },
          (error) => {
            console.log('Error: ', error);
            this.loadingIndicatorService.stopLoading();
            this.snackbar.show('There was an error initiating the purchase: ' + error.message);
          });
      }
    });

  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@services/snackbar.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { UserV2 } from '@models/user-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { TuneV2 } from '@models/tune-v2';

declare var _paq;

export interface DialogData {
  user: UserV2;
  tunes: TuneV2[];
  playlists: PlaylistV2[];
}

@Component({
  selector: 'app-dialog-add-to-playlist',
  templateUrl: './dialog-add-to-playlist.component.html',
  styleUrls: ['./dialog-add-to-playlist.component.scss'],
})
export class DialogAddToPlaylistComponent implements OnInit {

  private user:UserV2;
  private tunes:TuneV2[]; // support arrays

  public playlists:PlaylistV2[];
  public new_playlist_name:string;

  constructor(
    private router:Router,
    private api_tunes:ApiAmazingtunesV2Service,
    private snackbar: SnackbarService,
    public dialogRef: MatDialogRef<DialogAddToPlaylistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {

      console.log('playlist dialog data', data)

      if(!data.user){
        this.snackbar.show('No User!');
        this.close();
        return;
      }
      if(!data.playlists){
        this.snackbar.show('No Playlists data!'); // even if empty
        this.close();
        return;
      }
      if(!data.tunes){
        this.snackbar.show('No Tune to add!');
        this.close();
        return;
      }
      this.user = data.user;
      this.tunes = data.tunes;
      this.playlists = data.playlists;
    }


  selected(event){
    // console.log('selected  playlist: ', event.source._value[0]);
    // Add tune to this playlist..
    const _playlist:PlaylistV2 = event.source._value[0];
    if(_playlist?.id){
      this.addToPlaylist(_playlist.id);
    } else {
      this.snackbar.show('Error getting playlist ID', 'snackbarWarning');
    }
  }

  addToPlaylist(playlist_id:string){
    this.api_tunes.addPlaylistTunes(playlist_id, this.tunes).subscribe(
      (data) => {
        // console.log('Add to playlist: ', data);
        this.snackbar.snackbarRef = this.snackbar.snackBar.open((this.tunes.length === 1 ? 'Tune':'Tunes') + ' added to Playlist', 'View Playlist', {
          duration:3000,
          horizontalPosition:'end',
          verticalPosition:'bottom' // (no 'middle/center' og page. Top or bottom only)
        });
        this.snackbar.snackbarRef.onAction().subscribe(() => {
          this.router.navigate(['/profile', this.user.attributes.permalink, 'playlists', playlist_id]);
        });

        this.close();

        // console.log('Playlisting: ', (this.tunes.length === 1 ? this.tunes[0].attributes.name + ' > ' + data.attributes.name : this.tunes.length + ' Tunes > ' + data.attributes.name) + ' ('+data.id+')');
        _paq.push(['trackEvent', 'Playlisting', (this.tunes.length === 1 ? this.tunes[0].attributes.name + ' > ' + data.attributes.name : this.tunes.length + ' Tunes > ' + data.attributes.name) + ' ('+data.id+')' ]);

      },
      (error)=> {
        console.log('Error adding tunes to playlist: ', error);
        this.snackbar.show('Error adding to Playlist: '+error.message, 'snackbarWarning');
        this.close();
      }
    );
  }

  addPlaylist(){
    if(!this.new_playlist_name || this.new_playlist_name.length === 0){
      return;
    }
    // console.log('add to new playlist: ', this.new_playlist_name);
    this.api_tunes.createNewPlaylist(this.new_playlist_name).subscribe((data) => {
      // console.log('New playlist created: ', data);
      this.addToPlaylist(data.id);
    },
    (error)=> {
      console.log('Error creating new playlist: ', error);
      this.snackbar.show('Error creating new Playlist: ' + error.message, 'snackbarWarning');
    });
  }

  ngOnInit(): void { }

  close(){
    this.dialogRef.close();
  }

}

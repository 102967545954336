<!-- Donate dialog -->

<div class="header">
	<h6>Donate to {{ user.attributes.name }}</h6>
</div>

<div class="cont">
	<p>
    <!-- <strong>Donate to {{ user.attributes.name }}</strong> -->
    Choose an amount from the options below. You will be redirected to PayPal for payment and confirmation. Thank You.
  </p>

	<div class="donationOptions">
		<div class="fixedAmounts">
			<div class="label donate link" (click)="makeDonation('50')">Donate {{currency_char}}50</div>
			<div class="label donate link" (click)="makeDonation('20')">Donate {{currency_char}}20</div>
			<div class="label donate link" (click)="makeDonation('10')">Donate {{currency_char}}10</div>
			<div class="label donate link" (click)="makeDonation('5')">Donate {{currency_char}}5</div>
		</div>
		<div class="chooseAmount">
			<input #customAmount type="number" name="amount" [placeholder]="placeholder_txt">
			<div class="label donate link" (click)="makeDonation(customAmount.value)">Donate</div>
		</div>
	</div>

	<div class="label med-grey link small" (click)="closeDialog()">cancel</div>

</div>

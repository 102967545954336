import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from '@models/wp-post';


@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
  inputs: ['post', 'hide_show']
})
export class PostCardComponent implements OnInit {

  public post: Post;
  public hide_show:boolean;

  constructor(private router:Router) { }

  ngOnInit(): void {

    // console.log('Post card post: ', this.post);
    // console.log('FIRST Post category: ', this.post._embedded['wp:term'][0][0].name);

  }

  clicked(){
    //
    if(this.post.is_routerlink && this.post.routerlink_path) {
      this.router.navigateByUrl(this.post.routerlink_path);
    } else if(this.post.link_override && !this.post.is_routerlink){
      if(typeof window !== 'undefined'){
         window.open(this.post.link_override, '_blank');
      }
    } else {
      if(this.post.show_id){
        this.router.navigate(['/shows', this.post.show_id, 'features', this.post.slug]);
      } else {
        this.router.navigate(['/news', this.post.slug]);
      }
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlEntityDecode'
})
export class HtmlEntityDecodePipe implements PipeTransform {

  transform(value: string): string {
    // Parse html entities from text.
    return value.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });

    // Not available to SSR.
    // var doc = new DOMParser().parseFromString(value, "text/html");
    // return doc.documentElement.textContent;

  }


}

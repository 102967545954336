import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
const BASE_URI: string = environment.api_endpoint_v2;

@Injectable({
  providedIn: 'root'
})

export class ApiTokenRefreshService {
  // Imported by UserService for token refreshing.
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) { }
  tokenRefresh() {
    // Send a PATCH request to /auth. That's it.
    return this.http.patch<any>(BASE_URI + '/auth', { data: null }).pipe(
      catchError(this.handleError)
    );
  }

  // Common error handler
  private handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) { // NOT SSR
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        console.error('A client-side or network error occurred:', error.error.message);
        // return an observable with a user-facing error message
        return throwError({ status: -1, message: error.error.message });
      } else {
        // The backend returned an unsuccessful response code.
        console.log('ERROR: ', error);
        let error_message = error.message;
        if (error.error && error.error.errors) {
          error_message = error.error.errors[0].title;
        }
        return throwError({ status: error.status, message: error_message });
      }
    } else {
      console.log('ERROR: ', error);
      let error_message = error.message;
      if (error.error && error.error.errors) {
        error_message = error.error.errors[0].title;
      }
      return throwError({ status: error.status, message: error_message });
    }
  };
}


import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

// Main player slider used for tune progress and volume controls.
// Supports custom thumbsize, bar thickness and left and right track colours.
// @kosso

@Component({
  selector: 'app-slider',
  inputs: ['value', 'col_thumb', 'col_bg', 'col_track', 'bar_thickness', 'thumb_size', 'className'],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {

  public value: number;           // slider value (0.0 - 1.0)
  public col_thumb: string;       // thumb colour
  public col_bg: string;          // right track colour
  public col_track: string;       // left track (progress) colour.
  public className: string;       // optional class override
  public bar_thickness: number;   // slider bar thickness
  public thumb_size: number;      // thumb diameter (px)

  private sliding:boolean = false;
  private thumb_el: HTMLElement;
  private slider_el: HTMLElement;
  private percentage: number = 0;

  public mouseMoveSubscription: Subscription;
  public mouseUpSubscription: Subscription;


  // References to key elements in the template carrying the `#name` attribute. (eg: #slider, #thumb, etc.)
  @ViewChild('slider') slider: ElementRef;
  @ViewChild('thumb') thumb: ElementRef;
  @ViewChild('trackLeft') trackLeft: ElementRef;
  @ViewChild('trackRight') trackRight: ElementRef;

  // Allows 'start', 'change', and 'end' events and the value to be listened to from a host component and its own function.
  // Eg: (sliderEvent)="player.sliderEvent($event) in the player component template.
  @Output() sliderEvent = new EventEmitter();

  constructor() { }

  sliderClick = (event) => {
    // Detect click anywhere on the slider track bars
    // Calculate and update the percentage value
    this.setValue( (event.clientX - event.currentTarget.getBoundingClientRect().left)/event.currentTarget.clientWidth );
    // Emit event
    this.sliderEvent.emit({state:'end', value:this.value});
  }

  // Mouse events.
  /*
    Mousedown targets the thumb slider.
    But Mousemove and Mouseup targets the 'document', so
    gets handled using a Subscription instead of via the template.
  */

  mouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.sliding = true;
    // Set the thumb target and container.
    this.thumb_el = event.target;
    this.slider_el = event.target.parentElement.parentElement;

    // Add mouse listeners.
    this.mouseUpSubscription =
       fromEvent(document, 'mouseup')
                         .subscribe(e => this.mouseUp(e));
    this.mouseMoveSubscription =
       fromEvent(document, 'mousemove')
                         .subscribe(e => this.mouseMove(e));
    // Emit event
    this.sliderEvent.emit({state:'start', value:this.value});

  }

  mouseMove(event) {
    // While the mouse is down on the thumb and moving...
    event.preventDefault();
    if(!this.sliding){
      return;
    }
    // Calculate the percentage
    if ( (event.clientX - (this.thumb_el.offsetWidth / 2)) >= this.slider_el.getBoundingClientRect().left
      && (event.clientX - (this.thumb_el.offsetWidth / 2)) <= this.slider_el.getBoundingClientRect().width + this.slider_el.getBoundingClientRect().left - this.thumb_el.offsetWidth
    ) {
      this.percentage = (100 * ((event.clientX - (this.thumb_el.offsetWidth / 2)) - this.slider_el.getBoundingClientRect().left) / (this.slider_el.getBoundingClientRect().width - this.thumb_el.offsetWidth));
      // console.log('percentage value: ', _perc);
      this.setValue(this.percentage / 100);
    }
  }

  mouseUp = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.mouseMoveSubscription.unsubscribe();
    this.mouseUpSubscription.unsubscribe();
    this.sliding = false;
    this.thumb_el = null;
    this.slider_el = null;
    // Emit event
    this.sliderEvent.emit({state:'end', value:this.value});
  }

  setValue(value: number) {
    this.value = value;
    if(this.sliding){
      // Emit change event
      this.sliderEvent.emit({state:'change', value:this.value});
    }
    // Redraw
    this.updateSlider();
  }

  updateSlider() {
    this.slider.nativeElement.style.gridTemplateColumns = 'calc(' + (this.value * 100) + '% - ' + (this.thumb.nativeElement.offsetWidth * this.value) + 'px) ' + this.thumb.nativeElement.offsetWidth + 'px auto';
  }

  setColour(el_ref: ElementRef, colour: string) {
    el_ref.nativeElement.style.backgroundColor = colour;
  }

  ngOnChanges(e) {
    // Fires when the player changes the value. (SimpleChange type)
    if(e.value && !e.value.firstChange && !this.sliding){
      this.updateSlider();
    }
  }

  ngOnInit(){ }

  ngAfterViewInit() {
    // Read any customisation attributes.
    // console.log('viewinit:value ', this.value);
    // console.log('viewinit:col_thumb ', this.col_thumb);
    // console.log('viewinit:col_bg ', this.col_bg);
    // console.log('viewinit:col_track ', this.col_track);
    if (this.value) {
      this.setValue(this.value);
    }
    if( this.thumb_size ){
      this.thumb.nativeElement.style.width = this.thumb_size+'px';
      this.trackLeft.nativeElement.style.marginRight = ((this.thumb_size / 2) * -1)+'px';
      this.trackRight.nativeElement.style.marginLeft = (-1 * (this.thumb_size / 2))+'px';
      this.updateSlider();
    }
    if ( this.bar_thickness ){
      this.slider.nativeElement.style.gridTemplateRows = 'auto '+this.bar_thickness+'px auto';
      this.trackLeft.nativeElement.style.borderRadius = (this.bar_thickness / 2)+'px 0 0 '+(this.bar_thickness / 2)+'px';
      this.trackRight.nativeElement.style.borderRadius = '0 '+(this.bar_thickness / 2)+'px '+(this.bar_thickness / 2)+'px 0';
      this.updateSlider();
    }
    if (this.col_thumb) {
      this.setColour(this.thumb, this.col_thumb);
    }
    if (this.col_bg) {
      this.setColour(this.trackRight, this.col_bg);
    }
    if (this.col_track) {
      this.setColour(this.trackLeft, this.col_track);
    }
  }
}

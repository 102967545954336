<!-- Chart tune component : V2 version -->

<div *ngIf="tune && tune.artist" id="tune-{{tune.id}}" class="card chart">

  <!-- position playButton imageCont details buyDownloadContainer buttonsSurround movement -->

  <!-- Chart position -->
  <div aria-label="Chart position" class="position"><div class="number">{{ tune.chart.position }}</div></div>
  <!-- Movement (not on archive charts) -->
  <mat-icon *ngIf="tune.chart.movement" aria-hidden="true" [inline]="true" class="movement" [title]="(movement_title[tune.chart.movement]) + (tune.chart.absolute_movement ? ' '+( math.abs(tune.chart.absolute_movement) ) + ' place'+(math.abs(tune.chart.absolute_movement)===1?'':'s'):'')" [class]="tune.chart.movement" [innerText]="mat_icon_movement[tune.chart.movement]"></mat-icon>

  <!-- Play Button -->
  <app-play-button-v2 role="button" class="playButton" [playlistHead]="playlistHead" addClass="chart-entry" [unique_id]="tune._unique_id" [playlist]="playlist" [index]="index" [tune]="tune"></app-play-button-v2>

  <!-- <svg height="0" width="0">
    <defs>
        <clipPath id="icon-mask" clipPathUnits="objectBoundingBox">
           <path d="M0.5,1c0.1,0,0.2,0,0.3-0.1V0.1C0.7,0,0.6,0,0.5,0C0.2,0,0,0.2,0,0.5C0,0.8,0.2,1,0.5,1z"/>
          </clipPath>
    </defs>
  </svg> -->

  <!-- Tune image -->
  <div class="imageCont square" aria-hidden="true">
    <img routerLink="/profile/{{tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png">
  </div>

  <!-- Tune details -->
  <div class="details">
    <div class="info">
      <a role="link" class="elipsify name" [routerLink]="['/profile', tune.artist.attributes.permalink]" [title]="tune.artist.attributes.name" [innerHTML]="tune.artist.attributes.name"></a>
      <a role="link" class="elipsify title" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id]" [title]="tune.attributes.name" [innerHTML]="tune.attributes.name"></a>
    </div>
    <div class="buyDownloadContainer" *ngIf="tune.attributes.distribution.preference === 'sell' || tune.attributes.distribution.preference === 'free_download'">
      <div role="button" (click)="buy()" *ngIf="tune.attributes.distribution.preference === 'sell'" class="button buy" title="Buy this tune"><mat-icon class="icon" inline="true">shopping_cart</mat-icon><span>Buy</span></div>
      <div role="button" #downloadButton (click)="downloadTune()" *ngIf="tune.attributes.distribution.preference === 'free_download' && !has_downloaded; else downloaded" class="button download" title="Free Download"><mat-icon class="icon" inline="true">cloud_download</mat-icon></div>
    </div>

  </div>

  <!-- Action Buttons -->
  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like role="button" [title]="tune.meta.liked_by_user ? 'Remove Tune From Likes':'Like Tune'" [resource]="tune" class="interact like" *ngIf="!tune.attributes.is_private && !tune._is_owner && !tune._is_manager; else private;"></app-action-button-like>
      <app-action-button-playlist role="button" title="Add To Playlist" *ngIf="!tune._can_first_spin && !tune.attributes.is_private; else blank" [tunes]="[tune]" class="interact playlist"></app-action-button-playlist>
    </div>
  </div>

</div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private tunes -->
<ng-template #private>
  <div *ngIf="tune?.artist?.id !== logged_in_user?.id && !tune._is_manager; else edit" class="empty" title=""><mat-icon svgIcon="blank"></mat-icon></div>
</ng-template>
<ng-template #blank>
  <!-- <mat-icon class="playlist" svgIcon="blank"></mat-icon> -->
</ng-template>
<ng-template #edit>
  <mat-icon class="edit" role="button" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id, 'edit']" title="Edit Tune">edit</mat-icon>
</ng-template>
<ng-template #firstSpin>
  <mat-icon>new_releases</mat-icon>
</ng-template>
<ng-template #processing>
  <div *ngIf="!failed_upload;else failed" class="processingCont" title="This tune is still processing. It won't be long now.">
    <mat-icon class="icon spinner" svgIcon="buffering"></mat-icon>
  </div>
</ng-template>
<ng-template #failed>
  <div class="processingCont" title="This tune appears to have failed processing.">
    <mat-icon class="icon warning" color="warn">warning</mat-icon>
  </div>
</ng-template>
<ng-template #old>
  <div class="processingCont" title="This tune failed to process a long time ago.">
    <mat-icon class="icon warning" color="warn">error</mat-icon>
  </div>
</ng-template>
<ng-template #failedMessage>
  <ng-container *ngIf="!tune.attributes.is_ready_to_stream">
    <div class="label warn x-small deleteTune" *ngIf="!old_upload; else oldMessage">
      This upload failed to process in time.
    </div>
  </ng-container>
</ng-template>
<ng-template #oldMessage>
  <div class="label warn x-small deleteTune">
    This upload failed a long time ago.
  </div>
</ng-template>
<ng-template #downloaded>
  <div *ngIf="has_downloaded" class="button download" title="Downloaded"><mat-icon class="icon" inline="true">check_circle</mat-icon></div>
</ng-template>
<div *ngIf="is_redirecting" class="underlay">
  <mat-icon id="indicator" svgIcon="buffering" class="spinner" ></mat-icon>
</div>


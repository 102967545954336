import { Component, OnInit, Inject, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from '@models/video-v2';
import { SnackbarService } from '@services/snackbar.service';
import { HlsVideoPlayerService } from '@app/modules/_shared/ui/components/hls-player/hls-video-player.service';
import { Subscription } from 'rxjs';

export interface DialogData {
  video_id?: string;
  video_title?: string;
  video_sub_title?: string;
  video?: Video;
  video_playlist?: Video[];
  video_playlist_index?: number;
  has_no_playlist?:boolean;
  autoplay?:boolean;
}

@Component({
  selector: 'app-dialog-video-player-hls',
  templateUrl: './dialog-video-player-hls.component.html',
  styleUrls: ['./dialog-video-player-hls.component.scss']
})
export class DialogVideoPlayerHlsComponent implements OnInit, OnDestroy, OnChanges {

  public video?: Video;
  public video_id?: string;
  public video_title?: string;
  public video_sub_title?: string;
  public video_playlist?: Video[];
  public video_playlist_index?: number = 0;
  public dialog_data: DialogData;
  public has_no_playlist:boolean;
  public autoplay:boolean;

  private sub:Subscription;
  private dialogCloseSub:Subscription;

  // Updated by EventEmitter in hls-player comp.
  public state:string = '...';

  constructor(
    private dialogRef: MatDialogRef<DialogVideoPlayerHlsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private snackbar: SnackbarService,
    private hlsVideoPlayerService:HlsVideoPlayerService
  ) {
    // console.log('HLS video dialog data', data);
    this.video = data.video;
    // console.log('dialog video:', this.video);

    if(data.autoplay){
      this.autoplay = data.autoplay;
    }
    if(data.video_playlist){
      this.video_playlist = data.video_playlist;
      this.has_no_playlist = false;
      // console.log('has playlist:', this.video_playlist);
      this.hlsVideoPlayerService.playlist = this.video_playlist;
    } else {
      this.has_no_playlist = true;
    }

    if(data.video_playlist_index){
      this.video_playlist_index = data.video_playlist_index;
      this.hlsVideoPlayerService.playlist_index = this.video_playlist_index;
      // console.log('index: ', this.video_playlist_index);
    }

    this.sub = this.hlsVideoPlayerService.playlistIndexSubject.subscribe(data => {
      // console.log('new index: ', data);
      this.video_playlist_index = data;
      // set new video...
      this.video = this.video_playlist[this.video_playlist_index];
      // console.log('new dialog video:', this.video);
    });

    this.dialogCloseSub = this.hlsVideoPlayerService.playerDialogCloseSubject.subscribe(data => {
      console.log('dialogCloseSub', data);
      if(data === 'video'){
        this.goToVideoPage();
      } else if(data === 'artist'){
        this.goToArtistProfile();
      }

    });

  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }
    if(this.dialogCloseSub){
      this.dialogCloseSub.unsubscribe();
    }
  }

  ngOnChanges(change: any): void {
      console.log('CHANGES', change);
  }

  nextVideo(){
    this.hlsVideoPlayerService.getNext();
  }

  previousVideo(){
    this.hlsVideoPlayerService.getPrevious();
  }

  close() {
    this.dialogRef.close();
  }

  goToArtistProfile(){
    this.close();
    if(typeof window !== 'undefined'){
      window.scrollTo(0,0);
    }
    this.router.navigate(['/profile', this.video.artist.attributes.permalink]);
  }

  goToVideoPage(){
    this.close();
    if(typeof window !== 'undefined'){
      window.scrollTo(0,0);
    }
    this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos', this.video.id]);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from '@models/wp-post';

@Component({
  selector: 'app-show-post-card',
  templateUrl: './show-post-card.component.html',
  styleUrls: ['./show-post-card.component.scss'],
  inputs: ['post', 'show_id']
})
export class ShowPostCardComponent implements OnInit {

  public post: Post;
  public show_id:string;

  constructor(private router:Router) { }

  ngOnInit(): void {

    // console.log('Post card post: ', this.post);
    // console.log('FIRST Post category: ', this.post._embedded['wp:term'][0][0].name);

  }

  clicked(){
    //
    if(this.post.is_routerlink && this.post.routerlink_path) {
      this.router.navigateByUrl(this.post.routerlink_path);
    } else if(this.post.link_override && !this.post.is_routerlink){
      if(typeof window !== 'undefined'){
         window.open(this.post.link_override, '_blank');
      }
    } else {
      this.router.navigateByUrl('/shows/'+this.show_id+'/features/'+this.post.slug);
    }


  }

}

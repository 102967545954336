import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserV2 } from '@models/user-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SnackbarService } from '@services/snackbar.service';

interface DialogData {
    user: UserV2;
}

class AddVideoFormModel {
  constructor(
    public url?: string,
  ) { }
}

@Component({
  selector: 'app-dialog-add-video',
  templateUrl: './dialog-add-video.component.html',
  styleUrls: ['./dialog-add-video.component.scss']
})
export class DialogAddVideoComponent implements OnInit {

  public addVideoForm: AddVideoFormModel;
  private user:UserV2;

  constructor(
    private snackbar:SnackbarService,
    public dialogRef: MatDialogRef<DialogAddVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api_tunes: ApiAmazingtunesV2Service,
  ) {
    this.addVideoForm = new AddVideoFormModel();
    this.user = data.user;
  }

  ngOnInit(): void { }

  addVideo(event){
    //console.log('Add video: ', this.addVideoForm.url, this.user);
    this.api_tunes.addExternalVideo(this.addVideoForm.url, this.user).subscribe((data) => {
      // console.log('Add video result: ', data);
      this.close(data);
    },
    (error) => {
      console.log('Add video error:', error );
      if(error.message === 'Youtube has already been taken'){
        error.message = 'This YouTube video has already been added.';
      }
      this.snackbar.show(error.message);
    });
  }

  close(data:any){
    this.dialogRef.close({data:data});
  }

}

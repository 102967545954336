<div class="instructions">
  ADD A CARD AS FUNDING SOURCE
</div>
<div *ngIf="member_subscription.data.attributes?.next_payment_hours < 0" class="charged-now">
  Your card will be charged {{ member_subscription.data.relationships.recurring_plan.data.attributes.payment_amount |
  currency:member_subscription.data.relationships.recurring_plan.data.attributes.payment_currency }} immediately
</div>
<form id="payment-form">
  <div id="link-authentication-element">
    <!--Stripe.js injects the Link Authentication Element-->
  </div>
  <div id="payment-element">
    <!--Stripe.js injects the Payment Element-->
  </div>
  <button #submit id="submit" (click)="submitCard($event)">
    <div class="spinner hidden" id="spinner"></div>
    <span #button_text id="button-text">ADD CARD</span>
  </button>
  <div #payment_message id="payment-message" class="hidden"></div>
  <div class="about">Provided by Stripe.com</div>
</form>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Video } from '@models/video-v2';
import { UserV2 } from '@models/user-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SnackbarService } from '@services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DialogVideoPlayerHlsComponent } from '@modules/_shared/ui/components/dialog-video-player-hls/dialog-video-player-hls.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-video-card-int',
  templateUrl: './video-card-int.component.html',
  styleUrls: ['./video-card-int.component.scss'],
  inputs: ['video', 'owner_page', 'user', 'playlist', 'index', 'show_artist']
})
export class VideoCardIntComponent implements OnInit, OnDestroy {

  public url:string;
  
  private _sub_dialog:Subscription;

  constructor(
    private api: ApiAmazingtunesV2Service,
    private snackbar: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
  ) { 
    this.url = this.router.url;
  }

  public user: UserV2;
  public video: Video;
  public playlist: Video[];
  public index: number;
  public owner_page: boolean;
  public show_artist: boolean;
  public open_page: boolean;

  private interval_check:any;
  private check_interval_ms:number = 4000;
  private check_count:number;
  public has_error:boolean;

  ngOnInit(): void {
    // console.log('card vid', this.video)
    if(this.video.attributes.status !== 'transcoded'){
      this.check_count = 0;
      this.interval_check = setInterval( ()=> {
        this.checkStatus();
      }, this.check_interval_ms);
    }
  }

  goToArtistProfile(event) {
    event.stopPropagation();
    this.router.navigate(['/profile', this.video.artist.attributes.permalink]);
  }

  goToVideoPage(event) {
    event.stopPropagation();
    if(this.video.attributes.status !== 'transcoded'){
      this.snackbar.show('Video not transcoded yet. Please try again later.');
      return;
    }
    this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos', this.video.id]);
  }

  editVideo(event) {
    event.stopPropagation();
    console.log('edit video....');
    this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos', this.video.id, 'edit']);
  }

  deleteVideo(event) {
    event.stopPropagation();
    // console.log('delete video:', this.video.attributes.name);
    if (confirm('Are you sure you want to remove this video?')) {
      this.api.deleteVideo(this.video).subscribe((data) => {
        this.snackbar.show('Video Removed');
        this.router.navigate(['/profile']).then(ok => {
          this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos']);
        });
      },
      (error) => {
        console.log('Error deleting video');
        this.snackbar.show(error.message);
      });
    }
  }

  viewVideo(_open_page: boolean = false) {

    if(this.video.attributes.status !== 'transcoded'){
      this.snackbar.show('Video not transcoded yet. Please try again later.');
      return;
    }

    if (this.open_page || _open_page) {
      this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos', this.video.id]);
    } else {
      this.openVideoPlayerDialog(this.video, this.index);
    }
  }

  openVideoPlayerDialog(video, video_playlist_index) {
    if (typeof window === "undefined") {
      return; // SSR
    }
    const dialogRef = this.dialog.open(DialogVideoPlayerHlsComponent, {
      width: '90%',
      panelClass: 'dialogVideo',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        autoplay: true,
        video: this.video,
        video_playlist_index: video_playlist_index,
        video_playlist: this.playlist
      }
    });

    dialogRef.afterOpened().subscribe(data => {
      // console.log('dialog opened');
      // Detect any navigaton and close the dialog. eg: Snackbar login link if trying to like a video while not logged in.
      this._sub_dialog = this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((data: NavigationStart) => {
        dialogRef.close();
      });
    });

    dialogRef.afterClosed().subscribe(data => {
      // console.log('Video dialog closed');
      if(this._sub_dialog){
        this._sub_dialog.unsubscribe();
      }
    });


  }

  checkStatus(){
    // Check trancoding status.
    this.api.getVideo(this.video.id, true).subscribe(data => {
      // console.log('checking video :', data);
      if((data.attributes.thumbnail.small && data.attributes.status === 'transcoded') || data.attributes.status === 'error'){
        clearInterval(this.interval_check);
        this.interval_check = null;
        this.video = data;
        return;
      } else {
        this.check_count++;
      }
      // if(this.check_count > 100){
      //   console.log('Giving up video check. Taking too long..', this.video);
      //   clearInterval(this.interval_check);
      //   this.video.attributes.status = 'error';
      // }

    });
  }

  ngOnDestroy():void {
    if(this.interval_check){
      clearInterval(this.interval_check);
    }
  }


}

<!-- Header component -->

<div class="headerNav">

  <header>
    <div (click)="scrollToTop()" class="headerContent">

      <mat-icon *ngIf="breakpoints.is_small || breakpoints.is_x_small || show_burger" (click)="toggleSidenav()" role="button" aria-hidden="false" [attr.aria-label]="is_side_nav_open ? 'Close Menu':'Open Menu'" [title]="is_side_nav_open ? 'Close Menu':'Open Menu'" class="menu-burger">menu</mat-icon>

      <img (click)="goHome()" [attr.aria-label]="'Amazing Radio' + (site_country === 'us' ? ' USA':'') + ' - Home'" [title]="'Amazing Radio' + (site_country === 'us' ? ' USA':'')" role="button" aria-hidden="false" class="logo" [src]="amazing_logo"/>

      <span class="spacer"></span>

      <div class="headerBlock search">
        <app-search-form class="searchFormComp"></app-search-form>
      </div>

      <div class="headerBlock-cont" [class]="is_amazing_uploader_user === false ? 'fan-only':''">

        <div class="headerBlock flags" [class]="is_amazing_uploader_user === false ? 'fan-only':''">
          <div *ngIf="site_country === 'uk'" class="flag active uk">
            UK
          </div>
          <a (click)="goToOtherCountrySite($event)" role="button" *ngIf="site_country === 'uk'" [href]="is_dev ? site_urls.dev.us : site_urls.live.us" class="flag usa" title="Go to Amazing Radio USA">
            USA
          </a>
          <a (click)="goToOtherCountrySite($event)" role="button" *ngIf="site_country === 'us'" [href]="is_dev ? site_urls.dev.uk : site_urls.live.uk" class="flag uk" title="Go to Amazing Radio UK">
            UK
          </a>
          <div *ngIf="site_country === 'us'" class="flag active usa">
            USA
          </div>
        </div>

        <div role="button" *ngIf="classification !=='fan' && classification !=='songwriter' && classification !=='publisher'; else empty;"
            class="headerBlock account upload-btn" [title]="logged_in_user ? (logged_in_user.attributes.is_amazing ? 'Upload':'Go Amazing and Upgrade') :'Create an account & upload. First track Free'"
            (click)="showUploadOptions()" aria-label="Upload options">
            Upload
            <div *ngIf="show_upload_options" class="upload-options">
              <div role="button" (click)="upload($event, 'tunes')" class="option tune">Upload Tune</div>
              <div role="button" (click)="upload($event, 'videos')" class="option video">Upload Video</div>
            </div>
        </div>

        <div role="button" *ngIf="logged_in_user" title="My Profile" class="headerBlock profile" (click)="is_side_nav_open ? toggleSidenav(): null" routerLink="/profile">My<br>profile</div>
        <div role="button" *ngIf="!logged_in_user" title="Log In" class="headerBlock login" (click)="is_side_nav_open ? toggleSidenav(): null" routerLink="/connect">Log In</div>

      </div>
    </div>
  </header>
</div>

<ng-template #empty>
  <!-- <div class="headerBlock empty">
  </div> -->
</ng-template>

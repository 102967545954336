import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

// Extend the built-in DatePipe to return a timestamp

@Pipe({
  name: 'dateTimestamp'
})
export class DateTimestampPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Math.round(Date.parse(value) / 1000);
  }
}

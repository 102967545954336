import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})

/*
  Transform bytes to megabytes.
*/

export class FilesizePipe implements PipeTransform {
  transform(size: number, extension: string = 'Mb') {
    return (size / (1024 * 1024)).toFixed(2) + extension;
  }
}

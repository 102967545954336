<div class="header">
  <h6>Amazing {{ upgrade_type }}Upgrade</h6>
</div>

<mat-dialog-content #dialogContent>
  <div *ngIf="available_plans" class="subscriptionCont">

    <div class="planCont">

      <div class="plans">
        <div class="plan selected">

          <div class="limit">
            <!-- <span class="limitAmount">{{ plan.attributes.upload_limit }}</span><br> -->
            <div class="limitAmount">UNLIMITED</div>
            <div class="limitLabel">{{ upgrade_type === 'Label ' ? 'Artists/bands, ':'' }}Uploads & Videos</div>
            <!-- <div>and much more</div> -->
          </div>

          <!-- Payment period options to go here.... -->

          <div class="options-message" *ngIf="available_plans.length > 1">
            Please choose your preferred billing option
          </div>

          <div class="payment-options">
            <div *ngFor="let plan of available_plans" class="payment-option"
              [class]="(selected_plan?.id === plan.id ? 'selected':'') + (processing ? ' disabled ':'')"
              (click)="available_plans.length > 1 ? selectPaymentPlan(plan) : null">
              <div>
                <div *ngIf="available_plans.length > 1" class="price">{{ (plan.attributes.payment_amount | currency :
                  plan.attributes.payment_currency: 'symbol': (plan.attributes.payment_amount) % 1 === 0 ? '0.0':'0.02')
                  }}<span class="per-month"
                    [innerHTML]="'/' + (plan.attributes.billing_period === 'annual'?'year':'month')"></span></div>
                <div *ngIf="available_plans.length === 1" class="price">{{ (plan.attributes.payment_amount / 12 |
                  currency : plan.attributes.payment_currency: 'symbol': (plan.attributes.payment_amount / 12) % 1 === 0
                  ? '0.0':'0.02') }}<span class="per-month" [innerHTML]="'/ month'"></span></div>
                <div *ngIf="available_plans.length > 1 && plan.attributes.billing_period === 'annual';else total"
                  class="billing saving">
                  {{ this.saving_percent }}% SAVING
                </div>
                <ng-template #total>
                  <ng-container *ngIf="available_plans.length > 1">
                    (TOTAL {{ (plan.attributes.payment_amount * 12 | currency : plan.attributes.payment_currency:
                    'symbol': (plan.attributes.payment_amount * 12) % 1 === 0 ? '0.0':'0.02') }}/year)
                  </ng-container>
                </ng-template>

                <div class="billed" *ngIf="available_plans.length === 1">billed annually (<strong>{{
                    plan.attributes.payment_amount | currency : plan.attributes.payment_currency: 'symbol':
                    (plan.attributes.payment_amount * 12) % 1 === 0 ? '0.0':'0.02' }}</strong>)</div>
                <div *ngIf="plan.attributes.payment_amount_without_discount">
                  <strong>DISCOUNTED</strong><br>
                  <strong>{{ plan.attributes.payment_amount_without_discount | currency :
                    plan.attributes.payment_currency: 'symbol': (plan.attributes.payment_amount_without_discount * 12) %
                    1 === 0 ? '0.0':'0.02' }}</strong>/year thereafter
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>



      <div class="paymentCont">
        <!-- Payment gateway options -->

        <!-- Card payment CHECKOUT -->
        <app-card-payment *ngIf="CARD_PAYMENT_PROVIDER === 'checkout' && accept_card_payments && selected_plan"
          [(processing)]="processing" [selected_plan]="selected_plan"
          [parent_dialog_ref]="dialogRef"></app-card-payment>
        <!-- Card payment STRIPE -->
        <app-card-payment-stripe *ngIf="CARD_PAYMENT_PROVIDER === 'stripe' && accept_card_payments && selected_plan"
          [(processing)]="processing" [selected_plan]="selected_plan"
          [parent_dialog_ref]="dialogRef"></app-card-payment-stripe>
        <!-- Paypal -->
        <div *ngIf="selected_plan">
          <span class="instructions">
            <span *ngIf="accept_card_payments"> OR </span>
            CLICK BELOW TO
          </span>
          <div role="button" aria-label="Click to pay via PayPal" class="btn-buy" [class]="processing ? 'disabled':''"
            (click)="close(selected_plan, 'paypal')">
            <img aria-hidden="true" title="Please click here to start your purchase" src="/assets/logos/paypal.svg">
            <div aria-hidden="true">
              <div>Purchase via PayPal</div>
            </div>
          </div>
        </div>

        <!-- Only show if the current site has US-Eligible Plans available -->
        <div class="instructions">
          All uploads are eligible for Amazing Radio USA as well as Amazing Radio UK.
          <span aria-hidden="true"
            *ngIf="user.attributes.classification === 'label' || user.user_private.self_classification === 'label'">
            <br>As a label, these uploads will be applicable to share among any of your artists.
          </span>
        </div>
        <!-- <div class="instructions">
      billed annually.
    </div> -->

      </div>

    </div>


  </div>

  <!-- <div *ngIf="processing" class="underlay">
  <mat-icon id="indicator" svgIcon="buffering" class="spinner" ></mat-icon>
</div> -->
</mat-dialog-content>
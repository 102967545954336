<!-- <div class="options-cont">
  <div class="opt-row" role="button" title="Share on Twitter">
    <mat-icon aria-hidden="true" (click)="shareTwitter()" inline="true" class="icon" svgIcon="twitter"></mat-icon>
    <div aria-hidden="true" (click)="shareTwitter()" class="label">Share on Twitter</div>
  </div>
  <div class="opt-row" role="button" title="Share on Facebook">
    <mat-icon aria-hidden="true" (click)="shareFacebook()" inline="true" class="icon" svgIcon="facebook"></mat-icon>
    <div aria-hidden="true" (click)="shareFacebook()" class="label">Share on Facebook</div>
  </div>
    <div class="opt-row" role="button" title="Copy Tune URL">
    <mat-icon aria-hidden="true" (click)="copyToClipboard()" class="icon">assignment</mat-icon>
    <div aria-hidden="true" (click)="copyToClipboard()" class="label">Copy URL To Clipboard</div>
  </div>
</div> -->
<button mat-menu-item (click)="shareTwitter()" title="Share on Twitter">
  <mat-icon svgIcon="twitter"></mat-icon>
  <span>Share on Twitter</span>
</button>
<button mat-menu-item (click)="shareFacebook()" title="Share on Facebook">
  <mat-icon svgIcon="facebook"></mat-icon>
  <span>Share on Facebook</span>
</button>
<button mat-menu-item (click)="copyToClipboard()" title="Copy URL to Clipboard">
  <mat-icon>assignment</mat-icon>
  <span>Copy URL to Clipboard</span>
</button>
<!-- <button *ngIf="resource && allow_social_sharing" mat-menu-item (click)="openDialogEmbed()" title="Get Embed Code">
  <mat-icon>code</mat-icon>
  <span>Get Embed Code</span>
</button> -->
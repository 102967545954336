import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserV2 } from '@models/user-v2';
import { TuneV2 } from '@models/tune-v2';
import { PlaylistV2 } from '@models/playlist-v2';

import { DialogAddToPlaylistComponent } from '../dialog-add-to-playlist/dialog-add-to-playlist.component';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { UserService } from '@services/user.service';
import { SnackbarService } from '@services/snackbar.service';

import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-action-button-playlist',
  templateUrl: './action-button-playlist.component.html',
  styleUrls: ['./action-button-playlist.component.scss'],
  inputs: ['tune', 'tunes', 'with_label', 'playlist_id']
})
export class ActionButtonPlaylistComponent implements OnInit {
  public tune: TuneV2;
  public tunes: TuneV2[];
  private user: UserV2;
  public playlist_id: string;

  public with_label: string;

  constructor(private router: Router, private api_tunes: ApiAmazingtunesV2Service, private snackbar: SnackbarService, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit(): void {

  }

  setReturnPath(){
    // Set a localStorage item to send the user back after logging in,
    // Store the current url..
    const path = this.router.routerState.snapshot.url;
    if(typeof window !== 'undefined'){
      localStorage.setItem('return_path', path);
    }
  }

  addToPlaylist() {
    if (typeof window === "undefined") {
      return; // SSR
    }
    if (!this.userService.loggedIn()) {
      this.snackbar.snackbarRef = this.snackbar.snackBar.open('You need to be logged in to do that.', 'Log In', {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom' // (no 'middle/center' og page. Top or bottom only)
      });
      this.snackbar.snackbarRef.onAction().subscribe(() => {
        this.setReturnPath();
        this.router.navigate(['/connect']);
      });
      return;
    }

    // console.log('🎶 Add to playlist: ', this.tunes);

    this.user = this.userService.get();

    if (!this.tunes || this?.tunes?.length === 0) {
      if (this.playlist_id) {
        // console.log('NO TUNES IN THIS PLAYLIST YET - id: ', this.playlist_id);
        // Lazyload the tunes..
        this.api_tunes.getPlaylistV2(this.playlist_id, true).subscribe(data => {
          this.tunes = data._tunes;
          this.api_tunes.getAllUserPlaylistsV2(this.user.attributes.permalink).subscribe(data => {
            // console.log('All playlists for '+this.user.attributes.name+': ', data);
            this.openPlaylistsDialog(data);
          });
        });
      }

    } else {
      this.api_tunes.getAllUserPlaylistsV2(this.user.attributes.permalink).subscribe(data => {
        // console.log('All playlists for '+this.user.attributes.name+': ', data);
        this.openPlaylistsDialog(data);
      });
    }
  }

  openPlaylistsDialog(playlists: PlaylistV2[]) {
    const dialogRef = this.dialog.open(DialogAddToPlaylistComponent, {
      width: '500px',
      panelClass: 'dialogPlaylists',
      data: { user: this.user, tunes: this.tunes, playlists: playlists },
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(data => {
      //  console.log('The Playlists dialog was closed: ', data);
    });
  }

}

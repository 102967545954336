import { Injectable } from '@angular/core';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { TuneV2 } from '@models/tune-v2';
import { BehaviorSubject } from 'rxjs';

// Regularly updates Radio History/Recently Aired tunes.
// Previously existed in the Player service.
// Used by the Player componenent and now the radio homepages (soon to include the US channel)
const RADIO_HISTORY_UPDATE_INTERVAL = 30000;
const RADIO_HISTORY_COUNT = 7; // Required by home page now

@Injectable({
  providedIn: 'root'
})
export class RadioHistoryService {

  // Main radio stations history/now playing.
  public history:TuneV2[];
  // Subscribed to by the PlayerService (and PlayerComponent)
  public history_subject: BehaviorSubject<TuneV2[]> = new BehaviorSubject(null);
  // Update interval
  private history_interval:any;

  // When a Genre stream is selected...
  public genre_stream_id:string; // = 'beats'; // Genre stream ID.
  public genre_history:TuneV2[];
  public genre_history_subject: BehaviorSubject<TuneV2[]> = new BehaviorSubject(null);

  constructor(private api_tunes:ApiAmazingtunesV2Service, private userService:UserService) {

    this.getRadioHistory(this.genre_stream_id);
    if(typeof window !== 'undefined'){
      this.startRadioHistoryUpdates();
    }

  }

  private getRadioHistory(genre_stream_id?:string) {
    this.api_tunes.getRadioHistoryV2(0, true, RADIO_HISTORY_COUNT, genre_stream_id)
      .subscribe((data) => {
        if(!genre_stream_id){
          if (!this.history || this.history[0].id !== data[0].id) {
            this.history = data;
            this.history_subject.next(this.history);
            // console.log('%cRadio history:', 'color:yellow', this.history[0].attributes.name);
          }
        } else {
          if (!this.genre_history || this.genre_history[0].id !== data[0].id) {
            this.genre_history = data;
          }
          this.genre_history_subject.next(this.genre_history);
          // console.log('%cGenre history:', 'color:orange', this.genre_stream_id, this.genre_history[0].attributes.name);
        }

      },
      (error)=> {
        console.log('getRadioHistory error:', error);
        this.getRadioHistory(this.genre_stream_id); // Update again now, or the player won't show up for another 30 seconds.
      });
  }

  public stopRadioHistoryUpdates() {
    // Halt the interval when needed. eg: oembeds don't need this.
    // ... unless we do an oembed radio player with now playing info etc.
    clearInterval(this.history_interval);
  }

  public startRadioHistoryUpdates() {
    if (typeof window === "undefined") {
      return;
    }
    this.history_interval = setInterval(() => {
      this.getRadioHistory(this.genre_stream_id);
    }, RADIO_HISTORY_UPDATE_INTERVAL);
  }

  // Used after setting an offset.
  public updateRadioHistory() : void {
    this.getRadioHistory(this.genre_stream_id);
  }


}

import { AppConfiguration } from '@models/configurations';

export const environment: AppConfiguration = {
  // Which site is this built for?
  site_country:'uk',
  // Only used when sharing links to social media (for now) (so can be the live site url)
  site_url:'https://amazingradio.com',
  // Are subscriptions / uploads available? Temporary.
  has_subscriptions: true,
  production: true,
  name: 'Amazing Radio',
  cdn: {
    find: '//api.amazingradio.com/',
    replace: '//assets.amazingradio.com/'
  },
  asset_host: 'https://assets.amazingradio.com/',
  // Radio channel. Stream and shows.
  radio_channel: 'uk',
  radio_stream: 'https://stream.amazingradio.com:8443/stream.mp3?ngsw-bypass=true',
  schedule_api_endpoint: 'https://schedule.amazingradio.com',
  // Tunes API
  api_endpoint_v2: 'https://api.amazingtunes.com',
  // Wordpress Channel. Editorial Content.
  // New dual WP config. [PRODUCTION]
  wp: {
    uk:{
      api_endpoint:'https://api.amazingradio.com/wp-json',
      api_host:'https://api.amazingradio.com',
      home_host:'https://amazingradio.com',
    },
    us:{
      api_endpoint: 'https://wp-api.amazingradio.us/wp-json',
      api_host: 'https://wp-api.amazingradio.us',
      home_host: 'https://amazingradio.us',
    },
  },


  // Social accounts.
  social_twitter: 'amazingradio',
  social_facebook: 'amazingradio',
  social_instagram: 'amazingradiouk',
  social_youtube: 'amazingradiouk',
  social_tiktok: 'amazingradiouk',
  social_threads: 'amazingradiouk',

  search_proxy_host: 'https://search.amazing-media.com',
  play_uri: 'https://play.amazingtunes.com',
  rewind_s3_uri: 'https://s3.amazonaws.com/amazingrewind',
  at_client: '22e5c7db6cef449e57ca247e9f35b651',
  xs_path: '/xsauth/radio-production',
  // checkout.com key
  checkout_key: 'pk_lsvmmbsbxo2beap4tkr4caywvm#',
  accept_card_payments: true,

  // stripe publishable key (Amazing Music Technology : LIVE mode)
  stripe_key: 'pk_live_51N952ODxCDfR3LbWLmPEFoFo66wCgn3JTgNgpu83Gedbaseq10wmcvzpHfgfuvW9Aa8qQBMuGYuCFub0ebLsCtJJ00QYJEbcpU',

  card_payment_provider: 'checkout',
  
  analytics: {
    google:'UA-3046990-16',
  },
  firebase: {
    apiKey: "AIzaSyDJ8YF8hAI6iY1ek_3yAIMS6cBOqYFkcrg",
    authDomain: "amazing-radio-97f78.firebaseapp.com",
    projectId: "amazing-radio-97f78",
    storageBucket: "amazing-radio-97f78.appspot.com",
    messagingSenderId: "395296429201",
    appId: "1:395296429201:web:d65e6593459ae8bc805b30",
    measurementId: "G-B30363PJF7",
    vapidKey: "BLfE_qFi0ooJfEa7Zxipm0_aeKBwHFQcmRjGHJMqiW5SpOOvs4F4P_0DvCLvI46ICEfQDJxCkbBSvPvExGus1nU"
  }
};

import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { DialogImageViewerComponent } from '../components/dialog-image-viewer/dialog-image-viewer.component';

/**
 * A directive to detect a double-click/tap on an element which provides an amazingtunes resource,
 * to open a dialog to view the large image of the resource.
 *
 * eg: <div [doubleClickZoom]="tune"></div>
 *
 * Note: To stop iOS Safari zooming in the entire window, `user-scalable=no` has been added to the index viewport tag.
 *
 */

interface ResourceImages {
  large:string;
  medium:string;
  small:string;
}

@Directive({
  selector: '[doubleClickZoom]'
})
export class DoubleClickZoomDirective {

  @Input('doubleClickZoom') resource: any;

  private clickCount:number = 0;

  constructor(private el: ElementRef, private dialog: MatDialog) {
    // this.el.nativeElement.title = 'double-click for a larger view'
    this.el.nativeElement.title = 'click for a larger view'
  }

  // 'dblclick' is no good for mobiles
  @HostListener('click', ['$event']) onClicked(e:any) {
    e.preventDefault();

    // single click
    this.openImageViewer();

    // double-click
    // this.clickCount++;
    // setTimeout(() => {
    //     if (this.clickCount === 2) {
    //       // console.log('double click/tap', this.resource);
    //       this.openImageViewer();
    //     }
    //     this.clickCount = 0;
    // }, 300)
  }

  @HostListener('touchend', ['$event']) onTouchEnd(e:any) {
    e.preventDefault();
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 2) {
          // console.log('double click/tap', this.resource);
          this.openImageViewer();
        }
        this.clickCount = 0;
    }, 300)
  }


  openImageViewer(){
    if (typeof window === "undefined") {
      return; // SSR
    }
    const dialogRef = this.dialog.open(DialogImageViewerComponent, {
      // width: '880px',
      panelClass: 'dialogImage',
      scrollStrategy: new NoopScrollStrategy(),
      data: this.resource.attributes.image_urls
    });
  }


}

<div title="Share" class="share label link" [matMenuTriggerFor]="menu" [class]="hide_label ? 'hide-title':''">
	<span [class]="hide_label ? 'hide-title':''">Share</span> <mat-icon class="icon" inline="true" title="Share">share</mat-icon>
</div>

<mat-menu #menu="matMenu" overlapTrigger yPosition="above" class="shareResourcePanel">
  <button *ngIf="allow_social_sharing" mat-menu-item (click)="shareTwitter()" title="Share on Twitter">
    <mat-icon svgIcon="twitter"></mat-icon>
    <span>Share on Twitter</span>
  </button>
  <button *ngIf="allow_social_sharing" mat-menu-item (click)="shareFacebook()" title="Share on Facebook">
    <mat-icon svgIcon="facebook"></mat-icon>
    <span>Share on Facebook</span>
  </button>
  <button mat-menu-item (click)="copyToClipboard()" title="Copy URL to Clipboard">
    <mat-icon>assignment</mat-icon>
    <span>Copy URL to Clipboard</span>
  </button>
  <button *ngIf="resource && allow_social_sharing" mat-menu-item (click)="openDialogEmbed()" title="Get Embed Code">
    <mat-icon>code</mat-icon>
    <span>Get Embed Code</span>
  </button>
</mat-menu>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, Inject, VERSION, PLATFORM_ID, APP_ID } from '@angular/core';
import { Platform } from '@angular/cdk/platform'; // for mobile browser detection
import { isPlatformBrowser } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

// Module (non-routing) containing common reusable UI stuff like Dialog, Slider, DragAndDrop, etc. and also useful directives and pipes.
import { UiModule } from '@app/modules/_shared/ui/ui.module';
// Module (non-routing) containing the main audio player service logic and also the Footer player component and main site-wide play button component.
import { PlayerModule } from '@app/modules/_shared/player/player.module';

import { apiRequestInterceptor } from './services/api-request-interceptor-func';
import { loadingInterceptor } from './services/loading-interceptor-func';
import { LoadingIndicatorService } from '@services/loading-indicator.service';

import { LoadingIndicatorComponent } from './main/components/common/loading-indicator/loading-indicator.component';
import { CookieService } from '@services/cookies.service';

import { HomeWideComponent } from './main/components/home/home-wide.component';
import { HeaderComponent } from './main/components/common/header/header.component';
import { FooterComponent } from './main/components/common/footer/footer.component';
import { NotFoundComponent } from './main/components/common/not-found/not-found.component';
import { CookieBarComponent } from './main/components/common/cookie-bar/cookie-bar.component';

// Custom elements bootstrapping
import { PlayerEmbedComponent } from '@modules/_shared/player/components/player-embed/player-embed.component';
import { PlaylistPollsEmbedV2Component } from '@modules/_shared/player/components/playlist-polls-embed-v2/playlist-polls-embed-v2.component';
import { RouterLinkComponent } from '@modules/_shared/ui/components/router-link/router-link.component';

// Firebase Messaging 
import { initializeApp, } from "firebase/app";
import { isSupported } from "firebase/messaging";

// Add support for custom html element tags (provided via the WP plugins after converting shortcodes etc.).
import { createCustomElement } from '@angular/elements';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeWideComponent,
    NotFoundComponent,
    LoadingIndicatorComponent,
    CookieBarComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Allows hard-coded usage of our <embed-player> etc. custom elements in our template html, if needed.
  imports: [
    A11yModule,
    AppRoutingModule,
    UiModule,
    PlayerModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    BrowserAnimationsModule],
  providers: [
    CookieService,
    LoadingIndicatorService,
    {
      provide: APP_ID, useValue: 'serverApp' // since v16
    },
    provideHttpClient(withInterceptors([
      loadingInterceptor,
      apiRequestInterceptor
    ]))
  ]
})
export class AppModule {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private injector: Injector, private platform: Platform) {
    // console.log('Bootstrap custom elements for embedded players');
    // See: https://angular.io/guide/elements#angular-elements-overview
    // Creates a Custom Browser element from an Angular Component.

    // Only use this when NOT being run by Universal server.
    if (isPlatformBrowser(platformId)) {

      // Initialise Firebase. NON-SSR ONLY, And not Safari
      if (isSupported) {
        initializeApp(environment.firebase);
      }
      // Support <embed-player> for Tune, Colllection and Playlist embeds.
      const PlayerEmbedElement = createCustomElement(PlayerEmbedComponent, { injector: this.injector });
      // Register custom element with the browser
      customElements.define('embed-player', PlayerEmbedElement);
      customElements.whenDefined('embed-player').then(() => {
        //   console.log('Custom Element <embed-player> has been defined');
      });
      //

      const PlaylistPollEmbedV2Element = createCustomElement(PlaylistPollsEmbedV2Component, { injector: this.injector });
      // Register custom element with the browser
      customElements.define('embed-playlist-poll-v2', PlaylistPollEmbedV2Element);
      customElements.whenDefined('embed-playlist-poll-v2').then(() => {
        //  console.log('Custom Element <embed-playlist-poll> has been defined');
      });

      // RouterLinkComponent
      // These elements can come directly from Wordpress now.
      // They are also generated by the wp-api service when parsing post/tips/session data etc.
      //  which replaces all instances of hard coded https://amazingradio.com/... links to a custom element
      //  to be able to properly link within the Angualr app and not reload the page.
      const RouterLinkElement = createCustomElement(RouterLinkComponent, { injector: this.injector });
      // Register custom element with the browser
      customElements.define('router-link', RouterLinkElement);
      customElements.whenDefined('router-link').then(() => {
        //  console.log('Custom Element router-link has been defined');
      });

    } else {
      console.log('SSR * SSR * SSR * SSR * SSR * SSR * SSR * SSR * SSR');
    }

    console.log('ANGULAR       : ', VERSION.full);
    console.log('COUNTRY       : ', environment.site_country);

  }

}

// if (typeof window !== "undefined") {
//   console.log('%c  amazing%cradio  ', 'background: #111; padding:6px 0 6px 6px; color: #ffffff; font-family: Arial; font-size:1.1rem', 'background: #111; padding:6px 6px 6px 0; color: #f6ea5b; font-family: Arial; font-size:1.1rem');
// }


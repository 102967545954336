<div class="vc-cont">
    <div class="vc-player">
        <mat-icon (click)="togglePlay()" class="btn-control" [class]="state_class[state_index]"  [svgIcon]="state_svg_icons[state_index]"></mat-icon>
        <div #controls (click)="seek($event)" class="controls" [style.background-image]="voice_clip.meta._rendered_waveform_url">
            <!-- <img *ngIf="voice_clip.meta._rendered_waveform_url" [src]="voice_clip.meta._rendered_waveform_url" class="wave"> -->
            <div #bar class="bar"></div>
            <div class="info elipsify">
                <!-- {{ voice_clip?.attributes?.description ? voice_clip.attributes.description : 'Tune intro.' }} -->
            </div>
        </div>
    </div>
    <mat-icon class="vc-delete" title="delete voice clip" (click)="deleteClip()" inline>delete</mat-icon>

</div>
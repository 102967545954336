import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserV2 } from '@models/user-v2';
import { ONSEpisode } from '@models/one-night-stand';
import { SnackbarService } from '@services/snackbar.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';

export interface DialogData {
  user: UserV2;
  episode: ONSEpisode;
}

@Component({
  selector: 'app-dialog-donate',
  templateUrl: './dialog-donate.component.html',
  styleUrls: ['./dialog-donate.component.scss']
})
export class DialogDonateComponent implements OnInit {

  public user: UserV2;
  public currency_char:string;
  private currencies:any = {
    USD:'$',
    GBP:'£',
    EUR:'€'
  };
  public placeholder_txt:string;

  constructor(
    public dialogRef: MatDialogRef<DialogDonateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackbar: SnackbarService,
    private api_tunes: ApiAmazingtunesV2Service
  ) {
    // console.log('Donate Dialog data: ', data);
    this.user = data.user;
  
    this.currency_char = this.currencies[this.user.meta.default_currency];
    this.placeholder_txt = this.currency_char+'other';

  }

  makeDonation(amount: string) {

    if (isNaN( parseFloat(amount))) {
      this.snackbar.show('Please enter a valid number');
      return;
    }
    const amount_in_cents: number = Math.round(parseFloat(amount) * 100);
    // console.log('Donation :', amount_in_cents);
    if(amount_in_cents === 0){
      return;
    }
    this.dialogRef.close({ amount: amount_in_cents, user: this.user });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}

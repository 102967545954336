/* UIModule:
  Contains various UI instruments used around the site.
  eg: 'Tune cards' , Dialogs, Pagination.
  Also provides Material support and various Directives and Pipes.
*/

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CurrencyPipe} from '@angular/common';
import { TitleCasePipe} from '@angular/common';
import { ClipboardModule, CDK_COPY_TO_CLIPBOARD_CONFIG } from '@angular/cdk/clipboard';

// Angular Material Module dependencies.
// Components can no longer be imported simply through "@angular/material".
// Use the individual secondary entry-points, such as @angular/material/button. etc.
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCommonModule } from '@angular/material/core';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatChipsModule } from '@angular/material/chips';

import { MatTabsModule } from '@angular/material/tabs';

import { MatButtonToggleModule} from '@angular/material/button-toggle';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatDialogModule } from '@angular/material/dialog';
//import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { MatExpansionModule } from '@angular/material/expansion';

import { MatPaginatorModule } from '@angular/material/paginator';

import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

import { DragDropModule } from '@angular/cdk/drag-drop'; // For list reordering
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
// For new wide layout with side nav menu.
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatSliderModule } from '@angular/material/slider';

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import  _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
// We need to do this since Material's native date module only supports en-US. So we need to use Moment to build a custom format thanks to the weird way americans (etc.) do dates MM/DD/YYYY ;) .
// So, make things easier, I've gone for Moment's 'LL' format: eg: 'August 23, 2019' - if we want to add time too, that can be modified.
// See: https://material.angular.io/components/datepicker/overview#customizing-the-parse-and-display-formats
// Also: // https://momentjs.com/docs/#/displaying/format/ for the format codes.
export const CUSTOM_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// pipes
import { SecsToTimePipe } from './pipes/secs-to-time.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { DateTimestampPipe } from './pipes/date-timestamp.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HtmlEntityDecodePipe } from './pipes/html-entity-decode.pipe';
import { ArtistFilterPipe } from './pipes/artist-filter.pipe';
import { TuneV1V2Pipe, FeaturedTunesList2PlaylistV2, TuneSearchV1V2Pipe, CollectionV1V2Pipe, CollectionSearchV1V2Pipe } from '@services/tune-v2-v1.pipe';
import { ArtistV1UserV2Pipe } from '@services/artist-v1-user-v2-pipe';
import { AiringRewindPipe } from '@services/airing-rewind.pipe';
import { UrlEncodePipe } from './pipes/url-encode.pipe';
import { FormatOrderPipe } from './pipes/format-order.pipe';

// directives
import { LazyImageSrcDirective } from './directives/lazy-image-src.directive';
import { LazyImageSrcColourDirective } from './directives/lazy-image-src-colour.directive';
import { LazyImageBackgroundDirective } from './directives/lazy-image-background.directive';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { HoldableDirective } from './directives/holdable/holdable.directive';
import { InputLowercaseDirective } from './directives/input-lowercase/input-lowercase.directive';
import { InlineSVGSrcDirective } from './directives/inline-svg-src.directive';
import { DoubleClickZoomDirective } from './directives/double-click-zoom.directive';

// components
import { PostListBlockComponent } from './components/post-list-block/post-list-block.component';
import { LatestSessionsBlockComponent } from './components/latest-sessions-block/latest-sessions-block.component';
// Importing these over from the Player Module to make sharing them site-wide more easy.
import { RadioPlayBtnComponent } from '@modules/_shared/player/components/radio-play-btn/radio-play-btn.component';
import { PlayButtonV2Component } from '@modules/_shared/player/components/play-button-v2/play-button-v2.component';
import { CollectionPlayButtonV2Component } from '@modules/_shared/player/components/collection-play-button-v2/collection-play-button-v2.component';
import { PlaylistPlayButtonV2Component } from '@modules/_shared/player/components/playlist-play-button-v2/playlist-play-button-v2.component';
import { PlaylistPollsEmbedV2Component } from '@modules/_shared/player/components/playlist-polls-embed-v2/playlist-polls-embed-v2.component';
import { FirstSpinPlayButtonComponent } from './components/first-spin-play-button/first-spin-play-button.component';
import { LatestUploadsPlayButtonComponent } from './components/latest-uploads-play-button/latest-uploads-play-button.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { PlaylistCardV2Component } from './components/playlist-card-v2/playlist-card-v2.component';
import { TuneCardV2Component } from './components/tune-card-v2/tune-card-v2.component';
// New layout
import { TuneGridCardV3Component } from './components/tune-grid-card-v3/tune-grid-card-v3.component';
import { ChartEntryCardComponent } from './components/chart-entry-card/chart-entry-card.component';
import { UserCardV2Component } from './components/user-card-v2/user-card-v2.component';
import { CollectionCardV2Component } from './components/collection-card-v2/collection-card-v2.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { RouterLinkComponent } from './components/router-link/router-link.component';
import { SearchFormComponent } from '@modules/search/components/search-form/search-form.component';
// The 'dots menu' : share, like, add to playlist, follow, etc, aka: interactions.
import { ResourceOptionsMenuComponent } from './components/resource-options-menu/resource-options-menu.component';
import { CardCarouselComponent } from './components/card-carousel/card-carousel.component';
import { ShowCardComponent } from './components/show-card/show-card.component';
import { ShareOptionsComponent } from './components/share-options/share-options.component';
import { SliderComponent } from '@app/modules/_shared/ui/components/slider/slider.component';
// new layout
import { VideoCardV3Component } from './components/video-card-v3/video-card-v3.component';
import { VideoPlayerYoutubeComponent } from './components/video-player-youtube/video-player-youtube.component';
import { ImageCropperComponent } from './components/dialog-image-upload/image-cropper/image-cropper.component';
// 'pop-up Dialogs (uses MatDialogModule)
import { DialogImageUploadComponent } from './components/dialog-image-upload/dialog-image-upload.component';
import { DialogAddTunesComponent } from './components/dialog-add-tunes/dialog-add-tunes.component';
import { DialogBuySubscriptionComponent } from './components/dialog-buy-subscription/dialog-buy-subscription.component';
import { DialogAddVideoComponent } from './components/dialog-add-video/dialog-add-video.component';
import { DialogTopupSubscriptionComponent } from './components/dialog-topup-subscription/dialog-topup-subscription.component';
import { DialogLabelsInfoComponent } from './components/dialog-labels-info/dialog-labels-info.component';
import { DialogAddToPlaylistComponent } from './components/dialog-add-to-playlist/dialog-add-to-playlist.component';
import { DialogPurchaseComponent } from './components/dialog-purchase/dialog-purchase.component';
import { DialogFirstSpinComponent } from './components/dialog-first-spin/dialog-first-spin.component';
import { DialogDonateComponent } from './components/dialog-donate/dialog-donate.component';
import { DialogLabelArtistsComponent } from './components/dialog-label-artists/dialog-label-artists.component';
import { DialogVideoPlayerComponent } from './components/dialog-video-player/dialog-video-player.component';
import { DialogFundingSourceComponent } from './components/dialog-funding-source/dialog-funding-source.component';
import { DialogCancelSubscriptionComponent } from './components/dialog-cancel-subscription/dialog-cancel-subscription.component';
import { DialogAdTunecoreComponent } from './components/dialog-ad-tunecore/dialog-ad-tunecore.component';
import { DialogCustomEmbedComponent } from './components/dialog-custom-embed/dialog-custom-embed.component';
import { DialogContentReportComponent } from './components/dialog-content-report/dialog-content-report.component';
import { DialogImageViewerComponent } from './components/dialog-image-viewer/dialog-image-viewer.component';
import { ActionButtonLikeComponent } from './components/action-button-like/action-button-like.component';
import { ActionButtonPlaylistComponent } from './components/action-button-playlist/action-button-playlist.component';
import { ActionButtonFirstspinComponent } from './components/action-button-firstspin/action-button-firstspin.component';
import { ActionPlayerButtonsComponent } from './components/action-player-buttons/action-player-buttons.component';
import { ActionButtonEditComponent } from './components/action-button-edit/action-button-edit.component';
import { ActionButtonDonateComponent } from './components/action-button-donate/action-button-donate.component';
import { CardPaymentComponent } from './components/card-payment/card-payment.component';
import { CardFundingComponent } from './components/card-funding/card-funding.component';
import { HeroCarouselComponent } from './components/hero-carousel/hero-carousel.component';
import { RazorCutRowComponent } from './components/razor-cut-row/razor-cut-row.component';
import { HomeFeatureRowComponent } from './components/home-feature-row/home-feature-row.component';
import { PlaylistFeaturedRowComponent } from './components/playlist-featured-row/playlist-featured-row.component';
import { UserCardV3Component } from './components/user-card-v3/user-card-v3.component';
import { ShowPostCardComponent } from './components/show-post-card/show-post-card.component';
import { StoreCardTuneComponent } from './components/store-card-tune/store-card-tune.component';
import { StoreCardCollectionComponent } from './components/store-card-collection/store-card-collection.component';
import { HlsPlayerComponent } from './components/hls-player/hls-player.component';
import { DialogAllTunesComponent } from './components/dialog-all-tunes/dialog-all-tunes.component';
import { VideoCardIntComponent } from './components/video-card-int/video-card-int.component';
import { DialogVideoPlayerHlsComponent } from './components/dialog-video-player-hls/dialog-video-player-hls.component';
import { DialogDeleteAccountComponent } from './components/dialog-delete-account/dialog-delete-account.component';
import { ShowNotificationBellComponent } from './components/show-notification-bell/show-notification-bell.component';
import { DialogTimeSlipComponent } from './components/dialog-time-slip/dialog-time-slip.component';
import { OnAirComponent } from './components/on-air/on-air.component';
import { RecorderUploaderComponent } from './components/recorder-uploader/recorder-uploader.component';
import { DialogRecorderUploaderComponent } from './components/dialog-recorder-uploader/dialog-recorder-uploader.component';
import { VoiceClipPlayerComponent } from './components/voice-clip-player/voice-clip-player.component';
import { CardPaymentStripeComponent } from './components/card-payment-stripe/card-payment-stripe.component';
import { CardFundingStripeComponent } from './components/card-funding-stripe/card-funding-stripe.component';
// import { UploaderMultiComponent } from './components/uploader-multi/uploader-multi.component';

const materials = [
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatSelectModule,
  MatCommonModule,
  MatCardModule,
  DragDropModule,
  MatRadioModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatIconModule,
  MatListModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatMenuModule,
  MatSnackBarModule,
  MatButtonModule,
  FormsModule,
  ReactiveFormsModule,
  MatSidenavModule,
  MatSliderModule,
  MatButtonToggleModule
];

const components = [
  PlayButtonV2Component,
  RadioPlayBtnComponent,
  CollectionPlayButtonV2Component,
  PlaylistPlayButtonV2Component,
  FirstSpinPlayButtonComponent,
  PostListBlockComponent,
  LatestSessionsBlockComponent,
  PlaylistCardV2Component,
  PaginationComponent,
  NotificationBarComponent,
  DialogImageUploadComponent,
  DialogAddTunesComponent,
  ImageCropperComponent,
  TuneCardV2Component,
  TuneGridCardV3Component,
  UserCardV2Component,
  UserCardV3Component,
  CollectionCardV2Component,
  PostCardComponent,
  RouterLinkComponent,
  PlaylistPollsEmbedV2Component,
  SearchFormComponent,
  ResourceOptionsMenuComponent,
  CardCarouselComponent,
  ShowCardComponent,
  ShareOptionsComponent,
  DialogBuySubscriptionComponent,
  SliderComponent,
  DialogAddVideoComponent,
  VideoCardV3Component,
  DialogTopupSubscriptionComponent,
  DialogLabelsInfoComponent,
  DialogFirstSpinComponent,
  DialogAddToPlaylistComponent,
  ActionButtonLikeComponent,
  ActionButtonPlaylistComponent,
  ActionButtonEditComponent,
  DialogPurchaseComponent,
  ActionButtonFirstspinComponent,
  DialogDonateComponent,
  ActionPlayerButtonsComponent,
  VideoPlayerYoutubeComponent,
  LatestUploadsPlayButtonComponent,
  CardPaymentComponent,
  CardFundingComponent,
  DialogLabelArtistsComponent,
  DialogVideoPlayerComponent,
  ChartEntryCardComponent,
  DialogFundingSourceComponent,
  DialogCancelSubscriptionComponent,
  HeroCarouselComponent,
  RazorCutRowComponent,
  HomeFeatureRowComponent,
  PlaylistFeaturedRowComponent,
  DialogImageViewerComponent,
  ActionButtonDonateComponent,
  DialogCustomEmbedComponent,
  DialogAdTunecoreComponent,
  ShowPostCardComponent,
  StoreCardTuneComponent,
  StoreCardCollectionComponent,
  DialogContentReportComponent,
  HlsPlayerComponent,
  DialogAllTunesComponent,
  VideoCardIntComponent,
  DialogVideoPlayerHlsComponent,
  DialogDeleteAccountComponent,
  ShowNotificationBellComponent,
  DialogTimeSlipComponent,
  OnAirComponent,
  RecorderUploaderComponent,
  DialogRecorderUploaderComponent,
  VoiceClipPlayerComponent,
  CardPaymentStripeComponent,
  CardFundingStripeComponent,  
];

const directives = [
  LazyImageSrcDirective,
  LazyImageSrcColourDirective,
  InlineSVGSrcDirective,
  LazyImageBackgroundDirective,
  HoldableDirective,
  InputLowercaseDirective,
  DoubleClickZoomDirective,
];

@NgModule({ declarations: [
        components,
        directives,
        SecsToTimePipe,
        FilesizePipe,
        DateTimestampPipe,
        SafeHtmlPipe,
        HtmlEntityDecodePipe,
        DragAndDropDirective,
        ArtistFilterPipe,
        TuneV1V2Pipe,
        FeaturedTunesList2PlaylistV2,
        TuneSearchV1V2Pipe,
        ArtistV1UserV2Pipe,
        CollectionV1V2Pipe,
        CollectionSearchV1V2Pipe,
        UrlEncodePipe,
        FormatOrderPipe,
    ],
    exports: [
        RouterModule,
        CommonModule,
        materials,
        components,
        directives,
        ArtistFilterPipe,
        SecsToTimePipe,
        FilesizePipe,
        DateTimestampPipe,
        SafeHtmlPipe,
        FormatOrderPipe,
        HtmlEntityDecodePipe,
    ], imports: [materials,
        CommonModule,
        RouterModule,
        ClipboardModule], providers: [
        AiringRewindPipe,
        TuneV1V2Pipe,
        FeaturedTunesList2PlaylistV2,
        TuneSearchV1V2Pipe,
        CollectionV1V2Pipe,
        CollectionSearchV1V2Pipe,
        ArtistV1UserV2Pipe,
        ArtistFilterPipe,
        SecsToTimePipe,
        DatePipe,
        CurrencyPipe,
        UrlEncodePipe,
        TitleCasePipe,
        SafeHtmlPipe,
        HtmlEntityDecodePipe,
        DateTimestampPipe,
        FilesizePipe,
        FormatOrderPipe,
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATEPICKER_FORMATS },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class UiModule { }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ExternalVideo } from '@models/video-v2';
import { environment } from '@env/environment';
import { PlayerService } from '@modules/_shared/player/player.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { VideoPlaylistService } from '@services/video-playlist.service';
import { Subscription } from 'rxjs';

declare var YT: any; // Exists if youtube.com/iframe_api is loaded (in index.html)

@Component({
  selector: 'app-video-player-youtube',
  templateUrl: './video-player-youtube.component.html',
  styleUrls: ['./video-player-youtube.component.scss'],
  inputs: ['video_playlist_index', 'video', 'video_id', 'video_title', 'autoplay'],
})
export class VideoPlayerYoutubeComponent implements OnInit, OnDestroy {

  public youtube_player_src: SafeResourceUrl;
  public video: ExternalVideo;
  public video_playlist_index: number = -1;
  public video_id: string; // The YouTube video ID.
  public video_title: string;
  public has_played: boolean;
  private _Player: any;
  public autoplay: boolean;
  private vps_sub: Subscription;

  constructor(private videoPlaylistService: VideoPlaylistService, private api: ApiAmazingtunesV2Service, private playerService: PlayerService, private domSanitizer: DomSanitizer) {

    this.vps_sub = this.videoPlaylistService.current_video_subject.subscribe(data => {
      if (data) {
        this.has_played = false;
        this.video = data;
        this.video_id = this.video.attributes.resource_id;
        this.video_title = this.video.attributes.name;
        this.video_playlist_index = this.videoPlaylistService.current_video_index;
        // console.log('%cYouTube Video updated', 'color:cyan', this.video_playlist_index, this.video_id, this.video_title);
        if (this._Player) {
          this.autoplay = true;
          this._Player.loadVideoById(this.video_id, 0);
        }
      } else {
        this.has_played = false;
        this.video = null;
        this.video_id = null;
        this.video_title = null;
        this.video_playlist_index = -1;
      }
    });

  }

  ngOnDestroy(): void {

    // console.log('Destroying videoplayer-youtube');
    if (this.vps_sub) {
      this.vps_sub.unsubscribe();
    }
    this.autoplay = false;
    this.video = null;
    this.video_playlist_index = null;
    this.video_title = null;
    this.video_id = null;
    this.videoPlaylistService.current_video = null;
    this.videoPlaylistService.current_video_index = -1;

  }

  ngOnInit(): void {
    if (typeof window === 'undefined') {
      console.log('VideoPlayerYoutubeComponent NO SSR');
      return;
    }
    this.has_played = false;

    if (this.video) {
      // console.log('ngInit - YouTube Video ID: ', this.video.attributes.resource_id, this.video_playlist_index);
      this.video_id = this.video.attributes.resource_id;
    }

    // console.log('VideoPlayerYoutubeComponent ID: ', this.video_id);
    // Fallback / original method
    if (typeof YT === 'undefined') {
      console.log('YT is not defined. Using original method as fallback without events');
      this.youtube_player_src = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.video_id + '?enablejsapi=1&controls=1&origin=' + environment.site_url);
      return;
    }
    this.youtube_player_src = null;
    // Allow time for YT API to fully load.
    setTimeout(() => {
      // console.log('ngInit - Loading video id:', this.video_id, YT);
      this.loadYouTubeVideo(this.video_id);
    }, 250);

  }

  loadYouTubeVideo(video_id: string) {

    // console.log('%cloadYouTubeVideo:', 'color:orange', video_id);
    if (this._Player) {
      this._Player = null;
    }

    if (typeof YT !== 'undefined') {
      // console.log('%cNew YT.Player..', 'color:lime');
      this._Player = new YT.Player(video_id, { // we're now also using the video ID as the element ID for uniqueness.
        height: '100%', // fills the cont-16-9 container
        width: '100%',
        videoId: video_id,
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      });

    } else {
      console.log('YT is undefined....');
    }

  }

  onPlayerReady = (evt) => {
    // console.log('onPlayerReady', this.video_id);
    if (this.autoplay) {
      this._Player.playVideo();
    }
  }

  onPlayerStateChange = (evt) => {
    // console.log('onPlayerStateChange', evt);
    switch (evt.data) {
      case 0:
        // console.log('YT Player ended');
        if (this.video_playlist_index > -1) {
          // console.log('set next video...'); // should update the dialog container, and thus the YT video..
          this.videoPlaylistService.setNextVideoInPlaylist();
        }
        break;
      case 1:
        // console.log('YT Player playing');
        if (this.playerService.playing) {
          this.playerService.toggleAudio();
        }
        if (this.video) {
          // Only for submitted videos
          if (!this.has_played) {
            this.api.pingExternalVideoPlay(this.video.id).subscribe(data => {
              //  console.log('video play registered');
            });
            this.has_played = true;
          }
        }
        break;
      case 2:
        //console.log('YT Player paused');
        break;
      case 3:
        //console.log('YT Player buffering');
        if (this.playerService.playing) {
          this.playerService.toggleAudio();
        }
        break;
      default:
      //
    }
  }

}

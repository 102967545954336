import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserV2, SubscribedPlan } from '@models/user-v2';
import { SnackbarService } from '@services/snackbar.service';
// Used for stopping Universal from attempting to use localStorage.
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ApiTokenRefreshService } from '@services/api-token-refresh.service';
import { environment } from '@env/environment';

// User data localStorage service
@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Subcribed to by the header for changes to the user object when logging in.
  public userSub: BehaviorSubject<UserV2>;

  public logged_in_user: UserV2;

  // Value set by routing data. Used for checking if user is navigating to a protected page while checking for token expiry.
  public login_req: boolean = false;

  // capabilities
  //   this.can_see_upload_btn = false;
  //   this.can_see_add_artist_btn = false;
  //   this.can_see_label_artist_upload_btn = false;
  //   this.can_see_videos_btn = false;
  //   this.can_see_buy_membership_btn = false
  //   this.can_see_buy_more_btn = false;
  //   this.can_see_buy_usa_uploads_btn = false;
  //   this.requires_usa_credits = false;
  //   this.can_upload = false;
  //   this.can_add_artist = false;
  //   this.can_upload_label_artist_tune = false;
  //   this.can_add_videos = false;
  //   this.can_buy_membership = false;
  //   this.can_buy_more_uploads = false;
  //   this.can_buy_usa_uploads = false;
  //   this.is_amazing = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute, private tokenRefreshService: ApiTokenRefreshService, private router: Router, private snackBar: SnackbarService) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data: NavigationEnd) => {
      // console.log('%cURL:', 'font-weight:bold;color:orange', data);

      // Check route config data for login_req.
      let currentRoute = this.router.routerState.root;
      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;
      }
      if (currentRoute.routeConfig?.data?.login_req) {
        // console.log('%ccurrentRoute requires login', 'color:yellow');
        this.login_req = true;
      } else {
        this.login_req = false;
      }
      this.tokenRefresh();
    });
    this.userSub = new BehaviorSubject(this.get());
  }

  cookiesAccepted(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return false;
    }
    return !!localStorage.getItem('cookies');
  }

  acceptCookies() {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return
    }
    localStorage.setItem('cookies', 'true');
  }


  loggedIn(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return false;
    }
    return !!localStorage.getItem('user');
  }

  // Check for impending token expiry
  tokenRefresh() {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    if (!this.get()) {
      return;
    }

    const expiry_date: Date = new Date(this.get().expires_at);
    const date_now: Date = new Date();
    // console.log('Expiry Date : ', expiry_date);
    // console.log('Date Now    : ', date_now);
    if (date_now > expiry_date) {
      // console.log('Token has already expired! Clear all user data');
      localStorage.removeItem('user');
      this.clearAndUpdate(true);
      // If the URL has login required, send home.
      if (this.login_req) {
        this.router.navigateByUrl('/');
      }
      return;
    }

    const diff = expiry_date.getTime() - date_now.getTime();
    if (diff < (86400000 * 2)) { // 86400000 = One day in ms.
      // console.log('Token will expire soon (in two days)... let\'s refresh it now ... ');
      this.tokenRefreshService.tokenRefresh().subscribe(data => {
        // console.log('tokenRefresh Response: ', data);
        this.mergeUserData(data.data.attributes);
      });
    }
  }

  logOut(expired?: boolean) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Just in case multiple API call error trigger this at the same time.
    const _user = localStorage.getItem('user');
    if(!_user){
      // console.log('No user object found in LS');
      return;
    } 
    localStorage.removeItem('self_classification');
    localStorage.removeItem('user');
    localStorage.removeItem('utm');
    this.logged_in_user = null;
    this.userSub.next(null);
    this.snackBar.show(expired ? 'Your login session has expired':'Logged out', 'snackbarMessage');

    this.router.navigate(['/']);
    console.log(expired ? 'User session expired':'User logged out');

  }

  clear() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    localStorage.removeItem('self_classification');
    localStorage.removeItem('user');
  }

  clearAndUpdate(had_expired:boolean = false) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    localStorage.removeItem('self_classification');
    localStorage.removeItem('user');
    localStorage.removeItem('utm');
    this.logged_in_user = null;
    this.userSub.next(null);
    if(had_expired){
      this.snackBar.show('Your login session has expired.');
    }
    this.router.navigate(['/']);
  }

  // sets full user objects
  set(data: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.logged_in_user = data;
    this.logged_in_user._capabilities = {};
    localStorage.setItem('user', JSON.stringify(this.logged_in_user));
    // update any subscribers
    if (this.logged_in_user.id) {
      this.logged_in_user._capabilities.can_upload = this.canUpload();
      this.userSub.next(this.logged_in_user);
    }
    return this.get();
  }

  // gets full user object
  get(): UserV2 {
    if (!isPlatformBrowser(this.platformId)) {
      // console.log('SSR cannot UserService.get() user localStorage...');
      this.logged_in_user = null;
      return null;
    }
    if (!localStorage.getItem('user')) {
      // console.log('userService.get : no user item');
      return null;
    }
    const _user: UserV2 = JSON.parse(localStorage.getItem('user'));
    if (!_user.id) {
      // console.log('userService.get : NO user.id in user object.... ', _user);
      this.logged_in_user = null;
      return null;
    }
    // Check for token expiration
    const _expires = new Date(_user.expires_at);
    // TEST 
    // const _expires = new Date( Date.now() - 100 );
    // console.log('%cUSER', 'color:yellow', _expires);
    if (_expires < new Date()) {
      // console.log('%cUser token has expired','color:red',_expires, _user);
      this.clearAndUpdate(true);
      return null;
    }
    this.logged_in_user = _user;
    this.logged_in_user._capabilities = {};
    // console.log('this.loggedinuser: ', this.logged_in_user?.attributes?.permalink);
    this.logged_in_user._capabilities.can_upload = this.canUpload();

    return this.logged_in_user;
  }

  // adds/edits data to user object.
  mergeUserData(data: any): UserV2 {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    if (JSON.parse(localStorage.getItem('user'))) {
      let user = JSON.parse(localStorage.getItem('user'));
      user = Object.assign(user, data);
      this.set(user);
      return user;
    }
    return;
  }

  // used to simply test 'ownership' of data (for editing/ui purposes)
  getId(): string {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return null;
    }
    if (JSON.parse(localStorage.getItem('user'))) {
      return JSON.parse(localStorage.getItem('user')).id;
    }
    return null;
  }

  // Used by the HTTP interceptor for API requests.
  getJWToken(): string {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return null;
    }
    if (JSON.parse(localStorage.getItem('user'))) {
      return JSON.parse(localStorage.getItem('user')).token
    }
    return null;
  }

  getSubscriptions(): SubscribedPlan[] {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return [];
    }
    if (JSON.parse(localStorage.getItem('user'))) {
      if (JSON.parse(localStorage.getItem('user'))._subscriptions) {
        return JSON.parse(localStorage.getItem('user'))._subscriptions
      } else {
        return [];
      }
    }
    return [];
  }

  canUpload(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    let can_upload: boolean = false;

    if (!this.loggedIn()) {
      return false;
    }

    if (!this.logged_in_user?.meta.is_subscription_required) {
      this.logged_in_user._capabilities.can_upload = true;
      // console.log('canUpload: no sub required. all good.');
      return true;
    }

    // Not an artist or label (and self-classified as a fan)
    if (this.logged_in_user?.user_private?.self_classification === 'fan' && this.logged_in_user?.attributes.classification === 'fan') {
      this.logged_in_user._capabilities.can_upload = false;
      // console.log('canUpload: not an artist or label. no.');
      return false;
    }

    // Inactive subscription. (and has a start date)
    if (!this.hasActiveSub() && this.getSubscriptions()[0]?.attributes.starting_date !== null && this.logged_in_user?.meta.free_uploads_remaining === 0) {
      this.logged_in_user._capabilities.can_upload = false;
      // console.log('canUpload: no active sub. no uploading allowed.');
      return false;
    }

    // Legacy users
    if (this.logged_in_user?.meta.is_legacy_user && !this.logged_in_user?.meta.is_subscription_required && this.logged_in_user?.attributes.classification !== 'fan' && this.logged_in_user?.user_private?.self_classification !== 'fan') {
      can_upload = true;
      // Make sure legacy users can't upload on the US site if they don't have US eligible sub.
      // if(environment.site_country === 'us' && this.hasActiveSub() && !this.getSubscriptions[0].attributes.us_eligibility){
      //   can_upload = false;
      // }
    }

    // Has free upload remaining (and is eligible user type - since API still classifies as fan until first upload)
    if (this.logged_in_user?.meta.free_uploads_remaining > 0 && (
      this.logged_in_user?.user_private?.self_classification === 'label'
      || this.logged_in_user?.user_private?.self_classification === 'artist'
      || this.logged_in_user?.attributes?.classification === 'artist'
      || this.logged_in_user?.attributes?.classification === 'label'
    )) {

      can_upload = true;
    }

    // Has active sub and paid uploads remaining.
    if (this.hasActiveSub() && this.getSubscriptions()[0].attributes.uploads_remaining > 0) {

      can_upload = true;
      // Disable on the US site if the user has no US-eligible upload sub.
      // if(environment.site_country === 'us' && !this.getSubscriptions()[0].attributes.us_eligibility){
      //   can_upload = false;
      // }
    }
    this.logged_in_user._capabilities.can_upload = can_upload;

    return can_upload;
  }

  // Helper to reduce code in profile template
  hasActiveSub(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    if (this.getSubscriptions().length > 0 && this.getSubscriptions()[0].attributes.is_active) {
      return true;
    }
    return false;
  }

  hasUploadsRemaining(): boolean {
    // Artist/label with an active sub and uploads remaining
    if ((this.hasActiveSub() && this.getSubscriptions()[0].attributes.uploads_remaining > 0)
      ||
      // Non-fan with free upload remaining
      (this.get().meta.free_uploads_remaining > 0 && (this.get().attributes.classification !== 'fan' && this.get().user_private.self_classification !== 'fan'))
    ) {
      return true;
    }
    return false;
  }

  hasConfirmed(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const user: UserV2 = this.get();
    if (user.user_private && user.user_private.confirmed) {
      return true;
    }
    return false;
  }

  treatAsLabel(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes.classification === 'label' || user?.user_private?.self_classification === 'label') {
      return true;
    }
    return false;
  }

  isLabel(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes?.classification === 'label') {
      return true;
    }
    return false;
  }

  // Detect user from Amazing Songs.
  isSongsUser(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes?.classification === 'songwriter' || user?.attributes?.classification === 'publisher') {
      return true;
    }
    return false;
  }

  treatAsArtist(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes?.classification === 'artist' || user?.user_private?.self_classification === 'artist') {
      return true;
    } else {
      return false;
    }
  }

  isArtist(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes?.classification === 'artist') {
      return true;
    } else {
      return false;
    }
  }

  treatAsFan(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const user: UserV2 = this.get();
    if (user?.attributes.classification === 'fan' || user?.user_private?.self_classification === 'fan') {
      return true;
    }
    return false;
  }

  getClassification(): string {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    if (!this.loggedIn()) {
      return;
    }
    const user: UserV2 = this.get();

    // Deal with users who might have paid, but haven't actually done anything on the site yet.
    if (user.user_private?.self_classification !== user.attributes.classification && user.attributes.classification === 'fan') {
      return user?.user_private?.self_classification;
    }
    return user.attributes.classification;
  }


  // UI listview/gridview toggle.
  setGridView(value: boolean) {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return;
    }
    if (localStorage.getItem('grid_view') && !value) {
      localStorage.removeItem('grid_view');
    } else {
      localStorage.setItem('grid_view', 'true');
    }
  }
  // Return current grid view toggle setting.
  gridView(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return false;
    }
    if (localStorage.getItem('grid_view')) {
      return true;
    }
    // Default is now list view.
    return false;
  }

  public profileRouteRedirect(): string {
    return '/';
  }

}

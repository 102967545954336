import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Plan } from '@models/subscriptions-v2';
import { SnackbarService } from '@services/snackbar.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { environment } from '@env/environment'

// Analytics
declare var fbq;
declare var gtag;
declare var _paq;

declare var Frames; // checkout.com Frames API

// TEST CARD:  4242 4242 4242 4242
// CVV: 100
// MM/YY:  12/22

@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss'],
  inputs: ['selected_plan', 'parent_dialog_ref', 'is_processing'],
  outputs: ['processing']
})
export class CardPaymentComponent implements OnInit, AfterViewInit {

  private checkout_key: string = environment.checkout_key;
  public selected_plan: Plan;
  private card_token: string;
  private card_scheme: string;
  private site_country: string = environment.site_country;

  public parent_dialog_ref: any; // Attempt to call the parent component, which is a Material Dialog, to close it.

  @ViewChild('checkoutPaymentForm') checkoutPaymentForm: ElementRef;
  @ViewChild('checkoutPayButton') checkoutPayButton: ElementRef;

  @Input() processing: boolean;
  @Output() processingChange = new EventEmitter<boolean>();

  constructor(private loadingIndicator: LoadingIndicatorService, private router: Router, private api_tunes: ApiAmazingtunesV2Service, private snackbar: SnackbarService) {
    //
  }

  ngOnInit(): void {
    //
    // console.log('checkout key:', this.checkout_key);
    // console.log('Frames:', Frames);

  }

  ngAfterViewInit(): void {
    // Component *must* have a selected_plan input.
    if (!this.selected_plan) {
      this.router.navigate(['/profile']).then(ok => {
        if (this.parent_dialog_ref) {
          this.parent_dialog_ref.close();
        }
        this.snackbar.show('No Plan Selected');
      });
      return;
    }

    // console.log('Card Payment comp: Selected plan: ', this.selected_plan);

    Frames.init(this.checkout_key);
    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      (event) => {
        // console.log("CARD_VALIDATION_CHANGED: %o", event);
        this.checkoutPayButton.nativeElement.disabled = !Frames.isCardValid();
      }
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZED,
      (event) => {
        // console.log('Checkout card tokenized event', event);
        if (event.token && event.scheme) {
          this.card_token = event.token;
          this.card_scheme = event.scheme;
          // console.log('Card tokenisation OK: ', this.card_token);
          this.api_tunes.doSubscription(this.selected_plan.id, 'checkout', this.card_token, this.card_scheme).subscribe((response) => {
            if (typeof window === 'undefined') {
              // SSR
              return;
            }

            console.log('Subscription payment response.data.attributes: ', response.data.attributes);

            this.loadingIndicator.stopLoading();
            switch (response.data.attributes.status) {
              case 'paid':

                console.log('PAID OK... update user data..');

                // Will update the local user oject with new credits.
                this.api_tunes.verifyUserV2().subscribe(_data => {

                  // Redirect to profile...
                  this.router.navigate(['/profile']).then(ok => {
                    if (this.parent_dialog_ref) {
                      this.parent_dialog_ref.close();
                    }
                    this.snackbar.show('Thank you. Your payment was successful');
                  });

                  // Analytics Events....
                  if (typeof fbq != "undefined") {
                    fbq('track', 'Purchase', { value: response.data.attributes.payment_amount, currency: response.data.attributes.payment_currency });
                  }
                  // GA
                  if (typeof gtag != "undefined") {
                    // gtag('event', 'Account', 'Paid', response.data.attributes.payment_currency, response.data.attributes.payment_amount);

                    gtag('event', 'Purchase', {
                      'event_category': 'account',
                      'event_label': response.data.attributes.payment_currency,
                      'value': response.data.attributes.payment_amount * 100 // needs integer
                    });

                  }
                  if (typeof _paq != "undefined") {
                    _paq.push(['trackEvent', 'MembershipPurchase', response.data.attributes.payment_currency + ' ' + response.data.attributes.payment_amount, response.data.attributes.payment_amount]);
                  }

                });

                break;
              case 'declined':
                //
                this.router.navigate(['/profile']).then(ok => {
                  if (this.parent_dialog_ref) {
                    this.parent_dialog_ref.close();
                  }
                  this.snackbar.show('Payment declined: ' + response.data.attributes.gateway_response, 'snackbarWarning', 10000);
                });

                break;
              case 'pending':
                // Redirect to payment_approval_url
                // On returning from the payment gateway, the user is sent to /payments/:gateway/return  (or ../cancel ) to complete the transaction.
                if (response.data.attributes.payment_approval_url) {
                  this.snackbar.show('Redirecting to payment gateway ... ');
                  window.open(response.data.attributes.payment_approval_url, '_self');
                } else {

                  this.router.navigate(['/profile']).then(ok => {
                    if (this.parent_dialog_ref) {
                      this.parent_dialog_ref.close();
                    }
                    this.snackbar.show('API Error. No Approval URL provided', 'snackbarWarning');
                  });
                }
                break;
              case 'flagged':
                //
                this.router.navigate(['/profile']).then(ok => {
                  if (this.parent_dialog_ref) {
                    this.parent_dialog_ref.close();
                  }
                  this.snackbar.show('Payment could not be confirmed. Please contact support@amazingradio' + (this.site_country === 'uk' ? '.com' : '.us'), 'snackbarWarning', 14000);
                });
                break;
            }
          },
            (error) => {
              console.log('Subscription error: ', error);
              this.router.navigate(['/profile']).then(ok => {
                if (this.parent_dialog_ref) {
                  this.parent_dialog_ref.close();
                }
                this.snackbar.show(error.errors.title, 'snackbarWarning', 10000);
              });
            });
        }
      }
    );

    this.checkoutPaymentForm.nativeElement.addEventListener("submit", (event) => {
      event.preventDefault();
      this.loadingIndicator.startLoading();

      // Sends two-way bound data back to parent comp which disables other buttons
      this.processing = true;
      this.processingChange.emit(this.processing);
      this.checkoutPayButton.nativeElement.disabled = true;

      Frames.submitCard();

    });
  }

}

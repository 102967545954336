import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ExternalVideo } from '@models/video-v2';
import { UserV2 } from '@models/user-v2';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DialogVideoPlayerComponent } from '@modules/_shared/ui/components/dialog-video-player/dialog-video-player.component';
import { VideoPlaylistService } from '@services/video-playlist.service';
// Having to inject this to enable deleting videos on video cards.
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SnackbarService } from '@services/snackbar.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-card-v3',
  templateUrl: './video-card-v3.component.html',
  styleUrls: ['./video-card-v3.component.scss'],
  inputs: ['video', 'user', 'show_artist', 'open_page', 'owner_page', 'video_playlist_index', 'has_no_playlist']
})
export class VideoCardV3Component implements OnInit {

  public video: ExternalVideo;
  public video_playlist_index: number;
  public user: UserV2;
  public show_artist: boolean = false;
  public has_no_playlist: boolean;
  public open_page: boolean;
  public owner_page: boolean;
  public url:string;

  private _sub_dialog:Subscription;

  constructor(private snackbar:SnackbarService, private api_tunes: ApiAmazingtunesV2Service, private videoPlaylistService: VideoPlaylistService, private dialog: MatDialog, private router: Router) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    // console.log('Video Card data, index: ', this.video, this.video_playlist_index);
  }

  goToArtistProfile(event) {
    event.stopPropagation();
    this.router.navigate(['/profile', this.video.artist.attributes.permalink]);
  }

  goToVideoPage(event) {
    event.stopPropagation();
    this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'external_videos', this.video.id]);
  }

  deleteVideo(event) {
    event.stopPropagation();
    // console.log('delete video:', this.video.attributes.name);
    if (confirm('Are you sure you want to remove this video?')) {
      this.api_tunes.deleteExternalVideo(this.video.id).subscribe((data) => {
        this.snackbar.show('Video Removed');
        this.router.navigate(['/profile']).then(ok => {
          this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos']);
        });
      },
        (error) => {
          this.snackbar.show(error.message);
        });
    }

  }

  viewVideo(_open_page: boolean = false) {
    if (this.open_page || _open_page) {
      this.router.navigate(['/profile', this.video.artist.attributes.permalink, 'videos', this.video.id]);
    } else {
      this.openVideoPlayerDialog(this.video.attributes.resource_id, this.video.artist.attributes.name + ' - ' + this.video.attributes.name, this.video_playlist_index);
    }
  }

  openVideoPlayerDialog(youtube_video_id, dialog_title, video_playlist_index) {
    if (typeof window === "undefined") {
      return; // SSR
    }


    // console.log('Open Video index: ', video_playlist_index);
    this.videoPlaylistService.setCurrentVideoIndex(video_playlist_index);
    const dialogRef = this.dialog.open(DialogVideoPlayerComponent, {
      width: '880px',
      panelClass: 'dialogVideo',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        video_id: youtube_video_id,
        video_title: dialog_title,
        video: this.video,
        video_playlist_index: video_playlist_index,
        has_no_playlist: this.has_no_playlist
      }
    });

    dialogRef.afterOpened().subscribe(data => {
      // Detect any navigaton and close the dialog. eg: Snackbar login link if trying to like a video while not logged in.
      this._sub_dialog = this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe((data: NavigationStart) => {
        dialogRef.close();
      });
    });

    dialogRef.afterClosed().subscribe(data => {
      if(this._sub_dialog){
        this._sub_dialog.unsubscribe();
      }
      this.videoPlaylistService.clearVideoPlaylistIndex();
    });

  }

}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';
import { ShowWP, Show } from '@models/show';
import { PostsEnv, Post, HomeNews, SessionYear, PlaylistPoll } from '@models/wp-post';
import { TipsYear, TippedArtist } from '@models/wp-tips';
import { ApiScheduleService } from '@services/api-schedule.service';

//let BASE_URI = environment.wp_api_endpoint;
// new config format
let BASE_URI = environment.wp[environment.site_country].api_endpoint;
let API_HOST = environment.wp[environment.site_country].api_host;
let HOME_HOST = environment.wp[environment.site_country].home_host;


@Injectable({
  providedIn: 'root'
})
export class ApiAmazingradioWpService {


  // Injecting ApiScheduleService to collect all show data.
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, private api_schedule: ApiScheduleService) { }

  ////// Posts & Pages
  // Get individual Post or Page  by slug (and provide embedded players)
  getPost(slug: string, type: string = 'posts', id?: number, preview_id?: string, preview_nonce?: string): Observable<Post> {
    let params_data = new HttpParams();
    params_data = params_data.set('slug', slug);
    params_data = params_data.set('_embed', 'true');
    params_data = params_data.set('_angular2', 'true'); // Provides custom elements for player embed shortcodes..
    // console.log('getPost: ', BASE_URI, type, slug);

    return this.http.get<Post>(BASE_URI + '/wp/v2/' + type, { params: params_data })
      .pipe(
        map(data => {
          if (!data[0]) {
            return null;
          }
          if (typeof window !== "undefined") {
            data[0].content.rendered = this.replaceAmazingLinks(data[0].content.rendered);
          }
          if (data[0]._embedded["wp:featuredmedia"]) {
            data[0].featured_image_url = data[0]._embedded["wp:featuredmedia"][0].source_url;
          }
          return data[0]; // API returns an array of one
        }),
        catchError(this.handleError)
      );
  }


  // Get paginated news posts.
  getLatestPosts(page: number = 1, per_page: number = 5, compact: boolean = false, skip_loading:boolean = false, show_id?:string): Observable<PostsEnv> {
    // /wp/v2/posts
    let params_data = new HttpParams();
    params_data = params_data.set('_embed', 'true');
    params_data = params_data.set('order', 'desc');
    params_data = params_data.set('orderby', 'date');
    params_data = params_data.set('per_page', per_page.toString());
    params_data = params_data.set('page', page.toString());
    params_data = params_data.set('_envelope', 'true'); // returns data in { body: posts[], status:xxx, headers: { X-WP-Total:n, X-WP-TotalPages:N }}
    if(skip_loading){
      params_data = params_data.set('skip_loading', 'true');
    }

    if (compact) {
      // Removes post->content from responses.
      params_data = params_data.set('_compact', 'true');
    }
    //
    let endpoint = '/amazingradio/v1/news';
    if(show_id){
      endpoint += '/show/'+show_id;
    }
    // console.log('News posts endpoint', BASE_URI + endpoint);

    // return this.http.get<PostsEnv>(BASE_URI + '/wp/v2/posts', { params: params_data })
    return this.http.get<PostsEnv>(BASE_URI + endpoint, { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('%cgetLatestPosts: ', 'color:yellow',data, show_id);
        // }),
        map(data => {
          if (data === undefined) {
            return null;
          }
          if(!Array.isArray(data.body)){
            console.log('WP error? No Post items?', BASE_URI + endpoint);
            return null;
          }
          // console.log('Show posts API URL:', BASE_URI + endpoint);
          data.body.forEach(item => {
            delete item._links;
            if(!item.featured_image_url){
              if (item._embedded && item?._embedded['wp:featuredmedia']) {
                item.featured_image_url = item._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url;
              } else {
                item.featured_image_url = '/assets/placeholders/placeholder-square-300.jpg';
              }
            }

            if (item._embedded && item._embedded['wp:term']) {
              item._main_category = {
                name: item._embedded['wp:term'][0][0].name,
                slug: item._embedded['wp:term'][0][0].slug,
              }
            }
            // Removes all hostnames and paths from WP post link urls and replace with /news/...
            // Might even be IE friendly, unlike the `new URL(..)` method.
            if (typeof window !== "undefined") { // SSR trap
              const parser = document.createElement('a');
              parser.href = item.link;
              let pathname = parser.pathname;
              item.link = pathname.split('/').pop();
              if(item.show_id){
                item.link = '/shows/' + item.show_id + '/features/' + item.link;
              } else {
                item.link = '/news/' + item.link;
              }

              if(item.is_routerlink){
                item.link = item.routerlink_path;
              }

            }
            // console.log('link:', item.link);
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }

  // getCompetitions(): Observable<Post[]> {
  //   let params_data = new HttpParams();
  //   params_data = params_data.set('_embed', 'true');
  //   params_data = params_data.set('order', 'desc');
  //   params_data = params_data.set('orderby', 'date');
  //   params_data = params_data.set('per_page', '6');
  //   params_data = params_data.set('page', '1');
  //   params_data = params_data.set('_angular2', 'true');
  //   params_data = params_data.set('filter[taxonomy]', 'category');
  //   params_data = params_data.set('filter[term]', 'open-competition');

  //   const _now = new Date();
  //   return this.http.get<Post[]>(BASE_URI + '/wp/v2/competitions', { params: params_data })
  //     .pipe(
  //       map(data => {

  //         data.forEach(item => {
  //           if (item._embedded['wp:featuredmedia']) {
  //             item.featured_image_url = item._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url;
  //           } else {
  //             item.featured_image_url = '/assets/icons/defaultImage.png';
  //           }
  //           const link_url = new URL(item.link);
  //           item.link = item.link.split(link_url.origin).join('');
  //           //item.link = item.link.split(environment.wp_home_host).join('');
  //           //item.link = item.link.split('https://amazingradio.com').join('');
  //         });
  //         for (let i = 0; i < data.length; i++) {
  //           // Some expired compos still had 'open-competition' as the category! So, just in case..
  //           const _expiry = new Date(data[i].competition_expiry_date);
  //           if (_now > _expiry) {
  //             data.splice(i, 1);
  //           }
  //         }
  //         return data;
  //       }),

  //       catchError(this.handleError)
  //     );
  // }

  // // Rewinds (featured media)
  // getFeaturedMedia(skip_loading: boolean = false): Observable<FeaturedMediaItem> {
  //   let params_data = new HttpParams();
  //   if (skip_loading) {
  //     params_data = params_data.set('skip_loading', 'true');
  //   }
  //   return this.http.get<FeaturedMediaItem>(BASE_URI + '/amazingradio/v1/featured-media', { params: params_data })
  //     .pipe(
  //       map(
  //         data => {
  //           return data[0]; // only return the first
  //         }
  //       ),
  //       catchError(this.handleError)
  //     );
  // }

  // Home page Featured News
  // '/amazingradio/v1/home-featured'
  // getHomeFeatured(page: number = 1, per_page: number = 2, before: any = null): Observable<[HomeFeatured]> {
  //   let params_data = new HttpParams();
  //   params_data = params_data.set('page', page.toString());
  //   params_data = params_data.set('per_page', per_page.toString());
  //   return this.http.get<[HomeFeatured]>(BASE_URI + '/amazingradio/v1/home-featured', { params: params_data })
  //     .pipe(
  //       map(data => {
  //         data.forEach(item => {
  //           // Remove host
  //           const link_url = new URL(item.link);
  //           item.link = item.link.split(link_url.origin).join('');
  //           //item.link = item.link.split(environment.wp_home_host).join('');
  //           //item.link = item.link.split('https://amazingradio.com').join('');
  //           // Fix (previously 'fixed' by the amazingradio-api-v2 plugin in the amazingradio_post_link filter) links to /sessions/.. so they don't go to the new Sessions route.
  //           if (item.link.includes('/sessions/')) {
  //             item.link = item.link.split('/sessions/').join('/sessions_post/');
  //           }
  //         });
  //         return data;
  //       }),
  //       catchError(this.handleError)
  //     );
  // }

  // Home page Latest News
  // '/amazingradio/v1/home-news'
  getHomeNews(page: number = 1, per_page: number = 8, before: any = null): Observable<HomeNews[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('page', page.toString());
    params_data = params_data.set('per_page', per_page.toString());
    return this.http.get<HomeNews[]>(BASE_URI + '/amazingradio/v1/home-news', { params: params_data })
      .pipe(
        tap(data => {

          console.log('getHomeNews:', data);

        }),
        map(data => {
          data.forEach(item => {
            // Remove host
            const link_url = new URL(item.link);
            item.link = item.link.split(link_url.origin).join('');
            // item.link = item.link.split(environment.wp_home_host).join('');
            // item.link = item.link.split('https://amazingradio.com').join('');
            if (item.link.includes('/sessions/')) {
              item.link = item.link.split('/sessions/').join('/sessions_post/');
            }
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }

  // Shows.
  getScheduleShow(slug: string): Observable<Show> {
    return this.api_schedule.getShow(slug);
  }

  getShowPosts(category: string, page: number = 1, per_page: number = 8): Observable<Post[]> {
    let params_data = new HttpParams();
    params_data = params_data.set('_embed', 'true');
    // params_data = params_data.set('skip_loading', 'true'); // Don't trigger loading indicator
    params_data = params_data.set('order', 'desc');
    params_data = params_data.set('orderby', 'date');
    // For shows with show_posts_category, otherwise this returns all latest posts.
    if (category) {
      params_data = params_data.set('filter[taxonomy]', 'category');
      params_data = params_data.set('filter[term]', category);
    }
    params_data = params_data.set('page', page.toString());
    params_data = params_data.set('per_page', per_page.toString());

    return this.http.get<Post[]>(BASE_URI + '/wp/v2/posts', { params: params_data })
      .pipe(
        map(data => {
          // if (environment.wp_channel === 'us') {
          //   return [];
          // }
          // transform the post data...
          data.forEach(post => {
            // console.log(post);
            // link relative to this site, since the API has them hard-coded
            post.link = post.link.split(HOME_HOST).join('');
            // provide fallback when 'medium' size is not available. (use 'thumbnail')
            post.featured_image_url = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : post._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url;
            // and more...? Maybe make a separate 'PostSlim' type without all the gubbins.
            return post;
          });
          // console.log('getShowPosts (WP) [category]', category, data);
          return data;
        }),
        catchError(this.handleError)
      );
  }

  // Deprecated. moved to Schedule API
  // getShow(slug: string, preview_id?: string, preview_nonce?: string): Observable<ShowWP> {
  //   let params_data = new HttpParams();
  //   params_data = params_data.set('_embed', 'true');
  //   params_data = params_data.set('slug', slug);
  //   let endpoint = BASE_URI + '/wp/v2/shows';
  //   if (preview_id && preview_nonce) {
  //     endpoint += '/' + preview_id;
  //     params_data = params_data.set('_wpnonce', preview_nonce);
  //   }
  //   // params_data = params_data.set('skip_loading', 'true');
  //   return this.http.get<ShowWP>(endpoint, { params: params_data })
  //     .pipe(
  //       map(data => {
  //         if (environment.wp_channel === 'us') {
  //           return {};
  //         }
  //         // for some reason not all show_presenterImage values are full urls?? eg: Amazing Afternoons has it. Audition doesn't ?
  //         if (data[0].show_presenterImage && !data[0].show_presenterImage.includes('amazingradio.com')) {
  //           data[0].show_presenterImage = API_HOST + '/' + data[0].show_presenterImage;
  //         }
  //         return data[0];
  //       }),
  //       catchError(this.handleError)
  //     );
  // }

  ////////////// Sessions
  // Get individual Session post (and parse embedded players)
  getSession(slug: string, id?: number, preview_id?: string, preview_nonce?: string): Observable<Post> {
    // override BASE_URI to set UK WP for all sites
    BASE_URI = environment.wp.uk.api_endpoint;
    let params_data = new HttpParams();
    params_data = params_data.set('slug', slug);
    params_data = params_data.set('_embed', 'true');
    params_data = params_data.set('_angular2', 'true'); // Provides custom elements for player embed shortcodes..
    return this.http.get<Post>(BASE_URI + '/wp/v2/amazing-sessions', { params: params_data })
      .pipe(
        map(data => {
          //console.log('session: ', data);
          if (typeof window !== "undefined") {
            data[0].content.rendered = this.replaceAmazingLinks(data[0].content.rendered);
          }
          if (data[0]._embedded['wp:featuredmedia']) {
            data[0].featured_image_url = data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : (data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail ? data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url : '');
          } else {
            data[0].featured_image_url = '/assets/icons/defaultImage.png';
          }
          // featured_image_url
          return data[0]; // API returns an array of one
        }),
        catchError(this.handleError)
      );
  }

  // Used for block list of 6 latest sessions.
  getLatestSessions(count: number = 6, exclude: number = null): Observable<Post[]> {
    // override BASE_URI to set UK WP for all sites
    BASE_URI = environment.wp.uk.api_endpoint;
    // /amazingradio/v1/amazing-sessions
    let params_data = new HttpParams();
    params_data = params_data.set('_embed', 'true');
    params_data = params_data.set('_angular2', 'true');
    params_data = params_data.set('_compact', 'true');
    params_data = params_data.set('order', 'desc');
    params_data = params_data.set('orderby', 'date');
    params_data = params_data.set('per_page', count.toString());
    params_data = params_data.set('page', '1');
    params_data = params_data.set('skip_loading', 'true');
    if (exclude) {
      // console.log('getLatestSessions excluding: ', exclude);
      params_data = params_data.set('exclude', exclude.toString());
    }
    return this.http.get<Post[]>(BASE_URI + '/amazingradio/v1/amazing-sessions', { params: params_data })
      .pipe(
        map(data => {
          data.forEach(item => {
            delete item._links;
            if (item._embedded['wp:featuredmedia']) {
              item.featured_image_url = item._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : (item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail ? item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url : '');
            } else {
              item.featured_image_url = '/assets/icons/defaultImage.png';
            }
            // Remove host link
            const link_url = new URL(item.link);
            item.link = item.link.split(link_url.origin).join('');
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }
  //  Get all available years
  getSessionYears(): Observable<SessionYear[]> {
    let params_data = new HttpParams();
    // override BASE_URI to set UK WP for all sites
    BASE_URI = environment.wp.uk.api_endpoint;
    params_data = params_data.set('per_page', '20');
    return this.http.get<[SessionYear]>(BASE_URI + '/wp/v2/sessions_year', { params: params_data})
      .pipe(
        map(data => {
          return data.reverse();
        }),
        catchError(this.handleError)
      );
  }
  // Get session for year.
  getSessionsForYear(year: string, page: number = 1): Observable<Post[]> {
    // override BASE_URI to set UK WP for all sites
    BASE_URI = environment.wp.uk.api_endpoint;
    // /amazingradio/v1/amazing-sessions?year=2012
    let params_data = new HttpParams();
    params_data = params_data.set('_angular2', 'true');
    params_data = params_data.set('year', year);
    params_data = params_data.set('_embed', 'true');
    params_data = params_data.set('per_page', '100');
    params_data = params_data.set('page', page.toString());

    return this.http.get<Post[]>(BASE_URI + '/amazingradio/v1/amazing-sessions', { params: params_data })
      .pipe(
        map(data => {
          data.forEach(item => {
            //console.log('session: ', item);
            // if (item._embedded['wp:featuredmedia'][0].media_details) {
            //   console.log('media details seem ok...');
            // } else {
            //   console.log('%cNO MEDIA DETAILS?', 'color:yellow', item);
            // }
            delete item.meta;
            delete item.sessions_year;
            delete item._links;
            if (item._embedded['wp:featuredmedia'] && item._embedded['wp:featuredmedia'][0] && item._embedded['wp:featuredmedia'][0].media_details) {
              item.featured_image_url = item._embedded['wp:featuredmedia'][0].media_details.sizes.medium ? item._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url : item._embedded['wp:featuredmedia'][0].source_url;
            } else {
              item.featured_image_url = '/assets/icons/defaultImage.png';
            }
            // Remove host from link
            const link_url = new URL(item.link);
            item.link = item.link.split(link_url.origin).join('')
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }
  ///////


  ////// Tips
  // Get all Tips years.
  getTipsYears(): Observable<TipsYear[]> {
    // /amazingradio/v1/tips_years
    return this.http.get<TipsYear[]>(BASE_URI + '/amazingradio/v1/tips_years')
      .pipe(
        // tap(data => {
        //   console.log('%cTIP_YEARS', 'color:yellow', data);
        // }),
        catchError(this.handleError)
      );
  }
  // Get content and list of tipped artists for the provided year
  getTipsForYear(year: number): Observable<TipsYear> {
    // /amazingradio/v1/tipsfor[YYYY]
    let params_data = new HttpParams();
    params_data = params_data.set('_angular2', 'true');
    params_data = params_data.set('_compact', 'true');

    return this.http.get<TipsYear>(BASE_URI + '/amazingradio/v1/tipsfor' + year, { params: params_data } )
      .pipe(
        map(data => {
          data.tips.forEach(tip => {
            // Remove host link
            const link_url = new URL(tip.link);
            tip.link = tip.link.split(link_url.origin).join('');
          });
          return data;
        }),
        catchError(this.handleError)
      );
  }
  // Get tipped artist by slug
  getTippedArtist(tipped_artist_slug: string): Observable<TippedArtist> {
    // /wp/v2/tips?slug=[SLUG]
    let params_data = new HttpParams();
    params_data = params_data.set('slug', tipped_artist_slug);
    params_data = params_data.set('_embed', 'true'); // _embeds term (year) data.
    params_data = params_data.set('_angular2', 'true');

    return this.http.get<TippedArtist>(BASE_URI + '/wp/v2/tips', { params: params_data })
      .pipe(
        map(data => {
          // console.log('ARTIST: ', data);
          if (typeof window !== "undefined") {
            data[0].content.rendered = this.replaceAmazingLinks(data[0].content.rendered);
          }
          let artist = data[0];

          artist.year_data = artist['_embedded']['wp:term'][0][0];
          delete artist['_embedded'];
          return artist;
        }),
        catchError(this.handleError)
      );
  }

  // # Playlist polls
  // Latest Active audition poll
  // : /wp-json/amazingradio/v1/playlist-polls/audition

  getLatestActiveAuditionPoll(): Observable<PlaylistPoll> {
    let params_data = new HttpParams();
    params_data = params_data.set('ngsw-bypass', 'true');

    // For the US site, we need to access the UK Wordpress instance for Audition.
    let WP_ENDPOINT = BASE_URI;
    if(environment.site_country === 'us'){
      WP_ENDPOINT = environment.wp.uk.api_endpoint;
    }
    return this.http.get<any>(WP_ENDPOINT + '/amazingradio/v1/playlist-polls/audition', { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('Tapped Latest Audition: ', data[0]);
        // }),
        map(data => {
          return data[0];
        }),
        catchError(this.handleError)
      );
  }

  getPlaylistPoll(id: number): Observable<PlaylistPoll> {

    let WP_ENDPOINT = BASE_URI;
    if(environment.site_country === 'us'){
      WP_ENDPOINT = environment.wp.uk.api_endpoint;
    }

    let params_data = new HttpParams();
    params_data = params_data.set('ngsw-bypass', 'true');
    return this.http.get<PlaylistPoll>(WP_ENDPOINT + '/wp/v2/playlist-polls/' + id, { params: params_data })
      .pipe(
        catchError(this.handleError)
      );
  }

  pollVote(poll_data:any): Observable<PlaylistPoll>{

    // Note: This endpoint uses 'api_host' to access /wp-admin/...
    let WP_ENDPOINT = API_HOST;
    // if(environment.site_country === 'us'){
    //   WP_ENDPOINT = environment.wp.uk.api_host;
    // }
    const polling_endpoint = WP_ENDPOINT + '/wp-admin/admin-ajax.php?action=ppollvote';
    return this.http.post<any>(polling_endpoint, poll_data ).pipe(
      map(data => {
        return data;
      })
    )
  }

  // Replace host of image URLs in the given content string
  // with the host of our Amazon CloudFront CDN host
  cdnifyImages(content) {
    if (!environment.cdn) { return content; }
    return (content || '').replace(new RegExp(environment.cdn.find, 'g'), environment.cdn.replace);
  }

  // This may need further improvement due to the variations in how links have been added in WP Admin.
  // .. And particularly when styling tags like <em> and <strong? have been added 'within' and 'outside' the a href links.
  // which can make regex replacement a bit painful.
  replaceAmazingLinks(html) {

    if (typeof window === "undefined") {
      // console.log('replaceAmazingLinks. SSR no document..');
      return html;
    }

    // console.log('Post HTML: ', html);
    let el = document.createElement('div');
    el.innerHTML = html;
    let links = el.getElementsByTagName('a');

    for (let i = 0; i < links.length; i++) {
      let _url = links[i].href;
      let _internalRouterLink: boolean = false;
      let _classNames = "";

      if (links[i].className) {
        _classNames = "classNames='" + links[i].className + "'";
        if (_classNames.includes('routerLink') || _classNames.includes('routerlink')) {
          _internalRouterLink = true;
        }
      }
      // console.log('links _url: ', _url, _classNames);

      // Not SSR.
      if (isPlatformBrowser(this.platformId)) {
        _url = _url.split(location.protocol + '//' + location.host).join('');
      }

      if (_internalRouterLink) {
        //console.log('INTERNAL ROUTER LINK: ', _url);
        const _outer = links[i].outerHTML;
        const _text = links[i].innerHTML;
        const _encText = this.toHtmlEntities(_text);
        const _encoded_outer = _outer.replace(_text, _encText);
        const router_link = "<router-link  _class='" + links[i].className + "' text='" + _text + "' path='" + _url + "'>" + _text + "</router-link>";
        // console.log('%c'+router_link, 'color:yellow;font-size:14px');
        const outerRegex = new RegExp(_encoded_outer, 'g');
        html = html.replace(outerRegex, router_link);

      }

      if (_url.includes('/profile/')) {
        //console.log('PROFILE LINK', _classNames, _url);
        //console.log(links[i]);
        // const _outer = links[i].outerHTML;
        const _text = links[i].innerHTML;
        const path = links[i].href.split('/profile/')[1];
        //console.log('TEXT', _text);
        //console.log('PROFILE PATH', path);
        // console.log('CHILDNODES...: ', links[i].childNodes);
        // console.log('PATH: ', path);
        const router_link = "<router-link " + _classNames + " text='" + _text + "' path='/profile/" + path + "'>" + _text + "</router-link>";
        //console.log('ROUTER-LINK', router_link);
        // We need to build the link again to match any potential HTML entities within the link tag, which breaks the replacement.
        // console.log('OUTER: ', _outer);
        const _encoded_outer = '<a href="' + links[i].href +'">'+ this.toHtmlEntities(_text) +'</a>';
        // console.log('ENC. OUTER: ', _encoded_outer);
        // console.log('replace with:', router_link);
        const enc_outerRegex = new RegExp(_encoded_outer, 'g');
        html = html.replace(enc_outerRegex, router_link);

      } else if (_url.includes('/shows/')) {
        // console.warn('AMAZINGRADIO SHOW!');
        // console.log(_url, links[i]);
        // const _outer = links[i].outerHTML;
        const _text = links[i].innerHTML;
        const path = links[i].href.split('/shows/')[1];
        const router_link = "<router-link text='" + _text + "' path='/shows/" + path + "'>" + _text + "</router-link>";
        // console.log('replace: ', router_link);
        const _encoded_outer = '<a href="' + links[i].href +'">'+ this.toHtmlEntities(_text) +'</a>';
        // console.log('ENC. OUTER: ', _encoded_outer);
        const outerRegex = new RegExp(_encoded_outer, 'g');
        html = html.replace(outerRegex, router_link);

      } else if (_url.includes('//amazingtunes.com/')) {
        // console.warn('AMAZINGTUNES!');
        // console.log(_url, links[i]);
        // const _outer = links[i].outerHTML;
        const _text = links[i].innerHTML;
        let path = links[i].href.split('//amazingtunes.com/')[1];

        let slashes = path.split('/');
        if (slashes.length === 2) {
          // must be a label/artist url.
          // console.log('label/artist');
          // convert to label-artist format.
          path = slashes.join('-');
          // HMM ... try just the artist permalink.
          // path = slashes[1];
        }
        const router_link = "<router-link text='" + _text + "' path='/profile/" + path + "'>" + _text + "</router-link>";
        // console.log('replace: ', router_link);
        const _encoded_outer = '<a href="' + links[i].href +'">'+ this.toHtmlEntities(_text) +'</a>';
        // console.log('ENC. OUTER: ', _encoded_outer);
        const outerRegex = new RegExp(_encoded_outer, 'g');
        html = html.replace(outerRegex, router_link);

      } else if (_url.includes('amazingradio.com/home/')) {
        // console.warn('AMAZINGRADIO /HOME/ POST!');
        // console.log(_url, links[i]);
        // const _outer = links[i].outerHTML;
        const _text = links[i].innerHTML;
        const path = links[i].href.split('amazingradio.com/home/')[1];
        // Link to /news/... instead
        const router_link = "<router-link text='" + _text + "' path='/news/" + path + "'>" + _text + "</router-link>";
        // console.log('replace: ', router_link);
        const _encoded_outer = '<a href="' + links[i].href +'">'+ this.toHtmlEntities(_text) +'</a>';
        // console.log('ENC. OUTER: ', _encoded_outer);
        const outerRegex = new RegExp(_encoded_outer, 'g');
        html = html.replace(outerRegex, router_link);

      } else if(_url.includes('amazingradio.')){

        // console.log('%cUNCAUGHT amazingradio.* LINK: ', 'color:lime' ,_url);
        // console.log('Post HTML: ', html);
        // console.log('THE LINK: ', links[i]);
        // console.log('THE LINK inner: ', links[i].innerText);
        // if(links[i].innerText === links[i].innerHTML){
        //   console.log('INNERTEXT === INNERHTML');
        // }
        // console.log('OUTER: ', links[i].outerHTML);
        let _path = links[i].href.split('amazingradio.')[1];
        let _paths = _path.split('/');
        _paths.shift();
        _path = '/'+_paths.join('/');
        const _text = links[i].innerHTML;
        const router_link = "<router-link text='" + _text + "' path='" + _path + "'>" + _text + "</router-link>";
        const _encoded_outer = '<a href="' + links[i].href +'">'+ this.decodeHtmlEntity(_text) +'</a>';
        let outer_link_split = _encoded_outer.split('>');
        let link_text = outer_link_split[1].split('<')[0];
        // console.log('outer_link_split', outer_link_split);
        // console.log('link_text', link_text);
        // console.log('link_text to entities', this.toHtmlEntities(link_text));
        const outerRegex = new RegExp(_encoded_outer, 'g');
        // html = html.replace(outerRegex, router_link);
      }
    }
    // console.log('HTML:', html)
    return html;
  }

  decodeHtmlEntity = function (str: string) {
    return str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
  };

  toHtmlEntities = function (str) {
    // console.log('convert: ', str);
    return str.replace(/./gm, function (s) {
      // return "&#" + s.charCodeAt(0) + ";";
      // need to preserve -, <, >, &, ., : and /
      const _converted = (s.match(/^[\w\s.&;<>\/\-]+$/i)) ? s : "&#" + s.charCodeAt(0) + ";"
      // console.log('converted: ', _converted);
      return _converted;
    });
  };

  // Common error handler
  private handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) { // NOT SSR
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        console.error('A client-side or network error occurred:', error.error.message);
        // return an observable with a user-facing error message
        return throwError({ status: -1, message: error.error.message });
      } else {
        // The backend returned an unsuccessful response code.
        console.log('ERROR: ', error);
        let error_message = error.message;
        if (error.error && error.error.errors) {
          error_message = error.error.errors[0].title;
        }
        return throwError({ status: error.status, message: error_message });
      }
    } else {
      console.log('API-WP ERROR: ', error);
      let error_message = error.message;
      if (error.error && error.error.errors) {
        error_message = error.error.errors[0].title;
      }
      return throwError({ status: error.status, message: error_message });
    }
  };
}

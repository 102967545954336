<div class="cont">
  <form #videoForm="ngForm" novalidate (submit)="addVideo($event)">
    <mat-form-field appearance="outline" class="ytUrl">
      <mat-label>Enter a YouTube video URL:</mat-label>
      <input required matInput #ytUrl="ngModel" name="ytUrl" [(ngModel)]="addVideoForm.url" ngModel>
    </mat-form-field>
    <input [disabled]="videoForm.invalid" class="btn yellow" type="submit" value="Add YouTube Video">
  </form>
  <div class="message">Note: YouTube videos are not eligible for Amazing TV.</div>
</div>

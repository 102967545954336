<!-- Hero Carousel -->
<div class="hero-carousel-cont" (mouseover)="onMouseOver()" (mouseleave)="onMouseOut()">
  <div class="hero-carousel" [class]="(heroes[heroes_index].router_link ? 'has-link':'') + (with_x_large_margin ? ' with-x-large-margin':'')" [routerLink]="heroes[heroes_index]?.router_link">

    <div class="hero-image-cont" aria-hidden="true" [class]="heroes[heroes_index].custom_class ? heroes[heroes_index].custom_class:''">
      <div class="hero-image-parent">
        <img [lazyImgSrc]="heroes[heroes_index].image"  class="hero-image" [class]="anim_image_faded_in ? 'fade-in':'fade-out'" src="/assets/placeholders/placeholder-home-show-dark.png">
      </div>
    </div>

    <div class="hero-text-cont">
      <div class="hero-title-cont">
        <div class="hero-title" [class]="anim_title_zoomed_in ? 'zoom-in':'zoom-out'">
          <div class="hero-title-text" [innerHTML]="heroes[heroes_index].title"></div>
          <div class="hero-subtitle-text" [innerHTML]="heroes[heroes_index].subtitle"></div>
          <div *ngIf="heroes[heroes_index].show_button" class="hero-play-btn">
            <mat-icon inline="true" *ngIf="!heroes[heroes_index].is_radio_button">play_circle_outline</mat-icon>
            <app-radio-play-btn *ngIf="heroes[heroes_index].is_radio_button" [show_levels]="true" [show_listen_live_label]="false"></app-radio-play-btn>
          </div>
          <div *ngIf="heroes[heroes_index].custom_label_text">
            <div class="custom-label label large yellow" [innerText]="heroes[heroes_index].custom_label_text"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="cont">
  <div *ngIf="playlists.length > 0" class="listCont">
    <h6>Add to existing Playlist</h6>

    <mat-selection-list [multiple]="false" (selectionChange)="selected($event)">
      <mat-list-option *ngFor="let playlist of playlists" [value]="playlist">
        <div class="option">
          <div class="name" [innerHTML]="playlist.attributes.name"></div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div class="newCont">
    <h6>Add to New Playlist</h6>
    <form class="newForm" novalidate (submit)="addPlaylist()">
      <mat-form-field appearance="outline" class="newName">
        <mat-label>Enter New Playlist Name</mat-label>
        <input matInput name="new_playlist_name" [(ngModel)]="new_playlist_name" ngModel>
      </mat-form-field>
      <div class="inputIconCont">
        <mat-icon (click)="addPlaylist()" inline="true" class="add">add_circle</mat-icon>
      </div>
    </form>
  </div>

</div>

<!-- Cookie Bar -->
<app-cookie-bar *ngIf="show_ui && !is_server"></app-cookie-bar>

<!-- Header -->
<app-header [is_side_nav_open]="is_side_nav_open" [show_burger]="force_side_nav_closed" *ngIf="show_ui"></app-header>

<app-loading-indicator [force_side_nav_closed]="force_side_nav_closed" [hidden]="!loading || is_server">
</app-loading-indicator>

<!-- temporary while building new nav menu.. -->

<!--removed since Chrome warning. [attr.aria-hidden]="is_side_nav_open ? false:force_side_nav_closed ? true : true" -->

<div *ngIf="show_ui" class="sideNavContainer" role="navigation"
  [class]="is_side_nav_open ? 'opened':force_side_nav_closed ? 'hidden-menu':null">
  <mat-accordion [multi]="!isMobile()" class="mat-accordion" #menuAccordion displayMode="flat">
    <mat-expansion-panel #panelGetNoticed [expanded]="menu_panels.get_noticed.open">
      <mat-expansion-panel-header class="menu-panel-header">
        <mat-panel-title class="menu-title-get-noticed" title="Get Noticed">
          Get Noticed
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>

        <!-- Store link - here for now -->
        <div role="link" title="Visit the Amazing Music Store" class="menu-option"
          (click)="breakpoints.is_sidenav ? toggleSideNav() : scrollToTop()" routerLink="/store" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="benefits">Amazing Music Store</span>
        </div>

        <!-- Will need to act differently for logged in users, Artists and particularly Labels. -->
        <!-- Naturally just links to the sign up page for non-logged in users. -->
        <div *ngIf="!logged_in_user" role="link" class="menu-option" title="Amazing Benefits"
          (click)="breakpoints.is_sidenav ? toggleSideNav() : null" routerLink="/benefits" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <span class="benefits">Amazing Benefits</span>
        </div>

        <div role="link" *ngIf="can_see_upgrade_btn" title="Go Amazing" class="menu-option" routerLink="/benefits">
          <span>Upgrade Now</span>
        </div>

        <div *ngIf="!logged_in_user" role="link" class="menu-option" title="Create An Account"
          (click)="breakpoints.is_sidenav ? toggleSideNav() : null" routerLink="/connect/signup"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span class="account">Create An Account</span>
        </div>

        <div *ngIf="logged_in_user" role="link" title="My Profile" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed ? toggleSideNav() : null"
          [routerLink]="['/profile', logged_in_user.attributes.permalink]" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <span>My Profile</span>
        </div>

        <div role="link" *ngIf="logged_in_user?._capabilities?.can_upload" title="Upload Tune" class="menu-option"
          (click)="uploadResource('tunes')">
          <span>Upload Tune</span>
        </div>

        <div role="link" *ngIf="logged_in_user?._capabilities?.can_upload && !logged_in_user?.meta.is_subscription_required" title="Upload Video" class="menu-option"
          (click)="uploadResource('videos')">
          <span>Upload Video</span>
        </div>

        <div *ngIf="!logged_in_user; else logOut" role="link" title="Log In" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/connect"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Log In</span>
        </div>
      </ng-template>

      <ng-template #logOut>
        <div role="link" title="Log Out" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/connect/logout">
          <span>Log Out</span>
        </div>
      </ng-template>

    </mat-expansion-panel>
    <mat-expansion-panel #panelListenNow [expanded]="menu_panels.listen_now.open">
      <mat-expansion-panel-header class="menu-panel-header">
        <mat-panel-title class="menu-title-listen-now" title="Listen Now">
          Listen Now
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div title="Listen Live to Amazing Radio" class="menu-option on-air-now"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/onair"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>On Air Now</span>
          <div class="audio-levels" [class]="radio_playing ? 'playing':'not-playing'">
            <div class="bar1" [class]="radio_playing ? '':'stopped'"></div>
            <div class="bar2" [class]="radio_playing ? '':'stopped'"></div>
            <div class="bar3" [class]="radio_playing ? '':'stopped'"></div>
          </div>
        </div>
        <div title="Recently Played Tunes On Air" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/aired"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>Recently Played</span>
        </div>
        <div title="Check out our weekly Amazing Chart" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/chart"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>The Amazing Chart</span>
        </div>
        <div title="Non-stop New Music Streams By Genre" class="menu-option genre-streams"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null"
          routerLink="/genre-streams" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>Genre Streams</span>
          <div class="audio-levels" [class]="genre_stream_playing ? 'playing':'not-playing'">
            <div class="bar1" [class]="genre_stream_playing ? '':'stopped'"></div>
            <div class="bar2" [class]="genre_stream_playing ? '':'stopped'"></div>
            <div class="bar3" [class]="genre_stream_playing ? '':'stopped'"></div>
          </div>
        </div>
        <div title="Radio Playlists" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/playlist"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Playlist</span>
        </div>
        <div title="All our Radio Shows" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/shows"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>Shows</span>
        </div>
        <div title="This Week's Radio Show Schedule" class="menu-option"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/schedule"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Schedule</span>
        </div>

      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel #panelExplore [expanded]="menu_panels.explore.open" accordion="accordion">
      <mat-expansion-panel-header class="menu-panel-header">
        <mat-panel-title class="menu-title-explore" title="Explore">
          Explore
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="menu-option" title="First Spin"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/firstspin"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>First Spin</span>
        </div>
        <div class="menu-option" title="First Look"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/firstlook"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>First Look</span>
        </div>
        <div class="menu-option" title="Featured Playlists"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null"
          routerLink="/playlists/featured" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Featured Playlists</span>
        </div>
        <div class="menu-option" title="The Amazing Sessions"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/sessions"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">
          <span>The Amazing Sessions</span>
        </div>
        <div class="menu-option" title="Tips For The year" (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/tips" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Tips for the year</span>
        </div>
        <div class="menu-option" title="Hall of Fame"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/halloffame"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Hall of Fame</span>
        </div>
        <div class="menu-option" title="Amazing Artist Testimonials"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/testimonials"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Testimonials</span>
        </div>
        <div class="menu-option search" title="Search"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null" routerLink="/search"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <span>Search</span>
        </div>

        <div title="Check out AmazingTV" role="link" class="menu-option radio-link"
        (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null">
        <span class="radio-link"><a target="_blank" href="https://thisisamazing.com">
            <mat-icon inline="true">launch</mat-icon> AMAZING TV
          </a></span>
        </div>

        <div title="Check out Amazing Songs" role="link" class="menu-option radio-link"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null">
          <span class="radio-link"><a target="_blank" href="https://amazing-songs.com">
              <mat-icon inline="true">launch</mat-icon> AMAZING SONGS
            </a></span>
        </div>

        <div title="Check out the CMJ Music Marathon" class="menu-option cmj"
          (click)="breakpoints.is_sidenav || force_side_nav_closed  ? toggleSideNav() : null">
          <span class="cmj"><a target="_blank" href="https://cmj.com">
              <mat-icon inline="true">launch</mat-icon> CMJ MUSIC MARATHON
            </a></span>
        </div>
        <br>
        <br>

      </ng-template>
    </mat-expansion-panel>

  </mat-accordion>

  <div (click)="closeMenu()" [title]="(force_side_nav_closed ? 'Expand ':'Collapse ') + 'side menu'"
    class="menu-option menu-control" [innerHTML]="force_side_nav_closed ? 'menu ❯❯':'❮❮ menu'"></div>

</div>
<!-- underlay for sidenav when opened -->
<div *ngIf="show_ui" (click)="toggleSideNav($event)" aria-hidden="false" role="button" aria-label="Close Menu"
  class="sideNavUnderlay" [class]="is_side_nav_open ? 'opened':null">
</div>


<!-- When show_ui is false, this is for the /oembed/... routes where the main UI needs to be hidden -->
<div class="mainRouterOutletContainer" [class]="!show_ui || force_side_nav_closed ? 'hide-nav':null ">
  <router-outlet></router-outlet>
  <!-- Footer -->
  <app-footer *ngIf="show_ui"></app-footer>

</div>

<!-- Fixed Player -->
<app-player *ngIf="show_ui"></app-player>

<!-- icon mask : circle with side chopped off. Usage: clip-path:url(#icon-mask);  -->
<svg height="0" width="0">
  <defs>
    <clipPath id="icon-mask" clipPathUnits="objectBoundingBox">
      <path d="M0.5,1c0.1,0,0.2,0,0.3-0.1V0.1C0.7,0,0.6,0,0.5,0C0.2,0,0,0.2,0,0.5C0,0.8,0.2,1,0.5,1z"/>
    </clipPath>
  </defs>
</svg>

import { Component, OnInit, OnChanges } from '@angular/core';
import { LatestPostsService } from '@services/latest-posts.service';
import { Post } from '@models/wp-post';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-list-block',
  templateUrl: './post-list-block.component.html',
  styleUrls: ['./post-list-block.component.scss'],
  inputs: ['exclude_slug']
})
export class PostListBlockComponent implements OnInit, OnChanges {

  private posts:Post[];
  public latest_posts: Post[];
  public exclude_slug: string;
  public hide_show:boolean;

  constructor(private router:Router, private latestPostsService: LatestPostsService ) {

  }

  ngOnChanges():void {
    if(this.posts){
      this.setPosts();
    }
  }

  ngOnInit(): void {

    this.latestPostsService.getLatestPosts().subscribe(data => {
      if(data){
        this.posts = data;
        // console.log('Posts list: ', data);
        this.setPosts();
      }
    });

  }

  setPosts(){

    this.latest_posts = this.posts.filter(item => {
      // Remove the post if it's the same as the post page it's on.
      return item.slug !== this.exclude_slug;
    });

  }

  clicked(post:Post){
    if(post.is_routerlink && post.routerlink_path) {
      this.router.navigateByUrl(post.routerlink_path);
    } else if(post.link_override && !post.is_routerlink){
      if(typeof window !== 'undefined'){
         window.open(post.link_override, '_blank');
      }
    } else {
      this.router.navigateByUrl(post.link);
    }
  }
}

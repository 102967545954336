import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, HostListener, Inject, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Platform } from '@angular/cdk/platform'; // for mobile browser detection
import { DOCUMENT } from '@angular/common';
import Hls, { Level } from 'hls.js';
// https://github.com/video-dev/hls.js/blob/master/docs/API.md
import { Video } from '@models/video-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { HlsVideoPlayerService } from './hls-video-player.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
// Used for stopping any music playing when starting a video.
import { PlayerService } from '@modules/_shared/player/player.service';
import { SnackbarService } from '@services/snackbar.service';

@Component({
  selector: 'app-hls-player',
  templateUrl: './hls-player.component.html',
  styleUrls: ['./hls-player.component.scss'],
  inputs: ['state', 'dialogState', 'video', 'video_title', 'hide_meta', 'url', 'poster', 'video_user', 'autoplay', 'video_playlist', 'video_playlist_index', 'no_unload', 'playlist_controls'],
  outputs: ['stateChange'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(400)),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class HlsPlayerComponent implements OnInit, OnChanges, OnDestroy {

  @Output() stateChange : EventEmitter<string> = new EventEmitter();
  // @Output() dialogStateChange : EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.stopPropagation();
    // Stop space bar from scrolling the window down.
    // Toggle video play/pause.
    if (typeof document !== 'undefined' && event.target === document.body && event.code === 'Space') {
      event.preventDefault();
      this.toggleVideo();
      return false;
    }
    if(this.playing){
      switch (event.code) {
      case 'KeyM':
        this.toggleVolume(event);
        break;
      case 'ArrowLeft':
        this.el_video.nativeElement.currentTime = this.el_video.nativeElement.currentTime - 5;
        this.currentTime = this.el_video.nativeElement.currentTime;
        console.log('ArrowLeft');
        break;
      case 'ArrowRight':
        console.log('ArrowRight');
        this.el_video.nativeElement.currentTime = this.el_video.nativeElement.currentTime + 5;
        this.currentTime = this.el_video.nativeElement.currentTime;
        break;
      }
    }
  }

  // These do *NOT* appear to fire in iOS Safari on iPhones.
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:fullscreenchange', ['$event'])
  fullScreenChangeHandler(event: any) {
    // console.log('fullscreen change event: ', event);
    this.fullscreen = !this.fullscreen;
    if(this.fullscreen){
      this.startUITimer();
    }
  }


  private hls_config:any = {
    debug:false,
    enableWorker:true,
    lowLatencyMode:true,
    backBufferLength:15,
    capLevelToPlayerSize: true,
    liveBackBufferLength: 15,
    maxBufferSize: 10,
    maxBufferLength: 10,
    liveSyncDurationCount: 10,
  };

  private hls = new Hls(this.hls_config); // see big comment at the end of this file for all config options:

  @ViewChild('playerCont', { static: true }) private readonly playerCont: ElementRef<any>; // Was HTMLElement ...
  @ViewChild('video', { static: true }) el_video: ElementRef<any>; // HTMLVideoElement. Hmm try any for webkitEnterFullscreen

  @ViewChild('vol', { static: true }) el_vol: ElementRef<any>;

  public video_title: string;
  public video_user: string;
  public url: string;
  public poster: string;

  public video:Video;
  public autoplay:boolean;
  public video_playlist:Video[];
  public video_playlist_index:number;
  public playlist_controls:boolean; // show hide playlist controls (if diaplyedin dialog or not, etc.)

  public no_unload:boolean;
  public state:string = 'init';
  public dialogState:boolean = false;

  private has_pinged_play:boolean = false;

  public loading: boolean = false;
  public buffering: boolean = false;
  public playing: boolean = false;
  public hide_meta:boolean = false; // For the video page, where we don't need to embed the title/artist labels in the player.
  public show_controls: boolean = false;
  public show_info: boolean = true; // mouseover flag
  public loaded: boolean = false; // set after can play.
  public currentTime: number = 0;
  public duration: number = 0;
  public progress: number = 0; // 0 - 1.0 for slider
  // Uses Hls LEVEL_LOADED event to inspect LevelDetails object for `live` attribute value.
  // Use to hide UI as appropriate.
  public is_live:boolean = false;
  public is_level_loaded:boolean = false;
  private loaded_level: number;
  private levels:Level[];
  public p_res:string = '';
  // Manifest loading errors. CORs etc. Expired playlist URLs.
  public has_error:boolean = false;
  public fullscreen: boolean = false;
  // For iPhones: To detect if a play/pause was called by a click on the video or our control buttons (and not from returning from fullscreen)
  private did_call_playpause: boolean = false;
  // To hide volume control on iOS.
  public is_mobile:boolean;
  public is_ios:boolean;
  public is_muted:boolean = false;
  private last_volume:number;
  public volume:number = 1.0;
  // To stop autoplay on canplay when resetting/seeking time at the end.
  private did_reset:boolean = false;
  // mouseover timer
  private mouseoverTimeout:any;
  private is_over:boolean = false;
  private mouseover_timeout_ms = 3000;
  // Idle timeout reset.
  private idleTimeout:any;
  private idle_timeout_ms:number = 180000; // 3 minutes

  private videoListeners = {
    loadedmetadata: () => {
      // console.log('%cloadedmetadata event: video.duration: ', 'color:lime', this.el_video.nativeElement.duration);
      this.duration = this.el_video.nativeElement.duration;
      // Detect live stream when loaded without HLS. (iPhone)
      if(this.duration === Infinity){
        this.is_live = true;
      } else {
        this.is_live = false;
      }
      this.state = 'loadedmetadata';
      this.stateChange.emit(this.state);
    },
    durationchange: (e) => {
      // console.log('%cDURATIONCHANGE event', 'color:lime', this.el_video.nativeElement.duration);
      this.duration = this.el_video.nativeElement.duration;
    },
    canplay: () => {
      // console.log('%cCANPLAY event', 'color:lime');
      this.state = 'canplay';

      this.stateChange.emit(this.state);
      if(this.did_reset){
        this.did_reset = false;
        return;
      }
      this.buffering = false;
      this.loaded = true;
      this.loading = false;
      this.show_controls = true;

      this.playPauseVideo(true);
    },
    play: () => {
      // console.log('%cPLAY event', 'color:lime');
      this.state = 'play';
      this.el_video.nativeElement.volume = this.volume || 1.0;
      // console.log('VOL', this.volume);
      this.stateChange.emit(this.state);
      this.startUITimer();
    },
    playing: () => {
      // console.log('%cPLAYING event', 'color:lime');
      this.state = 'playing';
      this.stateChange.emit(this.state);
      this.did_call_playpause = false;
      this.playing = true;
      this.buffering = false;
      this.loaded = true;
      this.loading = false;

    },
    pause: () => {
      // console.log('%cPAUSE event', 'color:lime');
      if (!this.did_call_playpause && this.platform.IOS) {
        // console.log('IOS paused video without being asked to. Likely going fullscreen ... ');
        // unpause it ...
        this.playPauseVideo(true);
        return;
      }
      this.state = 'paused';
      this.stateChange.emit(this.state);
      this.playing = false;
      this.playerCont.nativeElement.style.cursor = 'auto';
      this.show_controls = true;
      this.show_info = true;
      this.startIdleTimeout();
    },
    ended: () => {
      // console.log('%cENDED event', 'color:lime');
      if(this.video_playlist){
        // console.log('go to next in playlist...');
        const _next_index = this.hlsVideoPlayerService.getNext();
        // console.log('.. at index', _next_index);
        return;
      }
      this.state = 'stopped';
      this.stateChange.emit(this.state);
      this.did_reset = true;
      this.el_video.nativeElement.currentTime = 0;
      this.show_info = true;
      this.did_call_playpause = true; // set this or iOS will play again
      this.show_controls = true;
      this.playerCont.nativeElement.style.cursor = 'auto';
      // this.state = 'ended';
      // this.stateChange.emit(this.state);
      // this.playing = false;
      // this.playerCont.nativeElement.style.cursor = 'auto';
      // this.startIdleTimeout();
    },
    waiting: () => {
      // console.log('%cWAITING event', 'font-size:16px;color:yellow', 'progress:', this.progress, this.currentTime, this.duration);
      this.playing = false;
      this.buffering = true;
      // Deal with issue where a sourceBuffer has been emptied but stalled due to low buffer. Just try to go to the next. Or reset.
      if(!isNaN(this.progress) && this.progress > 0.99){
        if(this.video_playlist){
          this.progress = 0;
          // console.log('go to next in playlist...');
          const _next_index = this.hlsVideoPlayerService.getNext();
          // console.log('.. at index', _next_index);
          return;
        }
        this.state = 'stopped';
        this.stateChange.emit(this.state);
        this.did_reset = true;
        this.el_video.nativeElement.currentTime = 0;
        this.show_info = true;
        this.did_call_playpause = true; // set this or iOS will play again
        this.show_controls = true;
        this.progress = 0;
        this.playerCont.nativeElement.style.cursor = 'auto';
      }
    },
    // stalled: (e) => {
    //   console.log('%cSTALLED event', 'color:lime', e);
    // },
    seeking: () => {
      // console.log('%cSEEKING event', 'color:lime');
      this.hls.loadLevel = 0;
      this.buffering = true;
      this.state = 'seeking';
      this.stateChange.emit(this.state);
    },
    seeked: () => {
      this.hls.loadLevel = -1;
      // console.log('%cSEEKED event', 'color:lime');
      this.buffering = false;
      // Note: triggers canplay again
    },
    timeupdate: (e) => {
      // console.log('%cTIMEUPDATE event', 'color:lime', e);
      this.currentTime = this.el_video.nativeElement.currentTime;
      this.progress = (this.currentTime / this.duration);
      // console.log('progress: ', this.progress);
      if(this.video?.id && !this.has_pinged_play && this.currentTime > 5){
        this.api.pingVideoPlay(this.video.id).subscribe(data => {
        //  console.log('video play pinged');
        });
        this.has_pinged_play = true;
      }
      if (
        this.el_video.nativeElement.currentTime === this.el_video.nativeElement.duration &&
        this.el_video.nativeElement.duration > 0
      ) {
        // Ended
        // console.log('TIME ended', this.video_playlist);
        if(this.video_playlist){
          
          const _next_index = this.hlsVideoPlayerService.getNext();
          // console.log('go to next in playlist...', _next_index, '/', this.video_playlist.length);
          return;
        }
        // console.log('reset and rewind.');
        this.state = 'stopped';
        this.stateChange.emit(this.state);
        this.did_reset = true;
        this.el_video.nativeElement.currentTime = 0;
        this.show_info = true;
        this.did_call_playpause = true; // set this or iOS will play again
        this.show_controls = true;
        this.progress = 0;
        this.playerCont.nativeElement.style.cursor = 'auto';
      }
    },
    error: (err) => {
      console.log('videoListeners ERROR', err);
      if(this.video_playlist){
        // Try to play the next...
        const _next_index = this.hlsVideoPlayerService.getNext();
        // console.log('go to next in playlist...', _next_index, '/', this.video_playlist.length);
        return;
      }

    }
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private platform: Platform,
    private api: ApiAmazingtunesV2Service,
    private hlsVideoPlayerService:HlsVideoPlayerService,
    private audioPlayer:PlayerService,
    private snackbar:SnackbarService,
  ) {
    // console.log('player component constructed');
    // console.log('HLS loaded: ', this.hls);
    // console.log('IOS     : ', this.platform.IOS);
    // console.log('ANDROID : ', this.platform.ANDROID);
    this.loading = false;
    this.loaded = false;
    this.buffering = false;
    if(this.platform.IOS){
      this.is_ios = true;
    }
    if(this.platform.ANDROID || this.platform.IOS){
      this.is_mobile = true;
    }

    if(typeof window !== 'undefined'){
      this.volume = parseFloat(localStorage.getItem('video_vol')) || 1.0;
    }

  }

  ngOnInit(): void {

    this.loading = false;
    this.loaded = false;
    this.buffering = false;

    this.dialogState = false;

    // iOS support for exitFullscreen
    if (!this.document.exitFullscreen) {
      if (this.document.webkitExitFullscreen) {
        this.document.exitFullscreen = this.document.webkitExitFullscreen;
      }
    }

    if(this.video_playlist){
      // console.log('INIT WITH VIDEO PLAYLIST', this.video_playlist);
      this.hlsVideoPlayerService.playlist = this.video_playlist;
      if(this.video_playlist_index){
        // console.log('VIDEO PLAYLIST INDEX', this.video_playlist_index);
        this.hlsVideoPlayerService.playlist_index = this.video_playlist_index;
      }
    }

    // Setup error recovery strategies ...
    this.hls.on(Hls.Events.ERROR, (event, data) => {
      // console.log('%cHLS ERROR EVENT', 'color:yellow', event, data);
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log('%cFatal network error encountered, try to recover', 'color:pink', data.response);
            if(data.response['code'] === 0 || data.response['code'] >= 400){
              console.log('%cAborting. Response status:', 'color:red', data.response);
              this.has_error = true;
              this.hls.stopLoad();
              if(this.loaded){
                this.stopAndUnloadVideo(true);
              }
              return;
            }
            this.hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('%cFatal media error encountered, try to recover', 'color:orange');
            this.hls.recoverMediaError();
            break;
          default:
            // cannot recover
            console.log('%cFatal media error encountered, cannot recover. destroy!', 'color:red');
            this.hls.destroy();
            this.has_error = true;
            break;
        }
      } else {
        switch (data.type) {
          case Hls.ErrorTypes.OTHER_ERROR:
            console.log('%cOTHER_ERROR', 'color:red', event, data);
            this.has_error = true;
            break;
          default:
            // Likely buffering..
            // console.log('DEFAULT Event.. Likely buffering..', event, data);
            // this.has_error = true;
            break;
        }
      }
    });

    Object.keys(this.videoListeners).forEach(videoListener =>
      this.el_video.nativeElement.addEventListener(videoListener, this.videoListeners[videoListener])
    );

    // or use OnChanges below..
    // Hold off on loading until video (or custom play button) is clicked.
    // if (this.url) {
    //   console.log('load init video URL: ', this.url);
    //   this.load(this.url);
    // }

  }

  ngOnDestroy(): void {
    // console.log('%cDESTROY!!', 'color:red');
    this.loading = false;
    this.loaded = false;
    this.buffering = false;
    this.url = null;
    this.currentTime = 0;
    this.show_controls = false;

    this.is_live = false;
    this.is_level_loaded = false;
    // console.log('%cREMOVE video listeners', 'color:cyan');
    Object.keys(this.videoListeners).forEach(videoListener =>
      this.el_video.nativeElement.removeEventListener(videoListener, this.videoListeners[videoListener])
    );
    this.hls.detachMedia();
    this.hls.destroy();
  }

  ngOnChanges(change: any) {
    if (change.url && change.url.currentValue) {
      // console.log('set provided video URL onChange: ', this.url);
      this.url = change.url.currentValue;
    }

    if (change.video_user && change.video_user.currentValue) {
      // console.log('set provided username onChange: ', this.video_user);
      this.video_user = change.video_user.currentValue;
    }

    if(change.video && change.video.currentValue){
        // console.log('%cOnChange: Video item provided:', 'color:lime', change.video.currentValue );
        if(this.video && this.url){
          this.stopAndUnloadVideo();
        }
        this.video = change.video.currentValue;

        // console.log('VIDEO OBJECT PROVIDED onChange: ', this.video);
        // for testing...
        // console.log(JSON.stringify(this.video));
        
        this.video_title = this.video.attributes.name;
        this.video_user = this.video.artist.attributes.name;

        // Use default thumbnail unless overidden in component input `[poster]`
        if(!this.poster){
          this.poster = this.video.attributes.thumbnail.large;
        }
        if(this.autoplay){
          this.loadVideoData();
        }
    }

    if(change.poster && change.poster.currentValue){
      // console.log('poster input updated: ', change.poster.currentValue);
      this.stopAndUnloadVideo();
    }

  }

  public noopClick(event): void {
    event.stopPropagation();
  }

  public onPosterPlayBtnClick(event): void {
    event.stopPropagation();
    if (this.url) {
      if(this.loading){
        // Abort..
        this.stopAndUnloadVideo();
        return;
      }

      this.load(this.url);
    } else {
      this.loadVideoData();
    }
  }

  private loadVideoData(){
    if(this.video){
      // console.log('Video item provided: ', this.video);
      // get secure url ...
      this.loading = true;
      this.api.getVideoStreamingUrl(this.video.id).subscribe((data) => {
        this.url = data.data.attributes.secure_url;
        this.load(this.url);
      }, 
      (err) => {
        console.log('Error loading video data');
        this.snackbar.show('Error loading video url data', 'snackbarWarning');
      });
    }
  }

  private stopAndUnloadVideo(has_error:boolean = false): void {
    //console.log('stopAndUnloadVideo...');
    this.hls.stopLoad();
    this.progress = 0;
    this.duration = null;
    this.playing = false;
    this.loaded = false;
    this.loading = false;
    this.is_level_loaded = false;
    this.show_controls = false;
    this.playerCont.nativeElement.style.cursor = 'auto';
    this.el_video.nativeElement.load();
    this.hls.detachMedia();
    // console.log('Video stopped and unloaded');
  }

  public onVideoClick() {
    if (this.platform.IOS && !this.loaded) {
      return;
    }
    if (this.playing) {
      this.did_call_playpause = true; // set back to false on 'paused' event.
      this.playPauseVideo(false);
    } else {
      this.did_call_playpause = true; // set back to false on 'playing' event.
      this.playPauseVideo(true);
    }
  }

  /**
   * Loads the video, if the browser supports HLS then the video use it, else play a video with native support
   */
  private load(currentVideo: string): void {
    if (currentVideo.length === 0) {
      console.error('load() called without a currentVideo');
      return;
    }
    // console.log('%cLOAD:','color:cyan;font-weight:bold', currentVideo);
    if(this.audioPlayer.isPlaying()){
      // console.log('Music is playing!');
      this.audioPlayer.toggleAudio();
    }

    this.hls.detachMedia();
    this.hls.destroy();

    this.hls = new Hls(this.hls_config);
    this.el_video.nativeElement.src = null;
    this.clearIdleTimeout();
    this.loading = true;
    this.loaded = false;
    this.progress = 0;
    this.has_pinged_play = false;
    this.url = null;
    this.state = 'loading';
    this.stateChange.emit(this.state);
    // test native support first ...
    if (this.el_video.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
      // console.log('%cNative support for application/vnd.apple.mpegurl available ...', 'color:lime');
      this.loadVideo(currentVideo);
    } else if (Hls.isSupported()) {
      // console.log('%cHls is supported. Load video with Hls...', 'color:lime');
      this.loadVideoWithHLS(currentVideo);
    } else {
      console.log('%cHls and native mpegurl not supported!', 'color:red;font-weight:bold');
    }
  }

  /**
   * Load the video with HLS support
   */
   private loadVideoWithHLS(currentVideo: string) {
    this.hls.once(Hls.Events.MANIFEST_PARSED, (e, data) => {
      // console.log('%cHLS MANIFEST_PARSED', 'color:orange' ,data.levels);
      this.hls.startLevel = 0;
      this.levels = data.levels;
      // console.log('LEVELS:');
      // data.levels.forEach(level => {
      //   console.log('bitrate: ', level.bitrate, 'codecSet: ', level.codecSet, 'width:', level.width, 'height:', level.height);
      // });
    });


    this.hls.once(Hls.Events.ERROR, (e, data) => {
      console.log('%cHLS ERROR LOADING MANIFEST', 'color:orange' , data );
      // go to next if available
      if(this.video_playlist){
        // this.snackbar.show('Error loading video. Playing next in playist...', 'snackbarInfo', 2000);
        const _next_index = this.hlsVideoPlayerService.getNext();
        return;
      }
    });

    this.hls.on(Hls.Events.LEVEL_LOADED, (e, data) => {
      // console.log('LIVE: ', data.details.live);
      this.is_level_loaded = true;
      this.loaded_level = data.level

      this.p_res = this.levels[this.loaded_level].height + 'p';

      if(data.details.live === true){
        console.log('%cLIVE STREAM', 'color:lime', 'level', data.level, this.levels[data.level]);
        this.is_live = true;
      } else {
        this.is_live = false;
        // console.log('%cLEVEL LOADED', 'color:cyan', 'level:', data.level, this.levels[data.level]);
      }
    });
    this.hls.loadSource(currentVideo);
    this.hls.attachMedia(this.el_video.nativeElement);
  }

  /**
   * Load the video without HLS support.
   */
  private loadVideo(currentVideo: string) {
    this.el_video.nativeElement.src = currentVideo;
    // console.log('Native HTML video src set:', currentVideo);
    this.loading = true;
    this.loaded = false;
    this.playPauseVideo(true);
  }

  /**
   * Play or Pause current video
   */
  private playPauseVideo(playing: boolean) {
    this.playing = playing;
    this.el_video.nativeElement[playing ? 'play' : 'pause']();
  }

  public toggleVideo(): void {
    if (!this.loaded) {
      return;
    }
    this.clearIdleTimeout();
    if(this.is_live && this.playing){
      // console.log('video is live. stop and unload it.');
      this.stopAndUnloadVideo();
      return;
    }
    this.did_call_playpause = true;
    this.playPauseVideo(!this.playing);
  }

  public sliderVolEvent(event): void {
    if(this.el_video.nativeElement){
      this.volume = event.value;
      this.el_video.nativeElement.volume = this.volume;
      if(typeof window !== 'undefined'){
        localStorage.setItem('video_vol', this.volume.toString());
      }
      this.is_muted = this.volume === 0;
    }
  }

  public sliderEvent(event): void {
    switch (event.state) {
      case 'start':
        this.clearIdleTimeout();
        // console.log('slider start', event.value, parseFloat(((event.value * this.duration)).toFixed(2)));
        this.did_call_playpause = true;
        this.el_video.nativeElement.pause();
        break;
      case 'change':
        this.currentTime = (event.value * this.duration);
        // console.log('slider change', event.value, parseFloat(((event.value * this.duration)).toFixed(2)));
        break;
      case 'end':
        // console.log('slider end', event.value, parseFloat(((event.value * this.duration)).toFixed(2)));
        this.currentTime = (event.value * this.duration);
        this.el_video.nativeElement.currentTime = this.currentTime;
        break;
    }
  }

  public toggleVolume(event): void {
    event.stopPropagation();
    // console.log('toggleVolume', this.el_video.nativeElement.volume);
    if(!this.is_muted){
      this.last_volume = this.el_video.nativeElement.volume;
      this.el_video.nativeElement.volume = 0;
      this.volume = 0;
    } else {
      this.volume = this.last_volume;
      this.el_video.nativeElement.volume = this.last_volume;
    }
    this.is_muted = !this.is_muted;
  }

  public toggleFullScreen(event): void {
    if (!this.loaded) {
      return;
    }
    event.stopPropagation();
    if (this.platform.SAFARI && !this.fullscreen) {
      // console.log('%cCheck video : webkitSupportsFullscreen: ', 'color:red', this.el_video.nativeElement.webkitSupportsFullscreen);

      if (this.playerCont.nativeElement.webkitRequestFullscreen) {
        // console.log('webkitRequestFullscreen *IS* supported on the playerCont element...');
        this.playerCont.nativeElement.webkitRequestFullscreen();
        return;
      }
      if (this.el_video.nativeElement.webkitEnterFullscreen) {
        // console.log('webkitEnterFullscreen *IS* supported on the video element...');
        this.el_video.nativeElement.webkitEnterFullscreen();
        return;
      }
    }

    if (this.fullscreen) {
      this.document.exitFullscreen();
      return;
    }
    if (this.playerCont.nativeElement.requestFullscreen) {
      try {
        this.playerCont.nativeElement.requestFullscreen();
      } catch (err) { }
    }
  }

  // Mouse events to show/hide controls/info.
  public mouseOver():void {
    this.is_over = true;
    if(this.mouseoverTimeout){
      clearTimeout(this.mouseoverTimeout);
      this.mouseoverTimeout = null;
      if(this.loaded && !this.has_error){
        this.show_controls = true;
        this.show_info = true;
      }
    }
    // console.log('mouseover', event);
  }
  public startUITimer():void {
    // Starts timeout to fade out controls and info.
    this.is_over = false;
    if(this.mouseoverTimeout){
      clearTimeout(this.mouseoverTimeout);
    }
    this.mouseoverTimeout = setTimeout(() => {
      if(this.playing){
        this.show_controls = false;
        this.show_info = false;
        this.playerCont.nativeElement.style.cursor = 'none';
        this.mouseoverTimeout = null;
      }
    }, this.mouseover_timeout_ms);
  }

  public mouseMove(event):void {
    // console.log('mouseMove', event);
    if(this.playing && !this.show_controls && !this.has_error){
      this.playerCont.nativeElement.style.cursor = 'auto';
      this.show_controls = true;
      this.show_info = true;
    } else if (!this.playing && !this.has_error) {
      this.show_info = true;
    }
  }

  private startIdleTimeout() : void {
    if(this.no_unload){
      return;
    }
    if(this.idleTimeout){
      clearTimeout(this.idleTimeout);
    }
    this.idleTimeout = setTimeout(() => {
      // console.log('IDLE TIMEOUT. RESET UI.');
      this.stopAndUnloadVideo();
    }, this.idle_timeout_ms)
  }

  private clearIdleTimeout() : void {
    if(this.idleTimeout){
      clearTimeout(this.idleTimeout);
    }
    this.idleTimeout = null;
  }

  goToArtistProfile(){
    //console.log('go to video artist profile');
    this.hlsVideoPlayerService.playerDialogCloseSubject.next('artist');
  }

  goToVideoPage(){
    //console.log('go to video page');
    this.hlsVideoPlayerService.playerDialogCloseSubject.next('video');
  }

}

/**

HLS Config options

var config = {
  autoStartLoad: true,
  startPosition: -1,
  debug: false,
  capLevelOnFPSDrop: false,
  capLevelToPlayerSize: false,
  defaultAudioCodec: undefined,
  initialLiveManifestSize: 1,
  maxBufferLength: 30,
  maxMaxBufferLength: 600,
  backBufferLength: Infinity,
  maxBufferSize: 60 * 1000 * 1000,
  maxBufferHole: 0.5,
  highBufferWatchdogPeriod: 2,
  nudgeOffset: 0.1,
  nudgeMaxRetry: 3,
  maxFragLookUpTolerance: 0.25,
  liveSyncDurationCount: 3,
  liveMaxLatencyDurationCount: Infinity,
  liveDurationInfinity: false,
  enableWorker: true,
  enableSoftwareAES: true,
  manifestLoadingTimeOut: 10000,
  manifestLoadingMaxRetry: 1,
  manifestLoadingRetryDelay: 1000,
  manifestLoadingMaxRetryTimeout: 64000,
  startLevel: undefined,
  levelLoadingTimeOut: 10000,
  levelLoadingMaxRetry: 4,
  levelLoadingRetryDelay: 1000,
  levelLoadingMaxRetryTimeout: 64000,
  fragLoadingTimeOut: 20000,
  fragLoadingMaxRetry: 6,
  fragLoadingRetryDelay: 1000,
  fragLoadingMaxRetryTimeout: 64000,
  startFragPrefetch: false,
  testBandwidth: true,
  progressive: false,
  lowLatencyMode: true,
  fpsDroppedMonitoringPeriod: 5000,
  fpsDroppedMonitoringThreshold: 0.2,
  appendErrorMaxRetry: 3,
  loader: customLoader,
  fLoader: customFragmentLoader,
  pLoader: customPlaylistLoader,
  xhrSetup: XMLHttpRequestSetupCallback,
  fetchSetup: FetchSetupCallback,
  abrController: AbrController,
  bufferController: BufferController,
  capLevelController: CapLevelController,
  fpsController: FPSController,
  timelineController: TimelineController,
  enableWebVTT: true,
  enableIMSC1: true,
  enableCEA708Captions: true,
  stretchShortVideoTrack: false,
  maxAudioFramesDrift: 1,
  forceKeyFrameOnDiscontinuity: true,
  abrEwmaFastLive: 3.0,
  abrEwmaSlowLive: 9.0,
  abrEwmaFastVoD: 3.0,
  abrEwmaSlowVoD: 9.0,
  abrEwmaDefaultEstimate: 500000,
  abrBandWidthFactor: 0.95,
  abrBandWidthUpFactor: 0.7,
  abrMaxWithRealBitrate: false,
  maxStarvationDelay: 4,
  maxLoadingDelay: 4,
  minAutoBitrate: 0,
  emeEnabled: false,
  widevineLicenseUrl: undefined,
  licenseXhrSetup: undefined,
  drmSystemOptions: {},
  requestMediaKeySystemAccessFunc: requestMediaKeySystemAccess,
};

var hls = new Hls(config);

 */


/*
    Since v18. New interceptor function to replace DI versions

    https://angular.dev/guide/http/interceptors
*/

import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { inject } from "@angular/core";
import { finalize } from "rxjs/operators";

export function loadingInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

    const skipUrls = [ // Urls to not show the loading indicator.
        'onair',
        'upcoming',
        'history',
        '/image',
        '/me',
        'mp3?',         // File download
        'skip_loading',
        '/genres',
        '/played',      // ping
        'playlist-polls',
        'autocomplete.php',
        'search.2.php',
        'upload.php',
        'amazon',       // video uploads
        'transloadit',  // tune uploads
        '/assemblies',
        '/tunes/upload',
        '/auth/social',
        'check_permalink',
        '/v1/home-featured',
        '/stream/',
        'data:image',   // data URL SVG icons
        '.svg'          // SVG icons are loaded by Angular using the HTTPClient, so we need to skip these or bad things happen with the loading overlay.
    ];
    let activeRequests: number = 0;
    let displayLoadingScreen = true;
    for (const skipUrl of skipUrls) {
        if (request.urlWithParams.includes(skipUrl)) {
            //  console.log('%cSKIP INDICATOR for url: ', 'color:cyan' , skippUrl, request.urlWithParams);
            displayLoadingScreen = false;
            break;
        }
    }

    if (displayLoadingScreen) {
        const loadingIndicator = inject(LoadingIndicatorService)
        if (activeRequests === 0) {
            // console.log('%cSHOWING INDICATOR for url:', 'color:orange', request.urlWithParams);
            loadingIndicator.startLoading();
        }
        activeRequests++;
        return next(request).pipe(
            finalize(() => {
                activeRequests--;
                if (activeRequests === 0) {
                    loadingIndicator.stopLoading();
                }
            })
        )
    } else {
        return next(request);
    }    
}

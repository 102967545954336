import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '@services/loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  inputs: ['force_side_nav_closed']
})
export class LoadingIndicatorComponent implements OnInit {

  public force_side_nav_closed:boolean = false;

  private clickCount = 0;

  constructor(private loadingIndicatorService:LoadingIndicatorService ) {

  }

  ngOnInit(): void { }

  click(){
    // double-click to force close the indicator.
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 2) {
          this.loadingIndicatorService.stopLoading();
        }
        this.clickCount = 0;
    }, 300)
  }

}

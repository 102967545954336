import { Component, Inject, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PlayerService } from '@modules/_shared/player/player.service';
import { RadioHistoryService } from '@services/radio-history.service';
import { HomeContentService } from '@services/home-content.service';
import { TimeSlipService } from '@services/time-slip.service';

interface DialogData {
  extra?: any;
}

@Component({
  selector: 'app-dialog-time-slip',
  templateUrl: './dialog-time-slip.component.html',
  styleUrls: ['./dialog-time-slip.component.scss']
})
export class DialogTimeSlipComponent implements OnInit {

  public time_str:string;
  public offset:number = 0;

  constructor(
    private player:PlayerService,
    private radioHistoryService:RadioHistoryService,
    private homeContentService:HomeContentService, 
    public timeSlipService: TimeSlipService,
    public dialogRef: MatDialogRef<DialogTimeSlipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.time_str = this.offsetToText(this.timeSlipService.offset);
    this.offset = this.timeSlipService.offset;
  }

  onChange(event:any){
    this.offset = event.target.value;
    this.time_str = this.offsetToText(this.offset);
  }

  close() : void {
    this.dialogRef.close();
  }

  setValue(value:number){
    this.offset = value;
    this.time_str = this.offsetToText(this.offset);
  }

  ok() : void{
    this.timeSlipService.offset_str = this.time_str;
    this.timeSlipService.offset = this.offset;    

    console.log('OFFSET', this.offset);

    
    // (re)start radio ... 
    if(this.player.playing){
      this.player.resetPlayer();
    }
    this.player.toggleRadioStream();
    // trigger update of current show and now playing tune..
    this.radioHistoryService.updateRadioHistory();
    this.homeContentService.getShows(false); // Adding false will only trigger the update of the current show. (Not the next) 
    this.dialogRef.close();
    if(typeof window !== 'undefined'){
      window.scrollTo(0,0);
    }
  }

  offsetToText(value){
		let _time_str = '';
		const _time_obj = this.msecsToTimeObject( (Math.abs(Math.round(value) - 0) * 60 * 1000 ) );
		if(_time_obj.hours > 0){
			_time_str += _time_obj.hours + 'h ';
		}
		if(_time_obj.minutes > 0){
			_time_str += _time_obj.minutes + 'm';
		}
		if(_time_obj.hours == 0 && _time_obj.minutes == 0){
			_time_str = 'LIVE';
		}
    _time_str = (_time_str == 'LIVE' ? '':'-') + _time_str;
		return _time_str;
	}

  msecsToTimeObject(msecs) {
    const msSecs = (1000);
    const msMins = (msSecs * 60);
    const msHours = (msMins * 60);
    const numHours = Math.floor(msecs / msHours);
    const numMins = Math.floor((msecs - (numHours * msHours)) / msMins);
    const numSecs = Math.floor((msecs - (numHours * msHours) - (numMins * msMins)) / msSecs);
    let numMillisecs:any = ((msecs - (numHours * msHours) - (numMins * msMins) - (numSecs * msSecs)) / 10).toFixed();
    if (numMillisecs == 100) {
        numMillisecs = 0;
    }
    return {
        hours:numHours,
        minutes:numMins,
        seconds:numSecs,
        milliseconds:numMillisecs
    }
  }

}

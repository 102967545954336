import { Pipe, PipeTransform } from '@angular/core';

import { TuneV1 } from '@models/tune-v1';
import { TuneV2 } from '@models/tune-v2';
import { CollectionV1 } from '@models/collection-v1';
import { CollectionV2 } from '@models/collection-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { FeaturedMediaItem } from '@models/wp-featured-media';
import { SearchResultsHitSource } from '@models/search';
import { ArtistV1UserV2Pipe } from './artist-v1-user-v2-pipe'

// Transform a V1 tune to a V2 tune. (for some WP responses still in V1 format)

@Pipe({
  name: 'tuneV1V2'
})
export class TuneV1V2Pipe implements PipeTransform {

  constructor(private artistV1UserV2Pipe: ArtistV1UserV2Pipe) { }

  transform(tune: TuneV1): TuneV2 {

    const v2_tune: TuneV2 = {
      id: tune.uuid,
      _id: tune.id, // old number
      _parent_id: tune._parent_id, // possible collection or playlist id.
      uuid: tune.uuid,
      attributes: {
        name: tune.title,
        description: tune.description,
        image_urls: {
          large: tune.image_shq,
          medium: tune.image_hq,
          small: tune.image
        },
        uploaded_at: tune.uploaded_at,
        is_private: tune.private,
      },
      meta: {
        liked_by_user: tune.liked_by_user || false,
      },
      artist: this.artistV1UserV2Pipe.transform(tune.artist),
      _is_owner: tune._is_owner || false,
      position: tune.position, // Only set when used in a chart listing. Gives footer player more info to show.
      movement: tune.movement, // Used for movement icon.
      movement_str: tune.movement_str // Icon title tag.
    };
    return v2_tune;
  }
}

// Transform for V1 Featured Media Playlists and Collections to a PlaylistV2
@Pipe({
  name: 'featuredTunesList2PlaylistV2'
})
export class FeaturedTunesList2PlaylistV2 implements PipeTransform {

  constructor(private tuneV1V2Pipe: TuneV1V2Pipe) { }

  transform(featured: FeaturedMediaItem): PlaylistV2 {

    // console.log('transforming: ', featured.resource_type, featured.resource);
    if (featured.resource.attributes) {
      // console.log('featured resource appears to already be V2');
      return featured.resource;
    }
    let _tunes: TuneV2[] = [];
    if (featured.resource.entries) {
      featured.resource.entries.forEach(entry => {
        _tunes.push(this.tuneV1V2Pipe.transform(entry.tune));
      });
    } else if (featured.resource.tunes) {
      // Add collection artist to each collection tune.
      // console.log('featured resource artist: ', featured.resource.artist);
      // console.log('transform .tunes: ', featured.resource.tunes);
      featured.resource.tunes.forEach(tune => {
        if (featured.resource_type === 'collection') {
          tune.artist = featured.resource.artist;
        }
        _tunes.push(this.tuneV1V2Pipe.transform(tune));
      });
    }
    const v2_playlist = {
      id: featured.resource.id,
      attributes: {
        name: featured.resource.title ? featured.resource.title : featured.resource.name,
        image_urls: {
          large: featured.resource.image_shq,
          medium: featured.resource.image_hq,
          small: featured.resource.image
        },
      },
      _tunes: _tunes
    };
    return v2_playlist;
  }
}


@Pipe({
  name: 'tuneSearchV1V2'
})
export class TuneSearchV1V2Pipe implements PipeTransform {
  // Transform search hit.source tunes to approximation of a TuneV2 with limited data
  //
  constructor(private artistV1UserV2Pipe: ArtistV1UserV2Pipe) { }

  transform(tune_source: SearchResultsHitSource): TuneV2 {

    // console.log('transform:', tune_source);

    const v2_tune: TuneV2 = {
      id: tune_source.uuid,
      uuid: tune_source.uuid,
      type: 'tune',
      _created_at_ago: tune_source._created_time_ago,
      attributes: {
        is_ready_to_stream: true,
        is_private: tune_source.is_streamable,
        is_explicit: tune_source.is_explicit || tune_source.explicit,
        name: tune_source.title,
        duration_secs: tune_source.duration,
        image_urls: {
          large: tune_source.image,
          medium: tune_source.image,
          small: tune_source.image_small
        },
        distribution: {
          preference: tune_source.distribution_pref,
          sale_price_pence: tune_source.sale_price_pence,
          currency: tune_source.currency
        }
      },
      meta: {
        liked_by_user: false
      },
      artist: this.artistV1UserV2Pipe.transform(
        {
          display_name: tune_source.artist,
          permalink: tune_source.artist_permalink,
        }
      ),
      primary_genre: {
        id: tune_source.primary_genre_id,
        name: tune_source.primary_genre
      }
    };
    return v2_tune;
  }
}


@Pipe({
  name: 'collectionV1V2'
})
export class CollectionV1V2Pipe implements PipeTransform {
  // Transform V1 Collections to CollectionV2.
  // This is a patch until the search results data can provide the Collection UUID.
  constructor(private artistV1UserV2Pipe: ArtistV1UserV2Pipe, private tuneV1V2Pipe: TuneV1V2Pipe) { }

  transform(collection_v1: CollectionV1): CollectionV2 {

    const v2_collection: CollectionV2 = {
      id: collection_v1.id,
      uuid: collection_v1.id,
      type: 'collection',
      attributes: {
        type: collection_v1.type,
        is_private: false,
        name: collection_v1.name,
        image_urls: {
          large: collection_v1.image_shq,
          medium: collection_v1.image_hq,
          small: collection_v1.image
        },
      },
      meta: {
        liked_by_user: collection_v1.liked_by_user
      },
      artist: this.artistV1UserV2Pipe.transform(
        {
          display_name: collection_v1.artist.display_name,
          permalink: collection_v1.artist.permalink,
        }
      ),
      _created_at_ago: collection_v1?._created_at_ago,
      _routing: collection_v1.routing_type
    };

    let _tunes: TuneV2[] = [];
    collection_v1.tunes.forEach(tune => {
      _tunes.push(this.tuneV1V2Pipe.transform(tune));
    });
    v2_collection._tunes = _tunes;
    return v2_collection;
  }
}


@Pipe({
  name: 'collectionSearchV1V2'
})
export class CollectionSearchV1V2Pipe implements PipeTransform {
  // Transform V1 Collections to CollectionV2.
  // This is a patch until the search results data can provide the Collection UUID.
  constructor(private artistV1UserV2Pipe: ArtistV1UserV2Pipe, private tuneV1V2Pipe: TuneV1V2Pipe) { }

  transform(collection_v1: any): CollectionV2 {

    const v2_collection: CollectionV2 = {
      id: collection_v1.uuid,
      uuid: collection_v1.uuid,
      type: 'collection',
      attributes: {
        type: collection_v1.type,
        is_private: false,
        name: collection_v1.name,
        image_urls: {
          large: collection_v1.image,
          medium: collection_v1.image,
          small: collection_v1.image
        },
        distribution: {
          preference: collection_v1.distribution_pref,
          sale_price_pence: collection_v1.sale_price_pence
        },
        tune_count: collection_v1.track_count
      },
      meta: {
        liked_by_user: collection_v1.liked_by_user
      },
      artist: {
        attributes: {
          name: collection_v1.artist,
          permalink: collection_v1.artist_permalink
        }
      },
      // artist: this.artistV1UserV2Pipe.transform(
      //   {
      //       display_name: collection_v1.artist.display_name,
      //       permalink: collection_v1.artist.permalink,
      //   }
      // ),
      _created_at_ago: collection_v1._created_time_ago,
      _routing: collection_v1.routing_type
    };

    // let _tunes:TuneV2[] = [];
    // collection_v1.tunes.forEach(tune => {
    //   _tunes.push(this.tuneV1V2Pipe.transform(tune));
    // });
    // v2_collection._tunes = _tunes;
    return v2_collection;
  }
}

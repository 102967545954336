<div class="tunecore-info">
  <mat-icon (click)="close()" class="close" title="Dismiss this offer" inline="true">close</mat-icon>

  <img class="logo" src="/assets/logos/TuneCore.svg">
  <br>

  <div class="headerText">Distribute your music to other streaming services including Spotify & Apple Music</div>

  <div class="icons">
    <img src="/assets/icons/iconSpotify.svg"/>
    <img src="/assets/icons/iconAppleMusic.svg"/>
    <img src="/assets/icons/iconAmazonMusic.svg"/>
    <div>And many more&hellip;</div>
  </div>

  We've negotiated a <strong>25% discount</strong> on your first distribution with TuneCore. Click below to make a free account - and <strong>save money</strong>

  <div class="applied">discount applied at checkout.</div>

  <div (click)="getOffer()" title="Head over to TuneCore now to get this offer" class="get-offer label blue link">Get Offer</div>
  <br>

  <div *ngIf="!no_dismiss" (click)="dismiss()" class="dismiss" title="Dismiss this offer and close">no thanks</div>

</div>

import { Component, OnInit } from '@angular/core';
import { PlayerService } from '@modules/_shared/player/player.service';
import { TuneV2 } from '@models/tune-v2';
import { UserV2 } from '@models/user-v2';
import { PlaylistV2 } from '@models/playlist-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { CollectionV1V2Pipe } from '@services/tune-v2-v1.pipe';
import { CollectionV2 } from '@app/models/collection-v2';

// Special Play button for PlaylistV2 cards since they need to get the first tune of the playlist it doesn't know about yet.
// Also, the template play button icon logic will show the right control if the tune that's playing is within that playlist. (he says)

declare var _paq;

@Component({
  selector: 'app-collection-play-button-v2',
  templateUrl: './collection-play-button-v2.component.html',
  styleUrls: ['./collection-play-button-v2.component.scss'],
  inputs: ['collection', 'playlistHead', 'tune', 'artist', 'playlist', 'index', 'addClass', 'parentClick', 'collection_uuid', 'unique_id', 'playlist_id']
})
export class CollectionPlayButtonV2Component implements OnInit {

  public tune: TuneV2;
  public artist: UserV2;
  public playlist: TuneV2[];
  public index: number;

  public collection_uuid: string;
  public playlist_id: string; // mid-refactoring..

  public addClass: string = '';
  public parentClick: boolean = false;

  public collection: CollectionV2 = {
    id:null,
    artist: {
      attributes: {
        name: ''
      }
    },
    attributes:{
      name:''
    }
  };

  public unique_id:string; // for play button states on activity feed and playlists with duplicates.

  public svg_icons = {
    play: 'play',
    pause: 'pause',
    stop: 'stop',
    buffering: 'buffering'
  };

  public stateIndex = 0;

  constructor(public player: PlayerService, private api_tunes: ApiAmazingtunesV2Service, private collectionV1V2Pipe: CollectionV1V2Pipe) { }

  playPause() {

    if (this.collection_uuid && !this.playlist && !this.collection._tunes) {
      // LazyLoad collection data.
      // console.log('lazyload collection tunes');
      this.api_tunes.getCollectionV2(this.collection_uuid, true).subscribe(data => {
        this.tune = data._tunes[0];
        this.playlist = data._tunes;
        this.collection = data; //
        // console.log('collection tunes: ', this.collection._tunes);
        this.index = 0;
        this.player.setPlaylistHeadDataV2(this.collection);
        this.player.playTune( this.tune, this.index, this.playlist, this.collection);

        _paq.push(['trackEvent', 'PlayCollection', data.attributes.name + ' - ' + data?.artist?.attributes?.name + ' ('+data.id+')']);

        return;
      });
      return;
    }
    // else if (this.collection && !this.playlist && !this.tune) {
    //   // LazyLoad the playlist tunes.
    //   // Liked playlists have no 'entries/tunes', so fill this up now on the first play
    //   console.log('lazy load collection');

    //   // this.player.clearTuneId();
    //   this.player.resetPlayer();

    //   // console.log('playlistHead: ', this.playlistHead);
    //   this.player.setPlaylistHeadDataV2(this.playlistHead);
    //   this.player.loading = true; // Trigger the spinner now
    //   this.api_tunes.getPlaylistV2(this.playlistHead.id, true).subscribe(data => {
    //     // console.log('selected playlist data: ', data);
    //     this.playlist = data._tunes;
    //     this.tune = this.playlist[0];
    //     this.player.rewinds = false;
    //     this.player.featured = false;
    //     this.index = 0;
    //     this.player.playTune(this.tune, this.index, this.playlist, this.playlistHead);

    //     _paq.push(['trackEvent', 'PlayPlaylist', data.attributes.name + ' - ' + data?._curator?.attributes?.name + ' ('+data.id+')']);

    //     return;
    //   });
    //   return;
     else if (this.collection._tunes && this.playlist) {
      this.tune = this.collection._tunes[0];
      // console.log('collection data provided', this.collection);
      if(this.playlist.includes(this.player.currentTune)){
        // console.log('current tune is in playlist');
        this.player.toggleAudio();
        return;
      }

      this.player.playTune(this.tune, 0, this.collection._tunes, this.collection);

      _paq.push(['trackEvent', 'PlayCollection', this.collection?.attributes?.name + ' ('+this.collection.id+')']);
      return;
    }
    else if(!this.collection && this.collection_uuid){
      // console.log('Get collection tunes... ');
    }
    // _paq.push(['trackEvent', 'PlayCollection', this.collection?.attributes?.name + ' ('+this.collection.id+')']);
    // this.player.playTune(this.tune, this.index, this.collection._tunes, this.collection);
  }

  ngOnInit(): void {
    // console.log(this.unique_id, this.collection_uuid, this.collection); // was playlistHead
  }

}

<!-- Tune card component : V3 grid-only version -->

<div *ngIf="tune" id="tune-{{tune.id}}" class="card" [class]="(tune._is_unlisted ? 'unlisted ':'') + (tune.attributes.is_private ? 'private ' + list_parent : (hide_artist === true ? 'profileTune ' + list_parent : list_parent))">

  <!-- Tune image -->
  <div class="imageCont large square">
    <img aria-hidden="true" routerLink="/profile/{{tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png">
    <!-- <span *ngIf="url === '/aired'" class="airing" [title]="tune._last_aired_at | date:'MMM d, y - HH:mm'" [innerHTML]="tune._last_aired_at | date:'HH:mm'"></span> -->
    <!-- <span *ngIf="url === '/firstspin'" title="Date uploaded" class="airing latest" [innerHTML]="tune.attributes.first_spin_at | date:'MMM d, h:mm a'"></span> -->
    <!-- <span *ngIf="url === '/latest'" title="Date uploaded" class="airing latest">{{ (tune.attributes.released_at || tune.attributes.uploaded_at) | date:'MMM d, h:mm a' }}</span> -->

    <div role="button" class="sell-img" (click)="buy()" *ngIf="tune.attributes.distribution.preference === 'sell'" title="This tune is for sale">
      <mat-icon inline="true">shopping_cart</mat-icon> <span >{{ tune.attributes.distribution.sale_price_pence / 100 | currency : tune.attributes.distribution.currency }}</span>
    </div>
    <div role="button" class="download-img" (click)="downloadTune()" *ngIf="tune.attributes.distribution.preference === 'free_download'" title="This tune is available for free download">
      <mat-icon inline="true">cloud_download</mat-icon> <span #downloadProgress>FREE</span>
    </div>
  </div>

  <!-- <app-play-button-v2 class="playButton playButtonList" [class]="tune._is_unlisted || (tune.attributes.is_private && (tune._is_owner || tune._is_manager)) ? 'owner-private':''" *ngIf="tune.attributes.is_ready_to_stream;else processing" [playlist]="playlist" [index]="index" [tune]="tune"></app-play-button-v2> -->
  <!-- Dont show these on logged in user's tunes -->
  <!-- <div class="buyDownloadContainer" *ngIf="tune.attributes.distribution.preference === 'sell' || tune.attributes.distribution.preference === 'free_download'" [class]="list_parent === 'user-downloads'?'stay-visible':''">
    <div (click)="buy()" *ngIf="list_parent !== 'user-downloads' && tune.attributes.distribution.preference === 'sell'" class="button buy" title="Buy this tune"><mat-icon class="icon" inline="true">shopping_cart</mat-icon><span>Buy</span></div>
    <div #downloadButton class="button download" [class]="tune.attributes.is_private ? 'private':''" (click)="downloadTune()" *ngIf="(list_parent === 'user-downloads' || tune.attributes.distribution.preference === 'free_download') && !has_downloaded; else downloaded" title="Free Download"><mat-icon class="icon" inline="true">cloud_download</mat-icon></div>
  </div> -->

  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like role="button" [title]="tune.meta.liked_by_user ? 'Remove Tune From Likes':'Like Tune'" [resource]="tune" class="interact like" *ngIf="!tune._is_owner && !tune._is_manager; else private;"></app-action-button-like>
      <app-play-button-v2 role="button" [attr.aria-label]="!tune.attributes.is_private ? 'Play and pause this tune':''" addClass=" tuneCardV3PlayButton" [class]="tune.attributes.is_private && (tune._is_owner || tune._is_manager) ? 'owner-private':''" *ngIf="tune.attributes.is_ready_to_stream;else processing" [playlistHead]="playlistHead" [playlist]="playlist" [unique_id]="tune._unique_id" [index]="index" [tune]="tune"></app-play-button-v2>
      <app-action-button-playlist role="button" title="Add To Playlist" *ngIf="!tune._is_unlisted && !tune._can_first_spin && !tune.attributes.is_private; else privatePlaylist" [tunes]="[tune]" class="interact playlist"></app-action-button-playlist>
    </div>
  </div>

  <div class="details" [class]="tune.attributes.distribution.preference === 'free_download' || tune.attributes.distribution.preference === 'sell' ? 'with-icon':''">
    <a role="link" class="elipsify name" *ngIf="!hide_artist" routerLink="/profile/{{tune.artist.attributes.permalink}}" title="{{ tune.artist.attributes.name }}" [innerHTML]="tune.artist.attributes.name"></a>
    <a role="link" class="elipsify title" [class]="hide_artist ? 'hidden_name':''" routerLink="/profile/{{tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" [title]="tune.attributes.name" [innerHTML]="tune.attributes.name"></a>
    <div aria-hidden="true" class="stats">
      <!-- <div class="label blue x-small" *ngIf="!tune.attributes.is_ready_to_stream && !failed_upload; else failedMessage">
        This tune is still processing ...
      </div> -->
      <div *ngIf="url === '/firstspin'" title="Date added" class="date-foot" [innerHTML]="tune.attributes.first_spin_at | date:'MMM d, h:mm a'"></div>
      <div *ngIf="url === '/aired' || url.includes('/genre-streams/')" class="date-foot" [title]="'Aired : '+ (tune._last_aired_at | date:'MMM d, y - HH:mm')" [innerHTML]="tune._last_aired_at | date:'HH:mm'"></div>
    </div>
  </div>

</div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private tunes -->
<ng-template #private>
  <div *ngIf="tune.artist.id !== userService.getId() && !tune._is_manager; else edit" class="empty" title=""><mat-icon svgIcon="blank"></mat-icon></div>
</ng-template>
<ng-template #privatePlaylist>
  <app-action-button-playlist role="button" title="Add To Playlist" *ngIf="!tune._is_unlisted && (tune._is_owner || tune._is_manager); else blank" [tunes]="[tune]" class="interact playlist"></app-action-button-playlist>
</ng-template>
<ng-template #blank>
  <!-- <mat-icon class="interact blank" svgIcon="blank"></mat-icon> -->
  <div class="empty blank"></div>
</ng-template>
<ng-template #edit>
  <app-action-button-edit role="button" class="edit" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id, 'edit']" title="Edit Tune"></app-action-button-edit>
</ng-template>
<ng-template #firstSpin>
  <mat-icon>new_releases</mat-icon>
</ng-template>
<ng-template #processing>
  <div *ngIf="!failed_upload;else failed" class="processingCont" title="This tune is still processing ...">
    <mat-icon class="icon spinner" svgIcon="buffering"></mat-icon>
  </div>
</ng-template>
<ng-template #failed>
  <!-- <div (click)="deleteFailedUpload(tune)" class="processingCont" title="This tune appears to have failed processing. You can safely delete it">
    <mat-icon class="icon warning" color="warn">warning</mat-icon>
  </div> -->
</ng-template>
<ng-template #old>
  <div role="button" (click)="deleteFailedUpload(tune)" class="processingCont" title="This tune failed to process a long time ago. You can safely delete it.">
    <mat-icon class="icon warning" color="warn">error</mat-icon>
  </div>
</ng-template>
<ng-template #failedMessage>
  <ng-container *ngIf="!tune.attributes.is_ready_to_stream">
    <div role="button" (click)="deleteFailedUpload(tune)" class="label warn x-small deleteTune" *ngIf="!old_upload; else oldMessage">
      The upload failed to process in time. Delete it?
    </div>
  </ng-container>
</ng-template>
<ng-template #oldMessage>
  <div role="button" (click)="deleteFailedUpload(tune)" class="label warn x-small deleteTune">
    The upload failed a long time ago! Delete it.
  </div>
</ng-template>

<ng-template #downloaded>
  <div *ngIf="has_downloaded" class="button download" title="Downloaded"><mat-icon class="icon" inline="true">check_circle</mat-icon></div>
</ng-template>


  <mat-icon role="button" (click)="parentClick ? null : playPause($event)"
  [title]="title ? title : (playlist_id === player.playlistData.id && player.isPlaying()) ? 'Pause Playlist': 'Play Playlist' "
  [class]="
  (playlist_id === player.playlistData.id) ?
    ( lazy_loading || (player.isLoading()
    && !player.featured
    && !player.rewinds
    && !player.streaming
    && !player.streaming_custom)
     ? 'spinner '+addClass : (player.isPlaying() ? 'playing':'') + addClass )
          : (lazy_loading ? 'spinner ':'' ) + addClass"
  [svgIcon]="
    (playlist_id === player.playlistData.id) ?
    ( lazy_loading || (player.isLoading()
    && !player.featured
    && !player.rewinds
    && !player.streaming
    && !player.streaming_custom)
    ? svg_icons.buffering : (player.isPlaying() ? svg_icons.pause : svg_icons.play) )
          : (lazy_loading ? svg_icons.buffering :svg_icons.play )"
  >
  </mat-icon>

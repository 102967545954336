import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PlayerService } from '../../player.service';
import { TuneV2 } from '@app/models/tune-v2';
import { Platform } from '@angular/cdk/platform';
import { UserService } from '@services/user.service';
import { CountriesService } from '@services/countries.service';
import { TimeSlipService } from '@services/time-slip.service';
import { SnackbarService } from '@app/services/snackbar.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})

export class PlayerComponent implements OnInit {

  // Map the icomoon icon classnames to the player state index. (makes life easier in the footer player component template)
  // A subscriber below will handle the observable from the player service.
  // Using new custom material icons  (see app_componenet.ts for icon registration and definition)
  public svg_icons = [
    'play',        // 0
    'pause',       // 1
    'levels',        // 2
    'buffering'    // 3
  ];
  public icon_class = [
    'playerControls',         // 0
    'playerControls',         // 1
    'playerControls playing',         // 2
    'playerControls spinner'  // 3
  ];

  public collections_type = {
    'Single': 'Single',
    'Album': 'Album',
    'ExtendedPlayer': 'EP',
    'EP': 'EP' // JIC?
  };

  public collection_routings = {
    'Single': 'singles',
    'Album': 'albums',
    'ExtendedPlayer': 'eps',
    'EP': 'eps' // JIC
  };

  public stateIndex: number = 0;
  public streaming: boolean = true;
  public streaming_custom: boolean = false;

  public duration_secs: number;
  public position_secs: number = 0;
  public progress: number = 0; // 0.0 - 1.0
  public featured: boolean = false; // Rewinds
  public is_mp3: boolean = false; // Rewinds and some Featured Media
  // The diplayed tune. (nowPlayingTune or currentTune, set within the player service)
  public tune: TuneV2;

  // We need to hide the volume slider on mobile devices as it won't work.
  public is_mobile: boolean = false;
  public is_iphonex: boolean = false;

  public playlist_reverse:boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public userService: UserService, public player: PlayerService, private platform: Platform, public cs:CountriesService, public timeSlipService:TimeSlipService, private snackbar: SnackbarService) {
    this.checkMobile();

    if(typeof window !== 'undefined'){
      const _rev = localStorage.getItem('amz-revplaylist')
      if(_rev){
        this.playlist_reverse = true;
      }
    }
  }

  checkMobile(): void {
    if (!isPlatformBrowser(this.platformId)) {
      // Universal
      return
    }
    // Use this to deal with the bottom edge issue on these devices where the iOS UI gets in the way of the player UI
    // Also used for hiding the volume slider.
    // console.log('checkMobile: ', this.platform);
    if (this.platform.IOS || this.platform.ANDROID) {
      // Get the device pixel ratio
      const ratio = window.devicePixelRatio || 1;
      // Define the users device screen dimensions
      const screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
      };
      this.is_mobile = true; // Hides volume slider.
      // console.log('MOBILE', screen.width, screen.height);
      if (
        (screen.width === 828 && screen.height === 1792) || // XR, 11
        (screen.width === 1125 && screen.height === 2436) ||  // X, XS, 11 PRO
        (screen.width === 1242 && screen.height === 2688)  || // XS MAX, 11 PRO MAX
        (screen.width === 1080 && screen.height === 2340)  || // 12 MINI
        (screen.width === 1170 && screen.height === 2532)  || // 12
        (screen.width === 1284 && screen.height === 2778)   // 12 PRO MAX
      ) {
        this.is_iphonex = true;
      }
    } else {
      // console.log('not mobile');
    }
  }

  ngOnInit() {


    // Listen (subscribe) to audio player Observables from the player service.
    // MOVED to RadioHistoryService
    this.player.displayed_tune.subscribe(tune => {
      if(tune){
        // console.log('%cdisplayed tune updated:', 'color:lime', tune?.attributes.name);
        this.tune = tune;
      }
    });
    this.player.duration_secs.subscribe(duration => {
      if (duration === 0 || isNaN(duration) || duration === Infinity) {
        return;
      }
      this.duration_secs = duration;
    });
    this.player.position_secs.subscribe(position => this.position_secs = position);
    this.player.progress.subscribe(progress => this.progress = progress);
    this.player.is_streaming.subscribe(streaming => this.streaming = streaming);
    this.player.is_streaming_custom.subscribe(streaming_custom => {
      // console.log('STREAMING CUSTOM: ', streaming_custom);
      this.streaming_custom = streaming_custom;
    });
    this.player.is_featured.subscribe(featured => this.featured = featured);
    this.player.is_mp3.subscribe(is_mp3 => this.is_mp3 = is_mp3);
    this.player.iconState.subscribe(state => this.stateIndex = state);
  }

  togglePlaylistReverse(){
    this.playlist_reverse = !this.playlist_reverse
    if(typeof window !== 'undefined'){
      if(this.playlist_reverse){
        localStorage.setItem('amz-revplaylist', 'true')
        this.snackbar.show('Playlists will now play in reverse');
      } else {
        localStorage.removeItem('amz-revplaylist')
        this.snackbar.show('Playlists will now play normally');
      }
    }
  }

}

<div class="header">
  <h6>Are You Label or Promoter?</h6>
</div>

<div class="info">
  If you represent a Label / Promoter with many Artists, you will need to purchase a Membership to create each one and
  upload tunes on their behalf.
</div>
<div class="info">
  When you need to add more, you can purchase Membership Top-Ups as your artist roster grows.
</div>

<div class="btnCont">
  <!-- <div (click)="labelChoiceNotLabel()" class="btn yellow">I'm Not a Label/Promoter</div> -->
  <div (click)="labelChoiceOK()" class="btn yellow">OK</div>
</div>

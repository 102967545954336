import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Video } from '@models/video-v2';

/**
 * Service storage for uploaded videos in a page. `playlist`
 */

@Injectable({
  providedIn: 'root'
})
export class HlsVideoPlayerService {

  public playlist:Video[];
  public playlist_index:number = 0;
  public playlistIndexSubject = new Subject<number>();

  public playerDialogCloseSubject = new Subject<string>();

  constructor() { }

  getNext() : number {
    if(this.playlist && this.playlist.length > 0){
      this.playlist_index = (this.playlist_index + 1) % this.playlist.length;
      // console.log('getNext index:', this.playlist_index);
      this.playlistIndexSubject.next(this.playlist_index);
    }
    return this.playlist_index;
  }

  getPrevious() : number {
    if(this.playlist && this.playlist.length > 0){
      this.playlist_index = (this.playlist.length + (this.playlist_index - 1) % this.playlist.length ) % this.playlist.length;
      // console.log('getPrevious index:', this.playlist_index);
      this.playlistIndexSubject.next(this.playlist_index);
    }
    return this.playlist_index;
  }

}

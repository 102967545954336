<div *ngIf="latest_posts" class="latest-posts-cont">
  <h4 >Latest News</h4>
  <div class="latestPosts">
    <div *ngFor="let post of latest_posts" class="postPreview" (click)="clicked(post)">
      <a role="link"><img aria-hidden="true" [lazyImgSrc]="post.featured_image_url" src="/assets/placeholders/placeholder-square-300.jpg"/></a>
      <div>
        <div class="show_name" *ngIf="post.show_id" [innerHTML]="post.show_name"></div>
        <a role="link" [attr.aria-label]="post.title.rendered" class="post-title" [innerHTML]="post.title.rendered"></a>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * A simple image view dialog to get a closer view on artwork
 *
 */

interface ResourceImages {
  large:string;
  medium:string;
  small:string;
}

@Component({
  selector: 'app-dialog-image-viewer',
  templateUrl: './dialog-image-viewer.component.html',
  styleUrls: ['./dialog-image-viewer.component.scss']
})
export class DialogImageViewerComponent implements OnInit {

  public images:ResourceImages;

  constructor(
    public dialogRef: MatDialogRef<DialogImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceImages,
  ) {
    if(data){
      this.images = data;
    }
  }

  close():void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}

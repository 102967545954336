import {
  Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output,
  SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { resetExifOrientation, transformBase64BasedOnExifRotation } from './exif-utils';
import { resizeCanvas, fitImageToAspectRatio } from './resize-utils';
import { ImageV2 } from '@models/image-v2';

export type OutputType = 'base64' | 'file' | 'both';
// interfaces
export interface CropRect {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
export interface Dimensions {
  width: number;
  height: number;
}

export interface CropperReadyEvent {
  loadedImageBase64: string;
  loadedImageSize: Dimensions;
  originalImageSize: Dimensions;
  newImageFile?: File;
}

export interface ImageCroppedEvent {
  base64?: string | null;
  originalImageBase64?: string | null;
  loadedImageBase64?: string | null;
  croppedImageBase64?: string | null;
  croppedImageDimensions?: Dimensions | null;
  originalImageDimensions?: Dimensions | null;
  cropRectRelativeToOriginal?: CropRect;
  manuallyCropped?: boolean;
}
export interface MoveStart {
  active: boolean;
  type: string | null;
  position: string | null;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  clientX: number;
  clientY: number;
}
///////

@Component({
  selector: 'image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnChanges {
  private pickedFile: File;

  private loadedImage: any | null;
  private loadedBase64: string;
  private moveStart: MoveStart;
  private maxSize: Dimensions;
  //private originalSize: Dimensions;
  private loadedSize: Dimensions;

  public is_loaded: boolean = false;

  private setImageMaxSizeRetries = 0;
  private cropperScaledMinWidth = 20;
  private cropperScaledMinHeight = 20;

  private scale: number = 1;

  private manuallyCropped: boolean = false;

  safeImgDataUrl: SafeUrl | string;
  marginLeft: SafeStyle | string = '0px';
  imageVisible = false;

  @ViewChild('sourceImage', { static: true }) sourceImage: ElementRef;

  @Input()
  set imageFileChanged(file: File) {
    this.initCropper();
    if (file) {

      this.loadImageFile(file);
    }
  }

  @Input()
  set imageChangedEvent(event: any) {
    this.initCropper();
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      // console.log('cropper:imageChangedEvent: ', event.target.files[0]);
      this.loadImageFile(event.target.files[0]);
    }
  }

  @Input()
  set imageBase64(imageBase64: string) {
    this.initCropper();
    // console.log('imageBase64 set...');
    this.checkExifAndLoadBase64Image(imageBase64);
  }

  @Input() format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';
  @Input() outputType: OutputType = 'both';
  @Input() maintainAspectRatio = true;

  @Input() inputImageFileUrl: string = '';

  @Input() aspectRatio = 1;
  @Input() resizeToWidth; // set in template
  @Input() resizeToHeight;
  @Input() cropperMinWidth = 0;
  @Input() minimumWidth = 0;
  @Input() cropperMinHeight = 0;
  @Input() roundCropper = false;
  @Input() onlyScaleDown = false;
  @Input() imageQuality = 92;
  @Input() autoCrop = true;
  @Input() backgroundColor: string;
  @Input() containWithinAspectRatio = false;
  @Input() originalImageData: ImageV2;
  @Input() cropper: CropRect = {
    x1: -100,
    y1: -100,
    x2: 10000,
    y2: 10000
  };
  @HostBinding('style.text-align')
  @Input() alignImage: 'left' | 'center' = 'center';


  @Output() startCropImage = new EventEmitter<void>();
  @Output() imageCropped = new EventEmitter<ImageCroppedEvent>();
  @Output() imageCroppedBase64 = new EventEmitter<string>();
  @Output() imageCroppedFile = new EventEmitter<Blob>();
  @Output() imageLoaded = new EventEmitter<void>();
  @Output() cropperReady = new EventEmitter<CropperReadyEvent>();
  @Output() loadImageFailed = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private zone: NgZone) {
    this.initCropper();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cropper) {
      this.setMaxSize();
      this.setCropperScaledMinSize();
      this.checkCropperPosition(false);
      this.doAutoCrop();
      this.cd.markForCheck();
    }
    if (changes.aspectRatio && this.imageVisible) {
      // console.log('calling resetCropperPosition in ngOnChanges.. orientation change?');
      this.resetCropperPosition();
    }
  }



  private initCropper(img?: any): void {
    this.scale = 1;
    this.imageVisible = false;
    this.loadedImage = null;
    this.safeImgDataUrl = 'data:image/png;base64,iVBORw0KGg'
      + 'oAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAU'
      + 'AAarVyFEAAAAASUVORK5CYII=';

    this.moveStart = {
      active: false,
      type: null,
      position: null,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      clientX: 0,
      clientY: 0
    };
    this.maxSize = {
      width: 0,
      height: 0
    };
    this.loadedSize = {
      width: 0,
      height: 0
    };
    this.cropper.x1 = -100;
    this.cropper.y1 = -100;
    this.cropper.x2 = 10000;
    this.cropper.y2 = 10000;
  }

  private loadImageFile(file: File): void {
    const fileReader = new FileReader();
    this.pickedFile = file;
    this.originalImageData = null; // clear this since we've just loaded a new image.
    fileReader.onload = (event: any) => {
      const imageType = file.type;
      if (this.isValidImageType(imageType)) {
        this.checkExifAndLoadBase64Image(event.target.result);
      } else {
        this.loadImageFailed.emit('invalid image type: ' + imageType);
      }
    };
    fileReader.readAsDataURL(file);
  }

  private isValidImageType(type: string): boolean {
    return /image\/(png|jpg|jpeg)/.test(type);
  }

  private checkExifAndLoadBase64Image(imageBase64: string): void {
    //console.log('checkExifAndLoadBase64Image');
    resetExifOrientation(imageBase64)
      .then((resultBase64: string) => this.fitImageToAspectRatio(resultBase64))
      .then((resultBase64: string) => this.loadBase64Image(resultBase64))
      .catch(() => this.loadImageFailed.emit('failed exif orientation check'));
  }

  private fitImageToAspectRatio(imageBase64: string): Promise<string> {
    return this.containWithinAspectRatio
      ? fitImageToAspectRatio(imageBase64, this.aspectRatio)
      : Promise.resolve(imageBase64);
  }

  private loadBase64Image(imageBase64: string): void {
    this.loadedBase64 = imageBase64;
    // console.log('%c LOAD BASE64IMAGE', 'fcolor:cyan');
    this.safeImgDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageBase64);
    this.loadedImage = new Image();
    this.loadedImage.onload = () => {
      // console.log('WIDTH', this.loadedImage.width);
      this.loadedSize.width = this.loadedImage.width;
      this.loadedSize.height = this.loadedImage.height;
      this.cd.markForCheck();
    };
    this.loadedImage.src = imageBase64;
  }

  private dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  // img load event in template
  imageLoadedInView(): void {
    if (this.loadedImage !== null) {
      this.manuallyCropped = false;
      this.imageLoaded.emit();
      this.setImageMaxSizeRetries = 0;
      setTimeout(() => this.checkImageMaxSizeRecursively());
    }
  }

  private checkImageMaxSizeRecursively(): void {
    if (this.setImageMaxSizeRetries > 40) {
      this.loadImageFailed.emit();
    } else if (this.sourceImage && this.sourceImage.nativeElement && this.sourceImage.nativeElement.offsetWidth > 0) {
      this.setMaxSize();
      this.setCropperScaledMinSize();
      this.resetCropperPosition();
      // console.log(`%c Loaded image size  : ${this.loadedSize.width} x ${this.loadedSize.height}`, 'color:lightblue;font-weight:bold');
      // console.log(`%c Visible image size in browser dialog : ${this.sourceImage.nativeElement.offsetWidth} x ${this.sourceImage.nativeElement.offsetHeight}`, 'color:green;font-weight:bold');
      if (this.originalImageData) {
        // console.log(`%c Original source image size: ${this.originalImageData.original_width} x ${this.originalImageData.original_height}`, 'color:green;font-weight:bold');
        this.is_loaded = true;
        this.cropperReady.emit({ loadedImageSize: this.loadedSize, originalImageSize: { width: this.originalImageData.original_width, height: this.originalImageData.original_height }, loadedImageBase64: this.loadedBase64, newImageFile: this.pickedFile });
      } else {
        // console.log('%c No original image data provided from API', 'color:red;font-weight:bold');
        this.is_loaded = true;
        this.cropperReady.emit({ loadedImageSize: this.loadedSize, originalImageSize: { width: this.loadedSize.width, height: this.loadedSize.height }, loadedImageBase64: this.loadedBase64, newImageFile: this.pickedFile });
        this.imageVisible = false;
      }
      this.cd.markForCheck();
    } else {
      this.setImageMaxSizeRetries++;
      setTimeout(() => {
        this.checkImageMaxSizeRecursively();
      }, 50);
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resizeCropperPosition();
    this.setMaxSize();
    this.setCropperScaledMinSize();
  }

  rotateLeft() {
    this.transformBase64(8);
  }

  rotateRight() {
    this.transformBase64(6);
  }

  flipHorizontal() {
    this.transformBase64(2);
  }

  flipVertical() {
    this.transformBase64(4);
  }

  private transformBase64(exifOrientation: number): void {
    if (this.loadedBase64) {
      transformBase64BasedOnExifRotation(this.loadedBase64, exifOrientation)
        .then((resultBase64: string) => this.fitImageToAspectRatio(resultBase64))
        .then((rotatedBase64: string) => this.loadBase64Image(rotatedBase64));
    }
  }

  private resizeCropperPosition(): void {
    const sourceImageElement = this.sourceImage.nativeElement;
    if (this.maxSize.width !== sourceImageElement.offsetWidth || this.maxSize.height !== sourceImageElement.offsetHeight) {
      this.cropper.x1 = this.cropper.x1 * sourceImageElement.offsetWidth / this.maxSize.width;
      this.cropper.x2 = this.cropper.x2 * sourceImageElement.offsetWidth / this.maxSize.width;
      this.cropper.y1 = this.cropper.y1 * sourceImageElement.offsetHeight / this.maxSize.height;
      this.cropper.y2 = this.cropper.y2 * sourceImageElement.offsetHeight / this.maxSize.height;
      // console.log('set cropper: ', this.cropper);
    }
  }

  private resetCropperPosition(): void {
    // console.log('%c resetCropperPosition', 'font-weight:bold');
    const sourceImageElement = this.sourceImage.nativeElement;
    if (this.originalImageData) {
      // scale the croprect to fit the image in the browser..
      // console.log('use this.originalImageData: ', this.originalImageData);
      // console.log('cropper image size in browser: ', sourceImageElement.offsetWidth, sourceImageElement.offsetHeight);
      // console.log('original SOURCE image size: ', this.originalSourceImageSize.width, this.originalSourceImageSize.height);
      // Store this to upscale the final cropRect values on their way back to the dialog
      this.scale = sourceImageElement.offsetWidth / this.originalImageData.original_width;
      // console.log('using scale for UI: ', this.scale);
      if (this.minimumWidth) {
        // limit cropper win width
        this.cropperMinWidth = this.minimumWidth * (this.loadedSize.width / this.originalImageData.original_width) + 1;
        // console.log('%cset this.cropperMinWidth to ', 'color:orange', this.cropperMinWidth);
        this.setCropperScaledMinSize();
      }

      this.cropper = {
        x1: Math.floor(this.originalImageData.crop.x * this.scale),
        y1: Math.floor(this.originalImageData.crop.y * this.scale),
        x2: Math.ceil((this.originalImageData.crop.x * this.scale) + (this.originalImageData.crop.w * this.scale)),
        y2: Math.ceil((this.originalImageData.crop.y * this.scale) + (this.originalImageData.crop.h * this.scale))
      }
      // console.log('this.cropper pre-scaled: ', this.cropper);
    } else {
      // console.log('NO originalImageData. aspectRatio', this.aspectRatio);
      if (!this.maintainAspectRatio) {
        this.cropper.x1 = 0;
        this.cropper.x2 = sourceImageElement.offsetWidth;
        this.cropper.y1 = 0;
        this.cropper.y2 = sourceImageElement.offsetHeight;
      } else if (sourceImageElement.offsetWidth / this.aspectRatio < sourceImageElement.offsetHeight) {
        this.cropper.x1 = 0;
        this.cropper.x2 = sourceImageElement.offsetWidth;
        const cropperHeight = sourceImageElement.offsetWidth / this.aspectRatio;
        this.cropper.y1 = (sourceImageElement.offsetHeight - cropperHeight) / 2;
        this.cropper.y2 = this.cropper.y1 + cropperHeight;
      } else {
        this.cropper.y1 = 0;
        this.cropper.y2 = sourceImageElement.offsetHeight;
        const cropperWidth = sourceImageElement.offsetHeight * this.aspectRatio;
        this.cropper.x1 = (sourceImageElement.offsetWidth - cropperWidth) / 2;
        this.cropper.x2 = this.cropper.x1 + cropperWidth;
      }
    }
    // console.log('%cthis.cropper: ', 'color:yellow', this.cropper);
    this.doAutoCrop();
  }

  startMove(event: any, moveType: string, position: string | null = null): void {
    event.preventDefault();
    this.moveStart = {
      active: true,
      type: moveType,
      position: position,
      clientX: this.getClientX(event),
      clientY: this.getClientY(event),
      ...this.cropper
    };
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  moveImg(event: any): void {
    if (this.moveStart.active) {
      event.stopPropagation();
      event.preventDefault();
      if (this.moveStart.type === 'move') {
        this.move(event);
        this.checkCropperPosition(true);
      } else if (this.moveStart.type === 'resize') {
        this.resize(event);
        this.checkCropperPosition(false);
      }
      this.cd.detectChanges();
    }
  }

  private setMaxSize(): void {
    const sourceImageElement = this.sourceImage.nativeElement;
    this.maxSize.width = sourceImageElement.offsetWidth;
    this.maxSize.height = sourceImageElement.offsetHeight;
    // console.log('maxSize: ', this.maxSize.width, this.maxSize.height);
    this.marginLeft = this.sanitizer.bypassSecurityTrustStyle('calc(50% - ' + this.maxSize.width / 2 + 'px)');
  }

  private setCropperScaledMinSize(): void {
    //console.log('setCropperScaledMinSize...');
    if (this.loadedImage) {
      this.setCropperScaledMinWidth();
      this.setCropperScaledMinHeight();
    } else {
      this.cropperScaledMinWidth = 20;
      this.cropperScaledMinHeight = 20;
    }
  }

  private setCropperScaledMinWidth(): void {

    if (this.cropperMinWidth === 0 && this.minimumWidth) {
      //console.log('image width in dialog: ', this.sourceImage.nativeElement.offsetWidth);
      this.cropperMinWidth = this.minimumWidth;
      // console.log('%cset this.cropperMinWidth to ', 'color:teal', this.cropperMinWidth, this.loadedSize.width);
    }


    this.cropperScaledMinWidth = this.cropperMinWidth > 0
      ? Math.max(20, this.cropperMinWidth / this.loadedImage.width * this.maxSize.width)
      : 20;
    // console.log('setCropperScaledMinWidth: cropperScaledMinWidth', this.cropperScaledMinWidth);
  }

  private setCropperScaledMinHeight(): void {
    if (this.maintainAspectRatio) {
      this.cropperScaledMinHeight = Math.max(20, this.cropperScaledMinWidth / this.aspectRatio);
    } else if (this.cropperMinHeight > 0) {
      this.cropperScaledMinHeight = Math.max(20, this.cropperMinHeight / this.loadedImage.height * this.maxSize.height);
    } else {
      this.cropperScaledMinHeight = 20;
    }
    // console.log('setCropperScaledMinHeight: cropperScaledMinHeight', this.cropperScaledMinHeight);
  }

  private checkCropperPosition(maintainSize = false): void {
    if (this.cropper.x1 < 0) {
      this.cropper.x2 -= maintainSize ? this.cropper.x1 : 0;
      this.cropper.x1 = 0;
    }
    if (this.cropper.y1 < 0) {
      this.cropper.y2 -= maintainSize ? this.cropper.y1 : 0;
      this.cropper.y1 = 0;
    }
    if (this.cropper.x2 > this.maxSize.width) {
      this.cropper.x1 -= maintainSize ? (this.cropper.x2 - this.maxSize.width) : 0;
      this.cropper.x2 = this.maxSize.width;
    }
    if (this.cropper.y2 > this.maxSize.height) {
      this.cropper.y1 -= maintainSize ? (this.cropper.y2 - this.maxSize.height) : 0;
      this.cropper.y2 = this.maxSize.height;
    }
  }

  @HostListener('document:mouseup')
  @HostListener('document:touchend')
  moveStop(): void {
    if (this.moveStart.active) {
      this.moveStart.active = false;
      this.manuallyCropped = true;

      this.doAutoCrop();
    }
  }

  private move(event: any) {
    const diffX = this.getClientX(event) - this.moveStart.clientX;
    const diffY = this.getClientY(event) - this.moveStart.clientY;

    this.cropper.x1 = this.moveStart.x1 + diffX;
    this.cropper.y1 = this.moveStart.y1 + diffY;
    this.cropper.x2 = this.moveStart.x2 + diffX;
    this.cropper.y2 = this.moveStart.y2 + diffY;
  }

  private resize(event: any): void {
    const diffX = this.getClientX(event) - this.moveStart.clientX;
    const diffY = this.getClientY(event) - this.moveStart.clientY;
    switch (this.moveStart.position) {
      case 'left':
        this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
        break;
      case 'topleft':
        this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
        this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
        break;
      case 'top':
        this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
        break;
      case 'topright':
        this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
        this.cropper.y1 = Math.min(this.moveStart.y1 + diffY, this.cropper.y2 - this.cropperScaledMinHeight);
        break;
      case 'right':
        this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
        break;
      case 'bottomright':
        this.cropper.x2 = Math.max(this.moveStart.x2 + diffX, this.cropper.x1 + this.cropperScaledMinWidth);
        this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
        break;
      case 'bottom':
        this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
        break;
      case 'bottomleft':
        this.cropper.x1 = Math.min(this.moveStart.x1 + diffX, this.cropper.x2 - this.cropperScaledMinWidth);
        this.cropper.y2 = Math.max(this.moveStart.y2 + diffY, this.cropper.y1 + this.cropperScaledMinHeight);
        break;
    }

    if (this.maintainAspectRatio) {
      this.checkAspectRatio();
    }
  }

  private checkAspectRatio(): void {
    let overflowX = 0;
    let overflowY = 0;

    switch (this.moveStart.position) {
      case 'top':
        this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
        overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
        overflowY = Math.max(0 - this.cropper.y1, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
        }
        break;
      case 'bottom':
        this.cropper.x2 = this.cropper.x1 + (this.cropper.y2 - this.cropper.y1) * this.aspectRatio;
        overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
        overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : (overflowX / this.aspectRatio);
        }
        break;
      case 'topleft':
        this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
        overflowX = Math.max(0 - this.cropper.x1, 0);
        overflowY = Math.max(0 - this.cropper.y1, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
        }
        break;
      case 'topright':
        this.cropper.y1 = this.cropper.y2 - (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
        overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
        overflowY = Math.max(0 - this.cropper.y1, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y1 += (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
        }
        break;
      case 'right':
      case 'bottomright':
        this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
        overflowX = Math.max(this.cropper.x2 - this.maxSize.width, 0);
        overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x2 -= (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
        }
        break;
      case 'left':
      case 'bottomleft':
        this.cropper.y2 = this.cropper.y1 + (this.cropper.x2 - this.cropper.x1) / this.aspectRatio;
        overflowX = Math.max(0 - this.cropper.x1, 0);
        overflowY = Math.max(this.cropper.y2 - this.maxSize.height, 0);
        if (overflowX > 0 || overflowY > 0) {
          this.cropper.x1 += (overflowY * this.aspectRatio) > overflowX ? (overflowY * this.aspectRatio) : overflowX;
          this.cropper.y2 -= (overflowY * this.aspectRatio) > overflowX ? overflowY : overflowX / this.aspectRatio;
        }
        break;
    }
  }

  private doAutoCrop(): void {
    // console.log('doAutoCrop: ', this.moveStart);
    if (this.autoCrop) {
      this.crop();
    }
  }

  crop(outputType: OutputType = this.outputType): ImageCroppedEvent | Promise<ImageCroppedEvent> | null {
    if (this.sourceImage.nativeElement && this.loadedImage != null) {
      // console.log('crop() ', this.sourceImage.nativeElement);
      this.startCropImage.emit();
      const imagePosition = this.getImagePosition();
      // console.log('IMAGE POSITION', imagePosition);
      const width = imagePosition.x2 - imagePosition.x1;
      const height = imagePosition.y2 - imagePosition.y1;

      const cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
      cropCanvas.width = width;
      cropCanvas.height = height;

      const ctx = cropCanvas.getContext('2d');
      if (ctx) {
        if (this.backgroundColor != null) {
          ctx.fillStyle = this.backgroundColor;
          ctx.fillRect(0, 0, width, height);
        }
        ctx.drawImage(
          this.loadedImage,
          imagePosition.x1,
          imagePosition.y1,
          width,
          height,
          0,
          0,
          width,
          height
        );

        // scale factor from the actual original image to the 'precrop' image that gets loaded..

        let end_scale = this.scale;
        if (this.originalImageData) {
          // console.log('re-calculate end scale: ', this.loadedSize.width, this.originalImageData.original_width);
          end_scale = this.loadedSize.width / this.originalImageData.original_width;
        }
        // console.log('use end_scale: ', end_scale);
        if (end_scale === undefined) {
          end_scale = 1;
        }

        // console.log('check imagePosition: ', imagePosition);

        let cropRectRelativeToOriginal: CropRect = {
          x1: Math.round(imagePosition.x1 / end_scale),
          y1: Math.round(imagePosition.y1 / end_scale),
          x2: Math.round(imagePosition.x2 / end_scale),
          y2: Math.round(imagePosition.y2 / end_scale)
        };

        // console.log('cropRectRelativeToOriginal: ', cropRectRelativeToOriginal);
        let _width = cropRectRelativeToOriginal.x2 - cropRectRelativeToOriginal.x1;
        let _height = cropRectRelativeToOriginal.y2 - cropRectRelativeToOriginal.y1;

        //console.log('%cw x h : RelativeToOriginal: ', 'color:yellow', _width, _height);
        if(_width !== _height && this.maintainAspectRatio){
           //console.log('%cImage is not quite square!', 'color:red');
          if(_width > _height){
            cropRectRelativeToOriginal.y2 = cropRectRelativeToOriginal.y2 - (_height - _width);
          } else if(_width < _height){
            cropRectRelativeToOriginal.x2 = cropRectRelativeToOriginal.x2 - (_width - _height);
          }
          _width = cropRectRelativeToOriginal.x2 - cropRectRelativeToOriginal.x1;
          _height = cropRectRelativeToOriginal.y2 - cropRectRelativeToOriginal.y1;
          //console.log('%cw x h : Adjusted RelativeToOriginal: ', 'color:orange', _width, _height);
        }

        // console.log('width, height: ', width, height);
        let croppedImageDimensions: Dimensions = {
          width: width,
          height: height
        };
        const output = { croppedImageDimensions, cropRectRelativeToOriginal: cropRectRelativeToOriginal, cropperPosition: { ...this.cropper } };
        //const output = { croppedImageDimensions, cropRectRelativeToOriginal: cropRectRelativeToOriginal };
        const resizeRatio = this.getResizeRatio(croppedImageDimensions.width, croppedImageDimensions.height);
        // console.log('resizeRatio, this.maintainAspectRatio: ', resizeRatio, this.maintainAspectRatio);
        if (resizeRatio !== 1) {
          croppedImageDimensions.width = Math.round(croppedImageDimensions.width * resizeRatio);
          croppedImageDimensions.height = this.maintainAspectRatio
            ? Math.round(croppedImageDimensions.width / this.aspectRatio)
            : Math.round(croppedImageDimensions.height * resizeRatio);

          resizeCanvas(cropCanvas, croppedImageDimensions.width, croppedImageDimensions.height);
        }
        output.croppedImageDimensions = croppedImageDimensions;
        return this.cropToOutputType(outputType, cropCanvas, output);
      }
    } else {
      console.log('NOTHING TO CROP:', this.sourceImage.nativeElement, this.loadedImage);
    }
    return null;
  }

  private getImagePosition(): CropRect {
    const sourceImageElement = this.sourceImage.nativeElement;
    const ratio = this.loadedSize.width / sourceImageElement.offsetWidth;
    return {
      x1: Math.round(this.cropper.x1 * ratio),
      y1: Math.round(this.cropper.y1 * ratio),
      x2: Math.min(Math.round(this.cropper.x2 * ratio), this.loadedSize.width),
      y2: Math.min(Math.round(this.cropper.y2 * ratio), this.loadedSize.height)
    };
  }

  private cropToOutputType(outputType: OutputType, cropCanvas: HTMLCanvasElement, output: ImageCroppedEvent): ImageCroppedEvent | Promise<ImageCroppedEvent> {
    output.manuallyCropped = this.manuallyCropped;

    // console.log('cropToOutputType: ', outputType, this.manuallyCropped);
    if (this.originalImageData) {
      output.originalImageDimensions = { width: this.originalImageData.original_width, height: this.originalImageData.original_height };
    }

    this.imageVisible = true;
    // output.cropperPosition.
    switch (outputType) {
      case 'file':
        return this.cropToFile(cropCanvas)
          .then((result: Blob | null) => {
            // output.file = result; // No need to send this back right now.
            output.loadedImageBase64 = this.loadedBase64;
            this.imageCropped.emit(output);
            return output;
          });
      case 'both':
        output.croppedImageBase64 = this.cropToBase64(cropCanvas);
        return this.cropToFile(cropCanvas)
          .then((result: Blob | null) => {
            // output.file = result; // No need to send this back right now.
            output.loadedImageBase64 = this.loadedBase64;
            this.imageCropped.emit(output);
            return output;
          });
      default:
        // console.log('OUTPUT cropRectRelativeToOriginal: ', output.cropRectRelativeToOriginal);
        // console.log('%coutput crop width,height: ', 'color:cyan', output.cropRectRelativeToOriginal.x2 - output.cropRectRelativeToOriginal.x1, output.cropRectRelativeToOriginal.y2 - output.cropRectRelativeToOriginal.y1);
        output.croppedImageBase64 = this.cropToBase64(cropCanvas);
        output.loadedImageBase64 = this.loadedBase64;
        this.imageCropped.emit(output);
        return output;
    }
  }

  private cropToBase64(cropCanvas: HTMLCanvasElement): string {
    const imageBase64 = cropCanvas.toDataURL('image/' + this.format, this.getQuality());
    this.imageCroppedBase64.emit(imageBase64);
    return imageBase64;
  }

  private cropToFile(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
    return this.getCanvasBlob(cropCanvas)
      .then((result: Blob | null) => {
        if (result) {
          this.imageCroppedFile.emit(result);
        }
        return result;
      });
  }

  private getCanvasBlob(cropCanvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise((resolve) => {
      cropCanvas.toBlob(
        (result: Blob | null) => this.zone.run(() => resolve(result)),
        'image/' + this.format,
        this.getQuality()
      );
    });
  }

  private getQuality(): number {
    return Math.min(1, Math.max(0, this.imageQuality / 100));
  }

  private getResizeRatio(width: number, height: number): number {
    if (this.resizeToWidth > 0) {
      if (!this.onlyScaleDown || width > this.resizeToWidth) {
        return this.resizeToWidth / width;
      }
    } else if (this.resizeToHeight > 0) {
      if (!this.onlyScaleDown || height > this.resizeToHeight) {
        return this.resizeToHeight / height;
      }
    }
    return 1;

  }

  private getClientX(event: any): number {
    return event.clientX || event.touches && event.touches[0] && event.touches[0].clientX;
  }

  private getClientY(event: any): number {
    return event.clientY || event.touches && event.touches[0] && event.touches[0].clientY;
  }
}

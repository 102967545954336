import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { VideoPlaylistService } from '@services/video-playlist.service';

declare var _paq;

@Component({
  selector: 'app-action-button-like',
  templateUrl: './action-button-like.component.html',
  styleUrls: ['./action-button-like.component.scss'],
  inputs:['resource','with_label']
})
export class ActionButtonLikeComponent implements OnInit {

  public resource:any;
  public with_label:string;

  constructor(private videoPlaylistService:VideoPlaylistService, private router:Router, private userService:UserService, private snackbar:SnackbarService, private api_tunes:ApiAmazingtunesV2Service) { }

  ngOnInit(): void {
    //  console.log(this.resource.type, this.resource.attributes.name, this.resource.attributes.type);
  }

  setReturnPath(){
    // Set a localStorage item to send the user back after logging in,
    // Store the current url..
    const path = this.router.routerState.snapshot.url;
    if(typeof window !== 'undefined'){
      localStorage.setItem('return_path', path);
    }
  }

  like(event?:any){
    if(event){
      event.stopPropagation();
    }

    if(!this.userService.loggedIn()){
      this.snackbar.snackbarRef = this.snackbar.snackBar.open('You need to be logged in to do that.','Log In', {
        duration:3000,
        horizontalPosition:'end',
        verticalPosition:'bottom' // (no 'middle/center' og page. Top or bottom only)
      });
      this.snackbar.snackbarRef.onAction().subscribe(() => {
          this.setReturnPath();
          this.router.navigate(['/connect']);
      });
      return;
    }

    // console.log((this.resource.meta.liked_by_user ? '💔':'❤️'), this.resource.type, this.resource);

    this.api_tunes.likeResource(this.resource).subscribe((data) => {
      // console.log('Like response: ', data); // null
      this.resource.meta.liked_by_user = !this.resource.meta.liked_by_user;
      this.snackbar.show((this.resource.meta.liked_by_user ? 'Liked':'Unliked')+' '+ (this.resource.type === 'external_video' ? 'video' : this.resource.type) );

      let _var:string = this.resource.type + ' : ';
      switch(this.resource.type){
        case 'tune':
        case 'collection':
          _var += this.resource.artist.attributes.name + ' - ' + this.resource.attributes.name;
          break;
        case 'external_video':
          _var += this.resource.artist.attributes.name + ' - ' + this.resource.attributes.name;
          this.videoPlaylistService.toggleLike(this.resource.id);
          break;
        case 'video':
          _var += this.resource.artist.attributes.name + ' - ' + this.resource.attributes.name;
          break;
        case 'playlist':
          _var += this.resource._curator.attributes.name + ' - ' + this.resource.attributes.name;
          break;
        case 'user':
          _var += this.resource.attributes.name;
          break;
        default:
          break;
      }
      _paq.push(['trackEvent', this.resource.meta.liked_by_user ? 'Like':'Unlike', _var ]);
    },
    (error)=> {
      console.log('Error liking resource: ', error);
      this.snackbar.show('Error liking '+this.resource.type);
    });
  }
}

<!-- Video card component - internal -->
<div class="video-card"  (click)="viewVideo()">
  <div class="cont-16-9" aria-hidden="true">
    <img [title]="'Play video'" [lazyImgSrc]="video.attributes.thumbnail.medium" src="/assets/placeholders/placeholder-wide-dark.png">
    <div *ngIf="video.attributes.status === 'transcoded' && !video.meta.is_available" [title]="(video.attributes.published_at ? 'Set to publish on ' + (video.attributes.published_at | date : 'MMMM d, y')+'\n\n' :'') + 'This video is not yet available to the public. Click here to edit the publish date.'" class="label x-small warn available" (click)="editVideo($event)"><div>Not published yet</div></div>
    <div *ngIf="video.attributes.status === 'uploaded'" title="Video is still transcoding" class="label x-small blue available"><div>transcoding</div><mat-icon class="spinner" svgIcon="buffering"></mat-icon> </div>
    <div *ngIf="video.attributes.status === 'error'" title="There was an error with this video. Please delete the record and try again." class="label x-small warn available">Error</div>
  </div>
  <div class="info-cont" [class]="!video.meta.is_available ? 'not-published':''">
    <div>
      <div role="link" aria-label="Go to artist profile"  (click)="goToArtistProfile($event)" class="name" *ngIf="show_artist" [innerHTML]="video.artist.attributes.name"></div>
      <div role="link" aria-label="Go to video page" (click)="goToVideoPage($event)" [class]="show_artist ? 'title':'title embolden'" [innerHTML]="video.attributes.name"></div>
      <div *ngIf="url.includes('/firstlook')" title="Date published" class="date-foot" [innerHTML]="video.attributes.published_at| date:'MMM d, h:mm a'"></div>
    </div>
    <div class="edit-delete" *ngIf="owner_page && (video._is_owner || video._is_manager)">
      <div title="Edit this video" (click)="editVideo($event)" class="edit">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
  </div>
</div>

<div class="recorder-cont">
    <h6 class="mat-dialog-title">{{ resource_type }} INTRO <span *ngIf="!is_mobile">RECORDER/</span>UPLOADER</h6>

    <div>
        <!-- <mat-dialog-content> -->
        <div class="inst">
            <span *ngIf="!is_mobile">Record or </span>Upload a short audio introduction for <strong *ngIf="resource"
                [innerHTML]="this.resource?.attributes?.name"></strong>.
        </div>

        <div @fadeIn *ngIf="!show_example; else example">

            <div class="option-tabs" *ngIf="!is_mobile">
                <div class="option upload" [class]="option === 'upload' ? 'active':''" (click)="setOption('upload')">
                    <mat-icon inline="true">upload</mat-icon> UPLOAD FILE
                </div>
                <div class="option record" [class]="option === 'record' ? 'active':''" (click)="setOption('record')">
                    <mat-icon inline="true">mic</mat-icon> RECORD AUDIO
                </div>
            </div>

            <div class="uploader" *ngIf="option === 'upload'">

                <input type="file" #fileInput style="display: none" [accept]="fileFormInputAccepts"
                    (change)="selectedFiles($event)" />

                <div class="uploaderCont">
                    <!-- Buttons -->
                    <div class="buttons" (drop)="droppedFiles($event)" (dragleave)="dragLeave($event)"
                        (dragover)="dragOver($event)">
                        <!-- Browse -->
                        <div class="browse" #browse (click)="browseFiles()">
                            <mat-icon class="icon">add_circle</mat-icon>
                            <div class="label uploaderButton block">Select Audio File</div>
                        </div>
                        <!-- Empty -->
                        <div class="dragArea" [class]="uploading ? 'uploading':''" #dragArea
                            *ngIf="!uploading && !file_uploaded && !isMobile();else empty">
                            You can also drag & drop an audio file here
                        </div>
                        <!-- Cancel -->
                        <div class="cancel" *ngIf="uploading" [class]="!uploading ? 'disabled':''"
                            (click)="clearFiles()" #cancel>
                            <mat-icon class="icon">cancel</mat-icon>
                            <div class="label uploaderButton block">Cancel</div>
                        </div>
                    </div>

                    <ng-template #empty>
                        <div></div>
                    </ng-template>

                    <!-- Instructions > Selected File info/Upload progress -->
                    <div class="fileInfoCont">
                        <div class="fileInfo" *ngIf="files.size > 0 || uploadSuccessful; else step1">

                            <div #progressFile class="progress" [id]="audio_item.audio_blob?.name"></div>

                            <div class="file" *ngIf="audio_item.audio_blob">
                                <div #fileName class="fileName elipsify" [innerHTML]="audio_item.audio_blob.name"></div>
                                <div #fileDetails class="fileDetails elipsify">{{audio_item.audio_blob.size |
                                    filesize}},
                                    {{audio_item.audio_blob.type}}
                                    <span *ngIf="audio_item.duration">
                                        Duration: {{audio_item.duration | secsToTime}}
                                    </span>
                                </div>
                                <div class="fileMessage" [innerHTML]="filesInfo"></div>
                            </div>
                        </div>

                        <ng-template #step1>
                            <div class="instructions">
                                <div class="label block">UPLOAD {{ resource_type }} INTRODUCTION</div>
                                {{ fileFormInputAccepts ? 'Accepted file types: ' + fileFormInputAccepts : '' }}
                            </div>
                        </ng-template>

                    </div>
                </div>

                <div class="uploader-inst">
                    <div *ngIf="!is_ready_to_upload; else readyToUploadFile">
                        Select an audio file recording of you introducing your {{ resource_type }}.
                    </div>
                </div>

                <ng-template #readyToUploadFile>
                    <div class="ready-btns">
                        <div (click)="clearFiles()" class="label x-small blue link"> RESET </div>
                        <div></div>
                        <div (click)="startUpload()" class="use-btn label x-small blue link"> 
                            <mat-icon inline="true">check_circle</mat-icon> UPLOAD THIS AUDIO FILE
                        </div>
                    </div>
                </ng-template>

            </div>

            <div *ngIf="!is_mobile && option === 'record'" class="recorder">

                <div class="controls-cont">

                    <mat-icon *ngIf="!is_ready_to_play" (click)="toggleRecording()" class="material-symbols-outlined"
                        inline="true" [innerText]="is_recording ? 'stop_circle':'radio_button_checked'"></mat-icon>

                    <mat-icon *ngIf="is_ready_to_play" (click)="togglePlay()" class="material-symbols-outlined"
                        inline="true" [innerText]="is_recording ? 'stop_circle':'radio_button_checked'"></mat-icon>

                    <div *ngIf="!is_ready_to_play" class="controls-txt" [innerText]="is_recording ? 'STOP':'RECORD'">
                    </div>
                    <div *ngIf="is_ready_to_play" class="controls-txt" [innerText]="is_playing ? 'PAUSE':'PLAY'"></div>
                </div>
                <div #canvasCont class="canvas-cont">
                    <div #level class="level"></div>
                    <canvas #canvas></canvas>
                    <div *ngIf="is_ready_to_play && duration > 0" class="position time"
                        [innerText]="secsToTime(current_time)"></div>
                    <div *ngIf="is_ready_to_play && duration > 0" class="duration time"
                        [innerText]="secsToTime(duration)">
                    </div>
                </div>
                <div class="recorder-inst instructions">

                    <div class="recorder-step-one" *ngIf="!is_ready_to_play; else readyToUpload">
                        <div class="label block">RECORD {{ resource_type }} INTRODUCTION</div>
                        Click the button above to start recording. If this is your first time, please click 'Allow' when
                        the browser asks for access to your microphone.
                    </div>
                    <div #progressRec class="progress uploading" [id]="audio_item?.audio_blob?.name"></div>

                    <ng-template #readyToUpload>
                        <div class="ready-btns">

                            <div (click)="resetRecording()" class="label x-small blue link"> RESET </div>
                            <div></div>
                            <div></div>
                            <!-- testing: 
                                <div (click)="downloadRecording()" class="use-btn label x-small blue link" title="Download recording">
                                <mat-icon inline="true">download</mat-icon> Download
                                </div> 
                            -->
                            <div (click)="startUpload()" class="use-btn label x-small blue link"> <mat-icon
                                    inline="true">check_circle</mat-icon> UPLOAD THIS RECORDING </div>
                        </div>
                    </ng-template>

                </div>
            </div>
        </div>
        <!-- </mat-dialog-content> -->
    </div>

    <ng-template #example>
        <div @fadeIn class="example">
            <h6>Examples</h6>
            <br>
            This is [your name] from [band name], here's our new track [track title] on Amazing Radio 
            <br><br>
            This is [your name], here's my new track [track title] on Amazing Radio
        </div>
    </ng-template>

    <mat-dialog-actions class="options">
        <!-- <div></div> -->
        <!-- Toggle example - when provided -->
        <div (click)="toggleExample()" class="use-btn label x-small blue link"
            [innerHTML]="show_example ? '&lt; Back ':'Show Example'"></div>
        <button class="cancel label yellow link x-small" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>
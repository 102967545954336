import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlayerService } from '@modules/_shared/player/player.service';
import { PlaylistV2 } from '@models/playlist-v2';
import { TuneV2 } from '@models/tune-v2';
import { FirstSpinService } from '@services/first-spin.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-first-spin-play-button',
  templateUrl: './first-spin-play-button.component.html',
  styleUrls: ['./first-spin-play-button.component.scss'],
  inputs: ['bare', 'addClass']
})
export class FirstSpinPlayButtonComponent implements OnInit, OnDestroy {

  public _playlist: PlaylistV2 = {
    id: 'firstspin',
    type: 'playlist',
    _router_link:'/firstspin',
    attributes: {
      name: 'First Spin'
    }
  };
  public playlist: TuneV2[] = [];

  public bare:boolean; // Show just the bare play button (UK style)
  private sub: Subscription;

  constructor(private firstSpinService: FirstSpinService, public player: PlayerService) {
    // console.log('First Spin Play button');
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  togglePlay() {
    // console.log('first spin toggle play');
    if (!this.playlist[0]) {
      // console.log('Load FirstSpin playlist');
      this.sub = this.firstSpinService.getFirstSpins().subscribe(data => {
        this._playlist = data;
        this._playlist.id = 'firstspin';
        this._playlist.type = 'playlist';
        this._playlist._router_link = '/firstspin';
        this._playlist.attributes = {
          name: 'First Spin'
        };
        this.playlist = data._tunes;
        // console.log('play first fitstspin tune');
        this.player.playTune(this.playlist[0], 0, this.playlist, this._playlist);
      });
      return;
    }
    // console.log('current player playlist_index: ', this.player.playlist_index);
    this.player.playTune(this.playlist[this.player.playlist_index], 0, this.playlist, this._playlist);

  }


  ngOnInit(): void { }

}

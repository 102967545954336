import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, concat } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DatePipe } from '@angular/common';
import { Show, ShowSchedule, ShowScheduleAirings } from '@models/show';
import { SnackbarService } from '@services/snackbar.service';
import { AiringRewindPipe } from './airing-rewind.pipe';
import { TimeSlipService } from '@services/time-slip.service';

// API Docs: https://schedule.amazingradio.com/docs/api

/*
  // Required request URIs:
  onair: /channels/uk/onair.json
  shows: /shows.json?takeover=false
  selected show: /shows/[*SHOW_ID*].json  (DEPRECATED: other content will come from WP-API https://api.amazingradio.com/wp-json/wp/v2/shows?_embed=true&slug=[*SHOWSLUG*] )
  -- 'features' for a selected show comes from https://api.amazingradio.com/wp-json/wp/v2/posts?_embed=true&filter%5Btaxonomy%5D=category&filter%5Bterm%5D=[*SHOWSLUG*]&order=desc&orderby=date&page=1&per_page=10
*/

const BASE_URI = environment.schedule_api_endpoint;

@Injectable({
  providedIn: 'root'
})
export class ApiScheduleService {

  // Current Show Observable
  public currentShowSubject: BehaviorSubject<ShowSchedule> = new BehaviorSubject(null);

  public current_show:ShowSchedule;
  public week_ahead:ShowSchedule[];
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, private datePipe: DatePipe, private airingRewindPipe:AiringRewindPipe, private snackbar: SnackbarService, private timeSlipService: TimeSlipService) { }

  // Current show on air.
  getOnAirShow(skip_loading?:boolean): Observable<ShowSchedule> {
    let params_data = new HttpParams();
    if (this.timeSlipService.offset > 0) { // Note: /upcoming.json does not support the offset param.
      params_data = params_data.set('offset', this.timeSlipService.offset.toString());
    }
    if (skip_loading) {
      params_data = params_data.set('skip_loading', 'true');
    }
    // console.log('calling getOnAirShow... ');
    return this.http.get<ShowSchedule>(BASE_URI + '/channels/'+environment.radio_channel+'/onair.json', {params: params_data})
      .pipe(
        tap(data => {
          // console.log('on air show: ', data);
          this.current_show = data;
          this.currentShowSubject.next(this.current_show);
        }),
        catchError(this.handleError)
      );
  }

  getUpcomingShows(skip_loading?:boolean): Observable<ShowSchedule[]> {
    let params_data = new HttpParams();
    if(skip_loading){
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<ShowSchedule[]>(BASE_URI + '/channels/'+environment.radio_channel+'/upcoming.json', {params: params_data})
      .pipe(
        map(data => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getWeekAhead(): Observable<ShowSchedule[]> {
    return this.http.get<ShowSchedule[]>(BASE_URI + '/channels/'+environment.radio_channel+'/week_ahead.json')
      .pipe(
        tap(data => {
          // console.log('weekahead', data);
          this.week_ahead = data;
        }),
        // Note: Mondays don't appear to start with a Non-Stop New Music from 12:00am like other days.
        catchError(this.handleError)
      );
  }

  // Get list of all shows (takeovers or not)
  getShows(takeover: boolean = false): Observable<Show[]> {
    let params_data = new HttpParams();
    if (takeover) {
      params_data = params_data.set('takeover', 'true');
      params_data = params_data.set('order', 'updated');
    } else {
      params_data = params_data.set('takeover', 'false');
    }
    return this.http.get<Show[]>(BASE_URI + '/shows.json', { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('all shows:', data);
        // }),
        catchError(this.handleError)
      );
  }

  // Get individual show by 'show slug'.
  getShow(show_slug: string, skip_loading:boolean = false): Observable<Show> {
    let params_data = new HttpParams();
    if(skip_loading){ // This tells the loading indicator interceptor to not show the laoding indicator
      params_data = params_data.set('skip_loading', 'true');
    }
    const _url = BASE_URI + '/shows/' + show_slug + '.json';
    return this.http.get<Show>(_url, { params: params_data })
      .pipe(
        // tap(data => {
        //   console.log('show data', show_slug, data);
        // }),
        map(data => {
          // Provide some extra data here to give the footer player some details whem playing rewinds (since they're not 'tunes')
          data.rewind.forEach(_rewind => {
            // console.log('rewind', _rewind.s3_url);
            _rewind.s3_url += '?ngsw-bypass=true';
            _rewind.display_artist = data.name;
            _rewind.display_title = this.datePipe.transform(_rewind.broadcast_from, 'EEEE MMMM d, yyyy');
            _rewind.image_url = data.square_300_image;
            _rewind.show_route = '/shows/' + data.id;
          });
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          let error_message = error.message;
          if (error.error && error.error.errors) {
            error_message = error.error.errors[0].title;
          }
          // Since this is used in a resolver, let's show a snackbar message now.
          this.snackbar.show(error_message, 'snackbarWarning');
          return this.handleError(error);
        })
      );
  }

  // Paginated Show airings.
  // Note: needed to provide the 'show_name' here as the airing list data doesn't have it (to be able to easily pass along to the player). (The individual airing data does. see below)
  getShowAirings(show_name:string, show_id:string, page:number = 1, skip_loading:boolean = false) :Observable<ShowScheduleAirings> {
    let params_data = new HttpParams();
    // filter out airings that have not been recorded as rewinds.
    params_data = params_data.set('only_rewinds', 'true');
    params_data = params_data.set('page', page.toString());
    if(skip_loading){
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<ShowScheduleAirings>(BASE_URI + '/shows/' + show_id + '/airings.json', { params: params_data }).pipe(
      tap(data => {
        data.airings_as_rewinds = [];
        data.airings.forEach(airing => {
          airing.show_id = show_id;
          airing.show_name = show_name;
          airing.rewind_url = airing.rewind_url + '?ngsw-bypass=true';
          // Transform the airing to a Rewind
          data.airings_as_rewinds.push(this.airingRewindPipe.transform(airing));
        });
        // Get rid of these now.
        // delete data.airings;
        // console.log('airings_as_rewinds: ', data);
      }),
      catchError(this.handleError)
    );
  }

  // Individual Show Airing
  getShowAiring(show_id:string, airing_id:number, skip_loading:boolean = false) :Observable<ShowSchedule> {
    let params_data = new HttpParams();
    if(skip_loading){
      params_data = params_data.set('skip_loading', 'true');
    }
    return this.http.get<ShowSchedule>(BASE_URI + '/shows/' + show_id + '/airings/' + airing_id + '.json', { params: params_data }).pipe(
      catchError((error: HttpErrorResponse) => {
        let error_message = error.message;
        if (error.error && error.error.errors) {
          error_message = error.error.errors[0].title;
        }
        // Since this is used in a resolver, let's show a snackbar message now.
        this.snackbar.show(error_message, 'snackbarWarning');
        return this.handleError(error);
      })
    );
  }


  getShowsByUUIDs(uuids:string[]) : Observable<any> {
    const _uuids = uuids.join(',');
    let params_data = new HttpParams();
    params_data = params_data.set('uuids', _uuids);
    return this.http.get<any>( BASE_URI + '/shows.json', { params: params_data } );
  }

  // Common error handler
  private handleError(error: HttpErrorResponse) {
    if (isPlatformBrowser(this.platformId)) { // NOT SSR
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred.
        console.error('A client-side or network error occurred:', error.error.message);
        // return an observable with a user-facing error message
        return throwError({ status: -1, message: error.error.message });
      } else {
        // The backend returned an unsuccessful response code.
        console.log('ERROR: ', error);
        let error_message = error.message;
        if (error.error && error.error.errors) {
          error_message = error.error.errors[0].title;
        }
        return throwError({ status: error.status, message: error_message });
      }
    } else {
      console.log('SCHEDULE ERROR: ', error);
      let error_message = error.message;
      if (error.error && error.error.errors) {
        error_message = error.error.errors[0].title;
      }
      return throwError({ status: error.status, message: error_message });
    }
  };
}

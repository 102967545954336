import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

declare var _paq;

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  private default_image:string = environment.site_url + '/assets/placeholders/placeholder-video-853.480.png';
  public current_page_title:string = environment.name;

  constructor(private meta:Meta, private title:Title) { }

  generateTags(config?:any){
    config = {
      title: environment.name,
      description: 'Amazing Radio, home to the best online Internet radio station. Supporting indie artists & bands worldwide helping your talent get heard.', // 'We play the world\'s best new music 24/7 - We showcase emerging talent - We build careers'
      image:this.default_image,
      url: environment.site_url,
      type:'page',
      resource_title: null,
      resource_path:'',
      ...config
    }

    // console.log('Generate tags: ', config);

    // Old title format
    // const _title = environment.name + ( config.resource_title ? ' - ' + config.resource_title : ' - ' + config.description ).replace(/(<([^>]+)>)/gi, "");

    // New title format
    const _title = ( config.resource_title ? config.resource_title + ' | ' : '') + environment.name;

    // For Matomo (collected in main AppComponent)
    this.current_page_title = config.resource_title ? config.resource_title.replace(/(<([^>]+)>)/gi, ""): environment.name;
    // console.log('Title set: ', this.current_page_title);

    this.title.setTitle( _title );
    // console.log('Title set: ', _title);

    this.meta.updateTag( { name: 'description', content: config.description } );
    // console.log('Meta.Description set: ', config.description);

    /*
      NOTE:
      To set custom meta.description and meta.keywords, we'd need to use:

      this.meta.updateTag( { name: 'description', content: '... the descriotion override ' } );

      this.meta.updateTag( { name: 'keywords', content: 'some, keywords, to, add, etc.' } );

      // ...

    */

    this.meta.updateTag( { name: 'twitter:card', content: config.type !== 'page' ? 'player':'summary' } );
    this.meta.updateTag( { name: 'twitter:site', content: '@'+environment.social_twitter } );
    this.meta.updateTag( { name: 'twitter:title', content: config.resource_title ? _title : environment.name } );
    this.meta.updateTag( { name: 'twitter:description', content: config.description } );
    this.meta.updateTag( { name: 'twitter:image', content: config.image !== null && config.image !== undefined ? config.image : this.default_image } );

    if(config.type !== 'page'){

      let oembed_url = environment.site_url + (config.resource_path.split('/profile/').join('/oembed/'));

      // player oembed
      this.meta.updateTag( { name: 'twitter:player', content: oembed_url } );
      this.meta.updateTag( { name: 'twitter:player:width', content: '600' } );
      this.meta.updateTag( { name: 'twitter:player:height', content: '600' } );

    } else {

      this.meta.updateTag( { name: 'twitter:player', content: ''} );
      this.meta.updateTag( { name: 'twitter:player:width', content: '' } );
      this.meta.updateTag( { name: 'twitter:player:height', content: '' } );

    }

    this.meta.updateTag( { property: 'og:type', content: 'article' } );
    this.meta.updateTag( { property: 'og:site_name', content: environment.name } );
    this.meta.updateTag( { property: 'og:title', content: config.resource_title ? _title : environment.name } );
    this.meta.updateTag( { property: 'og:description', content: config.description } );
    this.meta.updateTag( { property: 'og:image', content: config.image || this.default_image } );
    this.meta.updateTag( { property: 'og:url', content: ( config.resource_path ? environment.site_url + config.resource_path : config.url ) } );

    // console.log('og:url', ( config.resource_path ? environment.site_url + config.resource_path : config.url ));

  }

}


import { Component, OnInit } from '@angular/core';
import { PlaylistV2 } from '@models/playlist-v2';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-playlist-featured-row',
  templateUrl: './playlist-featured-row.component.html',
  styleUrls: ['./playlist-featured-row.component.scss'],
  inputs: ['playlistHead', 'hide_curator', 'featured', 'list_parent']
})
export class PlaylistFeaturedRowComponent implements OnInit {

  public playlist:PlaylistV2;
  public playlistHead:PlaylistV2;
  public hide_curator:boolean;
  public featured:boolean; // Changes name style when used in grid
  public is_genre:boolean; // Changed name style in lists when meta.is_featured is true

  public list_parent:string;

  constructor(public userService: UserService) { }

  ngOnInit(): void {
    this.playlist = this.playlistHead;
    // console.log('playlist:', this.playlist);
    if(this.playlist.attributes.is_featured){
      this.hide_curator = true;
      this.is_genre = true;
    }
    // console.log('rel.tunes: ', this.playlist.relationships);

  }
}

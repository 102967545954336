<div class="time-slip">
    <h2><mat-icon inline="true">history</mat-icon> TIME-SLIP</h2>
    <div class="inst">
        Move the slider to listen back in time up to 8 hours ago
    </div>

    <div class="slider-cont">
        <div class="end"></div>
        <mat-slider class="time-slider" step="10" max="480" min="0" [showTickMarks]="true">
            <input matSliderThumb [value]="offset" (input)="onChange($event)">
        </mat-slider>
        <div class="end"></div>
    </div>
    <div class="hours">
        <div (click)="setValue(60 * 8)" class="hour" title="Go back 8 hours">-8</div>
        <div (click)="setValue(60 * 7)" class="hour" title="Go back 7 hours">-7</div>
        <div (click)="setValue(60 * 6)" class="hour" title="Go back 6 hours">-6</div>
        <div (click)="setValue(60 * 5)" class="hour" title="Go back 5 hours">-5</div>
        <div (click)="setValue(60 * 4)" class="hour" title="Go back 4 hours">-4</div>
        <div (click)="setValue(60 * 3)" class="hour" title="Go back 3 hours">-3</div>
        <div (click)="setValue(60 * 2)" class="hour" title="Go back 2 hours">-2</div>
        <div (click)="setValue(60 * 1)" class="hour" title="Go back 1 hour">-1</div>
        <div (click)="setValue(0)" class="hour live" title="Go live!">LIVE</div>
    </div>
    <div class="inst-cont">
        <div class="time-cont">
            <div class="time" [innerText]="time_str"></div>
        </div>
        <div class="btn link large blue" (click)="ok()">
            OK
        </div> 
    </div>
</div>
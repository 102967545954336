<!-- User card component : V2 version -->
<div class="card" [class]="list_parent">

  <div class="imageCont square" [class]="list_parent" [routerLink]="['/profile', user.attributes.permalink]">
    <img [lazyImgSrc]="user.attributes.image_urls.medium" [title]="user.attributes.name" src="/assets/placeholders/placeholder-square-dark-300.png">
  </div>

  <div class="playButton playButtonList user" [class]="list_parent === 'likes-artists' || list_parent === 'label-artists' ? 'no-icon':''">
    <mat-icon *ngIf="list_parent === 'likes-all'" title="You follow this user">account_circle</mat-icon>
  </div>

  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like [title]="(user.meta.liked_by_user ? 'Unfollow':'Follow') + ' user'"  *ngIf="!user._is_manager; else edit" class="interact like" [resource]="user"></app-action-button-like>
    </div>
	</div>

  <div class="details">
    <a class="elipsify name" [routerLink]="['/profile', user.attributes.permalink]" title="{{ user.attributes.name }}" [innerHTML]="user.attributes.name"></a>
    <div *ngIf="list_parent !== 'label-artists'" class="listLabel userType" [innerHTML]="(user.attributes.is_amazing?'Amazing ':'') + user.attributes.classification | titlecase"></div>

    <!-- Show Artist stats if the logged in user is the Artist manager -->
    <div *ngIf="user._is_manager" class="stats">
      <!-- Artist Stats -->
      <div class="owner-stats" *ngIf="user.user_stats.upload_counts.tunes > 0" title="Tune uploads">
        <mat-icon inline="true">audiotrack</mat-icon> <span>{{ user.user_stats.upload_counts.tunes }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.upload_counts.collections > 0" title="Releases">
        <mat-icon inline="true">album</mat-icon> <span>{{ user.user_stats.upload_counts.collections }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.external_videos_count > 0" title="Videos">
        <mat-icon inline="true">videocam</mat-icon> <span>{{ user.user_stats.external_videos_count }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.artist_stats.tune_plays > 0" title="Tune Plays">
        <mat-icon inline="true">play_arrow</mat-icon> <span>{{ user.user_stats.artist_stats.tune_plays }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.artist_stats.airplays > 0" title="Radio Airplays">
        <mat-icon inline="true">speaker</mat-icon> <span>{{ user.user_stats.artist_stats.airplays }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.artist_stats.tune_likes > 0" title="Tune Likes">
        <mat-icon inline="true">favorite</mat-icon> <span>{{ user.user_stats.artist_stats.tune_likes }}</span>
      </div>
      <div class="owner-stats" *ngIf="user.user_stats.artist_stats.profile_likes > 0" title="Followers">
        <mat-icon inline="true">account_circle</mat-icon> <span>{{ user.user_stats.artist_stats.profile_likes }}</span>
      </div>
    </div>
  </div>

</div>

<ng-template #edit>
  <mat-icon class="interact edit" (click)="editUser()" title="Edit Artist">edit</mat-icon>
</ng-template>

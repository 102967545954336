import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  type?: string;
}

@Component({
  selector: 'app-dialog-delete-account',
  templateUrl: './dialog-delete-account.component.html',
  styleUrls: ['./dialog-delete-account.component.scss']
})
export class DialogDeleteAccountComponent implements OnInit {

  public type:string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { 

    if(data?.type === 'artist'){
      this.type = 'artist';
    }
    
  }

  ngOnInit(): void {

  }

  deleteAccount(){
    this.dialogRef.close({delete_account:true});
  }

  cancel(){
    this.dialogRef.close({delete_account:false});
  }


}

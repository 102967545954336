import { Injectable } from '@angular/core';
import { GenreStreams, GenreStream } from '@models/genre-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { BehaviorSubject } from 'rxjs';

// Gets and updates data for the site's available Genre Streams.
// When playing, will also update the displayed tune in the footer player component.

@Injectable({
  providedIn: 'root'
})
export class GenreStreamService {

  public genre_streams:GenreStreams;
  public selected_genre_stream:GenreStream;

  public update_interval:any;
  private update_interval_ms:number = 20000;

  public genre_streams_subject:BehaviorSubject<GenreStreams>;

  constructor(private api_tunes:ApiAmazingtunesV2Service ) {

    this.genre_streams_subject = new BehaviorSubject(null);

  }

  getGenreStreams(): void {
    this.api_tunes.getGenreStreams(this.genre_streams ? true : false).subscribe((data) => {
      this.genre_streams = data;
      this.genre_streams_subject.next(this.genre_streams);
      // console.log('GS', data);
    },
    (error) => {
      console.log('getGenreStreams service update error', error);
    });
  }

  startGenreStreamsUpdate() : void {
    // console.log('start GS updates....');
    if(this.update_interval){
      clearInterval(this.update_interval);
    }
    // Don't update for SSR
    if(typeof window !== 'undefined'){
      this.update_interval = setInterval(() => {
        this.getGenreStreams();
      }, this.update_interval_ms);
    }
  }

  stopGenreStreamsUpdate() : void {
    // console.log('stop GS updates....');
    if(this.update_interval){
      clearInterval(this.update_interval);
      this.genre_streams = null;
      this.update_interval = null;
    }
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Used for controlling mutiple instances of the voice-clip-player.component
@Injectable({
  providedIn: 'root'
})
export class VoiceClipPlayerService {

  public stopAudioSubject: BehaviorSubject<string> = new BehaviorSubject('');
  public current_id: string;
  
  constructor() { }

  public sendStop(id_playing:string): void {
    this.stopAudioSubject.next(id_playing);
  }

}


import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute, Params, ChildActivationEnd, NavigationCancel } from '@angular/router';
import { environment } from '@env/environment';
import { filter, tap } from 'rxjs/operators';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { CampaignParams } from '@models/campaign-params';
import { MetaTagsService } from '@services/meta-tags.service';
import { HTMLLinkService } from '@services/htmllink.service';
import { SnackbarService } from '@services/snackbar.service';
import { BreakpointObserverService, Breakpoints } from '@services/breakpoint-observer.service';
import { UserService } from '@services/user.service';
import { UserV2 } from '@models/user-v2';

import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { DialogLabelArtistsComponent } from '@modules/_shared/ui/components/dialog-label-artists/dialog-label-artists.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { PlayerService } from '@modules/_shared/player/player.service';
// Initialise the home rows data here.
import { HomeContentService } from '@services/home-content.service';
// FCM
import { MessagingService } from '@services/messaging.service';
// App update checking
import { CheckForUpdateService } from '@services/check-for-update.service';

declare var _paq: any;
declare var gtag: any;

interface bp {
  [key: string]: boolean;
}

interface MenuPanel {
  get_noticed: {
    open: boolean;
    options: string[];
  },
  listen_now: {
    open: boolean;
    options: string[];
  }
  explore: {
    open: boolean;
    options: string[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CheckForUpdateService]
})
export class AppComponent implements OnInit {

  // Hides main UI on page load when using this app for oembed players.
  public show_ui: boolean = true;
  public loading: boolean = false;
  public is_server: boolean = false;

  public site_country: string = environment.site_country;

  public is_side_nav_open: boolean = false; // is menu manually toggled open?
  public force_side_nav_closed = false;

  public breakpoints: Breakpoints;

  public logged_in_user: UserV2 | null;
  public is_amazing_uploader_user: boolean;
  public can_see_upgrade_btn: boolean;

  // To determine appropriate menu expansion panel on arrival
  private original_path: string = '/'; // original url

  public menu_panels: MenuPanel = {
    get_noticed: {
      open: true, // default
      options: [
        '/connect',
        '/profile',
        '/store'
      ]
    },
    listen_now: {
      open: true,
      options: [
        '/onair',
        '/aired',
        '/genres',
        '/genre-streams',
        '/shows',
        '/thetakeover',
        '/schedule',
        '/chart',
        '/playlist',
        '/chart',
        '/chart/archive',
      ]
    },
    explore: {
      open: null,
      options: [
        '/playlists/featured',
        '/news',
        '/home',
        '/sessions',
        '/testimonials',
        '/halloffame',
        '/firstspin',
        '/firstlook',
        '/search',
        '/tips',
      ]
    }
  };

  private _get_noticed_regex_str = '^' + this.menu_panels.get_noticed.options.join('|');
  private _get_noticed_regex: RegExp = new RegExp(this._get_noticed_regex_str, 'g');

  private _listen_now_regex_str = '^' + this.menu_panels.listen_now.options.join('|');
  private _listen_now_regex: RegExp = new RegExp(this._listen_now_regex_str, 'g');

  private _explore_regex_str = '^' + this.menu_panels.explore.options.join('|');
  private _explore_regex: RegExp = new RegExp(this._explore_regex_str, 'g');

  public radio_playing: boolean = false;
  public genre_stream_playing: boolean = false;

  private skipNavUpdate: boolean = false;

  private foo: any;

  constructor(
    private homeContentService: HomeContentService,
    private platform: Platform,
    public playerService: PlayerService,
    private dialog: MatDialog,
    private api_tunes: ApiAmazingtunesV2Service,
    private snackbar: SnackbarService,
    public userService: UserService,
    public bpoService: BreakpointObserverService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private metaTagsService: MetaTagsService,
    private linkService: HTMLLinkService,
    private liveAnnouncer: LiveAnnouncer,
    private messagingService: MessagingService,
    private checkForUpdateService: CheckForUpdateService
  ) {

    if (!isPlatformBrowser(this.platformId)) {
      this.is_server = true;
    } else {

      this.messagingService.isMessagingSupported().subscribe(res => {
        if (!res) return;
        // Initialise the localStorage data
        if (!this.messagingService.getStoredNotifications()) {
          const _token = this.messagingService.getStoredToken();
          if (_token) {
            this.api_tunes.getNotifications(_token).subscribe();
          }
        }

        this.messagingService.messageSubject.subscribe(data => {
          if (!data) return;
          // If the user has the site open and on the right tab, this will display a standard local notification.
          // If the user is not on the site tab or has the browser closed, the service worker will invoke the notification. 
          // console.log('%cNOTIFICATION', 'color:cyan', data);
          if (Notification?.permission === "granted" && data.notification) {
            // console.log('Notification permission has been granted...');
            const _notification_extra = { tag: data?.data?.tag || 'amazing-notification', body: data.notification?.body, icon: data?.data?.icon, image: data?.data?.image };
            // cant add actions here: actions:[{action:'https://amazingradio.com', title:"Click Action Title"}],
            // console.log('_notification_extra:', _notification_extra);
            const notification = new Notification(data.notification.title, _notification_extra); // , actions: data?.fcmOptions?.link ? [{ title:'Click here', action: data.fcmOptions.link }] : null
            notification.onclick = (e) => {
              console.log('Notification: clicked', e);
              notification.close();
            };
            // notification.onclose = (e) => {
            //   console.log('Notification: closed', e);
            // };
            // notification.onshow = (e) => {
            //   console.log('Notification: shown', e);
            // };
            notification.onerror = (e) => {
              console.log('Notification: ERROR', e);
            };
          }
        });
      });
    }

    // Get user geo location (US or not)
    if (isPlatformBrowser(this.platformId)) {
      this.api_tunes.geoDataLookup().subscribe(data => {
        // console.log('GEO', data);
        localStorage.setItem('geo', data.data.attributes.country_code);
      });
    }

    // Show/hide audio bars animation
    this.playerService.playerStatus.subscribe(data => {
      // console.log('playerStatus', data);
      if (data === 'playing' && this.playerService.radio_playing) {
        this.radio_playing = true;
      } else {
        this.radio_playing = false;
      }

      if (data === 'playing' && this.playerService.genre_stream) {
        this.genre_stream_playing = true;
      } else {
        this.genre_stream_playing = false;
      }

    });

    // Add custom Material icons.
    // See: https://alligator.io/angular/custom-svg-icons-angular-material/
    // https://material.io/resources/icons/?icon=skip_previous&style=round
    //

    // I have also added the source SVGs from Material for the other rounded player icons too.
    // As well as making it easier for switching between then in the play buttons, it'll make it easy to modify them in the future

    // eg: This one would be used by: <mat-icon svgIcon="stop"></mat-icon>

    // Needs nginx SSR SERVER URLS HERE. (USA: port 4200 / UK: port 4300)
    const domain = !isPlatformBrowser(platformId) ? (environment.site_country === 'us' ? 'http://localhost:4200' : 'http://localhost:4300') : '';
    // warning with cicle
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/warning_circle_outline.svg')
    );
    // outlined with cicle
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/info_outline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'levels',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/levels.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rewind',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/rewind_circle_outline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stop',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/stop_circle_outline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/play_circle_outline.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pause',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/pause_circle_outline.svg')
    );
    // No outlines. Smaller than SVG boundary.
    this.matIconRegistry.addSvgIcon(
      'stop_sml',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/stop_circle_sml.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play_sml',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/play_circle_sml.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pause_sml',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/pause_circle_sml.svg')
    );
    // No circle (just from front page)
    this.matIconRegistry.addSvgIcon(
      'stop_no_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/stop_no_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play_no_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/play_no_circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pause_no_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/pause_no_circle.svg')
    );
    // filled
    this.matIconRegistry.addSvgIcon(
      'stop_filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/stop_circle_filled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play_filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/play_circle_filled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pause_filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/pause_circle_filled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'skip_previous',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/skip_previous.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'skip_next',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/skip_next.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'buffering',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/buffering.svg')
    );
    // An empty one. Used for private tunes etc..
    this.matIconRegistry.addSvgIcon(
      'blank',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/blank.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/iconTwitterX.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/iconFacebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/iconInstagram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'threads',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/iconThreads.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/iconYoutube.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'amazing',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/logo_amazing_ref.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'amazing-loz',
      this.domSanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/custom_material_icons/logo_amazing_loz.svg'),
    );

    this.loadingIndicatorService.loadingStatus.subscribe(data => {
      setTimeout(() => { // stops detection change error on back
        this.loading = data;
      })
    });

    this.bpoService.breakpointsSubject.subscribe((data: Breakpoints) => {
      if (data) {
        // console.log('breakpoints:', data);
        this.breakpoints = data;
        // this.force_side_nav_closed = false;
        if (this.breakpoints.is_x_large) {
          if (!environment.production) console.log('%cx-large', 'color:cyan');
          this.force_side_nav_closed = false;
        } else if (this.breakpoints.is_large) {
          if (!environment.production) console.log('%clarge', 'color:cyan');
          this.force_side_nav_closed = false;
        } else if (this.breakpoints.is_medium) {
          if (!environment.production) console.log('%cmedium', 'color:cyan');
          this.force_side_nav_closed = false;
        } else if (this.breakpoints.is_medium_small) {
          if (!environment.production) console.log('%cmedium-small', 'color:cyan');
        } else if (this.breakpoints.is_small) {
          if (!environment.production) console.log('%csmall', 'color:cyan');
        } else if (this.breakpoints.is_x_small) {
          if (!environment.production) console.log('%cx-small', 'color:cyan');
        }

        // automatically close the sidenav, if we stretch wider while it's open.
        if (this.is_side_nav_open && this.breakpoints.is_medium) {
          this.is_side_nav_open = false;
        }
      }
    });

    // bpoService also contains sidenav toggler. This event comes from the burger in the header.
    this.bpoService.sideNavToggleEvent.subscribe(data => {
      // console.log('BURGER sideNavToggleEvent');
      this.toggleSideNav();
    });
  }

  isMobile(): boolean {
    return this.platform.IOS || this.platform.ANDROID;
  }

  scrollToTop(): void {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  ngOnInit(): void {

    this.userService.userSub.subscribe(data => {
      // console.log('app.comp userSub:', data);
      this.logged_in_user = data;
      if (data !== null) {
        if (this.userService.treatAsArtist() || this.userService.treatAsLabel()) {
          this.is_amazing_uploader_user = true;
          if (this.logged_in_user.meta.is_subscription_required) {
            this.can_see_upgrade_btn = true;
          } else {
            this.can_see_upgrade_btn = false;
          }
        }
      } else {
        this.can_see_upgrade_btn = false;
        this.is_amazing_uploader_user = false
      }
    });

    this.router.events.pipe(
      tap(event => {
        // console.log('Navtap', event);

        /**
         * Check to see if a custom state has been passed to the navigate method
         * eg: On a user profile, their default tab selection depends on their classification and is set via
         * another Navigation event (without chnaging the url.)
         * So, we want to trap these ones from altering the canonical URL.
         * eg: /profile/kosso  becoming  /profile/kosso/playlists - Where Playlists are the default tab for /profile/kosso
        */

        // Show tabs are set slightly differently, as we wantch nav events there too. (Something to do with Audtion IIRC)
        // So look for the NavigationCancel type of event.
        const _nav = this.router.getCurrentNavigation();

        if (event instanceof NavigationCancel && _nav?.extras?.replaceUrl) {
          // console.log('%cNavigation event cancelled by tab setting', 'color:lime', _nav.extras, event.url);
          this.linkService.updateCanonicalLink({
            rel: 'canonical',
            href: environment.site_url + event.url
          });
        }
        if (_nav?.extras?.state?.skipNavUpdate && event instanceof ChildActivationEnd) {
          // console.log('skipNavUpdate state detected', event ,_nav.extras?.state);
          this.skipNavUpdate = true;
        }
      }),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((data: NavigationEnd) => {
      // console.log('%cURL:', 'font-weight:bold;color:orange', this.metaTagsService.current_page_title, data);
      this.original_path = data.url;
      // console.log('%cPath data.url:', 'color:yellow', data.url, this.skipNavUpdate);
      // Skip updating this if a `skipNavUpdate` extras.state is detected.
      if (!this.skipNavUpdate) {
        this.linkService.updateCanonicalLink({
          rel: 'canonical',
          href: environment.site_url + this.original_path
        });
      }
      this.skipNavUpdate = false;
      // // Makes sure the right accordion panel is open on arrival
      if (this.original_path.match(this._get_noticed_regex)) {
        //console.log('%cGET NOTICED', 'color:blueviolet');
        this.menu_panels.get_noticed.open = true;
        this.menu_panels.listen_now.open = false;
        this.menu_panels.explore.open = false;
      } else if (this.original_path.match(this._listen_now_regex)) {
        //console.log('%cLISTEN NOW', 'color:lime');
        this.menu_panels.get_noticed.open = false;
        this.menu_panels.listen_now.open = true;
        this.menu_panels.explore.open = false;
      } if (this.original_path.match(this._explore_regex)) {
        //console.log('%cEXPLORE', 'color:deeppink');
        this.menu_panels.get_noticed.open = false;
        this.menu_panels.listen_now.open = false;
        this.menu_panels.explore.open = true;
      }

      if (data.urlAfterRedirects.includes('/oembed/')) {
        // Hide main site UI (header, footer, etc.) when using player embeds.
        this.show_ui = false;
      }
      if (environment.production) {
        // console.log('data: ', data);
        // Matomo page view..
        if (typeof _paq !== 'undefined') {
          _paq.push(['setCustomUrl', data.urlAfterRedirects]);
          _paq.push(['setDocumentTitle', this.metaTagsService.current_page_title]);
          _paq.push(['trackPageView']);
        }
        // Send GA pageview if Tracking ID is set.
        if (environment?.analytics?.google) {
          if (typeof gtag !== 'undefined') {
            gtag('event', 'page_view', {
              page_title: this.metaTagsService.current_page_title,
              page_location: environment.site_url + data.urlAfterRedirects,
              page_path: data.urlAfterRedirects,
              send_to: environment.analytics.google
            });
          }
        }
      }
      // Collect any utm_* campaign query params (from FB ads).
      // Stored in localStorage to be added with a new user registration.
      if (data.urlAfterRedirects.includes('utm_')) {
        const utm_params: CampaignParams = {};
        this.route.queryParams.subscribe((data: Params) => {
          // console.log('%cUTM Query Params', 'color:lime', data);
          for (const key in data) {
            // console.log(`${key}: ${data[key]}`);
            if (key === 'utm_source') {
              utm_params.utm_source = data[key];
            } else if (key === 'utm_medium') {
              utm_params.utm_medium = data[key];
            } else if (key === 'utm_campaign') {
              utm_params.utm_campaign = data[key];
            } else if (key === 'utm_content') {
              utm_params.utm_content = data[key];
            }
          }
          if (typeof window !== 'undefined') {
            localStorage.setItem('utm', JSON.stringify(utm_params));
          }
          // console.log('%cCampaign utm_* params stored: ', 'color:yellow' , utm_params);
        });
      }
    });
  }

  toggleSideNav(event?: any): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.is_side_nav_open = !this.is_side_nav_open;
    this.liveAnnouncer.announce(this.is_side_nav_open ? 'Menu is open' : 'Menu is closed');
  }

  // force menu closed
  closeMenu() {
    this.force_side_nav_closed = !this.force_side_nav_closed;
    if (!this.force_side_nav_closed) {
      this.is_side_nav_open = false;
    }
  }

  // Upload 'tunes' or 'videos'
  uploadResource(resource_types: string = 'tunes') {
    // console.log('Upload Artist resource ...');
    if (!this.logged_in_user) {
      this.snackbar.show('You need to be logged in to do that.', 'snackbarWarning');
      return;
    }
    if (this.logged_in_user?.meta.is_subscription_required && this.logged_in_user?.meta.free_uploads_remaining < 1) {
      this.snackbar.show('You will need an Amazing Artist Upgrade to upload new ' + resource_types + '.', 'snackbarWarning');
      return;
    }
    if (this.userService.getClassification() === 'label') {
      this.openLabelArtistsUploadDialog(resource_types);
      return;
    }
    // Check if terms need reviewing
    if (this.logged_in_user?.meta.terms_need_reviewing) {
      if (typeof window === 'undefined') {
        return;
      }
      // Store return page after saving terms options
      localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/' + resource_types + '/new');
      if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
        this.toggleSideNav();
      }
      this.router.navigate(['/terms-and-services']);
      return;
    }
    if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
      this.toggleSideNav();
    }
    this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, resource_types, 'new']);
  }

  openLabelArtistsUploadDialog(resource_types: string = 'tunes') {
    if (typeof window === "undefined") {
      return; // SSR
    }
    if (this.logged_in_user?.user_stats.label_counts.artists === 1) {
      // Just take them to the upload page for their only artist...
      this.api_tunes.getAllLabelArtistsCompact(this.logged_in_user?.attributes.permalink).subscribe(data => {
        if (this.logged_in_user?.meta.terms_need_reviewing) {
          if (typeof window === 'undefined') {
            return;
          }
          if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
            this.toggleSideNav();
          }
          // Store return page after saving terms options
          localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/artists/' + data[0].permalink_in_label + '/' + resource_types + '/new');
          this.router.navigate(['/terms-and-services']);
          return;
        }
        if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
          this.toggleSideNav();
        }
        this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, 'artists', data[0].permalink_in_label, resource_types, 'new']);
      });
    } else {
      // Get compact list of artists for a Dialog list to pick from.
      this.api_tunes.getAllLabelArtistsCompact(this.logged_in_user?.attributes.permalink).subscribe(data => {
        // console.log('All artists (compact: ', data);
        const dialogRef = this.dialog.open(DialogLabelArtistsComponent, {
          width: '560px',
          panelClass: 'dialogPlaylists',
          data: { artists_compact: data },
          scrollStrategy: new NoopScrollStrategy()
        });
        dialogRef.afterClosed().subscribe(artist_in_label => {
          // console.log('The LabelArtistsUploadDialog was closed: ', artist_in_label);
          if (artist_in_label && artist_in_label.permalink) {
            if (this.logged_in_user?.meta.terms_need_reviewing) {
              if (typeof window === 'undefined') {
                return;
              }
              if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
                this.toggleSideNav();
              }
              // Store return page after saving terms options
              localStorage.setItem('terms_next_page', '/profile/' + this.logged_in_user?.attributes.permalink + '/artists/' + artist_in_label.permalink_in_label + '/' + resource_types + '/new');
              this.router.navigate(['/terms-and-services']);
              return;
            }
            if (this.breakpoints.is_sidenav || this.force_side_nav_closed) {
              this.toggleSideNav();
            }
            // Go to Label Artist Tune Upload
            this.router.navigate(['/profile', this.logged_in_user?.attributes.permalink, 'artists', artist_in_label.permalink_in_label, resource_types, 'new']);
          }
        });
      });
    }
  }
}

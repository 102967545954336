<div class="instructions">
  ADD A CARD AS FUNDING SOURCE
</div>
<div *ngIf="member_subscription.data.attributes?.next_payment_hours < 0" class="charged-now">
  Your card will be charged {{ member_subscription.data.relationships.recurring_plan.data.attributes.payment_amount | currency:member_subscription.data.relationships.recurring_plan.data.attributes.payment_currency }} immediately
</div>
<form class="payment-form" #checkoutPaymentForm novalidate>
  <div class="one-liner">
    <div class="card-frame">
    </div>
    <button #checkoutPayButton id="pay-button" disabled>
      ADD CARD
    </button>
  </div>
  <div class="about">Provided by Checkout.com</div>
</form>


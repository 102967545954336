import { Injectable } from '@angular/core';
import { PlaylistV2 } from '@models/playlist-v2';
import { TuneV2 } from '@models/tune-v2';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { TitleCasePipe } from '@angular/common';
import { environment } from '@env/environment';
import { tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirstSpinService {

  public first_spin_playlist: PlaylistV2;
  public count: number = 20;
  public firstSpinSubject: BehaviorSubject<PlaylistV2> = new BehaviorSubject(null);

  constructor(private api: ApiAmazingtunesV2Service, private tcp: TitleCasePipe) { }

  getFirstSpins(): Observable<PlaylistV2> {

    if (this.first_spin_playlist) {
      // console.log('Return stored Observable (BehaviorSubject) of PlaylistV2');
      return this.firstSpinSubject;
    }

    return this.api.getFirstSpinTunes(0, false, this.count).pipe(
      tap(data => {
        let _tunes: TuneV2[] = JSON.parse(JSON.stringify(data.tunes)); // dont ask..

        // If user is from 'US', add '(City, State)' to the tune artist name. (If state has not yet been set, use '(City, Country)' )
        // First spin only currently shows on the US site.
        // if (environment.site_country === 'us') {
        //   _tunes.map(tune => {
        //     if (tune.artist.attributes.country === 'US') {
        //       tune.artist.attributes.name += ' (' + this.tcp.transform(tune.artist.attributes.city).trim();
        //       if (tune.artist.attributes.state) {
        //         tune.artist.attributes.name += ', ' + tune.artist.attributes.state;
        //       } else {
        //         tune.artist.attributes.name += ', ' + tune.artist.attributes.country;
        //       }
        //       tune.artist.attributes.name += ')';
        //     }
        //     // console.log(tune.artist.attributes.name, tune.artist.attributes.state);
        //     return tune;
        //   });
        // }
        // Do this for now until we only show one tune from an artist per list of uploaded tunes.
        // const shuffled = this.shuffle(JSON.parse(JSON.stringify(_tunes)));
        // console.log('First Spin: Latest tunes shuffled: ', shuffled);
        // Needs to pretend to be a playlist.

        // _tunes.map((tune, i) => {
        //   tune._unique_id = 'firstspin_' + i;
        // });

        this.first_spin_playlist = {
          id: 'firstspin',
          _tunes: _tunes
        };
        this.firstSpinSubject.next(this.first_spin_playlist);
        console.log('Fresh First Spin playlist: ', this.first_spin_playlist);
      }),
      map(data => {
        return this.first_spin_playlist;
      })
    )
  }

  shuffle(array: any[]): any[] {
    let counter = array.length, temp, index, _array;
    _array = array;
    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * counter);
      // Decrease counter by 1
      counter--;
      // And swap the last element with it
      temp = _array[counter];
      _array[counter] = _array[index];
      _array[index] = temp;
    }
    return _array;
  }

}

<div class="home-feature-row" [class]="(background_class ? background_class:'')" (click)="clicked()" >
  <div class="title-cont">

    <div class="title-cont-inner">
      <div class="title" *ngIf="title" [innerHTML]="title"></div>
      <div class="sub-title" *ngIf="sub_title" [innerHTML]="sub_title"></div>
    </div>

    <div class="title-frame">

    </div>

  </div>

  <div *ngIf="!row_images && image; else rowImages" class="image-cont" [lazyImgBg]="image">
    <div class="image-overlay"></div>
  </div>

  <ng-template #rowImages>
    <div class="image-cont list">

      <div *ngFor="let row of row_images; let i = index;" class="list-image -square" [class]="'list-img-'+i" [lazyImgBg]="row.image_url">
        <!-- <img [lazyImgSrc]="row.image_url" src="/assets/placeholders/placeholder-square-dark-300.png"> -->
        <!-- <img src="/assets/blank.png"> -->
      </div>
      <!-- <div class="image-overlay"></div> -->
    </div>
  </ng-template>

</div>

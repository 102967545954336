import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShowSchedule } from '@models/show';
import { HomeContentService } from '@services/home-content.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { TimeSlipService } from '@services/time-slip.service';
// Animated Hero Carousel

interface Hero {
  id?:string;
  title?:string;
  subtitle?:string;
  show_button?:boolean;
  is_radio_button?:boolean;
  image?:string;
  router_link?:string;
  custom_delay?:number;
  custom_label_text?:string;
  custom_class?:string;
  display_from_date?:string;
  display_until_date?:string;
  keep_after_until_date?:boolean; // false: remove it | true: set delay to default and push to array instead of unshift.
}

@Component({
  selector: 'app-hero-carousel',
  templateUrl: './hero-carousel.component.html',
  styleUrls: ['./hero-carousel.component.scss'],
  inputs: ['heroes', 'with_x_large_margin']
})
export class HeroCarouselComponent implements OnInit, OnDestroy {

  // Use the scheduled data presenter override in the main show page data for these show ids.
  public presenter_overrides:string[] = ['album-of-the-week', 'release-of-the-week', 'scratching-the-surface', 'world-music-day', 'off-the-record'];


  private site_country:string = environment.site_country;
  public with_x_large_margin:boolean = false;
  // Hero Carousel
  public hero_title:string = 'Hero Carousel!';
  public hero_subtitle:string = 'Where Talent Gets Noticed';

  public heroes_index:number = 0;
  public heroes:Hero[] = [
    {
      title:'Calling All Musicians',
      subtitle:'Get Yourself noticed',
      show_button:false,
      image:'/assets/banners/banner04.jpg',
      router_link:'/benefits'
    },
    {
      title:'Chart of 2023',
      subtitle:'The Greatest Tunes From The Past Year',
      show_button:false,
      image:'/assets/banners/gigs-1200x480.jpg',
      router_link:'/chart/2023'
    },
    {
      title:'First Spin',
      subtitle:'Be The First To Hear All The Latest Tunes',
      show_button:false,
      image:'/assets/banners/vinyl-player.jpg',
      router_link:'/firstspin'
    },
    {
      title:'The Amazing Chart',
      subtitle:'This Week\'s Greatest New & Emerging Music',
      show_button:true,
      image:'/assets/banners/banner-new1.jpg',
      router_link:'/chart'
    },
    {
      title:'Genre Streams',
      subtitle:'Non-stop New Music By Genre',
      show_button:false,
      image:'/assets/banners/audio-streams.jpg',
      router_link:'/genre-streams'
    },
    {
      title:'Amazing Music Store',
      subtitle:'100% Of Sales Goes Direct To Artists',
      show_button:false,
      image:'/assets/banners/record-store.jpg',
      router_link:'/store'
    },
    {
      title:'Hall of Fame',
      subtitle:'It All Started Here For These Amazing Artists',
      show_button:false,
      image:'/assets/banners/gigguide.jpg',
      router_link:'/halloffame'
    },
    {
      title:'First Look',
      subtitle:'Watch Videos From Our Amazing Artists',
      show_button:false,
      image:'/assets/banners/music-video-shoot.jpg',
      router_link:'/firstlook'
    },
  ];

  private sessions:Hero = {
    title:'The Amazing Sessions',
    subtitle:'Exclusive Performances From Amazing Artists',
    show_button:false,
    image:'/assets/banners/studio.jpg',
    router_link:'/sessions'
  };

  /**
   * Promotional hero banner.
   * Custom class, label text and display-from/to date.
   *
   * UTC Date format: 'Fri, 09 Dec 2022 12:00:00 GMT'
   *
   */
  private custom_promo_hero:Hero = {
    title:'Tips for 2024',
    subtitle:'Check Out This Year\'s Picks',
    show_button:false,
    image: this.site_country === 'uk' ? '/assets/banners/tips-2024-uk.jpg' : '/assets/banners/tips-2024-us.jpg',
    router_link:'/tips',
    custom_delay:14000,
    custom_label_text:'View The Acts',
    custom_class:'no-filters',
    display_from_date: this.site_country === 'uk' ? 'Fri, 15 Dec 2023 15:00:00 GMT' : 'Fri, 19 Jan 2024 05:00:00 EST',
    display_until_date:this.site_country === 'uk' ? 'Wed, 01 Feb 2024 00:00:00 GMT' : 'Wed, 01 Mar 2024 00:00:00 EST',
    keep_after_until_date:true,
  };

  private show_duration_ms:number = 7000;
  private show_duration_timeout:any;
  private is_paused_on_mouseover:boolean = false;
  private stagger_ms:number = 500;
  private fade1_timeout:any;
  private fade2_timeout:any;

  public anim_title_zoomed_in:boolean = true;
  public anim_image_faded_in:boolean = true;

  private sub_home_content_show:Subscription;

  public show: ShowSchedule;
  public last_show_id:string;

  constructor(private homeContentService:HomeContentService, private timeSlipService:TimeSlipService) {


    // Custom Promo Hero ////////////////////
    if(this.custom_promo_hero){
      const _now:Date = new Date();
      if(this.custom_promo_hero.display_until_date && new Date(this.custom_promo_hero.display_until_date) < _now){
        // console.log('Custom hero has past display until date', this.custom_promo_hero.display_until_date);
        if(this.custom_promo_hero.keep_after_until_date){
          delete this.custom_promo_hero.custom_delay;
          delete this.custom_promo_hero.custom_class;
          // add to end of list
          // console.log('custom hero added to end of list');
          this.heroes.push(this.custom_promo_hero);
        }
      } else {
        if(this.custom_promo_hero.display_from_date && _now > new Date(this.custom_promo_hero.display_from_date)){
          // console.log('Custom hero will display first.');
          this.heroes.unshift(this.custom_promo_hero);
        }
      }
    }

    if(this.site_country !== 'us'){
      this.heroes.push(this.sessions);
    }

    this.sub_home_content_show = this.homeContentService.showSubject.subscribe(data => {
      if(!data){
        return;
      }

      // Inserts current Show (if not Non-Stop...)
      if (!this.show || this.show.id !== data.id) {
        this.show = data;

        if(this.show.presenter_covering){
          this.show.show.presenter = this.show.presenter;
        }
        // console.log('New show:', this.show);
        if(this.show.show.id !== 'non-stop-new-music'){
          let show_hero:Hero = {
            id:this.show.show.id, // the show name 'id'.
            title:this.show.show.name,
            subtitle: this.timeSlipService.offset == 0 ? 'On Air Now - Listen Live' : 'Time-Slipped Playback',
            show_button:true,
            is_radio_button:true,
            image:this.show.show.homepage_image_wide,
            router_link:'/onair'
          }
          if(this.presenter_overrides.includes(this.show.show_id)){
            show_hero.subtitle = this.show.show.presenter;
          }
          // remove previous show hero
          let last_index:number;
          this.heroes.forEach((h, i) => {
            if(h.id === show_hero.id){
              last_index = i;
            }
          });
          if(last_index){
            this.heroes.splice(last_index, 1);
          }
          // Add to first Heroes as first item
          // this.heroes.unshift(show_hero);
          // Add as the second item.. (to avoid potential visual glitch on site load...)
          // this.heroes.splice(1, 0, show_hero);
          // test using first...
          this.heroes.splice(0, 0, show_hero);


          // console.log('new heroes', this.heroes);
        } else {
          // console.log('remove previous show while NSNM is playing.');
          let _index:number;
          this.heroes.forEach((h, i) => {
            if(h.id){
              _index = i;
            }
          });
          if(_index){
            this.heroes.splice(_index, 1);
          }
          //console.log('heroes', this.heroes);
        }
      }
    });
  }

  showHero(){
    // console.log('SHOW HERO: ', this.heroes[this.heroes_index].title, this.heroes[this.heroes_index].image);
    let _hero_image:string = this.heroes[this.heroes_index].image;
    // make sure the image is loaded first to avoid a visual glitch (especially with show images)...
    let img = new Image();
    img.onload = (e ) => {
      // console.log('hero image loaded .. zoom in... ');
      // wait a tiny bit longer..
      setTimeout(() => {
        // zoom in next hero..
        // activates css class animations in template
        this.anim_image_faded_in = true;
        this.anim_title_zoomed_in = true;
        this.startHeroWait(this.heroes[this.heroes_index].custom_delay || this.show_duration_ms);
      }, 250);

    };
    img.src = _hero_image;

  }

  startHeroWait(waiting_time:number){
    // console.log('start waiting... ');
    // wait for duration_ms...
    this.show_duration_timeout = setTimeout( () => {
      this.startNextHero();
    }, waiting_time);

    // fade out the image slightly earlier
    this.fade1_timeout = setTimeout( () => {
      if(!this.is_paused_on_mouseover){
        this.anim_image_faded_in = false;
      }
    }, waiting_time - 100);
  }

  startNextHero(){
    this.is_paused_on_mouseover = false;
    this.anim_title_zoomed_in = false;
    // console.log('anim_title_zoomed_in', this.anim_title_zoomed_in);
    setTimeout( () => {
      this.heroes_index++;
      if(this.heroes_index === this.heroes.length){
        this.heroes_index = 0;
      }
      // will zoom back in
      // console.log('NEXT! ... ');
      this.showHero();
    }, this.stagger_ms);

    this.fade2_timeout = setTimeout(() => {
      this.anim_image_faded_in = false;
    }, this.stagger_ms - 500);
  }

  preLoadImages(index:number = 0){

    let img = new Image();
    img.onload = (e ) => {
      // console.log('image preloaded ', e);
      if(index === this.heroes.length){
        // console.log('all images loaded');
        this.showHero();
        img = null;
        return;
      }
      index++;
      this.preLoadImages(index);
    };
    img.src = this.heroes[0].image;
  }

  ngOnInit(): void {
    if(typeof window !== 'undefined'){
      this.preLoadImages();
    }
  }

  ngOnDestroy():void {
    clearTimeout(this.show_duration_timeout);
    clearTimeout(this.fade1_timeout);
    clearTimeout(this.fade2_timeout);
    if(this.sub_home_content_show){
      this.sub_home_content_show.unsubscribe();
    }
  }

  onMouseOver(): void {
    clearTimeout(this.show_duration_timeout);
    clearTimeout(this.fade1_timeout);
    clearTimeout(this.fade2_timeout);
    this.is_paused_on_mouseover = true;
  }
  onMouseOut(): void {
    if(this.is_paused_on_mouseover){
      this.startHeroWait(this.show_duration_ms - 3000); // start waiting again for a bit less than usual. (calling the next hero now can cause things to get caught at the wrong time and bad things can happen)
    }
  }

}

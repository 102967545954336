// Self-contained audio player for voice clips.

import { Component, EventEmitter, OnInit, Output, PLATFORM_ID, Inject, ElementRef, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { VoiceClip } from '@models/voice-clips';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { DomSanitizer } from "@angular/platform-browser";
import { VoiceClipPlayerService } from '@services/voice-clip-player.service';
// import { PlayerService } from '@modules/_shared/player/player.service';

@Component({
  selector: 'app-voice-clip-player',
  templateUrl: './voice-clip-player.component.html',
  styleUrls: ['./voice-clip-player.component.scss'],
  inputs: ['voice_clip'],
  outputs: ['deletedEvent']
})
export class VoiceClipPlayerComponent implements OnInit {

  // Lets host tune form know which was deleted to update UI.
  @Output() deletedEvent = new EventEmitter<VoiceClip>();

  public voice_clip: VoiceClip;

  // Rendered waveform image colour.
  private waveform_color: string = '#fff';
  
  // Audio player
  public audio_src?: string;
  public audio_progress?: number;
  public audio_duration?: number;
  public audio_position_secs?:number;

  public state_index:number = 0;
  public state_svg_icons = [
    'play',
    'pause',
    'stop',
    'buffering'
  ];
  public state_class = [
    '',
    '',
    '',
    'spinner'
  ];

  public audio!:HTMLAudioElement;

  // position
  @ViewChild('bar', { static: false }) bar: ElementRef;
  // controls
  @ViewChild('controls', { static: false }) controls: ElementRef;

  public is_seeking: boolean = false;
  public slider_dir: number = 0; // 1 or -1 depending on direction of slider while sliding
  public last_slide_val: number | undefined;
  public currentTime: number = 0;
  public status: string = '';
  public playing: boolean = false;
  public paused: boolean = false;
  public loading: boolean = false;

  public is_mobile:boolean = false;
  public is_ios:boolean = false;
  public is_muted:boolean = false;
  public is_mobile_safari: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object, 
    private api: ApiAmazingtunesV2Service, 
    private domSanitizer: DomSanitizer,
    private vcps: VoiceClipPlayerService,
    // private main_player: PlayerService, 
  ) {



  }

  ngOnInit(): void {
    // console.log('VOICE CLIP:', this.voice_clip);
    // console.log('DURATION:', this.voice_clip.attributes.duration_secs);
    /**
     * Note: this.voice_clip.meta.waveform_url is JSON and not an image! 
     */
    if (this.voice_clip?.meta?.waveform_url) {
      this.renderWaveform(this.voice_clip.meta.waveform_url);
    }

    this.vcps.stopAudioSubject.subscribe(id => {
      if(id && id !== '' && id !== this.voice_clip?.id){
        // console.log(this.voice_clip.id,'received stop from:', id);
        this.resetPlayer();
      }
    });

  }

  renderWaveform(waveform_data_url) {

    fetch(waveform_data_url).then((response) => response.json()).then(data => {
      // console.log('waveform JSON data: ', data);
      this.drawTransloaditWaveformPromise(data, this.waveform_color).then(waveform_png => {
        // when using image src 
        // this.voice_clip.meta._rendered_waveform_url = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(waveform_png));
        // when using as background-image
        this.voice_clip.meta._rendered_waveform_url = this.domSanitizer.bypassSecurityTrustStyle(`url("${URL.createObjectURL(waveform_png)}")`);
      })
    });
  }

  deleteClip() {
    if (confirm('Delete this voice clip?')) {
      this.api.deleteVoiceClip(this.voice_clip).subscribe(data => {
        // Let the parent form know ...
        this.deletedEvent.emit(this.voice_clip);
      });
    }
  }

  drawTransloaditWaveformPromise(waveform_data, waveform_color, canvas_width = 1000, canvas_height = 200): Promise<any> {
    // Draws waveform image from JSON data provided by Transloadit.
    return new Promise((resolve, reject) => {
      let canvas_wave = document.createElement('canvas');
      canvas_wave.width = canvas_width;
      canvas_wave.height = canvas_height;
      let wave_ctx = canvas_wave.getContext('2d');
      // draw a centre line
      wave_ctx.beginPath();
      wave_ctx.moveTo(0, canvas_height / 2);
      wave_ctx.lineTo(canvas_width, canvas_height / 2);
      wave_ctx.lineWidth = 2;
      wave_ctx.strokeStyle = '#777';
      wave_ctx.stroke();
      // set waveform bars colour
      wave_ctx.fillStyle = waveform_color;
      const bar_width = canvas_width / waveform_data.length;
      for (let i = 0; i < waveform_data.length; i++) {
        const datum = waveform_data[i];
        wave_ctx.fillRect(i * bar_width, (canvas_height / 2) - ((datum * canvas_height) / 2), bar_width, datum * canvas_height);
      }
      // create a PNG from the canvas
      canvas_wave.toBlob((blob) => {
        // console.log('rendered waveform image blob: ', blob);
        canvas_wave = null;
        wave_ctx = null;
        resolve(blob);
      }, 'image/png');
    });
  }

  seek(evt: any) {
    if(!this.audio){
      return;
    }
    // control element clicked
    const _seek_perc = evt.offsetX / evt.target.clientWidth;
    this.seekAudio( _seek_perc * this.audio_duration, true );
  }

  // Simple(ish!) audio player 

  public toggleAudio(): void {
    if(!this.audio){
      console.log('toggleAudio: no audio');
      return;
    }
    this.audio.paused ? this.audio.play().catch(this.audioError) : this.audio.pause();
  }

  public togglePlay(){
    // console.log('togglePlay', this.voice_clip.id);
    if(!this.voice_clip.meta.audio_url){
      console.log('no audio URL');
      return;
    }
    if(!this.audio){
      this.setupAudio();
      this.setAudioSource(this.voice_clip.meta.audio_url);
    }
    this.toggleAudio();
  }

  setupAudio(){
    // console.log('setupAudio', this.voice_clip.id);
    if (isPlatformBrowser(this.platformId)) {
      // Non-SSR only for audio
      this.audio = new Audio();
      this.removeListeners();
      this.attachListeners();
      this.vcps.sendStop(this.voice_clip.id);
    }
  }

  private attachListeners(): void {
    if(this.audio){
      this.audio.addEventListener('timeupdate', this.calculateTime, false);
      this.audio.addEventListener('playing', this.setPlayerStatus, false);
      this.audio.addEventListener('pause', this.setPlayerStatus, false);
      this.audio.addEventListener('progress', this.calculatePercentLoaded, false);
      this.audio.addEventListener('waiting', this.setPlayerStatus, false);
      this.audio.addEventListener('ended', this.setPlayerStatus, false);
      // this.audio.addEventListener('durationchange', this.durationChange, false);
      this.audio.addEventListener('seeking', this.seekingEvent, false);
      this.audio.addEventListener('seeked', this.seekedEvent, false);
    }
  }

  private removeListeners(): void {
    if(this.audio){
      this.audio.removeEventListener('timeupdate', this.calculateTime, false);
      this.audio.removeEventListener('playing', this.setPlayerStatus, false);
      this.audio.removeEventListener('pause', this.setPlayerStatus, false);
      this.audio.removeEventListener('progress', this.calculatePercentLoaded, false);
      this.audio.removeEventListener('waiting', this.setPlayerStatus, false);
      this.audio.removeEventListener('ended', this.setPlayerStatus, false);
      // this.audio.addEventListener('durationchange', this.durationChange, false);
      this.audio.removeEventListener('seeking', this.seekingEvent, false);
      this.audio.removeEventListener('seeked', this.seekedEvent, false);
    }
  }

  private durationChange = (evt: any) => {
    // console.log('DURCHANGEEVENT', evt);
    if (evt?.path[0]?.duration) {
      // console.log('durationChange: ', evt.path[0].duration);
      this.audio_duration = evt.path[0].duration;
    }
    // console.log('audio duration:', this.audio_duration);
  }

  private setPlayerStatus = (evt:any) => {
    if(!this.audio){
      return;
    }
    // console.log('playerStatus: ', evt.type);
    switch (evt.type) {
      case 'playing':
        this.playing = true;
        this.paused = false;
        this.loading = false;
        this.status = 'playing';
        this.state_index = 1;

        this.bar.nativeElement.style.display = 'block';

        break;
      case 'pause':
        this.playing = false;
        this.paused = true;
        this.loading = false;
        this.status = 'paused';
        this.state_index = 0;
        break;
      case 'waiting':
        this.playing = false;
        this.paused = false;
        this.loading = true;
        this.status = 'loading';
        this.state_index = 3;
        break;
      case 'ended':
        this.playing = false;
        this.paused = false;
        this.loading = false;
        this.status = 'ended';
        this.state_index = 0;
        this.currentTime = 0;
        this.audio_progress = 0;
        this.audio.currentTime = 0;

        this.bar.nativeElement.style.left = '0%';
        this.bar.nativeElement.style.display = 'none';

        break;
      default:
        this.status = 'paused';
        this.state_index = 0;
        break;
    }
  }

  public setAudioSource(src: string, no_bypass?: boolean): void {
    if(!this.audio){
      return;
    }
    this.audio_src = src;
    if(this.voice_clip?.attributes?.duration_secs){
      this.audio_duration = parseFloat(this.voice_clip?.attributes?.duration_secs);
    }    
    // console.log('%csetAudioSource: ', 'color:orange', src);
    this.audio.src = this.audio_src;
    this.audio.load();
  }

  public getAudioSource(): string | undefined{
    if(!this.audio){
      console.log('no audio source');
      return;
    }
    return this.audio.src;
  }

  public isPaused(): boolean {
    if (!this.audio) {
      return false;
    }
    return this.audio.paused;
  }

  public isPlaying(): boolean {
    if (!this.audio) {
      return false;
    }
    return !this.audio.paused;
  }

  public pauseAudio(): void {
    if (!this.audio) {
      return;
    }
    this.audio.pause();
  }

  private seekingEvent = (evt: any) => {
    // console.log('%cSEEKING', 'color:yellow');
    this.is_seeking = true;
  }

  private seekedEvent = (evt: any) => {
    // console.log('%cSEEKED', 'color:lime');
    this.is_seeking = false;
  }

  private calculateTime = (evt: any) => {
    if(!this.audio){
      return;
    }
    this.currentTime = this.audio.currentTime;
    // console.log('calculateTime:',this.duration, this.audio.duration);
    // this.position_secs.next(this.currentTime);
    this.setProgress(this.audio_duration, this.currentTime);
    if (this.audio.duration !== this.audio_duration) {
      this.audio_duration = this.audio.duration;
      // console.log(this.audio_duration);
      // this.duration_secs.next(this.audio_duration);
    }
  }

  public seekAudio(position: number, then_play: boolean = false): void {
    if(!this.audio) return;
    this.audio.currentTime = position;
    if (then_play) {
      this.audio.paused ? this.audio.play().catch(this.audioError) : null;
    }
  }

  private setProgress(duration: number, currentTime: number): void {
    let progress_percent: number = ((100 / duration) * currentTime) || 0;
    this.audio_progress = progress_percent / 100;
    this.bar.nativeElement.style.left = `${progress_percent}%`;
  }

  public resetPlayer(): void {

    if (this.isPlaying()) {
      this.pauseAudio();
    }
    this.setAudioSource('/assets/nowt.mp3'); // loading an 'empty' mp3 will stop the stream
    this.state_index = 0
    this.audio_duration = 0;
    this.playing = false;
    this.paused = false;
    this.status = 'stopped';
    this.currentTime = 0;
    this.audio_progress = 0;
    this.audio = null;
    this.audio_src = null;
    if(this.bar){
      this.bar.nativeElement.style.left = '0%';
      this.bar.nativeElement.style.display = 'none';  
    }
  }

  private calculatePercentLoaded = (evt:any) => {
    if(!this.audio){
      return;
    }
    if (this.audio.duration > 0) {
      for (var i = 0; i < this.audio.buffered.length; i++) {
        if (this.audio.buffered.start(this.audio.buffered.length - 1 - i) < this.audio.currentTime) {
          let percent = (this.audio.buffered.end(this.audio.buffered.length - 1 - i) / this.audio.duration) * 100;
          // console.log('percent loaded: ', percent);
          if(percent === 100){
              // console.log('audio is loaded');
              this.bar.nativeElement.style.display = 'block';
          }
          break;
        }
      }
    }
  }

  public noopClick(event:any){
    event.stopPropagation();
  }

  private audioError(error:any) {
    console.log('audio error: ', error);
  }


}

<div class="latest-sessions-cont">
  <h4>Latest Sessions</h4>

  <div class="latestSessions">

    <div *ngFor="let session of latest_sessions;" [routerLink]="session.link" [title]="session.title.rendered" class="sessionPreview">
      <div  class="img-backdrop-cont">
        <div [lazyImgBg]="session.featured_image_url" class="img-backdrop">
        </div>
      </div>

      <div class="info-cont">
        <div class="session-title" [innerHTML]="session.title.rendered"></div>
        <!-- <div class="session-date" [innerHTML]="session.date | date:'MMMM, YYYY'"></div> -->

      </div>

    </div>

  </div>

</div>


import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { UrlEncodePipe } from '@modules/_shared/ui/pipes/url-encode.pipe';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { Clipboard } from "@angular/cdk/clipboard"
import { SnackbarService } from '@services/snackbar.service';

import { MatDialog } from '@angular/material/dialog';
import { DialogCustomEmbedComponent } from '../dialog-custom-embed/dialog-custom-embed.component';

@Component({
  selector: 'app-share-options',
  templateUrl: './share-options.component.html',
  styleUrls: ['./share-options.component.scss'],
  inputs: ['path', 'title', 'hide_label', 'parent', 'resource']
})
export class ShareOptionsComponent implements OnInit {

  public path: string;
  public title: string;
  public site_url: string;
  public hide_label: boolean; // Optionally hide the 'Share' title at very-small width. Used on VideoPlayerDialog
  public parent:string; // Optional name of parent component. eg: 'profile-home'

  public resource:any; // Will show option to embed resource. Opens customisation dialog.

  public allow_social_sharing:boolean = true;

  constructor(private dialog:MatDialog, private snackbar:SnackbarService, private clipBoard:Clipboard, @Inject(PLATFORM_ID) private platformId: Object, private urlEncode: UrlEncodePipe) {
    this.site_url = environment.site_url;
  }

  ngOnInit(): void {

    if(this.parent === 'profile-home'){
      this.hide_label = true; // Will hide the word 'Share' *only* at bp-x-small
    }

    // check tune resource privacy/unlisted
    if(this.resource && this.resource.type === 'tune'){
      // console.log('resource: ', this.resource);
      if(this.resource._is_unlisted || this.resource.attributes.is_private){
        this.allow_social_sharing = false;
      }

    }

  }

  shareTwitter() {
    if (isPlatformBrowser(this.platformId)) {
      let _url = 'https://twitter.com/intent/tweet?url=' + this.urlEncode.transform(this.site_url + this.path) + '&text=' + this.urlEncode.transform(this.title);
      window.open(_url, '_blank', 'width=400,height=350,left=200,top=200');
    }
  }

  shareFacebook() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('Share url to FB: ', this.urlEncode.transform(this.site_url + this.path));
      let _url = 'https://www.facebook.com/share.php?u=' + this.urlEncode.transform(this.site_url + this.path) + '&title=' + this.urlEncode.transform(this.title);
      window.open(_url, '_blank', 'width=400,height=350,left=200,top=200');
    }
  }

  copyToClipboard(){
    if (isPlatformBrowser(this.platformId)) {
      this.clipBoard.copy(this.site_url + this.path);
      this.snackbar.show('URL copied to clipboard');
    }
  }

  openDialogEmbed(){
    const dialogRef = this.dialog.open(DialogCustomEmbedComponent, {
      width: '600px',
      panelClass: 'dialogDonate',
      data: { resource: this.resource, path:this.path }
    });

  }

}

<form class="search" [class]="addClass" novalidate>

  <div class="container">
    <input [class]="addClass" #searchTerm matInput [placeholder]="placeholder" type="text" name="search" [matAutocomplete]="auto" [formControl]="searchFormCtrl">
    <button [class]="addClass" type="submit" (click)="search(searchTerm.value)">
      <span class="icon material-icons" [class]="addClass">search</span>
    </button>
  </div>
  <mat-autocomplete (optionSelected)="suggestionSelected($event)" [panelWidth]="panelWidth" class="auto-complete" #auto="matAutocomplete">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let option of options" [value]="option.text">
        <span>{{option.text}} <!-- ({{ option.payload._type }}) --></span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>

</form>

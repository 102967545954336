import { Directive, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';

/*
  Lazyload an img source.
  Allows images to have a placeholder image and a 'lazyImgSrc' source which replaces the placeholder when loaded.

  eg (in the template):  <img [lazyImgSrc]="show.square_300_image" src="/assets/placeholders/placeholder-square-300.jpg">
*/

@Directive({
  selector: '[lazyImgSrc]'
})
export class LazyImageSrcDirective implements AfterViewInit, OnChanges {

  @Input('lazyImgSrc') imageSource: string;

  private loading:boolean = false;

  private img:HTMLImageElement;

  constructor(private el: ElementRef) { }

  ngOnChanges(): void {
    // We need this or the directive content doesn't change when the model does. (eg: for now playing tune and current show)
    if(this.imageSource){
      // console.log('LazyImageSrcDirective OnChange: ', this.imageSource);
      if(this.img){
        this.img.onload = (e) => {};
        this.img = null;
      }
      this.loadImage(this.imageSource);
    }
  }

  ngAfterViewInit(): void {
    this.loadImage(this.imageSource);
  }

  private loadImage(url: string){
    if(typeof window === "undefined"){
      // Lazy way of detecting SSR..
      this.el.nativeElement.src = url;
      return;
    }
    if(!url){
      // console.log('no url');
      return;
    }
    this.loading = true;
    // this.el.nativeElement.src = '/assets/placeholders/placeholder-square-dark-300.png';
    // this.el.nativeElement.style.opacity = '0';
    // this.el.nativeElement.style.opacity = 1.0;
    this.img = new Image();
    this.img.onload = (e) => {
      this.loading = false;
      this.el.nativeElement.src = url;
      //this.el.nativeElement.style.opacity = '1';
    };
    this.img.onerror = (e) => {
      this.loading = false;
      // console.log('LazyImgSrc error');
      // Should be no need for this fallback as original src should remain.
      this.el.nativeElement.src = '/assets/placeholders/placeholder-square-300.jpg';
      //this.el.nativeElement.style.opacity = '1';
    };
    this.img.src = url;
  }

}

<mat-card appearance="outlined">
    <mat-selection-list *ngIf="artist_tunes.length > 0" #tunes (selectionChange)="selected($event)">
        <mat-list-option color="primary" *ngFor="let tune of artist_tunes" [value]="tune">
            {{ tune.attributes.name }}
        </mat-list-option>
    </mat-selection-list>
    <div *ngIf="artist_tunes.length === 0">
        <h4>No more tunes available</h4>
        <p>This collection might already contain all your tunes</p>
    </div>
</mat-card>
<br>

<p>Tunes selected: {{selected_tunes.length}}</p>

<div class="btnContainer"><div class="btn yellow" (click)="close()">OK</div></div>

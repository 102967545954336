import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MemberSubscription, FundingSource, SubscriptionPayment } from '@models/subscriptions-v2';
import { SnackbarService } from '@services/snackbar.service';
import { UserService } from '@services/user.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { environment } from '@env/environment';

import { loadStripe } from '@stripe/stripe-js';
let elements;

// Analytics
declare var fbq;
declare var gtag;
declare var _paq;


/**
 * Test cards : 
 * 
 * 4242 4242 4242 4242  exp: any future date:  ccv: any 3 digits
 * 
 * 4000 0027 6000 3184  '' - will always require further authentication and thus redirect the user to /payments/stripe/return 
 * 
 * 
 */


@Component({
  selector: 'app-card-funding-stripe',
  templateUrl: './card-funding-stripe.component.html',
  styleUrls: ['./card-funding-stripe.component.scss'],
  inputs: ['member_subscription', 'parent_dialog_ref'],
  outputs: ['processing']
})
export class CardFundingStripeComponent implements OnInit {

  // MIGRATE TO STRIPE (https://stripe.com/docs/payments/quickstart  https://www.npmjs.com/package/@stripe/stripe-js )

  private gateway: string = 'stripe';

  private stripe_key: string = environment.stripe_key;
  public stripe: any;
  private client_secret?: string;

  public member_subscription: MemberSubscription;
  public funding_source: FundingSource;

  public parent_dialog_ref: any; // Attempt to call the parent component, which is a Material Dialog, to close it.

  // @ViewChild('checkoutPaymentForm') checkoutPaymentForm: ElementRef;
  // @ViewChild('checkoutPayButton') checkoutPayButton: ElementRef;

  @ViewChild('payment_message') payment_message: ElementRef;
  @ViewChild('submit') submit: ElementRef;
  @ViewChild('button_text') button_text: ElementRef;

  @Input() processing: boolean;
  @Output() processingChange = new EventEmitter<boolean>();

  constructor(private userService: UserService, private router: Router, private api_tunes: ApiAmazingtunesV2Service, private snackbar: SnackbarService) {
    //
  }

  closeParentDialog() {
    if (this.parent_dialog_ref) {
      this.parent_dialog_ref.close();
    }
  }

  async ngOnInit(): Promise<any> {
    //
    this.processing = true;
    this.processingChange.emit(this.processing);
  }

  async ngAfterViewInit(): Promise<void> {
    if (!this.member_subscription) {
      console.log('No member subscription provided');
      if (this.parent_dialog_ref) {
        this.parent_dialog_ref.close();
      }
      return;
    }

    console.log('Member subscription:', this.member_subscription);

    this.stripe = await loadStripe(this.stripe_key);
    console.log('stripe:', this.stripe);

    if (this.stripe && this.member_subscription.data.id) {

      this.api_tunes.doStripeFundingSource(this.member_subscription.data.id).subscribe(data => {

        console.log('funding_source Intent', data);

        if (data.data.meta.stripe_client_secret) {

          this.client_secret = data.data.meta.stripe_client_secret
          console.log('intent client secret: ', this.client_secret);

          elements = this.stripe.elements({ theme: 'stripe', clientSecret: this.client_secret });
          console.log('elements', elements);

          const payment = elements.create("payment", { layout: 'tabs' });
          payment.mount("#payment-element");

          this.processing = false;
          this.processingChange.emit(this.processing);

        }
      });
    }
  }

  submitCard(event: any) {
    event.preventDefault()
    this.processing = true;
    this.processingChange.emit(this.processing);

    if (!elements || !this.client_secret) {
      console.log('Error : No elements or client secret', elements, this.client_secret);
      return;
    }

    let confirmOpts = {
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: environment.site_url + '/payments/stripe/funding-return'
      }
    };

    let stripePromise: Promise<any>;

    if (this.client_secret.startsWith('pi_')) {
      console.log('paymentIntent...');
      stripePromise = this.stripe.confirmPayment(confirmOpts);
    } else if (this.client_secret.startsWith('seti_')) {
      console.log('setupIntent...');
      stripePromise = this.stripe.confirmSetup(confirmOpts);
    } else {
      this.snackbar.show('Unexpected Funding Source Error', 'snackbarWarning');
      this.processing = false;
      this.processingChange.emit(this.processing);

      return;
    }

    stripePromise.then(data => {

      console.log('STRIPE', data)

      if (data.error) {
        console.log('Stripe Error', data.error);
        this.snackbar.show(data.error.message, 'snackbarWarning');
        this.processing = false;
        this.processingChange.emit(this.processing);
        return;
      }

      let stripe_intent = data.paymentIntent || data.setupIntent;
      if (stripe_intent) {

        console.log('STRIPE INTENT ID', stripe_intent.id);

        // now..   verifyFundingSource with stripe_intent.id (as the token)
        console.log('Verifying Funding Source: subscription id:', this.member_subscription.data.id, 'stripe intent id:', stripe_intent.id);

        this.api_tunes.verifyFundingSource('stripe', this.member_subscription.data.id, stripe_intent.id).subscribe(data => {

          console.log('Verify Funding Source Response: ', data);

          let _new_funding_source:any = data;

          this.processing = false;
          this.processingChange.emit(this.processing);

         if (data.meta.approval_status === 'declined') {
            console.log('CARD DECLINED... ');
            this.snackbar.show('Card Declined', 'snackbarWarning');
          
          } else {
            // Updates the local user object with new data
            this.api_tunes.verifyUserV2().subscribe(_data => {

              this.parent_dialog_ref.close({ funding_source: _new_funding_source });

              this.router.navigate(['/profile', this.userService.get().attributes.permalink, 'subscription']).then(ok => {
                this.snackbar.show('Thank you. Your Funding Source has been added.');
              });

            });
          }
        });

      } else {
        this.snackbar.show('Error getting funding intent details', 'snackbarWarning');
      }

    }).catch(err => {
      console.log('Error getting confirmation details', err);
      this.snackbar.show('Error getting confirmation details', 'snackbarWarning');
    });

  }

}

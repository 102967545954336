import { Component, OnInit } from '@angular/core';
import { PlayerService } from '@modules/_shared/player/player.service';
import { TuneV2 } from '@models/tune-v2';
import { UserV2 } from '@models/user-v2';
import { PlaylistV2 } from '@models/playlist-v2';

declare var _paq;

@Component({
  selector: 'app-play-button-v2',
  templateUrl: './play-button-v2.component.html',
  styleUrls: ['./play-button-v2.component.scss'],
  inputs: ['playlistHead', 'tune', 'artist', 'playlist', 'index', 'addClass', 'playlist_id', 'unique_id'],
})
export class PlayButtonV2Component implements OnInit {

  public tune: TuneV2;
  public artist: UserV2;
  public playlist: TuneV2[];
  public index: number;
  public addClass: string = ''; // TEST Allow class override via the component container attribute in Recently Played component. Will apply after the mixin.
  public playlistHead: PlaylistV2;

  public tune_id:string;
  public unique_id:string;    // for differentiating play state on activity lists and all lists of tunes.
  public playlist_id:string;  // name|id of list of tunes

  // New custom Material Icons. See app_component.ts for registration and /assets/icons/ for SVG sources
  public svg_icons = {
    play:   'play',
    pause:  'pause',
    stop:   'stop',
    buffering: 'buffering'
  };

  public icon_class = [
    'playButton',         // 0
    'playButton',         // 1
    'playButton',         // 2
    'spinner playButton'  // 3
  ];

  public stateIndex = 0;

  constructor(public player: PlayerService) { }

  playPause() {
    this.player.setPlaylistHeadDataV2({id: null});
    // this.player.unique_id = this.unique_id;
    _paq.push(['trackEvent', 'PlayButtonTune', this.tune.attributes.name + ' - ' + this.tune.artist.attributes.name + ' ('+this.tune.id+')']);
    // console.log('playPause tune', this.tune ,' - playlist:', this.playlistHead);
    this.player.playTune(this.tune, this.index, this.playlist, this.playlistHead);
  }

  ngOnInit(): void {
    // console.log('tune', this.tune);
    // console.log('addClass:', this.addClass);
    this.icon_class = [
      'playButton ' + this.addClass + ' ',         // 0
      'playButton ' + this.addClass + ' ',         // 1
      'playButton ' + this.addClass + ' ',         // 2
      'spinner playButton ' + this.addClass + ' '  // 3
    ];

    if(this.tune._unique_id){
      this.unique_id = this.tune._unique_id;
      // console.log('playbtn for unique_id ', this.unique_id);
    }

  }
}

<!-- <mat-icon role="button" [title]="tune.attributes.is_private ? 'This tune is non-streamable. Only you can stream it on-demand here':''" *ngIf="!tune.attributes.is_private || tune._is_owner || tune._is_manager; else privateTune" -->
  <!-- [title]="tune.attributes.is_private ? 'This tune is non-streamable. Only you can stream it on-demand here':'Play ' + tune.attributes.name + ' by ' + tune.artist.attributes.name" -->

  <mat-icon role="button" [title]="tune.artist.attributes.name + ' - ' + tune.attributes.name" *ngIf="!tune.attributes.is_private || tune._is_owner || tune._is_manager; else privateTune"
  (click)="playPause()"
  id="btn-tune-{{tune.id}}"
  class="playButton"
  [class]="
    (
      (tune.id === player.getCurrentTuneId() && player.unique_id === this.unique_id)
        ? (!player.streaming && player.isLoading(tune.id)
          ? 'spinner' : (player.isPlaying()
            ? 'playing':''))
              : '') +' '+addClass"
  [svgIcon]="
      tune.id === player.getCurrentTuneId() && player.unique_id === this.unique_id
        ?
        (
          (!player.streaming && !player.isLoading(tune.id) && player.isPlaying() && !player.streaming_custom && !player.streaming)
            ? svg_icons.pause : (!player.streaming && player.isLoading(tune.id))
              ? svg_icons.buffering : svg_icons.play
        ) : svg_icons.play">
</mat-icon>
<ng-template #privateTune>
  <mat-icon title="This tune is not available to stream on-demand" [class]="'playButton private ' + addClass" svgIcon="play"></mat-icon>
</ng-template>


<!-- Tune card component : V2 version -->

<div *ngIf="tune" id="tune-{{tune.id}}" class="card" [class]="(tune._is_unlisted ? 'unlisted ':'') + (tune.attributes.is_private ? 'private ' + list_parent : (hide_artist === true ? 'profileTune ' + list_parent : list_parent))">

  <!-- Tune image -->
  <div aria-hidden="true" class="imageCont square">
    <img routerLink="/profile/{{tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" [lazyImgSrc]="tune.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png">
    <!-- Only show on the recently played page -->
    <span *ngIf="url === '/aired'" class="airing" title="{{ tune._last_aired_at | date:'MMM d, y - HH:mm' }}">{{ tune._last_aired_at | date:'HH:mm' }}</span>
    <span *ngIf="url === '/firstspin'" class="airing latest">{{ tune.attributes.first_spin_at | date:'MMM d, y - h:mm a' }}</span>
    <span *ngIf="url === '/latest'" class="airing latest">{{ (tune.attributes.released_at || tune.attributes.uploaded_at) | date:'MMM d, y - h:mm a' }}</span>
  </div>

  <app-play-button-v2 role="button" [attr.aria-label]="!tune._is_unlisted && !tune.attributes.is_private ? 'Play and Pause this tune':''" *ngIf="tune.attributes.is_ready_to_stream;else processing" class="playButton playButtonList" [class]="tune._is_unlisted || (tune.attributes.is_private && (tune._is_owner || tune._is_manager)) ? 'owner-private':''" [unique_id]="tune._unique_id" [playlist]="playlist" [playlistHead]="playlistHead" [index]="index" [tune]="tune"></app-play-button-v2>

  <!-- Show if free download or if we're viewing this card from the purchased user-downloads component - -->
  <div class="buyDownloadContainer" *ngIf="tune.attributes.distribution.preference === 'sell' || tune.attributes.distribution.preference === 'free_download'" [class]="list_parent === 'user-downloads'?'stay-visible':''">
    <!-- Should link directly to the purchase dialog (unless viewed on 'user-downloads'/purchased tune) -->
    <div role="button" (click)="buy()" *ngIf="list_parent !== 'user-downloads' && tune.attributes.distribution.preference === 'sell'" class="button buy" title="Buy this tune"><mat-icon class="icon" inline="true">shopping_cart</mat-icon><span>Buy</span></div>
    <!-- Free download : Due to template required for downloads, we'll just hide it with css if the downloadable tune happens to be set to private/non-streamable -->
    <div #downloadButton role="button" class="button download" [class]="tune.attributes.is_private ? 'private':''" (click)="downloadTune()" *ngIf="(list_parent === 'user-downloads' || tune.attributes.distribution.preference === 'free_download') && !has_downloaded; else downloaded" title="Free Download"><mat-icon class="icon" inline="true">cloud_download</mat-icon></div>
  </div>

  <div class="buttonsSurround">
  	<div class="buttonsInner">
      <app-action-button-like role="button" [title]="tune.meta.liked_by_user ? 'Remove Tune From Likes':'Like Tune'" [resource]="tune" class="interact like" *ngIf="!tune._is_owner && !tune._is_manager; else private;"></app-action-button-like>
      <app-action-button-playlist role="button" title="Add To Playlist" *ngIf="!tune._is_unlisted && !tune._can_first_spin && !tune.attributes.is_private; else privatePlaylist" [tunes]="[tune]" class="interact playlist"></app-action-button-playlist>
      <!-- <app-action-button-firstspin role="button" [tune]="tune" *ngIf="tune._can_first_spin && tune.attributes.is_ready_to_stream && !tune.attributes.is_private" class="interact playlist" title="Promote tune in First Spin"></app-action-button-firstspin> -->
    </div>
  </div>

  <div class="details" >
    <a class="elipsify name" role="link" *ngIf="!hide_artist" routerLink="/profile/{{tune.artist.attributes.permalink}}" title="{{ tune.artist.attributes.name }}" [innerHTML]="tune.artist.attributes.name"></a>
    <a class="elipsify title" role="link" [class]="hide_artist ? 'hidden_name':''" routerLink="/profile/{{tune.artist.attributes.permalink }}/tunes/{{ tune.id }}" [title]="tune.attributes.name" [innerHTML]="tune.attributes.name"></a>
    <div *ngIf="hide_artist" aria-hidden="true" class="stats">
      <div class="private" *ngIf="tune.attributes.is_private" title="This tune is not available for streaming">
        NON-STREAMABLE
      </div>
      <div class="private" *ngIf="tune._is_unlisted" title="This tune is unlisted.">
        UNLISTED TUNE
      </div>

      <div class="label blue x-small" *ngIf="!tune.attributes.is_ready_to_stream && !failed_upload; else failedMessage">
        Still processing ...
      </div>

      <div class="sell" role="button" (click)="buy()" *ngIf="tune.attributes.distribution.preference === 'sell'" title="This tune is for sale">
        <mat-icon inline="true">bookmark_border</mat-icon> <span >{{ tune.attributes.distribution.sale_price_pence / 100 | currency : tune.attributes.distribution.currency }}</span>
      </div>

      <!-- <div class="downloadable" *ngIf="tune.attributes.distribution.preference === 'free_download'" title="This tune is available for Free download">
        <mat-icon inline="true">cloud_download</mat-icon> <span *ngIf="tune._private && tune._private.stats.downloads > 0" >{{ tune._private.stats.downloads }}</span>
      </div> -->

      <!-- Owner/manager Stats -->
      <!--
        Hiding owner stats on lists for now. (Also hidden via scss)

      <div class="owner-stats" *ngIf="tune._stats && tune._stats.airplays.total > 0 && (tune._is_owner || tune._is_manager)" [title]="'Total Radio Airplays' +  (tune._stats.airplays.last_airplay !== null ? ' - Last Airplay: '+(tune._stats.airplays.last_airplay | date:'MMMM d, y - h:mm a'):'') ">
        <mat-icon inline="true">speaker</mat-icon> <span>{{ tune._stats.airplays.total }}</span>
      </div>
      <div class="owner-stats" *ngIf="tune._private && tune._private.stats.plays > 0 && (tune._is_owner || tune._is_manager)" title="Total Tune Plays">
        <mat-icon inline="true">play_arrow</mat-icon> <span>{{ tune._private.stats.plays }}</span>
      </div>
      <div class="owner-stats" *ngIf="tune._private && tune._private.stats.likes > 0 && (tune._is_owner || tune._is_manager)" title="Likes">
        <mat-icon inline="true">favorite</mat-icon> <span>{{ tune._private.stats.likes }}</span>
      </div>
      <div class="owner-stats" *ngIf="tune._private && tune._private.stats.playlistings > 0 && (tune._is_owner || tune._is_manager)" title="Playlistings">
        <mat-icon inline="true">queue_music</mat-icon> <span>{{ tune._private.stats.playlistings }}</span>
      </div> -->

    </div>
  </div>

</div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private tunes -->
<ng-template #private>
  <div *ngIf="tune.artist.id !== userService.getId() && !tune._is_manager; else edit" class="empty" title=""><mat-icon svgIcon="blank"></mat-icon></div>
</ng-template>
<ng-template #privatePlaylist>
  <app-action-button-playlist role="button" title="Add To Playlist" *ngIf="!tune._is_unlisted && (tune._is_owner || tune._is_manager); else blank" [tunes]="[tune]" class="interact playlist"></app-action-button-playlist>
</ng-template>
<ng-template #blank>
  <div class="empty blank"></div>
</ng-template>
<ng-template #edit>
  <app-action-button-edit role="button" class="edit" [routerLink]="['/profile', tune.artist.attributes.permalink, 'tunes', tune.id, 'edit']" title="Edit Tune">edit</app-action-button-edit>
</ng-template>
<ng-template #firstSpin>
  <mat-icon>new_releases</mat-icon>
</ng-template>
<ng-template #processing>
  <div *ngIf="!failed_upload;else failed" class="processingCont" title="This tune is still processing ...">
    <mat-icon class="icon spinner" svgIcon="buffering"></mat-icon>
  </div>
</ng-template>
<ng-template #failed>
  <div role="button" (click)="deleteFailedUpload(tune)" class="processingCont" title="This tune appears to have failed processing. You can safely delete it">
    <mat-icon class="icon warning" color="warn">warning</mat-icon>
  </div>
</ng-template>
<ng-template #old>
  <div role="button" (click)="deleteFailedUpload(tune)" class="processingCont" title="This tune failed to process a long time ago. You can safely delete it.">
    <mat-icon class="icon warning" color="warn">error</mat-icon>
  </div>
</ng-template>
<ng-template #failedMessage>
  <ng-container *ngIf="!tune.attributes.is_ready_to_stream">
    <div role="button" (click)="deleteFailedUpload(tune)" class="label warn x-small deleteTune" *ngIf="!old_upload; else oldMessage">
      The upload failed to process in time. Delete it?
    </div>
  </ng-container>
</ng-template>
<ng-template #oldMessage>
  <div role="button" (click)="deleteFailedUpload(tune)" class="label warn x-small deleteTune">
    The upload failed a long time ago! Delete it.
  </div>
</ng-template>

<ng-template #downloaded>
  <div *ngIf="has_downloaded" class="button download" title="Downloaded"><mat-icon class="icon" inline="true">check_circle</mat-icon></div>
</ng-template>

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Plan, SubscriptionPayment } from '@models/subscriptions-v2';
import { SnackbarService } from '@services/snackbar.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { ApiAmazingtunesV2Service } from '@services/api-amazingtunes-v2.service';
import { UserService } from '@services/user.service';

import { environment } from '@env/environment'


/**
 * Test cards : 
 * 
 * 4242 4242 4242 4242  exp: any future date:  ccv: any 3 digits
 * 
 * 4000 0027 6000 3184  '' - will always require further authentication and thus redirect the user to /payments/stripe/return 
 * 
 * 
 */

// Analytics
declare var fbq;
declare var gtag;
declare var _paq;

import { loadStripe } from '@stripe/stripe-js';
let elements; // Must be out here to work.

@Component({
  selector: 'app-card-payment-stripe',
  templateUrl: './card-payment-stripe.component.html',
  styleUrls: ['./card-payment-stripe.component.scss'],
  inputs: ['selected_plan', 'parent_dialog_ref', 'is_processing'],
  outputs: ['processing']
})
export class CardPaymentStripeComponent {

  private gateway: string = 'stripe';

  private stripe_key: string = environment.stripe_key;
  public stripe: any;
  public elements: any;

  public selected_plan: Plan;
  public subscription_id: string;

  public parent_dialog_ref: any;

  @ViewChild('payment_message') payment_message: ElementRef;
  @ViewChild('submit') submit: ElementRef;
  @ViewChild('button_text') button_text: ElementRef;

  @Input() processing: boolean;
  @Output() processingChange = new EventEmitter<boolean>();

  constructor(private userService: UserService, private router: Router, private api_tunes: ApiAmazingtunesV2Service, private snackbar: SnackbarService) {
    //
  }

  ngOnInit(): void {
    this.processing = true;
    this.processingChange.emit(this.processing);
  }

  async ngAfterViewInit(): Promise<void> {
    // Component *must* have a selected_plan input.
    if (!this.selected_plan) {
      this.router.navigate(['/profile']).then(ok => {
        if (this.parent_dialog_ref) {
          this.parent_dialog_ref.close();
        }
        this.snackbar.show('No Plan Selected');
      });
      return;
    }

    console.log('Card Payment comp: Selected plan: ', this.selected_plan);

    this.stripe = await loadStripe(this.stripe_key);
    console.log('stripe:', this.stripe);

    this.api_tunes.doSubscription(this.selected_plan.id, 'stripe').subscribe(data => {

      console.log('SubscriptionPayment response: ', data);
      this.subscription_id = data.data.id;

      if (data.data?.meta?.stripe_client_secret) {
        const client_secret = data.data.meta.stripe_client_secret;
        
        this.processing = false;
        this.processingChange.emit(this.processing);

        if (client_secret) {
          elements = this.stripe.elements({ theme: 'stripe', clientSecret: client_secret });
          // console.log('elements', elements);
          const payment = elements.create("payment", { layout: 'tabs' });
          payment.mount("#payment-element");

        } else {
          
          console.log('Error : No stripe_client_secret');
          this.snackbar.show('Error : No stripe_client_secret provided', 'snackbarWarning');
        }
      }
    });

  }

  async submitPayment(event: any) {
    event.preventDefault()
    this.processing = true;
    this.processingChange.emit(this.processing);

    console.log('submitting stripe payment...');

    const { error: submitError } = await elements.submit();

    if (submitError) {
      console.log('Payment error', submitError);
      if (submitError.type === "card_error" || submitError.type === "validation_error") {
        this.showMessage(submitError.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }
      return;
    }

    if (elements) {

      let confirmOpts = {
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: environment.site_url + '/payments/stripe/return'
        }
      };

      console.log('confirmPayment options:', confirmOpts);

      this.stripe.confirmPayment(confirmOpts)
        .then((result: any) => {

          if (result.paymentIntent) {
            const _payment_intent = result.paymentIntent;
            console.log('Payment Intent:', _payment_intent);
            // POST to /subscriptions/stripe/verify with token set to _payment_intent.id ('pi_...')
            if (_payment_intent.id) {

              this.verifySubscription(_payment_intent.id)

            } else {
              console.log('Error: No paymentIntent.id');
              this.showMessage('Error: No paymentIntent id');
            }

          } else if (result.error) {
            console.log('Error:', result.error);
            this.showMessage(result.error.message);
          } else {
            this.showMessage('An unexpected error occurred.');
          }
        })
    }
  }

  showMessage(messageText: string) {
    const messageContainer = this.payment_message.nativeElement;
    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;
    
    this.processing = false;
    this.processingChange.emit(this.processing);

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageContainer.textContent = "";
    }, 4000);
  }

  setLoading(isLoading: boolean) {

    this.submit.nativeElement.disabled = isLoading;

    // emits back to the parent dialog component to show indicator
    this.processing = isLoading;
    this.processingChange.emit(this.processing);

    isLoading ? this.button_text.nativeElement.classList.add("hidden") : this.button_text.nativeElement.classList.remove("hidden")

  }

  verifySubscription(_token: string) {
    // _token is Stripe paymentIntent.id 
    console.log('%cverifySubscription ...', 'color:lime;font-size:14px');
    console.log('Stripe Payment token : ', _token);

    this.api_tunes.verifySubscription(_token, this.gateway, this.subscription_id).subscribe((data: SubscriptionPayment) => {
      console.log('%cSTRIPE PAYMENT SUCCESSFUL! ...', 'color:lime;font-size:16px');
      console.log('RESPONSE: ', data);

      const spv: SubscriptionPayment = data;
      // This will update the localStorage user object and should add the new _subscriptions data .
      this.api_tunes.verifyUserV2().subscribe(_data => {
        console.log('PaymentsReturnComponent: New Membership: localuser data: ', this.userService.get());
        // Analytics Events....
        if (typeof fbq !== "undefined") {
          // console.log('PaymentsReturnComponent: Sending FB Pixel Purchase Event: value:', spv.data.attributes.payment_amount, 'currency:', spv.data.attributes.payment_currency);
          fbq('track', 'Purchase', { value: spv.data.attributes.payment_amount, currency: spv.data.attributes.payment_currency });
        }
        // GA
        if (typeof gtag !== "undefined") {
          // console.log('PaymentsReturnComponent: Sending Google Account Paid Event: value:', spv.data.attributes.payment_amount, 'currency:', spv.data.attributes.payment_currency);
          gtag('event', 'Paid', {
            'event_category': 'account',
            'event_label': spv.data.attributes.payment_currency,
            'value': spv.data.attributes.payment_amount * 100 // needs integer
          });
        }
        if (typeof _paq !== "undefined") {
          // console.log('PaymentsReturnComponent: Sending Matomo MembershipPurchase Event: value:', spv.data.attributes.payment_amount, 'currency:', spv.data.attributes.payment_currency);
          _paq.push(['trackEvent', 'MembershipPurchase', spv.data.attributes.payment_currency + ' ' + spv.data.attributes.payment_amount, spv.data.attributes.payment_amount]);
        }

        this.processing = false;
        this.processingChange.emit(this.processing);

        this.parent_dialog_ref.close();

        this.router.navigate(['/profile']).then(ok => {
          this.snackbar.show('Payment successful');
        });
      });
    },
      (error) => {
        console.log('PaymentsReturnComponent: ERROR verifying subscription:', error);

        this.processing = false;
        this.processingChange.emit(this.processing);

        this.snackbar.snackbarRef = this.snackbar.snackBar.open('Error verifying subscription : ' + error.message, 'OK', {
          horizontalPosition: 'end',
          panelClass: 'snackbarWarning',
          verticalPosition: 'bottom' // (no 'middle/center' og page. Top or bottom only)
        });
        this.snackbar.snackbarRef.onAction().subscribe(() => {
          this.parent_dialog_ref.close();
          this.router.navigate(['/profile']);
        });
      });
  }
}

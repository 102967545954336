<div role="button" [attr.aria-label]="post.title.rendered" class="newsItem">
  <img aria-hidden="true" (click)="clicked()" [lazyImgSrc]="post.featured_image_url"
    src="/assets/placeholders/placeholder-square-300.jpg"/>
  <div aria-hidden="true" class="line"></div>
  <div class="info">
    <div class="date" [innerHTML]="post.date | date : 'MMMM d, y'"></div>
    <h6 class="show-name" *ngIf="post.show_name && !hide_show" [innerHTML]="post.show_name"></h6>
    <h5 (click)="clicked()" [innerHTML]="post.title.rendered"></h5>
  </div>
</div>

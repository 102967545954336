<div class="store-card tune">
  <div class="play-button">
    <app-play-button-v2 addClass="searchResultsPlayButton" [tune]="resource"></app-play-button-v2>
  </div>
  <div class="image">
    <img [lazyImgSrc]="resource.attributes.image_urls.medium" src="/assets/placeholders/placeholder-square-dark-300.png"
          [routerLink]="['/profile', resource.artist.attributes.permalink, 'tunes', resource.id]">
  </div>
  <div class="meta">
    <div class="name" [routerLink]="['/profile', resource.artist.attributes.permalink]" [innerHTML]="resource.artist.attributes.name"></div>
    <div class="title" [routerLink]="['/profile', resource.artist.attributes.permalink, 'tunes', resource.id]" [innerHTML]="resource.attributes.name"></div>
    <div class="genre-time" [innerHTML]="resource.primary_genre.name + ' - added ' + resource._created_at_ago"></div>
  </div>
  <div class="buy-price" [title]="'Click here to buy \'' + resource.attributes.name + '\' from ' + resource.artist.attributes.name"  (click)="buy(resource)">
    <div class="buy-now">BUY NOW</div>
    <div class="sale-price" [innerHTML]="resource.attributes.distribution.sale_price_pence / 100 | currency : resource.attributes.distribution.currency"></div>
  </div>
</div>

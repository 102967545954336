<div>
  <div class="header">
    <h6>Add Payment Method</h6>
  </div>

  <mat-dialog-content #dialogContent>
    <div class="fundingCont">

      <div class="instructions">
        Add a payment method for your subscription renewals
      </div>

      <div class="paymentCont">

        <!-- Card funding CHECKOUT -->
        <app-card-funding *ngIf="CARD_PAYMENT_PROVIDER === 'checkout' && accept_card_payments && member_subscription" [member_subscription]="member_subscription" [parent_dialog_ref]="dialogRef" ></app-card-funding>

        <!-- Card funding STRIPE -->
        <app-card-funding-stripe *ngIf="CARD_PAYMENT_PROVIDER === 'stripe' && accept_card_payments && member_subscription" [(processing)]="processing" [member_subscription]="member_subscription"  [parent_dialog_ref]="dialogRef"></app-card-funding-stripe>

        <div *ngIf="member_subscription">
          <div class="instructions">
            <span *ngIf="accept_card_payments"> OR </span>
            CLICK BELOW TO
          </div>
          <div class="btn-buy" (click)="addPayPal()">
            <img title="Please click here to add PayPal as your preferred payment method"
              src="/assets/logos/paypal.svg">
            <div>
              <div>Add PayPal</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="processing" class="underlay">
      <mat-icon id="indicator" svgIcon="buffering" class="spinner" ></mat-icon>
    </div> -->
  </mat-dialog-content>
</div>
import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { TuneV2 } from '@models/tune-v2';
import { UserService } from '@services/user.service';
import { SnackbarService } from '@services/snackbar.service';
import { ApiAmazingtunesV2Service } from '@app/services/api-amazingtunes-v2.service';
import { PlaylistV2 } from '@models/playlist-v2';
// Buy / Download requirements...
import { ApiDownloadService } from '@services/api-download.service';
import { PaymentV2 } from '@app/models/payment-v2';
import { MatDialog } from '@angular/material/dialog';
import { DialogPurchaseComponent } from '@modules/_shared/ui/components/dialog-purchase/dialog-purchase.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';


@Component({
  selector: 'app-chart-entry-card',
  templateUrl: './chart-entry-card.component.html',
  styleUrls: ['./chart-entry-card.component.scss'],
  inputs: ['tune', 'artist', 'index', 'playlist', 'hide_artist', 'position', 'movement', 'playlistHead'],
})
export class ChartEntryCardComponent implements OnInit {

  public math:any = Math;

  public tune: TuneV2;
  public index: number;
  public path: string;
  public playlist: TuneV2[];
  public playlistHead:PlaylistV2;
  public url: string;
  public hide_artist: boolean = false; // Optional
  public position: number; // Only set when used for chart listings.
  public movement: string; // ditto.
  // Icon classnames for chart movements.
  public mat_icon_movement = {
    'up': 'arrow_upward',
    'down': 'arrow_downward',
    'new': 'star',
    'reentry': 'arrow_forward',
    'none': 'unfold_less'
  };

  public movement_title = {
    'up': 'Up',
    'down': 'Down',
    'new': 'New Entry',
    'reentry': 'Re-Entry',
    'none': 'Non-mover'
  };

  public logged_in_user = this.userService.get();
  public failed_upload:boolean; // If the checking has given up.
  public old_upload:boolean; // If the upload was ages ago
  public old_upload_limit_days:number = 1; // If the upload was ages ago
  public is_redirecting:boolean;
  public has_downloaded:boolean;
  public is_downloading:boolean;
  @ViewChild('downloadButton') downloadButton: ElementRef;

  constructor(private renderer:Renderer2, private dialog: MatDialog, private api_dl:ApiDownloadService, public datePipe:DatePipe, private api_tunes:ApiAmazingtunesV2Service, private snackbar: SnackbarService, private router: Router, public userService: UserService) {
    // Detect which route we're on to control display of aired time or chart position.
    this.url = this.router.url;
  }

  ngOnInit(): void {
     // console.log('Chart Entry:', this.tune, this.movement);
  }

  buy() {
    // console.log('Buy: ', this.tune.attributes.name);
    if (!this.tune.attributes.is_ready_to_stream || this.tune.attributes.distribution.preference !== 'sell') {
      this.snackbar.show('This tune is not available for sale.');
      return;
    }
    // open dialog
    if (typeof window === "undefined") {
      return; // SSR
    }
    const dialogRef = this.dialog.open(DialogPurchaseComponent, {
      width: '580px',
      panelClass: 'dialogBuySubscription',
      data: { resource: this.tune },
      scrollStrategy: new NoopScrollStrategy()
    });
    dialogRef.afterClosed().subscribe(data => {
      // console.log('The purchasing dialog was closed: ', data);
      if (data && data.resource) {
        // console.log('Start purchase of ', data.resource);
        this.api_tunes.purchaseResource(data.resource).subscribe((data:PaymentV2) => {
          // console.log('redirect to payment gateway: ', data);
          this.is_redirecting = true; // Show an indicator while we redirect.
          if (data.attributes.payment_approval_url) {
            // console.log('redirect user for payment to : ', data.attributes.payment_approval_url);
            window.open(data.attributes.payment_approval_url, '_self');
            return;
            // On returning from the payment gateway, the user is sent to /payments/:gateway/purchases to complete the transaction.
          }
        },
        (error)=> {
          console.log('Error: ', error);
          this.snackbar.show('There was an error initiating the purchase: '+error.message);
        });
      }
    });
  }

  downloadTune() {
    if(!this.userService.loggedIn()){
      this.snackbar.snackbarRef = this.snackbar.snackBar.open('You need to be logged in to download free tunes.','Log In', {
        duration:3000,
        horizontalPosition:'end',
        verticalPosition:'bottom' // (no 'middle/center' og page. Top or bottom only)
      });
      this.snackbar.snackbarRef.onAction().subscribe(() => {
          if(typeof window !== 'undefined'){
            localStorage.setItem('return_path', '/profile/'+this.tune.artist.attributes.permalink+'/tunes/'+this.tune.id);
          }
          this.router.navigate(['/connect']);
      });
      return;
    }
    if (this.is_downloading || this.has_downloaded) {
      return;
    }
    if (this.tune.attributes.is_private || !this.tune.attributes.is_ready_to_stream || this.tune.attributes.distribution.preference !== 'free_download') {
      this.snackbar.show('This tune is not available for download.');
      return;
    }
    this.api_tunes.downloadTune(this.tune).subscribe((data) => {
      // console.log('Download tune info:', data.meta.download_url);
      if (data.meta && data.meta.download_url) {
        this.renderer.addClass(this.downloadButton.nativeElement, 'downloading');
        this.is_downloading = true;
        this.api_dl.download(data.meta.download_url, this.tune).subscribe(
          (progress_percentage) => {
            // console.log('download progress: ', progress_percentage);
            this.downloadButton.nativeElement.innerHTML = progress_percentage + '%';
          },
          (error) => {
            console.log('Error: ', error);
            this.is_downloading = false;
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.snackbar.show('Error downloading tune: ' + error.message);
          },
          () => {
            console.log('Complete!');
            this.renderer.removeClass(this.downloadButton.nativeElement, 'downloading');
            this.downloadButton.nativeElement.innerHTML = 'done';
            this.is_downloading = false;
            this.has_downloaded = true;
            // do all tunes have .meta now?
            // This will swap the div/buttons
            if (this.tune.meta) {
              this.tune.meta.download_url = data.meta.download_url;
            } else {
              this.tune.meta = { download_url: data.meta.download_url }
            }
          }
        );
      }
    },
    (error) => {
      console.log('Download Error!', error);
      this.snackbar.show(error.message, 'snackbarWarning');
    });
  }



}

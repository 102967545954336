<!-- Featured Playlist Row component -->

<div role="button" [attr.aria-label]="playlist.attributes.name + '. ' + playlist.attributes.tune_count + ' tune' + (playlist.attributes.tune_count === 1 ? '':'s')" class="featured-playlist-cont bg-dark" [class]="list_parent" [routerLink]="['/profile', playlist._curator.attributes.permalink, 'playlists', playlist.id]">

  <div class="title-cont" *ngIf="playlist">
    <div class="title-cont-inner">
      <div class="title" [innerHTML]="playlist.attributes.name"></div>
      <div aria-hidden="true" class="sub-title">
        {{ playlist.attributes.tune_count + ' tune' + (playlist.attributes.tune_count === 1 ? '':'s') }}  <span class="dash">-</span><span class="break"><br></span> duration: {{ (playlist.attributes.duration_secs | secsToTime)  }}
      </div>
    </div>

  </div>

  <div class="image-play-cont">
    <div class="play-btn-cont">
      <app-playlist-play-button-v2 addClass=" featuredPlaylistButton" class="playButton" [playlist_id]="playlist.id" [playlistHead]="playlist" [playlist]="playlist._tunes"></app-playlist-play-button-v2>
    </div>

    <div aria-hidden="true" class="image-outer">
      <div class="image-overlay">
      </div>
      <div class="image-cont" [lazyImgBg]="playlist.attributes.image_urls.medium">
      </div>
    </div>

    <!-- <div class="image-frame">
    </div> -->

  </div>




</div>

<!-- Holds the CSS grid formation when interacton buttons are not displayed in private playlists (if they exist??) -->
<ng-template #empty>
</ng-template>

<ng-template #privatePlaylist>
  <div *ngIf="playlist._curator.id !== userService.getId(); else edit" class="empty" title="private tune"><mat-icon>lock</mat-icon></div>
</ng-template>
<ng-template #edit>
  <app-action-button-edit class="interact edit" [routerLink]="['/profile', playlist._curator.attributes.permalink, 'playlists', playlist.id, 'edit']" title="edit playlist">edit</app-action-button-edit>
</ng-template>

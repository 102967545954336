import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { AutoCompleteResponse, AutoCompleteResponseOptions } from '@models/search';
import { ApiSearchService } from '@services/api-search.service';
// Need to to update the search page form when submitting the menu form *while on* the search page.
import { SearchFormService } from './search-form.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
// The re-usable search form and auto-complete/suggestions.
// This component is declared and exported in the UiModule for re-usability.

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  inputs: ['value', 'panelWidth', 'addClass']
})
export class SearchFormComponent implements OnInit, OnDestroy {

  public panelWidth: string;  // Drop-down panel override. See notes in the .scss file.
  public addClass: string;    // Override the re-usable form style

  @Input() value: string;       // Can be preset by the component input attribute.
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();       // Can be preset by the component input attribute.


  public placeholder: string = 'search';
  public searchFormCtrl = new UntypedFormControl();
  public isLoading = false;
  public errorMsg: string;

  public autoCompleteResponse: AutoCompleteResponse;
  public options: AutoCompleteResponseOptions[] = [];

  public origins:string = 'amazingtunes';
  private origins_sub: Subscription;
  private term_sub: Subscription;


  constructor(private searchFormService: SearchFormService, private router: Router, private api_search: ApiSearchService) {
    if(environment.site_country === 'us'){
      // Add index from wp-api.amazingradio.us
      this.origins += ',wp_amazingradio_us';
    } else {
      // Add index from api.amazingradio.com
      this.origins += ',wp_amazingradio';
    }
  }

  search(term?: string) {
    // The search itself will be performed on the /search/:term page
    // console.log('search for : ', term);

    // doing this should force the search to reload if already on the search page.
    this.router.navigate(['/search']).then(() =>
      this.router.navigate(['/search'], { queryParams: { q: term, p: 1, o:this.searchFormService.origins } }));

  }

  suggestionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log('suggestion selected:  ', event.option.value);
    this.search(event.option.value);
  }

  ngOnInit(): void {
    // Subscribes to the text input value changes.
    this.term_sub = this.searchFormService.formTerm.subscribe(data => {
     // console.log('%cformTerm updated: ', 'color:#ff9900', data);
     this.value = data;
     this.searchFormCtrl.setValue(this.value);
   });

   this.origins_sub = this.searchFormService.originsSubject.subscribe(data => {
     // console.log('%corigins updated: ', 'color:#ff9900', data);
     this.origins = data;
   });

    // console.log('search form addClass: ', this.addClass, this.value); // Style override for form.search element Will only be 'search-page' for now
    this.searchFormCtrl.setValue(this.value); // set by input attribute on results page

    this.searchFormCtrl.valueChanges
      .pipe(
        debounceTime(500), // debounce delay here
        tap(data => {
          this.isLoading = true;
          // console.log('%ctap; ', 'font-size:20px;color:red', data);
        }),
        switchMap(value => this.api_search.suggest(value)) // does the auto-suggest request here after 500ms since last (debounced) keypress.
      ).subscribe(data => {
        // console.log('response: ', data);
        this.options = data;
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.term_sub.unsubscribe();
    this.origins_sub.unsubscribe();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'artistFilter'})
export class ArtistFilterPipe implements PipeTransform {
  
    transform(artists: any[], search: string): any {
      if (!artists) {return [];}
        if (!search || search.length < 3) {return [];}
         let solution = artists.filter(artist => {
            if ( !artist ) {return;}
           return  artist.attributes.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        })
        return solution;
    }
}
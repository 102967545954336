import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secsToTime'
})
export class SecsToTimePipe implements PipeTransform {

  /**
   * Transforms a number of seconds into a time in hours, minutes
   * and seconds.
   *
   * value: no. of seconds (e.g. 202.8 => 3:23)
   */
  transform(value: number): string {

    if(isNaN(value)){
      return '-:--';
    }
    // round milliseconds 
    value = Math.round(value);

    var h = Math.floor(value / 3600);
    var m = Math.floor(value % 3600 / 60);
    var s = Math.floor(value % 3600 % 60);
    return ( h > 0 ? (('' + h).slice(-2) + 'h ') :'') + ((h < 1 && m < 10 ? '':'0') + m).slice(-2) + ":" + ('0' + s).slice(-2);

  }
}

import { Directive, ElementRef, Input, AfterViewInit, Renderer2, OnChanges, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

// A directive to try and load an SVG inline into the directive element (so it can be fully styled as required)...

// interface ImageSource {
//   url:string;
//   target_element_id?:string;
// }

@Directive({
  selector: '[inlineSVGSrc]'
})
export class InlineSVGSrcDirective implements AfterViewInit, OnChanges {

  @Input('inlineSVGSrc') svgSource:string;

  private targetElementId:string = ''; // The element ID that we want to target with the dominant colour.

  constructor(private sanitizer: DomSanitizer, private http:HttpClient, private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(): void {

    // this.el.nativeElement.src = '/assets/placeholders/placeholder-square-dark-300.png';
    // this.el.nativeElement.style.opacity = 0;

  }

  ngAfterViewInit(): void {
    // console.log('inline SVG afterViewIinit, ', this.svgSource);
    if(this.svgSource){
      this.loadSVGSource(this.svgSource);
    }

  }


  loadSVGSource(url:string){

    this.http.get(url, { responseType:'text' }).subscribe((data) => {
      // console.log('SVG SOURCE:', data);
      if(data.includes('<svg ')){
        // console.log('looks like an SVG..');
        this.el.nativeElement.innerHTML = (
            this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(data))
        )
      }
    },
    (error) => {
      console.log('loadSVGSource error', error);
    });

  }


}
